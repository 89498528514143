<template>
  <div class="dsf_nc_xlyquestion">
    <div class="outside">
      <div class="com-title-wrap">
        <div class="com-title-inner">
          <span class="com-title">问卷</span>
        </div>
      </div>
      <div class="content">
        <div class="row" v-for="(item, index) in dataList" :key="index">
          <!-- <div class="row-1">
            <img
              src="../../../assets/styles/img/pcimg/guizhou/nodata.png"
              alt=""
            />
          </div> -->
          <div class="row-2">
            <p class="rowtitle">{{ item["asq_info_listdata.name"] }}</p>
            <p class="time">问卷批次：{{ item["asq_info_listdata.batchName"] }}</p>
            <p class="num">{{ item["asq_info_listdata.submit_num"] }}人 完成了问卷</p>
            <p class="go isgo" @click="joinin(item)">{{ item["asq_info_listdata.isComplete"] ? "已完成" : "去参加" }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[3, 6, 9]"
      :page-size="npageSize"
      layout="total, sizes ,prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default dsf.component({
  name: "DsfNcXlyQuestion",
  ctrlCaption: "训练营详情问卷",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      total: 0,
      npageNum: 1,
      npageSize: 3,
      currentPage: 1,
    };
  },
  created() {
    this.getData();
    this.getData(1);
    this.getState();
  },
  computed: {
    ...mapState(["coursestate"]),
  },
  mounted() {
    if (this.$route.query.bannerId) {
      $(".el-pagination").css("display", "none");
    }
  },
  methods: {
    ...mapActions(["getState"]),
    handleSizeChange(val) {
      this.npageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.npageNum = val;
      this.getData();
    },
    getData(type) {
      let that = this;
      let mainUrl;
      if (type) {
        mainUrl = "/inc/meta/list/count";
      } else {
        mainUrl = "/inc/meta/list/data";
      }
      const params = {
        pageNum: that.npageNum,
        pageSize: that.npageSize,
        query: JSON.stringify({ searchValue: "" }),
        order: JSON.stringify([]),
        filter: JSON.stringify([]),
        namespace: "nc.questionnaire.gzcustom",
        pageName: "asqRelevanceList",
        id: location.hash.split("id=")[1],
        bannerId: this.$route.query.bannerId,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            if (type) {
              that.total = res.data.count;
            } else {
              console.log(res.data);
              that.dataList = res.data;
            }
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    joinin(item) {
      if (location.hash.split("#")[1] == "/pc/nc/page/pc/asq/ztbqsq") {
        // 已报名
        if (this.coursestate) {
          this.goexam(item);
        } else {
          dsf.layer.message("请报名后进入", false);
        }
      } else {
        this.goexam(item);
      }
    },
    goexam(item) {
      let that = this;
      const mainUrl = "/nc/asq/api/getAsqUrl";
      const params = {
        batchId: item["asq_info_listdata.batchId"],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // that.$open({
            //   url: location.origin + res.data,
            //   state: 2,
            // });
            window.open(location.origin + res.data);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
