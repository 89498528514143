<template>
  <div class="ds-control ds-form-item" :class="getCss">
    <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label> -->
    <DsfFieldLabel
      v-if="showLabel"
      :mode="showDataCaptionMode"
      :style="getLabelWidthStyle()"
      :show-icon="showDataCaptionIcon" 
      :trigger="showDataCaptionTrigger"
      :data-caption="dataCapion"
      :is-design="isDesign"
      :owner="_self">
      {{ $t(label) }}
    </DsfFieldLabel>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <el-input v-if="!readOnly" v-model="inputValue.pid" type="text" @change="inputChangeHandler" @blur="inputBlurHandler" />
      <div v-else class="ds-form-readonly">
        <span>{{ inputValue.pid }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfGzTreeInfo",
  mixins: [$mixins.formControl],
  ctrlCaption: "树信息",
  props: {
    caption: {
      type: String,
      default: "treeinfo"
    },
    label: {
      type: String,
      default: "树信息"
    },
    pidExpress: {
      type: String,
      default: ""
    },
    iconExpress: {
      type: String,
      default: ""
    },
    iconMap: {
      type: Array,
      default: () => []
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    forceHidden: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Object],
      default() {
        return {
          pid: "",
          icon: ""
        };
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("tree-info-meta-data");
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (value != undefined) {
          if (typeof value == "string") {
            this.value.pid = value;
          } else {
            this.value = {
              pid: value.pid,
              icon: value.icon
            };
          }
        }
        this.emitValueChange(this.value);
      }
    }
  },
  created() {
    // 监听表单数据提交前 console.log("this", this);
    if (this.pidExpress) {
      this.value.pid = this.pidExpress.getValueString(",");
    }
    if (this.iconExpress) {
      this.value.icon = this.iconExpress.getValueString(",");
    }
  },
  methods: {
    inputChangeHandler() {
      this.$dispatch("change");
      this.emitValueChange(this.value);
    },
    inputBlurHandler() {
      this.$dispatch("blur");
      this.emitValueChange(this.value);
    }
  },
  design: {
    layout: {
      //不做命名验证
      nameValidate: false
    }
  }
});
</script>
