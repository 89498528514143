var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ds-control ds-navtree ds-tree ds-no-padding",class:{ 'tree-loaded': !_vm.loading }},[_c('DsfPanel',{attrs:{"hasHeader":_vm.hasHeader},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]},proxy:true}])},[[_c('div',{staticClass:"searchbox"},[(_vm.openSearchBox)?_c('el-input',{attrs:{"placeholder":"查询（多个关键词空格隔开）"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}):_vm._e(),_vm._t("searchboxSolt")],2),_c('DsfVirtualScroll',{staticClass:"ds-tree-box",attrs:{"scroll-y":"","scroll-x":""}},[_c('el-tree',{ref:"tree",staticClass:"filter-tree ds-tree-select-tree-box",attrs:{"empty-text":_vm.loading ? '数据查询中...' : '暂无数据',"props":_vm.defaultProps,"default-expanded-keys":_vm.expandedKeys,"default-checked-keys":_vm.showCheckbox ? _vm.checkedKeys : [],"lazy":_vm.$async,"load":_vm.lazyLoad,"data":_vm.data,"highlight-current":true,"expand-on-click-node":_vm.showCheckbox ? true : false,"filter-node-method":_vm.filterNode,"show-checkbox":_vm.showCheckbox,"check-strictly":_vm.checkStrictly,"node-key":"_id"},on:{"check-change":_vm.checkChange,"current-change":_vm.currNodeChange,"node-expand":_vm.nodeExpand,"node-collapse":_vm.nodeCollapse},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"custom-tree-node"},[_c('div',{directives:[{name:"context-menu",rawName:"v-context-menu",value:({ params: node, data: _vm.treeMenu }),expression:"{ params: node, data: treeMenu }"}],staticClass:"tree-name",attrs:{"id":`${data._id}_navtree_nodeid`}},[_c('span',[(!_vm.icons)?[(
                      data[_vm.defaultProps.children] !== undefined && _vm.showIcon
                    )?_c('i',{staticClass:"iconfont",class:[
                      node.expanded ? 'icon-dakai' : 'icon-wenjianjia',
                    ]}):(_vm.showIcon)?_c('i',{staticClass:"iconfont icon-shiyongwendang"}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.dsf.safe.xss(data[_vm.defaultProps.label]))}})]:_vm._l((_vm.icons.filter((icon) => {
                      return _vm.getIconClass(icon, node)[0] != '';
                    })),function(icon,iconI){return _c('i',{key:iconI,staticClass:"iconfont",class:_vm.getIconClass(icon, node),style:({ color: icon.color })})}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.dsf.safe.xss(data[_vm.defaultProps.label]))}})],2),_c('DsfButtonBar',{staticClass:"ds-no-padding edit-span",attrs:{"render-button":_vm.renderRowButton,"max-number":_vm.rowButtonsMaxNumber,"more-button-text":_vm.rowButtonsMoreText,"more-button-style":_vm.rowButtonsMoreStyle,"buttons":_vm.getRowButtons(_vm.rowButtons),"emit-prefix":'row-button',"local":data,"size":"small","align":"center"}})],1)])}},{key:"empty",fn:function(){return [(!_vm.loading)?_c('dsf-empty-data'):_vm._e(),(_vm.loading)?_c('span',[_vm._v("数据查询中...")]):_vm._e()]},proxy:true}])})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }