<template>
  <div class="dsf_nc_liveclass">
    <div class="liveclass">
      <div class="title">
        <div class="imgbox">
          <img src="~@/assets/styles/img/pcimg/guizhou/bg/b2-zbkt.png" alt="" />
          <div class="more" @click="more">更多</div>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="godetail(item.nc_live_info_id)">
          <div class="imgbox">
            <img :src="setImg(item.cover)" alt="" />
            <div class="shade">
              <div class="desc">
                <div class="name">
                  {{ item.teacher_text }}
                </div>
                <div class="job">
                  {{ item.job }}
                </div>
              </div>
              <div class="date">
                {{ item.time_sdate }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="info">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgimg">
      <img src="~@/assets/styles/img/pcimg/guizhou/bg/b2-bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzLiveClass",
  ctrlCaption: "直播课堂",
  mixins: [$mixins.control],
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.showList();
  },
  mounted() {},
  methods: {
    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },

    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      } else {
        src = "static/img/project/defaultCardImage.png";
      }
      return src;
    },
    godetail(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`/page.html#/pc/nc/pagelive/liveDetail?id=${id}`);
      } else {
        this.islogin();
      }
    },
    more() {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/ncIndex.html#/pc/nc/pagelive/wonderfulLive`);
      } else {
        this.islogin();
      }
    },
    showList() {
      let that = this;
      const mainUrl = "/nc/gz/home/liveCourse/list";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data.length > 0) {
            that.list = res.data;
          } else if (!res.success) {
            // } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style></style>
