<template>
  <div class="dsf_nc_ztbsearch">
    <div class="search">
      <input class="input" type="text" placeholder="专题班名称" />
      <i class="iconfont icon-sousuo" @click="gosearch"></i>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzZtbSearch",
  ctrlCaption: "贵州专题班搜索",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    getData() {
      let that = this;
      const mainUrl = "";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>