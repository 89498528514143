<template>
  <div class="dsf_nc_classportrait">
    <!-- <div class="portrait_header">
                <ul class="user_info" handler="userInfo">
                  <li>
                    <img
                      src="/dsfa/upload/files/20220527/145923f95aae48f28112f562a95d7818.png"
                      onerror='onerror=null;src="../res/images/head_logo.png"'
                      alt=""
                    />
                    <p><span class="diaglog_show">lv6榜眼</span></p>
                  </li>
                  <li>
                    <p>
                      hi，nc超级管理员
                      <span
                        ><b class="bhidden">叮叮叮</b
                        ><input
                          placeholder="还没有签名"
                          class="choosetxt jschoosetxt"
                          type="text"
                          value="叮叮叮"
                          style="width: 60px"
                        /><i
                          class="layui-icon layui-icon-edit jschangetext"
                          style="
                            font-size: 20px;
                            color: #fff;
                            border-bottom: 1px solid #fff;
                          "
                        ></i>
                      </span>
                    </p>
                    <p>1001012</p>
                  </li>
                  <li>
                    <ul class="annual_report">
                      <li>
                        <p>2022</p>
                        <p>学习年度</p>
                      </li>
                      <li>
                        <p>4/5</p>
                        <p>必修课程</p>
                      </li>
                      <li>
                        <p>58/50</p>
                        <p>总学时</p>
                      </li>
                      <li>
                        <p>3514</p>
                        <p>积分</p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div> -->
    <div class="mine_content">
      <div class="module_wrap" style="width: 965px">
        <!-- <div class="module_head">
                    <img src="~@/assets/styles/img/pcimg/guizhou/title_left.png" alt="" />
                    <h4>画像</h4>
                    <img
                      src="~@/assets/styles/img/pcimg/guizhou/title_right.png"
                      alt=""
                    />
                  </div> -->
        <div class="content_module_wrap">
          <div class="chart_wrap" style="display: flex">
            <div id="portrait_chart" ref="cavans" style="width: 500px; height: 400px"></div>
            <div ref="cavanspie" style="width: 550px; height: 370px; position: absolute; right: -9px"></div>
          </div>
        </div>
      </div>
      <!-- <div class="module_wrap learn_info">
                  <div class="module_head">
                    <img src="~@/assets/styles/img/pcimg/guizhou/title_left.png" alt="" />
                    <h4>学习轨迹</h4>
                    <img
                      src="~@/assets/styles/img/pcimg/guizhou/title_right.png"
                      alt=""
                    />
                  </div>
                  <ul class="learn_list">
                    <li>
                      <img
                        src="~@/assets/styles/img/pcimg/guizhou/icon-mine-kc.png"
                        alt=""
                      />
                      <h6>课程</h6>
                      <p><span>19</span>条</p>
                    </li>
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/kaoshi.png" alt="" />
                      <h6>考试</h6>
                      <p><span>1</span>门</p>
                    </li>
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/renwu.png" alt="" />
                      <h6>评价</h6>
                      <p><span>20</span>门</p>
                    </li>
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/shoucang.png" alt="" />
                      <h6>收藏</h6>
                      <p><span>2</span>个</p>
                    </li>
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/pingjia.png" alt="" />
                      <h6>笔记</h6>
                      <p><span>47</span>条</p>
                    </li>
                  </ul>
                </div> -->
      <!-- <div class="module_wrap results_wrap"></div> -->

      <!-- <div class="module_wrap results_wrap file_wrap">
                  <div class="module_head">
                    <img src="~@/assets/styles/img/pcimg/guizhou/title_left.png" alt="" />
                    <h4>档案</h4>
                    <img
                      src="~@/assets/styles/img/pcimg/guizhou/title_right.png"
                      alt=""
                    />
                  </div>
                  <ul class="file_list">
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/zhengshu.png" alt="" />
                      <h6>证书</h6>
                      <p><span>5</span>个</p>
                    </li>
                    <li>
                      <img src="~@/assets/styles/img/pcimg/guizhou/xuedang.png" alt="" />
                      <h6>学档</h6>
                      <p><span>1</span>个学年</p>
                    </li>
                    <li>
                      <img
                        src="~@/assets/styles/img/pcimg/guizhou/pgbg-icon.png"
                        style="width: 100px; height: 100px"
                        alt=""
                      />
                      <h6>评估报告</h6>
                      <p><span>7</span>份报告</p>
                    </li>
                  </ul>
                </div> -->
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default dsf.component({
  name: "DsfNcGzClassPortrait",
  ctrlCaption: "学员画像",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: {},
      infolist: {},
      infolistMaxValue: 0,
      nameArr: [],
      valueArr: [],
      initinfo: {
        head_logo: require("../../../assets/styles/img/pcimg/guizhou/portrait/head_logo.png"),
      },
      workload: [
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/fwxy.png"),
          unit: "个",
          desc: "管理的专题班",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/jxgg.png"),
          unit: "人",
          desc: "服务的学员",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/pyzy.png"),
          unit: "次",
          desc: "发布的教学公告",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/hdtw.png"),
          unit: "篇",
          desc: "批阅的作业",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/sczl.png"),
          unit: "条",
          desc: "回答的提问",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/wjdc.png"),
          unit: "份",
          desc: "上传的学习资料",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/glztb.png"),
          unit: "份",
          desc: "发起的问卷调查",
        },
      ],
      sportsIcon: {
        xxd: require("../../../assets/styles/img/pcimg/sportsIcon/aicon.png"),
        wcd: require("../../../assets/styles/img/pcimg/sportsIcon/bicon.png"),
        cjd: require("../../../assets/styles/img/pcimg/sportsIcon/cicon.png"),
        gxd: require("../../../assets/styles/img/pcimg/sportsIcon/dicon.png"),
        cyd: require("../../../assets/styles/img/pcimg/sportsIcon/eicon.png"),
      },
      echartsinstance: null,
      piechart: null,

      //   饼
      piechartOption: {},
    };
  },
  created() {
    this.getData();
  },
  computed: {
    maxNumLd() {
      let arr = [];
      arr.push(Number(this.infolist.cjdUser));
      arr.push(Number(this.infolist.cydUser));
      arr.push(Number(this.infolist.gxdUser));
      arr.push(Number(this.infolist.wcdUser));
      arr.push(Number(this.infolist.xxdUser));
      return Math.max(...arr);
    },
  },
  watch: {
    // options() {
    //   this.echartsinstance.setOption(this.options);
    // },
    // piechartOption() {
    //   this.piechart.setOption(this.piechartOption);
    // },
  },
  mounted() {},

  methods: {
    // 雷达
    getEchartData() {
      let that = this;
      const chart = this.$refs.cavans;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        //   雷达
        let option = {
          color: ["#FF6666", "#39A5FE"],
          legend: {
            data: [
              {
                // value: 0,
                name: "我",
              },
            ],
            right: "70px",
            bottom: "80px",
            orient: "vertical",
          },
          radar: {
            indicator: [
              {
                name: "学习度",
                max: that.maxNumLd,
              },
              {
                name: "完成度",
                max: that.maxNumLd,
              },
              {
                name: "成就度",
                max: that.maxNumLd,
              },
              {
                name: "贡献度",
                max: that.maxNumLd,
              },
              {
                name: "参与度",
                max: that.maxNumLd,
              },
            ],
            center: ["44%", "50%"],
            radius: 110,
            startAngle: 90,
            splitNumber: 4,
            name: {
              formatter: function (params) {
                var str = "";
                switch (params) {
                  case "学习度":
                    str = "{d|}\n" + params + "";
                    break;
                  case "完成度":
                    str = "{c|}\n" + params;
                    break;
                  case "成就度":
                    str = "{b|}\n" + params;
                    break;
                  case "贡献度":
                    str = "{a|}\n" + params;
                    break;
                  case "参与度":
                    str = "{e|}\n" + params;
                    break;
                }
                return str;
              },
              lineHeight: 30,
              textStyle: {
                fontSize: "14",
                color: "#333333",
              },
              rich: {
                a: {
                  width: 26,
                  height: 26,
                  align: "center",
                  backgroundColor: {
                    image: that.sportsIcon.xxd,
                  },
                },
                b: {
                  width: 30,
                  height: 30,
                  align: "center",
                  backgroundColor: {
                    image: that.sportsIcon.wcd,
                  },
                },
                c: {
                  width: 30,
                  height: 30,
                  align: "center",
                  backgroundColor: {
                    image: that.sportsIcon.cjd,
                  },
                },
                d: {
                  width: 30,
                  height: 30,
                  align: "center",
                  backgroundColor: {
                    image: that.sportsIcon.gxd,
                  },
                },
                e: {
                  width: 30,
                  height: 30,
                  align: "center",
                  backgroundColor: {
                    image: that.sportsIcon.cyd,
                  },
                },
              },
            },
            splitArea: {
              areaStyle: {
                /*color: ['rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],*/
                color: "#fff",
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 4,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0091FF",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#0091FF",
              },
            },
          },
          series: [
            {
              name: "雷达图",
              type: "radar",
              symbolSize: 0,
              data: [
                {
                  value: [
                    Number(that.infolist.xxdUser) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.xxdUser),
                    Number(that.infolist.wcdUser) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.wcdUser),
                    Number(that.infolist.cjdUser) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.cjdUser),
                    Number(that.infolist.gxdUser) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.gxdUser),
                    Number(that.infolist.cydUser) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.cydUser),
                  ],
                  // value: [Number(that.infolist.xxdAvg), Number(that.infolist.wcdAvg), Number(that.infolist.cjdAvg), Number(that.infolist.gxdAvg), Number(that.infolist.cydAvg)],
                  // value: [10, 30, 60, 90, 150],
                  name: "平均",
                  symbol: "circle",
                  symbolSize: 5,
                  lineStyle: {
                    //   color: "#D73D38",
                  },
                  shadowColor: "#ff6700",
                  areaStyle: {
                    normal: {
                      color: "rgba(255, 102, 102, .4)",
                    },
                  },
                },
                // {
                //   value: [
                //     Number(that.infolist.xxdAvg) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.xxdAvg),
                //     Number(that.infolist.wcdAvg) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.wcdAvg),
                //     Number(that.infolist.cjdAvg) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.cjdAvg),
                //     Number(that.infolist.gxdAvg) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.gxdAvg),
                //     Number(that.infolist.cydAvg) > that.maxNumLd ? that.maxNumLd : Number(that.infolist.cydAvg),
                //   ],
                //   // value: [10, 30, 60, 90, 160],
                //   name: "我",
                //   lineStyle: {
                //     color: "#3B5364",
                //   },
                //   areaStyle: {
                //     normal: {
                //       color: "rgba(57, 165, 254, .4)",
                //     },
                //   },
                // },
              ],
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    // 饼
    getEchartData2() {
      let that = this;
      console.log(that.dataList, "that.dataList.studyTimeCount");
      const chart = this.$refs.cavanspie;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        let option = {
          color: ["#9270CA", "#6DC8EC", "#5B8FF9", "#5AD8A6 ", "#5D7092", "#F6BD16", "#E8684A"],
          // 图例
          legend: {
            data: that.nameArr,
            icon: "circle",
            itemWidth: 10,
            itemHeight: 10,
            orient: "vertical",
            left: "0",
            top: "120",
          },
          series: [
            {
              left: "-20",
              top: "0",
              type: "pie",
              radius: ["0%", "60%"],
              center: ["60%", "60%"],
              label: {
                show: true,
                formatter: function (arg) {
                  return `${arg.name}${arg.value}分钟`;
                },
              },
              data: that.dataList.studyTimeCount,
              itemStyle: {
                borderWidth: 2,
                borderColor: "#f6f6f6",
                emphasis: {
                  itemStyle: {
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(246,246,246,.5)",
                  },
                  borderColor: "#f6f6f6",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },

    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    getData() {
      let that = this;
      const mainUrl = "/inc/nc/zxsd/displaySelfPage";
      that.dsf.http
        .get(mainUrl)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "sss");
            that.dataList = { ...res.data };
            that.infolist = res.data.integral;
            var infolist = [];
            for (const key in that.infolist) {
              infolist.push(that.infolist[key]);
            }
            that.infolistMaxValue = Math.max(...infolist); //求最大值
            console.log(that.infolistMaxValue);
            that.dataList.studyTimeCount.forEach(item => {
              that.nameArr.push(item.name);
              //   that.valueArr.push({
              //     name: `${item.name}:${item.value}`,
              //     value: item.value,
              //   });
            });
            that.dataList.studyTimeCount.forEach(item => {
              //   that.nameArr.push(item.name);
              that.valueArr.push({
                name: `${item.name}:${item.value}`,
                value: item.value,
              });
            });

            that.getEchartData();
            that.getEchartData2();

            console.log(res.data, "datadada");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
