<!--  -->
<template>
  <div class="dsf_userTitle">
    <div class="dsf_userTitle-title">
      <span class="dsf_userTitle-txt">{{ title }}</span>
      <span class="dsf_userTitle-icon pointer" v-if="showMore">
        <!-- <dsf-icon name="gengduo" @click="goDetail"></dsf-icon> -->
        <!-- <span class="more-text" @click="goDetail">更多</span> -->
      </span>
    </div>

    <el-dialog
      :title="'修改' + title"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <!-- <div>11</div> -->
      <iframe
        :src="moreUrl"
        frameborder="0"
        width="100%"
        height="600px"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcUserTitle",
  ctrlCaption: "信息标题",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "个人信息",
    },
    moreUrl: {
      type: String,
      default: "",
    },
    showMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {},
  mounted() {
    // console.log(this.moreUrl, this.showMore);
  },
  methods: {
    goDetail() {
      if (this.moreUrl) {
        // console.log(this.$open);
        this.$open({
          url: this.moreUrl,
          state: 3,
          width: "750px",
          title: "修改信息",
        });
        // this.dialogVisible = true;
        // this.$openDialog({ title: "修改" + this.title, content: "test/iframe.html" });
      }
    },
  },
});
</script>
