import newloginDialog from "./loginDialog";
import newchangePwd from "./changePwd";

import register from "./register";
import newchangePhone from "./changePhone";
import registerModelTwo from "./registerModelTwo";
import selectPlatform from "./selectPlatform";
import registerFormation from "./registerFormation";
import userInvitation from "./userInvitation";
import forgetpwd from "./forgetpwd.vue"
export default [newloginDialog, newchangePwd, newchangePhone, register, registerModelTwo, selectPlatform, registerFormation, userInvitation, forgetpwd];
