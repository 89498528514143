<template>
  <div class="dsf_nc_xlymyreply">
    <div class="breadbarlist">
      <div class="breadbar">
        <div class="home" @click="changeindex">
          <i class="iconfont icon-home"></i> 论坛首页 >
        </div>
        <span class="listtab">我的回复</span>
      </div>
    </div>
    <div class="aboutme">
      <div class="header">
        <div class="title">我的回复</div>
      </div>
      <div class="content" v-if="replyList">
        <div class="noreply">
          <div class="item" v-for="(item, index) in replyList" :key="index">
            <div class="top">
              <div class="imgbox">
                <img :src="setImg(item.photo)" alt="" />
              </div>
              <div class="right">
                <div class="name">
                  {{ item["plr_text"] }}:<span class="replytitle">{{
                    item["comment_content"]
                  }}</span>
                </div>
                <div class="replyrow">
                  <div class="time">{{ item["ds_create_time"] }}</div>
                  <div class="replybtn" @click="deletebtn(item)">删除</div>
                </div>
              </div>
            </div>
            <!-- 帖子不存在 -->
            <template v-if="!item.replyJson.isTrue">
              <div class="bottom">
                <div style="margin-bottom: 10px">该帖子已删除</div>
              </div>
            </template>
            <!-- 帖子存在 -->
            <template v-else>
              <!-- 直接回复帖子 -->
              <div class="bottom" v-if="!item.replyJson.isComment">
                <div class="desctitle ellipsis">
                  {{ item.replyJson.title }}
                </div>
                <div
                  class="descinfo ellipsis"
                  v-html="item.replyJson.content"
                ></div>
                <div class="all_content_info">
                  <div class="all_item_person">
                    <i class="iconfont"></i
                    >{{ item.replyJson["ds_create_user_name"] }}
                    <span class="all_item_date">{{
                      item["ds_create_time"]
                    }}</span>
                  </div>
                  <div class="all_item_do_block">
                    <div class="all_block_item">
                      <i class="icon iconfont"></i
                      ><span class="all_item_num">{{
                        item.replyJson["collect_num"] || 0
                      }}</span>
                    </div>
                    <div class="all_block_item m30">
                      <i class="icon iconfont"></i
                      ><span class="all_item_num">{{
                        item.replyJson["view_num"] || 0
                      }}</span>
                    </div>
                    <div class="all_block_item">
                      <i class="icon iconfont"></i
                      ><span class="all_item_num">{{
                        item.replyJson["reply_num"] || 0
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 回复评论 -->
              <div class="bottom2" v-else>
                <div class="info">
                  <div class="imgbox">
                    <img :src="setImg(item.replyJson.photo)" alt="" />
                  </div>
                  <div class="rightbox">
                    <div class="name">
                      {{ item.replyJson["ds_create_user_name"] }}
                    </div>
                    <div class="time">
                      {{ item.replyJson["ds_create_time"] }}
                    </div>
                  </div>
                </div>
                <div class="title">{{ item.replyJson["comment_content"] }}</div>
              </div>
            </template>
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
          class="all_el-pagination"
        >
        </el-pagination>
      </div>
      <div class="nodatas" v-if="!replyList">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      replyList: [],
      pageNum: 1,
      total: null,
    };
  },
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 2) {
          this.getMyRply();
        }
      },
    },
  },
  created() {},
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getMyRply();
    },
    changeindex() {
      this.$emit("changeindex");
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    // 我的回复
    getMyRply() {
      let that = this;
      const mainUrl = "/nc/class/train/community/queryMyComment";
      const params = {
        classId: this.$route.query.id,
        pageSize: 10,
        pageNum: that.pageNum,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "我的挥发度");
            that.replyList = res.data;
            that.total = res.count;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    //删除评论
    deletebtn(item) {
      let that = this;
      const mainUrl = "/nc/class/train/community/deleteComment";
      let data = {
        commentId: item["comment_id"],
        communityId: item["community_id"],
      };
      const params = {
        ...data,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.getMyRply();
            dsf.layer.message("删除成功");
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
};
</script>

<style></style>
