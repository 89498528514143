<template>
  <div class="long_march_two long_march">
    <template v-if="isDesign">
      <div class="long_march_tab">
        <div class="long_march_tab_content">
          <div class="tab_arrow tab_arrow_left">
            <img class="tab_arrow_img" src="@/assets/styles/img/pcimg/guizhou/longmarch/tab_left.png" alt="">
          </div>
          <div class="tab_content">
            <div class="tab_content_item" v-for="(itemChild, indexChild) in 6" :key="indexChild">
              <span v-if="indexChild == 0" class="tab_item_xian tab_item_xian_left"></span>
              <span :class="['tab_item_text',{tab_item_text_wc: indexChild % 2 == 0}]">江口</span>
              <span :class="['tab_item_xian', {tab_item_xian_right: indexChild == 5}]"></span>
            </div>
          </div>
          <div class="tab_arrow tab_arrow_right">
            <img class="tab_arrow_img" src="@/assets/styles/img/pcimg/guizhou/longmarch/tab_right.png" alt="">
          </div>
        </div>
      </div>
      <div class="long_march_map">
        <img class="map_zs" src="@/assets/styles/img/pcimg/guizhou/longmarch/map_zs_1.png" alt="">
        <img class="map_xian" src="@/assets/styles/img/pcimg/guizhou/longmarch/xian_two.png" alt="">
        <img v-for="(item, index) in 21" key="index" slot="reference" :class="['qz','qz'+index]" src="@/assets/styles/img/pcimg/guizhou/longmarch/qz.png" alt="">
        <img v-for="(item, index) in 10" key="index" slot="reference" :class="['xx','xx'+index]" src="@/assets/styles/img/pcimg/guizhou/longmarch/xx.png" alt="">
        <div slot="reference" class="breviaries">
          <div class="breviaries_item">
            <img class="item_xian" src="@/assets/styles/img/pcimg/guizhou/longmarch/item_one.png" alt="">
            <span>中央红军路线</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- 顶部tab -->
      <div class="long_march_tab">
        <div class="long_march_tab_content">
          <div class="tab_arrow tab_arrow_left">
            <img class="tab_arrow_img" src="@/assets/styles/img/pcimg/guizhou/longmarch/tab_left.png" alt="">
          </div>
          <swiper class="swiperContainer" ref="mySwiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in tabList" :key="index">
              <div class="tab_content">
                <div class="tab_content_item" v-for="(itemChild, indexChild) in item" :key="indexChild" @click="lookInfo(itemChild)">
                  <span v-if="indexChild == 0" class="tab_item_xian tab_item_xian_left"></span>
                  <span :class="['tab_item_text',{small_font: itemChild.name.length > 4} ,{tab_item_text_wc: itemChild.finishValue == 0}]">{{itemChild.name}}</span>
                  <span :class="['tab_item_xian', {tab_item_xian_right: indexChild == item.length - 1}]"></span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="tab_arrow tab_arrow_right">
            <img class="tab_arrow_img" src="@/assets/styles/img/pcimg/guizhou/longmarch/tab_right.png" alt="">
          </div>
          <!-- 返回 -->
          <div class="back_map" @click="$router.back();">
            <img class="back_map_img" src="@/assets/styles/img/pcimg/guizhou/longmarch/fanhui.webp" alt="">
          </div>
        </div>
      </div>
      <div class="long_march_map">
        <!-- 线 -->
        <img class="map_xian" src="@/assets/styles/img/pcimg/guizhou/longmarch/xian_two.png" alt="">
        <!-- 证书 -->
        <div :class="['certificate_content', {map_zs_animation: longMarchInfo.certId}]">
          <img @click="lookCert" v-if="longMarchInfo.certId" class="map_zs map_zs_animation" src="@/assets/styles/img/pcimg/guizhou/longmarch/map_zs_1.png" alt="">
          <img v-else class="map_zs" src="@/assets/styles/img/pcimg/guizhou/longmarch/map_zs_2.png" alt="">
          <span class="zs_title">中央红军路线</span>
        </div>
        <!-- 旗子 -->
        <el-popover v-for="(item, index) in qzList" key="index" popper-class="qz_popover" :visible-arrow="false" placement="bottom-start" :offset="10" trigger="hover">
          <div class="qz_popover_content">
            <div class="qz_popover_content_title">{{ item.name }}</div>
            <div :title="item.content" class="qz_popover_content_content ell3">
              {{ item.content || '暂无简介...' }}
            </div>
          </div>
          <div @click="lookInfo(item)" slot="reference" :class="['qz','qz'+index]">
            <img v-if="item.finishValue == 1" src="@/assets/styles/img/pcimg/guizhou/longmarch/qz.png" alt="">
            <img v-else src="@/assets/styles/img/pcimg/guizhou/longmarch/qz_h.png" alt="">
          </div>
        </el-popover>
        <!-- 星星 -->
        <el-popover v-for="(item, index) in xxList" key="index" popper-class="qz_popover" :visible-arrow="false" placement="bottom-start" :offset="10" trigger="hover">
          <div class="qz_popover_content">
            <div class="qz_popover_content_title">{{ item.name }}</div>
            <div :title="item.content" class="qz_popover_content_content ell3">
              {{ item.content || '暂无简介...' }}
            </div>
          </div>
          <div @click="lookInfo(item)" slot="reference" :class="['xx','xx'+index]">
            <img v-if="item.finishValue == 1" src="@/assets/styles/img/pcimg/guizhou/longmarch/xx.png" alt="">
            <img v-else slot="reference" src="@/assets/styles/img/pcimg/guizhou/longmarch/xx_h.png" alt="">
          </div>
        </el-popover>

        <!-- 底部标识 -->
        <el-popover popper-class="breviaries_popover" placement="top" offset="-60" trigger="hover">
          <div class="breviaries_popover_content">
            <div class="breviaries_content_title">中央红军路线</div>
            <div class="breviaries_content_scroll">
              <div class="breviaries_content_item" v-for="(item, index) in qzList" :key="index">
                <img v-if="item.finishValue == 1" class="breviaries_content_item_qz" src="@/assets/styles/img/pcimg/guizhou/longmarch/qz.png" alt="">
                <img v-else class="breviaries_content_item_qz" src="@/assets/styles/img/pcimg/guizhou/longmarch/qz_h.png" alt="">
                <span @click="lookInfo(item)" class="breviaries_content_item_name">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div slot="reference" class="breviaries">
            <div class="breviaries_item">
              <img class="item_xian" src="@/assets/styles/img/pcimg/guizhou/longmarch/item_two.png" alt="">
              <span>中央红军路线</span>
            </div>
          </div>
        </el-popover>
      </div>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
export default dsf.component({
  name: 'DsfNcMapTwo',
  ctrlCaption: '第二条路线',
  mixins: [$mixins.control],
  props: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.tab_arrow_right',
          prevEl: '.tab_arrow_left'
        }
      },
      qzList: [
        { name: '黎平', code: '557300' },
        { name: '剑河', code: '556400' },
        { name: '镇远', code: '557700' },
        { name: '施秉', code: '556200' },
        { name: '黄平', code: '556100' },
        { name: '瓮安', code: '550400' },
        { name: '湄潭', code: '564100' },
        { name: '绥阳', code: '563300' },
        { name: '娄山关', code: '563299' },
        { name: '仁怀', code: '564500' },
        { name: '二郎滩', code: '564622' },
        { name: '土城', code: '564204' },
        { name: '古蔺', code: '646500' },
        { name: '威信（扎西）', code: '657900' },
        { name: '大方', code: '551600' },
        { name: '纳雍', code: '553300' },
        { name: '贵阳', code: '550000' },
        { name: '惠水', code: '550600' },
        { name: '紫云', code: '550800' },
        { name: '贞丰', code: '562200' },
        { name: '兴仁', code: '562300' }
      ],
      xxList: [
        { name: '元厚', code: '564709' },
        { name: '太平渡', code: '564621' },
        { name: '茅台', code: '564501' },
        { name: '团溪', code: '563316' },
        { name: '回龙场', code: '564409' },
        { name: '江界河', code: '550415' },
        { name: '珠藏', code: '552102' },
        { name: '茶山关', code: '550307' },
        { name: '富禄', code: '545514' },
        { name: '板寨', code: '558403' }
      ],
      tabList: [],
      longMarchInfo: {},
      longMarchId: '017293fafec04e11b44a8e2150cfa2cb'
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  watch: {},
  created() {
    let longMarchId = this.queryString['longMarchId'];
    if (longMarchId) {
      this.longMarchId = longMarchId;
    }
    this.getInfo();
    this.getRegionData();
  },
  mounted() {},
  methods: {
    getRegionData() {
      const dzUrl = '/nc/longmarch/info';
      const loading = dsf.layer.pc.loading();
      this.$http
        .get(
          dzUrl,
          {
            longMarchId: this.longMarchId
          },
          $$webRoot.nc
        )
        .done((res) => {
          if (res.success) {
            /* 匹配棋子 */
            this.qzList = this.qzList.map((qzItem) => {
              for (const item of res.data) {
                if (qzItem.code == item.code) {
                  qzItem = item;
                  break;
                }
              }
              return qzItem;
            });
            /* 匹配星星 */
            this.xxList = this.xxList.map((qzItem) => {
              for (const item of res.data) {
                if (qzItem.code == item.code) {
                  qzItem = item;
                  break;
                }
              }
              return qzItem;
            });

            this.tabList = this.chunkArray(this.qzList, 6);
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    getInfo() {
      const dzUrl = '/nc/longmarch/record';
      const loading = dsf.layer.pc.loading();
      this.$http
        .get(
          dzUrl,
          {
            longMarchId: this.longMarchId
          },
          $$webRoot.nc
        )
        .done((res) => {
          if (res.success) {
            this.longMarchInfo = res.data;
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    lookInfo(item) {
      this.$open({
        url: `#/pc/nc/page/pc/commonpage/classcolumn?id=${item.channel_value}`,
        state: 2
      });
    },
    lookCert() {
      this.$open({
        url: `${this.longMarchInfo.certUrl}`,
        state: 2
      });
    },
    chunkArray(array, chunkSize) {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    }
  }
});
</script>
