<template>
  <div class="dsf_nc_xlymypost">
    <div class="breadbarlist">
      <div class="breadbar">
        <div class="home" @click="changeindex">
          <i class="iconfont icon-home"></i>
          论坛首页 >
        </div>
        <span class="listtab">所有帖子</span>
      </div>
      <div class="postbtn" @click="postbtn">发布帖子</div>
    </div>
    <div class="hotananew">
      <div class="left">
        <div class="all active" @click="allactive = true">全部</div>
      </div>
      <div class="right">
        <div :class="['new', isactive ? 'active' : '']" @click="getnew">
          最新
        </div>
        <div :class="['hot', isactive ? '' : 'active']" @click="gethot">
          最热
        </div>
      </div>
    </div>
    <div class="datalist">
      <div
        class="dataitem"
        v-for="(item, index) in dataList"
        :key="index"
        @click.stop="godetail(item)"
      >
        <div class="titledom">
          <span class="title">{{ item.title }}</span>
          <template v-if="isstudent == 1">
            <span class="close" @click.stop="dombtn(item, 0)">关闭帖子</span>
            <span class="detail" @click.stop="dialogbtn(item)">修改</span>
            <span class="detail" @click.stop="dombtn(item, 1)">删除</span>
          </template>
        </div>
        <!-- <div class="all_content" v-html="item.content"></div> -->
        <img class="topimg" v-if="item.img" :src="setImg(item.img)" alt="" />
        <div class="all_content_info">
          <div class="all_item_person">
            <i class="iconfont"></i>
            {{ item["ds_create_user_name"] }}
            <span class="all_item_date">{{ item["ds_create_time"] }}</span>
          </div>
          <div class="all_item_do_block">
            <div class="all_block_item">
              <i class="iconfont"></i>
              <span class="all_item_num">{{ item["collect_num"] || 0 }}</span>
            </div>
            <div class="all_block_item m30">
              <i class="iconfont"></i>
              <span class="all_item_num">{{ item["view_num"] || 0 }}</span>
            </div>
            <div class="all_block_item">
              <i class="icon iconfont"></i>
              <span class="all_item_num">{{ item["reply_num"] || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="dataList.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        class="all_el-pagination"
      ></el-pagination>
      <!-- 修改帖子 -->
      <!-- <div class="dataitem" v-for="(item, index) in dataList" :key="index">
        <div class="titledom">
          <span class="title"
            >标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题</span
          >
          <span class="detail" @click="true" v-show="true">修改</span>
          <span class="detail" @click="godetail">详情</span>
          <span class="detail" @click="true">删除</span>
        </div>
        <div class="all_content">
          内容 内容 内容 内容内容内容内容内容内容内容内容内容内容内
          内容内容内容内容内容内容内容内容内容内容内
          内容内容内容内容内容内容内容内容内容内容内
          内容内容内容内容内容内容内容内容内容内容内 内容 内容 内容 内容 内容
          内容 内容 内容 内容 内容 内容 内容 内容 内容 内容 内容 内容 内容 内容
          内容
        </div>
        <div class="all_content_info">
          <div class="all_item_person">
            <i class="iconfont"></i>大熊
            <span class="all_item_date">2022-04-15 14:06</span>
          </div>
          <div class="all_item_do_block">
            <div class="all_block_item">
              <i class="iconfont"></i><span class="all_item_num">0</span>
            </div>
            <div class="all_block_item m30">
              <i class="iconfont"></i><span class="all_item_num">0</span>
            </div>
            <div class="all_block_item">
              <i class="icon iconfont"></i><span class="all_item_num">0</span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="nodatas" v-if="!dataList.length">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      isactive: true,
      allactive: true,
      dataList: [],
      total: 0,
      allpageNum: 1,
      allpageSize: 10,
      type: 1,
    };
  },
  props: {
    tabIndex: {
      type: Number,
      default: "",
    },
  },
  created() {
    this.getIsStudent();
  },
  computed: {
    ...mapState(["isstudent"]),
  },
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 5) {
          this.getinitdata(this.allpageNum, this.allpageSize, "", this.type);
        }
      },
    },
  },
  methods: {
    ...mapActions(["getIsStudent"]),
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    // 发布帖子按钮
    postbtn() {
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?class_value=${
            location.hash.split("=")[1]
          }`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  that.postrequest(data);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 发布请求
    postrequest(data) {
      let that = this;
      const mainUrl = "/inc/meta/persistData";
      let namespace = "nc.class.train.community";
      let pageName = "edit";
      let classId = (this.classId = this.$route.query.id);
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        classId,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("发布成功");
            that.getinitdata(that.allpageNum, that.allpageSize, "", that.type);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    changeindex() {
      this.$emit("changeindex");
      this.getinitdata(this.allpageNum, this.allpageSize, "", this.type);
    },
    handleCurrentChange(val) {
      this.allpageNum = val;
      // console.log(`当前页: ${val}`);
      this.getinitdata(this.allpageNum, this.allpageSize, "", this.type);
    },
    closehandleCurrentChange(val) {
      this.allpageSize = val;
      // console.log(`当前页: ${val}`);
      this.getinitdata(this.allpageNum, this.allpageSize, "", this.type);
    },
    godetail(item) {
      eventBus.$emit("tabIndex6", item);
    },
    getinitdata(pageNum, pageSize, query, type) {
      let that = this;
      let classId = this.$route.query.id;
      const mainUrl = "/nc/class/train/community/allList";
      const params = {
        pageNum,
        pageSize,
        query,
        classId,
        type,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, " this.getinitdata(1, 10, , 1)");
            that.dataList = res.data;
            that.total = res.count;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getnew() {
      this.type = 1;
      this.isactive = true;
      this.getinitdata(this.allpageNum, this.allpageSize, "", 1);
    },
    gethot() {
      this.type = 2;
      this.isactive = false;
      this.getinitdata(this.allpageNum, this.allpageSize, "", 2);
    },
    dombtn(item, type) {
      let mainUrl;
      if (type) {
        //删除
        mainUrl = "/nc/class/train/community/deleteRecord";
      } else {
        //关闭
        mainUrl = "/nc/class/train/community/closeRecord";
      }
      let that = this;
      const params = {
        communityId: item.communityId,
      };
      if (type) {
        dsf.layer
          .confirm("确认要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.dsf.http
              .post(mainUrl, params)
              .done(function (res) {
                if (res.success) {
                  that.getinitdata(that.pageNum, that.pageSize, "", that.type);
                  if (type) {
                    //删除
                    dsf.layer.message("删除成功");
                  } else {
                    //关闭
                    dsf.layer.message("关闭成功");
                  }
                } else {
                  that.message({
                    message: res.message || "获取列表数据异常",
                    type: "warning",
                  });
                }
              })
              .error(function (error) {
                // that.dsf.layer.toast("服务器异常", false);
              })
              .always(function () {
                // that.dsf.layer.closeLoading(loader);
              });
          })
          .catch(() => {});
      } else {
        that.dsf.http
          .post(mainUrl, params)
          .done(function (res) {
            if (res.success) {
              that.getinitdata(that.pageNum, that.pageSize, "", that.type);
              if (type) {
                //删除
                dsf.layer.message("删除成功");
              } else {
                //关闭
                dsf.layer.message("关闭成功");
              }
            } else {
              that.message({
                message: res.message || "获取列表数据异常",
                type: "warning",
              });
            }
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            // that.dsf.layer.closeLoading(loader);
          });
      }
    },
    dialogbtn(item) {
      console.log(item, "id");
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?id=${item._id}`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  const mainUrl = "/nc/class/train/community/edit";
                  let datas = {
                    title: data["nc_class_train_community.title"],
                    img: data["nc_class_train_community.img"],
                    content: data["nc_class_train_community.content"],
                  };
                  data = JSON.stringify(datas);
                  // console.log(data, "dadatda");
                  const params = {
                    ...datas,
                    communityId: item._id,
                  };
                  that.dsf.http
                    .post(mainUrl, params)
                    .done(function (res) {
                      if (res.success) {
                        dsf.layer.message("发布成功");
                        that.getinitdata(
                          that.pageNum,
                          that.pageSize,
                          "",
                          that.type
                        );
                      } else {
                        that.message({
                          message: res.message || "获取列表数据异常",
                          type: "warning",
                        });
                      }
                    })
                    .error(function (error) {
                      // that.dsf.layer.toast("服务器异常", false);
                    })
                    .always(function () {
                      // that.dsf.layer.closeLoading(loader);
                    });
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
  },
};
</script>

<style></style>
