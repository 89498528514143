<template>
  <div class="ds-control ds-subtable" :class="[...getCss,{'show-empty-text':showEmptyText}]">
    <div v-if="showLabel || buttonBars.length" class="ds-subtable-tools">
      <dsf-title v-if="showLabel" :value="label" class="ds-no-padding" style="float: left; margin-top: 5px" :font-type="3" :show-weight="true" :show-underline="showLabel && label ? labelShowUnderline : false" :show-color-lump="showLabel && label ? labelShowColorLump : false">
        <slot name="head-left"></slot>
      </dsf-title>
      <div class="head-right-item" style="float: right">
        <div v-if="showSearch" class="head-right-item-search" style="margin-left: 10px">
          <el-input v-model="searchStr" :placeholder="searchPlaceholder" class="search-input"></el-input>
          <button class="search-button">
            <i class="iconfont icon-fangdajing"></i>
          </button>
        </div>
        <slot name="head-right"></slot>
        <DsfButtonBar
          v-if="buttonBars.length"
          class="ds-no-padding"
          :owner="_self"
          :local="local"
          :buttons="buttonBars"
          :render-button="renderButton"
          :max-number="buttonsMaxNumber"
          :more-button-text="buttonsMoreButonText"
          :more-button-style="buttonsMoreButtonStyle"
          size="small" />
      </div>
    </div>
    <div v-if="isDesign" class="ds-subtable-box">
      <!--设计时子表-->
      <el-table ref="table" :data="designerValue" :row-key="setRowKey" :header-cell-class-name="headerCellClassName" :cell-class-name="cellClassName" :height="tableHeight" :max-height="tableMaxHeight" border>
        <el-table-column v-if="isShowIndexColumn" :key="'indexColumn'" :type="'index'" :label="'序号'" :width="indexColumnWidth" :resizable="false" :header-align="'center'" :align="'center'"></el-table-column>
        <!-- <el-table-column v-if="isShowIndexColumn" :type="'index'" :index="indexMethod" :label="'序号'" :width="80" :header-align="'center'" :align="'center'">
        </el-table-column> -->
        <slot></slot>
        <template v-if="!isReDrawHander">
          <DsfSubTableColumnNew v-for="(col, $index) in columns" ref="header" :key="$index" :column="col">
            <template v-for="column in getLastColumns(col)" v-slot:[column.columnId]>
              <slot :name="column.columnId">
              </slot>
            </template>
          </DsfSubTableColumnNew>
        </template>
        <el-table-column v-if="isShowOperateColumn && !readOnly" :key="'operateColumn'" :resizable="false" :label="'操作'" :width="operateColumnWidth" :header-align="'center'" align="center">
          <template v-slot:default="scope">
            <DsfButton v-if="scope._self.allowEdit && !scope._self.foreverEditor" btn-style="icon-text" size="small" icon="tianxie" title="修改" style="min-width: auto"></DsfButton>
            <DsfButton btn-style="icon-text" size="small" class="ds-subtable-button" icon="shanchu" title="删除" style="min-width: auto"></DsfButton>
            <DsfButton btn-style="icon-text" size="small" class="ds-subtable-button" icon="shang" title="上移" style="min-width: auto"></DsfButton>
            <DsfButton btn-style="icon-text" size="small" class="ds-subtable-button" icon="xia" title="下移" style="min-width: auto"></DsfButton>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="!isDesign" class="ds-subtable-box" @click.stop>
      <el-table
        ref="table"
        :data="list"
        :row-key="setRowKey"
        :row-class-name="setRowClass"
        :height="tableHeight"
        :max-height="tableMaxHeight"
        :highlight-current-row="!!$listeners['current-change']"
        border
        :tree-props="treeProps"
        @focus.native="editBlurHandler"
        @row-dblclick="rowDblClickHander($event)"
        @current-change="tableCurrentChange">
        <el-table-column v-if="isShowIndexColumn" :type="'index'" :index="indexMethod" :label="'序号'" :resizable="false" :width="indexColumnWidth" :header-align="'center'" :align="'center'"></el-table-column>
        <slot></slot>
        <template v-if="!isReDrawHander">
          <template v-for="(col, $index) in columns">
            <DsfSubTableColumnNew v-if="renderTableHandler(col) !== false" ref="header" :key="$index" :column="col">
              <template v-for="column in getLastColumns(col)" v-slot:[column.columnId]="scope">
                <slot :name="column.columnId" v-bind="scope"></slot>
              </template>
            </DsfSubTableColumnNew>
          </template>
        </template>
        <el-table-column v-if="isShowOperateColumn && !readOnly" type="" :label="'操作'" :width="operateColumnWidth" :resizable="false" :header-align="'center'" align="center">
          <template v-slot:default="scope">
            <template v-if="($isLeastOne)||!scope.row.$editing || scope._self.foreverEditor">
              <DsfButton
                v-if="scope._self.allowEdit && !scope._self.foreverEditor && renderRowButton('edit', scope.row)!==false"
                class="ds-subtable-button edit-button"
                :class="['edit-button-' + scope.row.$hash]"
                btn-style="icon-text"
                size="small"
                icon="tianxie"
                title="修改"
                style="min-width: auto"
                @click="rowEditHandler(scope)"></DsfButton>
              <DsfButton
                v-if="renderRowButton('delete', scope.row)!==false"
                btn-style="icon-text"
                size="small"
                class="ds-subtable-button"
                icon="shanchu"
                :class="['delete-button-' + scope.row.$hash]"
                title="删除"
                style="min-width: auto"
                v-show="!(isLeastOne&&scope.row.$index==0&&value.length==1)"
                @click="rowDeleteHandler(scope)"></DsfButton>
              <DsfButton
                v-if="renderRowButton('up', scope.row)!==false"
                btn-style="icon-text"
                size="small"
                class="ds-subtable-button"
                icon="shang"
                title="上移"
                :class="['up-button-' + scope.row.$hash]"
                style="min-width: auto"
                @click="rowUpHandler(scope)"></DsfButton>
              <DsfButton
                v-if="renderRowButton('down', scope.row)!==false"
                btn-style="icon-text"
                size="small"
                class="ds-subtable-button"
                icon="xia"
                title="下移"
                :class="['down-button-' + scope.row.$hash]"
                style="min-width: auto"
                @click="rowDownHandler(scope)"></DsfButton>
            </template>
            <template v-else>
              <template v-if="!scope.row.$leastrow">
                <DsfButton btn-style="icon-text" size="small" class="ds-subtable-button" icon="dui" title="确定" style="padding: 8px; min-width: auto" @click="rowEditCompleteHandler(scope)"></DsfButton>
                <DsfButton btn-style="icon-text" size="small" class="ds-subtable-button" icon="cancel" title="撤销" style="padding: 8px; min-width: auto" @click="rowEditCancelHandler(scope)"></DsfButton>
              </template>
            </template>
          </template>
        </el-table-column>
        <template v-slot:empty>
          <slot>
            <dsf-empty-data v-show="showEmptyText"></dsf-empty-data>
            <!-- <div v-show="showEmptyText" class="ds-empty-data" text="暂无数据"></div> -->
          </slot>
        </template>
      </el-table>
    </div>
    <!--设计状态下分页-->
    <div v-if="showPagination && isDesign" class="ds-subtable-footer">
      <el-pagination :hide-on-single-page="false" :total="1" background :layout="'total,prev,pager,next,jumper'"></el-pagination>
    </div>
    <!--运行状态下分页-->
    <div v-else-if="showPagination && !isDesign" class="ds-subtable-footer">
      <el-pagination
        ref="pagination"
        :hide-on-single-page="true"
        :current-page="currentPage"
        :page-count="pageCount"
        :page-size="pageSize"
        :total="allList.length"
        :pager-count="pagerCount"
        background
        :layout="'total,prev,pager,next,jumper'"
        @current-change="currentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import { time } from "echarts";
import DsfSubTableCellContentNew from "./subTableCellContent";
let indexW = 60;
let operateW = 130;
//注入一个全局的点击事件，结束所有正在编辑的子表
document.addEventListener("click", () => {
  let subTables = document.querySelectorAll(".ds-subtable");
  //取消所有子表编辑
  _.each(subTables, async (it) => {
    if (it && it.__vue__ && it.__vue__.editAllEnd) {
      let subtable = it.__vue__;
      subtable.editAllEnd();
    }
  });
});

const STATE = {
  DEFAULT: "default",
  GENERAL: "general"
};
// const desSettingMenus=dsf.mix(true,[],$mixins.formControl.design.settingMenus||[]);

export default dsf.component({
  name: "DsfSubTableNew",
  mixins: [$mixins.list, $mixins.oneToMany],
  ctrlCaption: "子表-新",
  props: {
    showLabel: {
      type: Boolean,
      default: false
    },
    labelShowUnderline: {
      type: Boolean,
      default: true
    },
    labelShowColorLump: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    isEditabled: {
      type: Boolean,
      default() {
        return true;
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    //是否显示操作列
    isShowOperateColumn: {
      type: Boolean,
      default: true
    },
    //是否显示索引列
    isShowIndexColumn: {
      type: Boolean,
      default: true
    },
    indexColumnWidth: {
      type: Number,
      default: indexW
    },
    operateColumnWidth: {
      type: Number,
      default: operateW
    },
    // 是否强制全表可编辑
    foreverEditor: {
      type: Boolean,
      default: false
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            scope: "scope",
            controls: []
          }
        ];
      }
    },
    //是否允许分页
    showPagination: {
      type: Boolean,
      default: false
    },
    //分页页码按钮最多显示个数
    pagerCount: {
      type: Number,
      default: 5
    },
    //一页显示多少条记录
    pageSize: {
      type: Number,
      default: 15
    },
    buttonBars: {
      type: Array,
      default() {
        return [];
      }
    },
    renderButton: {
      type: Function,
      default: null
    },
    buttonsMoreButonText: {
      type: String,
      default: "更多"
    },
    buttonsMoreButtonStyle: {
      type: String,
      default: ""
    },
    buttonsMaxNumber: {
      type: Number,
      default: 4
    },
    removeRowBefore: {
      type: Function,
      default: function (next) {
        next();
      }
    },
    renderRowButton: {
      type: Function,
      default: dsf.noop
    },
    // 需要隐藏的列
    renderHeader: {
      type: Function,
      default: dsf.noop
    },
    //数据过滤
    filterData: {
      type: Function,
      default: null
    },
    //导出配置
    exportOptions: {
      type: [Object, Array],
      default() {
        return {
          "rule-1": []
        };
      }
    },
    isLeastOne: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: [String, Number],
      required: false
    },
    tableMaxHeight: {
      type: [String, Number],
      required: false
    },
    //是否开启弹框新增
    isUseDialogEdit: {
      type: Boolean,
      default: false
    },
    //弹框地址
    editDialogUrl: {
      type: String,
      default: ""
    },
    //弹框标题
    editDialogTitle: {
      type: String,
      default: ""
    },
    //弹框宽度
    editDialogWidth: {
      type: String,
      default: ""
    },
    //弹框高度
    editDialogHeight: {
      type: String,
      default: ""
    },
    editDialogButtons: {
      type: Array,
      default() {
        return [
          { text: "确定", handler: "editDialogYes", isUser: false },
          { text: "下一条", handler: "editDialogNext", isUser: false },
          { text: "关闭", handler: "", isUser: false }
        ];
      }
    },
    getRowIndex: {
      type: Function,
      default: null
    },
    treeProps: {
      type: Object,
      default() {
        return { children: null, hasChildren: null };
      }
    },
    // 是否显示检索框
    showSearch: {
      type: Boolean,
      default: false
    },
    // 检索框提示文字
    searchPlaceholder: {
      type: String,
      default: "请输入检索关键字"
    },
    // 搜索字段
    searchFields: {
      type: String,
      default: ""
    },
    showEmptyText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReDrawHander: false,
      currentPage: 1,
      drawTable: false,
      editingData: null,
      oldData: {},
      deleted: [],
      dataWatch: [],
      elTable: null,
      // columnMaps: {},
      subControls: {},
      subControlsArray: [],
      templateData: [{ _id: dsf.uuid() }],
      tableWidth: 0,
      pageChanging: false,
      pagereloaded: false,
      currerPageErrors: [],
      validateTempTable: "",
      searchStr: "",
      isEdit: false,
      // leastOneRowDefaultData: {}
    };
  },
  computed: {
    // 监听items数组中每个对象的name属性变化
    editingArr: function() {
      return this.value.map(item => item['$editing']);
    },
    pageCount() {
      return Math.ceil(this.allList.length / this.pageSize);
    },
    designerValue() {
      let dl = this.$isPrototype;
      let sd = this.staticData;
      let td = this.templateData;
      if (dl) {
        return sd || td || [];
      } else {
        return td;
      }
    },
    list() {
      let value = this.allList;
      let showPagination = this.showPagination;
      let pageSize = this.pageSize;
      let currentPage = this.currentPage;
      // if (this.validating) {
      //   return value;
      // }

      // 搜索
      if (!this.filterData && this.showSearch && this.searchStr && this.searchFields) {
        var searchFields = this.searchFields.split(",");
        value = value.filter((item) => {
          let exit = false;
          searchFields.forEach((key) => {
            if (dsf.isObject(item[key])) {
              if (item[key]?.text?.indexOf(this.searchStr) > -1) {
                exit = true;
              }
            } else if (dsf.isString(item[key]) && item[key]?.indexOf(this.searchStr) > -1) {
              exit = true;
            }
          });
          return exit;
        });
      }
      if (showPagination) {
        value = this.$getPageList(value, currentPage, pageSize);
      }
      return value;
    },
    allList() {
      let value = this.value;
      if (this.filterData) {
        value = this.filterData(value);
        // 前端搜索
      }
      return value;
    },
    getColumnSlots() {
      return _.filter(slots, (s) => s.name.indexOf("column-") == 0);
    },
    columnMaps() {
      let arr = {};
      this.deep((c, i, colIndex, level) => {
        if (!arr[level]) {
          arr[level] = [];
        }
        arr[level].push(c);
      }, this.columns);
      return arr;
    },
    allowEdit() {
      let readOnly = this.readOnly;
      let foreverEditor = this.foreverEditor;
      let isEditabled = this.isEditabled;
      if (readOnly) {
        return false;
      } else {
        if (foreverEditor) {
          return true;
        } else {
          return isEditabled;
        }
      }
    },
    metadataDict() {
      let dict = this?.$vm?.metadataDict;
      if (dict) {
        let arr = {};
        _.each(dict, (it, k) => {
          if (it.inSubTable == this.formName) {
            arr[k] = it;
          }
        });
        return arr;
      }
      return {};
    },
    $isLeastOne() {
      return this.isLeastOne && this.value && this.value.length == 1;
    }
  },
  beforeCreate() {
    // this.leastOneRowDefaultData = {};
    this.outputColumns = [];
  },
  created() {
    createDesignDefaultColumns.call(this);
    if (this.isDesign) {
      this.refreshHeader();
    }
    //兼容renderRowButton类型错误问题
    if (dsf.isDef(this.renderRowButton) && !dsf.isFunction(this.renderRowButton)) {
      this.$emit("update:renderRowButton", dsf.noop);
    }
  },
  mounted() {
    this.elTable = this.$refs.table;
  },
  beforeUpdate() {
    this.outputColumns = [];
  },
  updated() {
    this.doLayout();
  },
  methods: {
    timeToXueShi(times) {
        var result = '0.0';
        var minute = 0;
        if (times && times > 0) {
          minute = times;
          if (0 < minute && minute <= 15 * 60) {
            result = "0.25";
          }
          if (15 * 60 < minute && minute <= 30 * 60) {
            result = "0.5";
          }
          if (minute > 30 * 60) {
            result = "1.0";
          }
        }
        return result;
    },
    // 表格行单选抛出事件
    tableCurrentChange(row, oldRow) {
      this.$dispatch("current-change", { row, oldRow });
    },
    getChildErrors() {
      let errorCtrls = this.$el.querySelectorAll(".ds-error");
      let errors = [];
      errorCtrls.forEach((it) => {
        if (it.__vue__ && it.__vue__.isFormControl) {
          if (it.__vue__.errors.length > 0) {
            errors.push(it.__vue__.errors);
          }
        }
      });
      return errors;
    },
    //更新索引
    indexMethod(index) {
      if (this.getRowIndex) {
        return this.getRowIndex(index);
      }
      let vm = this.$vm;
      let idx = index;
      if (this.showPagination) {
        idx = (this.currentPage - 1) * this.pageSize + index + 1;
      } else {
        idx = index + 1;
      }
      // if (this.$isLeastOne) {
      //   return "*";
      // }
      return idx;
    },
    isDefaultRow(row) {
      if (this.isLeastOne && !this.readOnly) {
        return row.$type == STATE.DEFAULT;
      }
      return false;
    },
    /*******对外方法 START********/
    // 追加行
    pushRow(rows, isPageChange, isEdit = true) {
      let arr = [];
      this.isEdit = true;
      if (dsf.type(rows) === "array") {
        arr = rows;
      } else if (dsf.type(rows) === "object") {
        arr = [rows];
      } else {
        dsf.layer.message("子表追加行数据格式错误！", false);
        dsf.error("子表追加行数据格式错误，必须为Object对象");
        return;
      }
      this.value.push(...arr);
      this.$nextTick(() => {
        if (isPageChange) {
          if (this.pageCount != this.currentPage) {
            this.currentPage = this.pageCount;
          }
        }
        isEdit && this.editBegin(_.last(arr));
      });
    },
    // 插入行
    insertRow(row, index, isPageChange, isEdit = true) {
      
      this.value.splice(index, 0, row);
      this.$nextTick(() => {
        if (isPageChange) {
          //获取插入的新行在第几页
          let row = this.value[index];
          let rowIndex = _.findIndex(this.list, (it) => {
            return it == row;
          });
          let num = Math.floor(rowIndex / this.pageSize);
          if (num <= 0) {
            num = 1;
          }
          if (num != this.currentPage) {
            this.currentPage = num;
          }
        }
        isEdit && this.editBegin(row);
      });
    },
    // 删除行
    removeRow(data, isAppendToDeletedRows) {
      this.editAllEnd();
      if (isAppendToDeletedRows) {
        dsf.array.remove(this.value, data);
        this.deleted.push(data);
      } else {
        dsf.array.remove(this.value, data);
      }
      if (!this.list.length && this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // 删除多条数据
    removeRows(rows) {
      _.eachRight(rows, (row) => {
        row._id ? this.removeRow(row, true) : this.removeRow(row, false);
      });
    },
    //清空所有行
    clearRows() {
      _.eachRight(this.value, (row) => {
        row._id ? this.removeRow(row, true) : this.removeRow(row, false);
      });
    },
    /*******对外方法 END********/
    getLastColumns(column) {
      let arr = [];
      this.deep((c) => {
        if (!c.children || !c.children.length) {
          c.isLast = true;
          arr.push(c);
        } else {
          c.columnId = "";
          c.isLast = false;
        }
      }, column);
      return arr;
    },
    deep(callback, column) {
      const fn = function (columns, level) {
        let columnIndex = 0;
        _.each(columns, (c, i) => {
          callback && callback(c, i, columnIndex, level);
          fn(c.children, level + 1);
          columnIndex++;
        });
      };
      let cols = column ? column : this.columns;
      cols = dsf.type(cols) !== "array" ? [cols] : cols;
      fn(cols, 0);
    },
    //分页组件页码变化
    async currentChange(evt) {
      this.currentPage = evt;
    },
    setRowKey(row) {
      return row.$hash;
    },
    setRowClass() {},

    doLayout() {
      this.elTable = this.$refs.table;
      if (this.elTable) {
        this.elTable.doLayout();
      }
    },
    headerCellClassName(info) {
      let className = [];
      if (this.isDesign) {
        let columnIndex = info.columnIndex;
        if (info.rowIndex === 0) {
          if (this.isShowIndexColumn) {
            columnIndex = columnIndex - 1;
          }
        }
        if (this.columnMaps) {
          let row = this.columnMaps[info.rowIndex];
          let col = row[columnIndex];
          if (col && col.hide && col.label == info.column.label) {
            className.push("ds-design-hide");
          }
        }
      }
      return className.join(" ");
    },
    cellClassName(info) {
      let className = [];
      if (this.isDesign) {
        let columnIndex = info.columnIndex;
        let lastColumns = this.getLastColumns();
        if (this.isShowIndexColumn) {
          columnIndex = columnIndex - 1;
        }
        let col = lastColumns[columnIndex];
        if (col && col.hide) {
          className.push("ds-design-hide");
        }
      }
      return className;
    },
    //更新表头和slot
    refreshHeader() {
      let arr = this.getLastColumns();
      let useSlots = {};
      _.each(arr, (c, i) => {
        // let columnId = "table-column-" + i;
        let columnId = c.columnId;
        let slot = _.find(this.slots, (s) => s.name == columnId);
        if (!slot) {
          let newSlot = (slot = {
            name: columnId,
            scope: "scope",
            controls: [
              //此处默认追加一层组件,el-table貌似没有行数据加载过程中的控制事件，所以需要控制单元格中的子元素只能通过追加的一层组件完成
              dsf.designer.createComponent(DsfSubTableCellContentNew, { "slot-name": "default" })
            ]
          });
          this.slots.push(newSlot);
          slot = newSlot;
        } else {
          slot.name = columnId;
        }
        c.columnId = columnId;
        slot.scope = "scope";
        slot.repeat = true;
        slot.textProxy = false;
        slot.dataKey = "row";
        useSlots[slot.name] = 1; //push(slot.name);
      });
      this.slots = this.slots.sort((v1, v2) => {
        let n1 = _.findIndex(arr, (it) => it.columnId == v1.name);
        let n2 = _.findIndex(arr, (it) => it.columnId == v2.name);
        return n1 > n2 ? 1 : -1;
      });
      _.eachRight(this.slots, (s) => {
        if (s.name.indexOf("table-column-") == 0 || s.name.indexOf("column-") == 0) {
          if (!useSlots[s.name]) {
            dsf.array.remove(this.slots, s);
          }
        }
      });
    },
    rowDeleteHandler(scope) {
      let fn = this.removeRowBefore;
      let result = dsf.toPromise(fn, scope.row);
      let deletedRow = () => {
        dsf.layer
          .confirm({
            message: "确认是否要删除？"
          })
          .then(() => {
            this.removeRow(scope.row, true);
            this.$dispatch("remove-row", scope.row);
          })
          .catch((err) => {
            console.error(err);
          });
      };
      result
        .then((res) => {
          if (res === true || dsf.isUnDef(res)) {
            deletedRow.call(this);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    rowEditHandler(scope) {
      if (!this.isUseDialogEdit) {
        this.isEdit = true;
        this.editBegin(scope.row);
      } else {
        this.openDialogEdit({
          row: scope.row
        });
      }
    },
    async rowEditCompleteHandler(scope) {
      this.editAllEnd();
    },
    updateVideoName(key){
      const loading = dsf.layer.loading();
      let row = this.value[key];
      let that = this;
      console.log(row)
      var durationArr = row['nc_courses_info_sub.duration'].split(":");
      let keshi = '0.0';
      if(durationArr.length == 3){
        var durationLong = Number(durationArr[0]) * 60 * 60 + Number(durationArr[1]) * 60 + Number(durationArr[2]);
        keshi = this.timeToXueShi(durationLong);
      }
      this.list[key]['nc_courses_info_sub.keshi'] = keshi;
      let params = {
        namespace: 'nc.courses.courseware',
        pageName: 'edit',
        data: JSON.stringify({"_id": row['nc_courses_info_sub.relation_id'], "nc_courses_courseware.title": row['nc_courses_info_sub.title'], "nc_courses_courseware.duration": row['nc_courses_info_sub.duration'], "nc_courses_courseware.keshi": keshi}),
        attach: {},
      };
      let coursewareType = row['nc_courses_info_sub.r_type'] ? row['nc_courses_info_sub.r_type'].value : 'video';
      this.dsf.http
        .post(`meta/persistData?id=${row['nc_courses_info_sub.relation_id']}&coursewareType=${coursewareType}`, params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          that.isEdit = false;
          dsf.layer.closeLoading(loading);
        });
    },
    rowEditCancelHandler(scope) {
      let row = scope.row;
      let hash = row.$hash;
      let oldData = this.oldData[hash];
      for (let k in scope.row) {
        if (dsf.isDef(oldData[k])) {
          if (k !== "$editing") {
            scope.row[k] = oldData[k];
          }
        } else {
          scope.row[k] = null;
        }
      }
      this.$nextTick(() => {
        this.editAllEnd();
      });
    },
    rowDblClickHander(row) {
      if (!row.$editing) {
        let button = "edit-button-" + row.$hash;
        let buttonEl = this.$el.querySelector("." + button);
        if (buttonEl) {
          buttonEl.click();
        }
        // this.editBegin(row);
      }
    },
    rowUpHandler(scope) {
      let indexInCurrentPage = scope.$index;
      sort.call(this, scope, "up", () => {
        if (indexInCurrentPage == 0 && this.currentPage > 1) {
          this.currentPage--;
        }
      });
      this.$dispatch("order", this.value);
    },
    rowDownHandler(scope) {
      let indexInCurrentPage = scope.$index;
      sort.call(this, scope, "down", () => {
        if (indexInCurrentPage >= this.list.length - 1 && this.currentPage > 1) {
          this.currentPage++;
        }
      });
      this.$dispatch("order", this.value);
    },
    editBegin(row) {
      if (this.allowEdit) {
        if (!this.foreverEditor) {
          this.editAllEnd();
        }
        this.oldData[row.$hash] = dsf.mix(true, {}, row);
        row.$editing = true;
        let subControls = this.getSubControls(row.$hash);
        _.each(subControls, (s) => {
          this.updateSubControls(s.props, s);
        });
      }
    },
    editAllEnd() {
      for (let i = 0; i < this.value.length; i++) {
        let v = this.value[i];
        v.$editing = false;
        if (this.isLeastOne && this.$isLeastOne) {
          if (dsf.isUnDef(v._id)) {
            v.$editing = true;
          } else {
            v.$editing = false;
          }
        }
        let subControls = this.getSubControls(v.$hash);
        _.each(subControls, (s) => {
          this.updateSubControls(s.props, s);
        });
        delete this.oldData[v.$hash];
      }
    },
    updateSubControls(ctrlProps, ctrl) {
      this.addSubControls(ctrl);
      let scope = ctrl.local;
      if (!this.isDesign && scope && ctrlProps.isFormControl) {
        if (ctrlProps.metadata_fullcode) {
          let mcode = ctrlProps.metadata_fullcode;
          let defaultValue = this.getVmDefaultValue(mcode);
          if ((scope[mcode] === undefined) & dsf.isDef(defaultValue)) {
            this.$vm.$set(scope, mcode, defaultValue);
          }
        }
        if (this.$vm && this.$vm?.$setControlPropsByWorkFlow) {
          this.$vm.$setControlPropsByWorkFlow(ctrlProps, "formControl");
        }
        if (dsf.isUnDef(ctrlProps.defaultReadOnly)) {
          ctrlProps.defaultReadOnly = ctrlProps.readOnly;
        }
        if (ctrlProps.isFormControl) {
          if (!this.readOnly && this.foreverEditor) {
            scope.$editing = true;
          }
          if (!ctrlProps.defaultReadOnly) {
            //子表只读，则强制全部只读无视一切状态
            if (this.readOnly) {
              ctrlProps.readOnly = true;
            }
            //this.foreverEditor如果强制可写无视编辑状态
            else if (this.foreverEditor) {
              ctrlProps.readOnly = false;
            } else {
              //否则按编辑状态
              ctrlProps.readOnly = !scope.$editing;
              if (!this.isDefaultRow(scope)) {
                ctrlProps.readOnly = !scope.$editing;
              } else {
                ctrlProps.readOnly = false;
              }
            }
          }
        }
        return ctrlProps;
      }
    },
    getSubControls(hash) {
      let sub = [];
      for (let i = 0; i < this.subControlsArray.length; i++) {
        let key = this.subControlsArray[i];
        if (key.indexOf(hash) > 0) {
          sub.push(this.subControls[key]);
        }
      }
      return sub;
    },
    removeSubControls(ctrl, hash) {
      if (ctrl.designId && (hash || ctrl.local.$hash)) {
        let key = ctrl.designId + "-" + (hash || ctrl.local.$hash);
        if (this.subControls[key]) {
          this.subControls[key] = null;
          delete this.subControls[key];
          dsf.array.remove(this.subControlsArray, key);
        }
      }
    },

    addSubControls(ctrl) {
      if (ctrl.designId && ctrl.local.$hash) {
        let key = ctrl.$designId; // ctrl.designId + "-" + ctrl.local.$hash;
        this.subControls[key] = ctrl;
        dsf.array.ensure(this.subControlsArray, key);
      }
    },
    editBlurHandler() {
      // console.log("dfsfsfsdf")
    },
    renderTableHandler(col) {
      let fn = this.renderHeader || dsf.noop;
      if (col.hide === true) {
        return false;
      }
      let result = fn(col);
      if (result !== false && col.isLast) {
        dsf.array.ensure(this.outputColumns, col);
      }
      return result;
    },
    resize() {
      this.doLayout();
    },
    //检索框查询
    search() {
      if (this.isDesign) {
        return;
      }
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          await this.reloadData(1);
          this.$emit("search");
          resolve();
        }, 200);
      });
    },
    getVmDefaultValue(key) {
      if (this?.$vm) {
        return this?.$vm?.$getDefaultValue(key);
      }
    },
    openDialogEdit(options) {
      let btns = [];
      let _this = this;
      for (let i = 0; i < this.editDialogButtons.length; i++) {
        let it = this.editDialogButtons[i];
        if (!options.isAddNew && it.handler == "editDialogNext") {
          continue;
        }
        btns.push({
          text: it.text,
          async handler(res) {
            if (!it.isUser) {
              if (it.handler) {
                if (it.customerFn) {
                  await it.customerFn(res, options);
                }
                return _this?.[it.handler]?.(res, options);
              }
            } else {
              return _this?.$vm?.[it.handler]?.(res, options);
            }
          }
        });
      }
      let row = dsf.mix(true, {}, options.row);

      for (let k in row) {
        if (k.startsWith("$")) {
          delete row[k];
        }
      }
      this.$openDialog({
        title: this.editDialogTitle,
        width: this.editDialogWidth,
        height: this.editDialogHeight,
        params: {
          path: this.$replace(this.editDialogUrl, row),
          choiceParams: options.row,
          localData: row
        },
        btns: btns
      });
    },
    async editDialogYes(res, options) {
      return await this.editDialogReturnValueHandler(res, options);
    },
    async editDialogNext(res, options) {
      let result = await this.editDialogReturnValueHandler(res, options);
      if (result) {
        this.$nextTick(() => {
          res?.$refs?.view?.$viewDataReset();
        });
      }
      return false;
    },
    async editDialogReturnValueHandler(res, options) {
      let row = options.row;
      let isAddNew = options.isAddNew;
      let firstRowInser = options.firstRowInser;
      let _this = this;
      if (res?.yes) {
        let data = await res.yes();
        if (data) {
          let data_temp = {};
          row = isAddNew ? {} : row;
          for (var k in _this.metadataDict) {
            // var field = dsf.metadata.getField(k);
            if (data[k] !== undefined) {
              _this.$set(row, k, data[k]);
            }
          }
          if (isAddNew) {
            if (firstRowInser === true) {
              this.insertRow(row, 0, true);
            } else {
              this.pushRow(row, true);
            }
          }
          this?.$vm?.resetValidate?.();
        }
        return data;
      }
    }
  },
  beforeDestroy() {
    // if (this.$root.editors) {
    //   dsf.array.remove(this.$root.editors, this);
    // }
  },
  watch: {
    editingArr: {
      handler(newState, oldState) {
        if(!newState.length) return;
        for (const key in newState) {
          let newItem = newState[key];
          let oldItem = oldState[key];
          if(this.isEdit && !newItem && oldItem){
            this.updateVideoName(key); /* 修改课件名称 */
          }
        }
      },
      immediate: false,
    },
    value: {
      handler(v, o) {
        if (v) {
          if (this?.$vm?.$isReady && v.length <= 0) {
            createLeastOneRow.call(this);
            return;
          }
          for (let i = 0; i < v.length; i++) {
            let row = v[i];
            !("$editing" in row) && this.$set(row, "$editing", false);
            !("$hash" in row) && this.$set(row, "$hash", dsf.uuid());
            if ("$order" in row) {
              this.$set(row, "$order", i);
            } else {
              row["$order"] = i;
            }
            row.$index = i;
            //检索行内数据是否设置过默认值
            _.each(this.metadataDict || [], (it, key) => {
              if (dsf.isUnDef(row[key])) {
                let defaultValue = this.getVmDefaultValue(key);
                dsf.isDef(defaultValue) && this.$set(row, key, defaultValue);
              }
            });
          }
          if (this.$isLeastOne && dsf.isUnDef(v[0]._id)) {
            this.editBegin(v[0]);
          }
          if (o && v) {
            this.emitValueChange(v);
          }
        }
      },
      immediate: true,
    },
    columns: {
      handler() {
        this.refreshHeader();
        // this.$refs.table.doLayout();
        this.isReDrawHander = true;
        this.$nextTick(() => {
          this.isReDrawHander = false;
        });
      },
      deep: true
    },
    "elTable.bodyWidth"(v) {
      let wrap = this.elTable.$el.querySelector(".el-table__body-wrapper");
      this.tableWidth = wrap.offsetWidth;
    },
    //监听表单加载完成后，如果数据为0条且配置了默认强制有一条数据时，将强制添加一条记录
    "$vm.$isReady"(v) {
      v && createLeastOneRow.call(this);
    },
    "$system.setting.fontSize": {
      handler(v, ov) {
        let x = ["bigFont_1", "bigFont_2", "bigFont_3"];
        if (x.indexOf(v) >= 0) {
          this.$emit("update:indexColumnWidth", indexW + 20);
          this.$emit("update:operateColumnWidth", operateW + 30);
        } else {
          this.$emit("update:indexColumnWidth", indexW);
          this.$emit("update:operateColumnWidth", operateW);
        }
        if (this.$refs.table) {
          this.$refs.table.doLayout();
        }
      },
      immediate: true
    }
  },
  design: {
    settingButton: true,
    settingMenus: [
      ...($mixins.list.design.settingMenus || []),
      {
        text: "表头设置",
        command: "header-setting",
        handler: headerSetting
      }
    ]
  }
});
function createLeastOneRow() {
  if (!this.isDesign && this.isLeastOne && !this.readOnly) {
    if (!this.isDesign && this.isLeastOne && !this.readOnly) {
      var value = null;
      if (!this.value) {
        value = [];
      } else {
        value = this.value;
      }
      if (value.length <= 0) {
        value.push({});
      }
      this.emitValueChange(value);
    }
  }
}
function createDesignDefaultColumns() {
  if (!this.isDesign) {
    return;
  }
  if (this.columns.length == 0) {
    let size = 4;
    for (let i = 0; i < size; i++) {
      addColumn.call(this, {
        label: `列${i}`,
        level: 0,
        columnId: "table-column-" + dsf.math.numToEnglishLetter(i + 1)
      });
    }
  }
}
//排序
function sort(scope, type, callback) {
  let row = this.list[scope.$index];
  let index = _.findIndex(this.value, (r) => r == row);
  let targetIndex = type == "up" ? index - 1 : index + 1;
  let targetRow = this.value[targetIndex];
  if (targetRow) {
    row.$order = targetIndex;
    targetRow.$order = index;
    this.$set(this.value, targetIndex, row);
    this.$set(this.value, index, targetRow);
    callback && callback(scope);
  }
}
function addColumn(column, index, parentColumn) {
  let defaultColumn = {
    label: "",
    columnId: "",
    level: 0,
    isLast: false,
    hide: false,
    headerAlign: "center",
    align: "center",
    resizable: false,
    width: "auto",
    children: []
  };
  let col = dsf.mix({}, defaultColumn, column);
  if (parentColumn) {
    if (dsf.isUnDef(index)) {
      parentColumn.children.push(col);
      col.level = parentColumn.level + 1;
    } else {
      parentColumn.children.splice(index, 0, col);
    }
  } else {
    dsf.isUnDef(index) ? this.columns.push(col) : this.columns.splice(index, 0, col);
  }
  return col;
}

function headerSetting(item, proxy) {
  dsf.designer.triggerDialogPropty("columns");
}
</script>
