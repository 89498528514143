<template>
  <div class="dsf-selectPlatform-pc-control" :style="controlStyle">
    <div class="dsf-selectPlatform-title">选择平台</div>
    <div class="dsf-selectPlatform-content" v-if="!isAapplying">
      <div class="content-title">请选择创建或加入平台：</div>
      <div class="join-row">
        <div class="col" :class="{ active: joinType == 1 }">
          <el-radio v-model="joinType" label="1">加入平台</el-radio>
          <p class="hint">你的同伴也许正在使用学习邦，赶紧加入平台来一起学习吧！</p>
        </div>
        <div class="col" :class="{ active: joinType == 2 }">
          <el-radio v-model="joinType" label="2">创建平台</el-radio>
          <p class="hint">创建平台组织，作为管理员，邀请团队成员共同学习！</p>
        </div>
        <div class="col" :class="{ active: joinType == 3 }">
          <el-radio v-model="joinType" label="3">试用平台</el-radio>
          <p class="hint">加入平台默认组织，作为平台学员进行学习、了解平台！</p>
        </div>
      </div>
    </div>
    <div class="dsf-selectPlatform-content" v-else>
      <div class="applying">
        <div class="message-box"></div>
        <div class="message-hint">申请提交成功，待审核</div>
        <div class="message-hint2">申请成功/失败将以短信方式通知您，请留意短信信息！</div>
      </div>
    </div>
    <div class="type-content-detail" v-if="!isAapplying">
      <div v-if="joinType == 2" class="creat-platform">
        <div class="content-title">请填写创建的平台资料</div>
        <el-form ref="form" :model="creatForm" label-width="100px" class="creatForm">
          <el-form-item label="平台类型：" required>
            <el-select v-model="creatForm.platformType" placeholder="请选择" class="width-400" @change="handleChangePlatform">
              <el-option :label="item.text" :value="item.value" v-for="(item, idx) in platformTypeList" :key="idx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平台名称：" class="width-500" required>
            <el-input v-model="creatForm.unitname" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="人员规模：">
            <el-select v-model="creatForm.peopleScale" placeholder="请选择" class="width-400" @change="handleChangePeople">
              <el-option :label="item.text" :value="item.value" v-for="(item, idx) in peopleScaleList" :key="idx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="width-500" label="所在地区：">
            <DsfAreaSelect :range="['0', '2']" :showLabel="false" v-model="creatForm.areaselectList" />
          </el-form-item>
          <el-form-item label="详细地址：" class="width-500">
            <el-input v-model="creatForm.address" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="join-platform" v-if="joinType == 1">
        <div class="content-title">请选择要加入的平台</div>
        <div class="search-box">
          <el-input placeholder="请输入搜索内容" v-model="searchText">
            <el-button slot="append" @click="handleSearch">搜索</el-button>
          </el-input>
        </div>
        <div class="platform-row">
          <div class="platform-row-col" v-for="(item, idx) in joinList" :key="idx">
            <el-radio :label="item.unitid" class="platform-radio" v-model="joinPlatform" @change="handleChangeJionPlatfrom"></el-radio>
            <div class="col">
              <!-- <DsfNcDefaultHeader :src="item.pc_logo" width="50px" height="50px" class="col-header"></DsfNcDefaultHeader> -->
              <div
                class="col-header imgType"
                :class="{ imgType1: item.platform_type_value == 0, imgType2: item.platform_type_value == 1, imgType3: item.platform_type_value == 2, imgType4: item.platform_type_value == 3 }"
              ></div>
              <div class="col-positon">
                <span class="name">{{ item.unitname }}</span>
                <span
                  class="type"
                  v-if="item.platform_type_value"
                  :class="{ type1: item.platform_type_value == 0, type2: item.platform_type_value == 1, type3: item.platform_type_value == 2, type4: item.platform_type_value == 3 }"
                >
                  {{ item.platform_type_text }}
                </span>
                <div class="col-address ell">{{ item.area_text }}{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="join-platform" v-if="joinType == 3">
        <div class="content-title">试用平台</div>
        <div class="platform-row">
          <div class="platform-row-col" v-for="(item, idx) in testList" :key="idx">
            <el-radio :label="item.unitid" class="platform-radio" v-model="testPlatform" @change="changeTestList"></el-radio>
            <div class="col">
              <!-- <DsfNcDefaultHeader :src="item.pc_logo" width="50px" height="50px" class="col-header"></DsfNcDefaultHeader> -->
              <div
                class="col-header imgType"
                :class="{ imgType1: item.platform_type_value == 0, imgType2: item.platform_type_value == 1, imgType3: item.platform_type_value == 2, imgType4: item.platform_type_value == 3 }"
              ></div>
              <div class="col-positon">
                <span class="name">{{ item.unitname }}</span>
                <span
                  class="type"
                  v-if="item.platform_type_value"
                  :class="{ type1: item.platform_type_value == 0, type2: item.platform_type_value == 1, type3: item.platform_type_value == 2, type4: item.platform_type_value == 3 }"
                >
                  {{ item.platform_type_text }}
                </span>
                <div class="col-address ell">{{ item.area_text }}{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="type-content-detail applying" v-else>
      <div class="content-title">加入平台</div>
      <div class="join-platform">
        <div class="platform-row" v-if="joinType != 3">
          <div class="platform-row-col" v-for="(item, idx) in joinPlatformCache" :key="idx">
            <div class="col">
              <!-- <DsfNcDefaultHeader :src="item.pc_logo" width="50px" height="50px" class="col-header"></DsfNcDefaultHeader> -->
              <div
                class="col-header imgType"
                :class="{ imgType1: item.platform_type_value == 0, imgType2: item.platform_type_value == 1, imgType3: item.platform_type_value == 2, imgType4: item.platform_type_value == 3 }"
              ></div>
              <div class="col-positon">
                <span class="name">{{ item.unitname }}</span>
                <span
                  class="type"
                  v-if="item.platform_type_value"
                  :class="{ type1: item.platform_type_value == 0, type2: item.platform_type_value == 1, type3: item.platform_type_value == 2, type4: item.platform_type_value == 3 }"
                >
                  {{ item.platform_type_text }}
                </span>
                <div class="col-address ell">{{ item.area_text }}{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="platform-row" v-if="joinType == 3">
          <div class="platform-row-col" v-for="(item, idx) in testPlatformCache" :key="idx">
            <div class="col">
              <!-- <DsfNcDefaultHeader :src="item.pc_logo" width="50px" height="50px" class="col-header"></DsfNcDefaultHeader> -->
              <div
                class="col-header imgType"
                :class="{ imgType1: item.platform_type_value == 0, imgType2: item.platform_type_value == 1, imgType3: item.platform_type_value == 2, imgType4: item.platform_type_value == 3 }"
              ></div>
              <div class="col-positon">
                <span class="name">{{ item.unitname }}</span>
                <span
                  class="type"
                  v-if="item.platform_type_value"
                  :class="{ type1: item.platform_type_value == 0, type2: item.platform_type_value == 1, type3: item.platform_type_value == 2, type4: item.platform_type_value == 3 }"
                >
                  {{ item.platform_type_text }}
                </span>
                <div class="col-address ell">{{ item.area_text }}{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-title-applying">申请理由</div>
      <div class="applying-content">{{ applyReason }}</div>
    </div>
    <el-dialog :visible.sync="dialogJoinVisible" width="520px" title="申请理由" :modal-append-to-body="false">
      <div class="apply-dialog">
        <div class="hint">加入该平台需填写申请理由，审批通过后方可加入。</div>
        <div class="reason">
          <span class="reasonLabel">理由：</span>
          <el-input type="textarea" placeholder="请输入" v-model.trim="applyReason" maxlength="1000" show-word-limit></el-input>
        </div>
        <div class="apply-btns">
          <div class="cancel-btn" @click="dialogJoinVisible = false">关闭</div>
          <div class="submit-btn" @click="applySubmit">提交</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" width="380px" :modal-append-to-body="false">
      <div class="join-dialog">
        <div class="bg-box"></div>
        <div class="dialog-success-text" :class="{ 'dialog-success-text-2': joinType != 2 }">{{ joinType == 2 ? "恭喜您，创建成功！" : "恭喜您，加入成功！" }}</div>
        <div class="hint-text" v-if="joinType != 2">快前往首页进行学习吧</div>
        <div class="dialog-btn pointer" @click="handleGoLogin">前往登录</div>
      </div>
    </el-dialog>
    <div class="btns" v-if="!isAapplying">
      <div class="cancel-btn" @click="window.close()">取消</div>
      <div class="submit-btn" @click="onSubmit">确认</div>
    </div>
  </div>
</template>
<script>
// import DsfMobileNcArea from "../other/dsfArea.vue";
export default dsf.component({
  name: "DsfNCSelectPlatform",
  ctrlCaption: "选择平台",
  mixins: [$mixins.control],
  props: {
    padding: {
      type: Array,
      default() {
        return [0, 0, 100, 0];
      },
    },
    width: {
      type: String,
      default: "1200px",
    },
    getCodeUrl: {
      type: String,
      default: "nc/org/user/sendNcVerifCode?codetype=modifyPhone",
    },
    searchUrl: {
      type: String,
      default: "/nc/reg/getUnitList",
    },
    // 创建平台
    submitCreatUrl: {
      type: String,
      default: "nc/reg/userRegister",
    },
    //加入平台
    submitJoinUrl: {
      type: String,
      default: "nc/reg/userRegister",
    },
    //申请理由
    applyUrl: {
      type: String,
      default: "",
    },
    //试用平台url
    testUrl: {
      type: String,
      default: "nc/reg/userRegister",
    },
  },
  data() {
    return {
      dialogJoinVisible: false, //弹出层.加入时需要审核
      dialogVisible: false, //弹出层创建和加入不需要审核
      searchText: "",
      id: "",
      joinType: "1",
      joinPlatform: "",
      testPlatform: "",
      applyReason: "",
      isAapplying: false,
      formData: {},
      joinList: [],
      platformTypeList: [
        { text: "企业", value: "0" },
        { text: "学校", value: "1" },
        { text: "政府", value: "2" },
        { text: "其他", value: "3" },
      ],
      peopleScaleList: [
        { text: "1-9", value: "1" },
        { text: "10-20", value: "2" },
        { text: "21-50 ", value: "3" },
        { text: "51-100 ", value: "4" },
        { text: "101-200 ", value: "5" },
        { text: "201-500 ", value: "6" },
        { text: "501-2000", value: "7" },
        { text: ">2000", value: "8" },
      ],
      //试用平台list
      testList: [],
      creatForm: {
        platformType: "",
        platformText: "",
        unitname: "",
        peopleScale: "",
        peopleScaleText: "",
        areaselectList: "",
        address: "",
      },
      joinPlatformCache: [],
      testPlatformCache: [],
      isType: false,
    };
  },
  components: {
    // DsfMobileNcArea,
  },
  computed: {
    controlStyle() {
      let { width, height, padding, isDesign, visible } = this;
      let style = {
        height,
        width,
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
  },
  watch: {},
  created() {
    // this.formData = this.queryString["formData"] || "{}";
    this.formData = sessionStorage.getItem("register_form_data");
    this.formData = JSON.parse(this.formData || "{}");
    this.id = this.queryString["id"] || "";
    //获取试用平台
    this.getTestData();
  },
  async mounted() {},

  beforeDestroy() {},
  methods: {
    getTestData() {
      this.dsf.http
        .get("nc/reg/getDefaultUnit", {}, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.testList = res.data || [];
            if (res.data.length) {
              this.testPlatform = res.data[0].unitid;
              this.testPlatformCache = [res.data[0]];
            }
          } else {
            dsf.layer.message(res.message || "服务器异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "服务器异常", false);
        });
    },
    handleSearch() {
      if (!this.searchUrl) return;
      if (!this.searchText) return dsf.layer.message("请输入搜索内容", false);
      this.dsf.http
        .get(this.searchUrl, { unitname: this.searchText }, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.joinList = res.data || [];
          } else {
            dsf.layer.message(res.message || "服务器异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "服务器异常", false);
        });
    },
    handleChangePlatform(val) {
      let arr = [];
      arr = this.platformTypeList.filter(item => {
        return item.value == val;
      });
      if (arr.length) {
        this.creatForm.platformText = arr[0].text || "";
      }
    },
    handleChangePeople(val) {
      let arr = [];
      arr = this.peopleScaleList.filter(item => {
        return item.value == val;
      });
      if (arr.length) {
        this.creatForm.peopleScaleText = arr[0].text || "";
      }
    },
    //改变试用平台
    changeTestList(val) {
      this.testPlatformCache = this.testList.filter(item => {
        return item.unitid == val;
      });
    },
    //改变申请平台
    handleChangeJionPlatfrom(val) {
      this.joinPlatformCache = this.joinList.filter(item => {
        return item.unitid == val;
      });
    },
    onSubmit() {
      if (this.joinType == 1) {
        //加入平台
        this.submitJoinPlatform();
      }
      if (this.joinType == 2) {
        //创建平台
        this.submitCreatPlatform();
      }
      //试用平台.
      if (this.joinType == 3) {
        this.submitJoinPlatform();
      }
    },
    submitJoinPlatform() {
      if (this.joinType == 3) {
        if (!this.testPlatform) return dsf.layer.message("请选择平台", false);
        //平台设置需要审核填写申请理由
        if (this.testPlatformCache[0].isaudit_value == 1) {
          this.dialogJoinVisible = true;
        } else {
          //平台设置不需要审核
          this.submitFn();
        }
      } else {
        if (!this.joinPlatform) return dsf.layer.message("请选择平台", false);
        //平台设置需要审核填写申请理由
        if (this.joinPlatformCache[0].isaudit_value == 1) {
          this.dialogJoinVisible = true;
        } else {
          //平台设置不需要审核
          this.submitFn();
        }
      }
    },
    submitFn() {
      let val = this.joinType == 3 ? true : false;
      let params = JSON.parse(JSON.stringify(this.formData));
      params.unitid = val ? this.testPlatformCache[0].unitid : this.joinPlatformCache[0].unitid;
      params.unitName = val ? this.testPlatformCache[0].unitname : this.joinPlatformCache[0].unitname;
      params.platformJson = val ? JSON.stringify(this.testPlatformCache) : JSON.stringify(this.joinPlatformCache);
      params.applyreason = this.applyReason || "";
      console.log(params);
      if (!this.submitJoinUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .post(this.submitJoinUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            dsf.layer.message(res.data || "成功", true);
            //需要审核显示审核页面
            if (res.state == 30000) {
              this.isAapplying = true;
            } else {
              this.dialogVisible = true;
            }
          } else {
            dsf.layer.message(res.message || "服务器异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "服务器异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    //提交申请
    applySubmit() {
      if (!this.applyReason) {
        dsf.layer.message("请输入申请理由", false);
      } else {
        this.submitFn(this.joinType);
        this.dialogJoinVisible = false;
      }
    },
    submitCreatPlatform() {
      let params = {};
      if (!this.creatForm.platformType) return dsf.layer.message("请选择平台类型", false);
      if (!this.creatForm.unitname) return dsf.layer.message("请输入平台名称", false);
      params = Object.assign(params, this.formData);
      params.unitname = this.creatForm.unitname;
      params.platformJson = JSON.stringify({
        areaText: this.creatForm.areaselectList.text || "",
        areaValue: this.creatForm.areaselectList.value || "",
        platformText: this.creatForm.platformText,
        platformType: this.creatForm.platformType,
        peopleScaleText: this.creatForm.peopleScaleText,
        peopleScale: this.creatForm.peopleScale,
        address: this.creatForm.address,
        unitname: this.creatForm.unitname,
      });
      params.unitid = "";
      console.log(params);
      if (!this.submitCreatUrl) return;
      const loading = dsf.layer.loading();
      this.dsf.http
        .post(this.submitCreatUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            dsf.layer.message(res.data || "成功", true);
            this.dialogVisible = true;
          } else {
            dsf.layer.message(res.message || "服务器异常", false);
          }
        })
        .error(response => {
          dsf.layer.message(response.message || "服务器异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    reLogin() {
      dsf.storage.session.clear();
      dsf.cookies.remove("userId");
      dsf.cookies.set("isLogin", "");
      dsf.cookies.set("authorization_token", "");
      this.$root.close();
      this.$checkLogin();
    },
    handleGoLogin() {
      window.location.href = location.origin + dsf.url.getWebPath(dsf.config.setting_public_pcindex_url) + "?showLogin=1";
    },
  },
});
</script>
