var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_c('div',{style:({
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '1200px',
      padding: '0 20px',
      boxSizing: 'border-box',
    })},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"dsf-nc-spacil-card-no-hover",style:({ width: `280px`, height: `236px` }),attrs:{"title":item['nc_class_page_mainData.name']},on:{"click":function($event){return _vm._clickBefore(_vm.click)}}},[_c('div',{staticClass:"cover dsf-nc-image"},[_c('dsf-nc-image',{attrs:{"src":_vm._f("imgFormat")(_vm.setImg(item['nc_class_page_mainData.cover'])),"error-src":_vm.defaultSrc}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"card-state",style:(`background-color: ${_vm.showBgColor(item['nc_class_page_mainData.current_state_text'])}`)},[_vm._v(" "+_vm._s(item["nc_class_page_mainData.current_state_text"])+" ")]),_c('div',{staticClass:"card-info"},[(item['nc_class_page_mainData.show_description'] == 1)?_c('span',[_vm._v(_vm._s(item["nc_class_page_mainData.state_description"]))]):_c('span',[_vm._v(_vm._s(item["nc_class_page_mainData.pxsj_sdate"])+" 至 "+_vm._s(item["nc_class_page_mainData.pxsj_edate"]))])])])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item["nc_class_page_mainData.name"]))])]),_c('div',{staticClass:"footer"},[_c('span',[_c('i',{staticClass:"iconfont icon-icon-bi"}),_c('b',[_vm._v(_vm._s(item["nc_class_page_mainData.compulsoryPeriod"])+"学时")])]),_c('span',[_c('i',{staticClass:"iconfont icon-icon-xuan"}),_c('b',[_vm._v(_vm._s(item["nc_class_page_mainData.electivesPeriod"])+"学时")])]),_c('span',[_c('i',{staticClass:"iconfont icon-icon-people"}),_c('b',[_vm._v(_vm._s(item["nc_class_page_mainData.studentcount"]))])])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"des-custom-order",attrs:{"ctrl_type":"dsf.customorder"}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"des-custom-order-item"},[_c('span',[_vm._v("最新")]),_c('i',{staticClass:"sort-bar"})]),_c('div',{staticClass:"des-custom-order-item"},[_c('span',[_vm._v("最热")]),_c('i',{staticClass:"sort-bar"})])]),_c('div',{staticClass:"right"})])
}]

export { render, staticRenderFns }