<!-- 通用列表组件 -->
<template>
  <div class="dsf-datagrid ds_control" ref="dsf-datagrid">
    <div class="fixed" ref="fixedbox">
      <slot name="search" ref="search"></slot>
    </div>
    <div
      class="list-box"
      ref="list"
      @scroll.passive="watchScroll"
      :style="{ height: 'calc(100% - ' + fixedHeight + 'rem)' }"
    >
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :disabled="!hasRefresh"
      >
        <van-list
          v-if="getted && data.length > 0"
          ref="list"
          :style="{ margin: marginToRem }"
          :class="{ dimidiate: col != 1 }"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div
            :style="{
              padding: paddingToRem,
              'background-color': backgroundColor,
              overflow: 'auto',
            }"
          >
            <div
              class="top-item"
              v-if="topData.length > 0"
              :style="{ margin: topMarginToRem }"
            >
              <van-swipe
                class="news-swipe"
                :autoplay="3000"
                indicator-color="white"
              >
                <van-swipe-item v-for="(item, $index) in topData" :key="$index">
                  <slot name="top" :data="item"></slot>
                </van-swipe-item>
              </van-swipe>
            </div>
            <div
              v-for="(item, $index) in normalData"
              class="item"
              :style="{ width: col == 1 ? 'auto' : 100 / col + '%' }"
              :key="$index"
            >
              <slot name="default" :data="item"></slot>
            </div>
          </div>
        </van-list>
        <div class="no-data" v-if="getted && data.length == 0">暂无数据</div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfNcNewDataGrid",
  ctrlCaption: "贵州新数据列表",
  props: {
    col: {
      type: [String, Number],
      default: 1,
      desc: "一列显示几个，暂支持1和2",
    },
    hasLoad: {
      type: Boolean,
      default: true,
      desc: "是否有上拉加载",
    },
    hasRefresh: {
      type: Boolean,
      default: true,
      desc: "是否有下拉刷新",
    },
    dataSource: {
      type: String,
      default: "meta/list/data",
      desc: "请求接口，可自定义",
    },
    curr: {
      type: [String, Number],
      default: 1,
      desc: "从哪一页开始",
    },
    limit: {
      type: [String, Number],
      default: 12,
      desc: "每页显示多少",
    },
    httpParam: {
      type: Object,
      default: function () {
        return {};
      },
      desc: "接口参数",
    },
    maxTopNumber: {
      type: Number,
      default: 5,
      desc: "置顶数据最大限制",
    },
    topProps: {
      type: String,
      default: "",
      desc: "top属性名称",
    },
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    topMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    immediate: {
      type: Boolean,
      default: true,
      desc: "初始化后立即自动查询",
    },
    pageNumKey: {
      type: String,
      default: "pageNum",
      desc: "加载页码的key",
    },
    saveScroll: {
      type: Boolean,
      default: true,
      desc: "是否保存滚动条位置",
    },
    dataListString: {
      type: String,
      default: "",
      desc: "取值的data参数",
    },
  },
  computed: {
    paddingToRem: function () {
      let arr = _.map(this.padding, (item) => {
        let px = item || 0;
        let rem = px / 50;
        return rem;
      });
      return arr.join("rem ") + "rem";
    },
    marginToRem: function () {
      let arr = _.map(this.margin, (item) => {
        let px = item || 0;
        let rem = px / 50;
        return rem;
      });
      return arr.join("rem ") + "rem";
    },
    topMarginToRem: function () {
      let arr = _.map(this.topMargin, (item) => {
        let px = item || 0;
        let rem = px / 50;
        return rem;
      });
      return arr.join("rem ") + "rem";
    },
    scrollKey: function () {
      return this._uid + "_list_scroll";
    },
  },
  data() {
    return {
      data: [],
      fixedHeight: 0,
      topData: [],
      normalData: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNumV: this.curr,
      getted: false,
    };
  },
  mounted() {
    this.fixedHeight = this.$refs.fixedbox.offsetHeight;
    this.fixedHeight = this.fixedHeight / 50;

    // this.$nextTick(() => {
    //   console.log(
    //     (this.$refs["dsf-datagrid"].offsetHeight - this.fixedHeight) / 50
    //   );
    // });
    if (this.immediate) {
      this.loadData(true);
    }
  },
  activated() {
    //将滚动条复位
    this.restoreScroll();
  },
  destroyed() {
    window.sessionStorage.removeItem(this.scrollKey);
  },
  methods: {
    loadData(isInit) {
      if (this.geting) return;
      let _this = this;
      this.geting = true;
      let args = dsf.mix(
        {
          [this.pageNumKey]: _this.pageNumV,
          pageSize: _this.limit,
        },
        _this.httpParam
      );
      let loader = isInit && dsf.layer.loading();
      let state = "";
      this.$http
        .get(_this.dataSource, args, $$webRoot.nc)
        .done((d) => {
          if (d.success) {
            let data = d.data || [];
            if (this.dataListString) {
              data = d.data[this.dataListString] || [];
            }
            //第一屏数据this.data.length为0
            _this.checkData(data, _this.data.length == 0);
            _this.data = _this.data.concat(data);
            if (this.hasLoad) {
              if (data.length < _this.limit) {
                _this.loading = false;
                _this.finished = true;
              }
            } else {
              //如果列表不允许下滑加载数据则将finished设置为false,使finished-text不显示
              _this.finished = false;
            }
          } else {
            state = d.message || "获取列表数据异常";
          }
        })
        .error((response) => {
          state = response.message;
        })
        .always((res) => {
          if (!isInit) {
            _this.$nextTick(() => {
              _this.refreshing = false;
              _this.loading = false;
            });
          } else {
            _this.dsf.layer.closeLoading(loader);
          }
          if (state) {
            window.setTimeout(() => {
              _this.dsf.layer.message(state, false);
            }, 1000);
          }
          _this.geting = false;
          _this.getted = true;
        });
    },
    onLoad(isRefresh) {
      if (this.geting) return;
      if (!this.hasLoad && !isRefresh) {
        return;
      }
      if (this.refreshing) {
        this.data = [];
        this.topData = [];
        this.normalData = [];
        this.pageNumV = this.curr - 1;
      }

      this.pageNumV++;
      this.loadData();
    },
    onRefresh() {
      //是否已加载完成，加载完成后不再触发load事件
      this.finished = false;
      //是否处于加载状态，加载过程中不触发load事件
      this.loading = true;
      this.onLoad(1);
    },
    query(key) {
      this.refreshing = true;
      this.onRefresh();
    },
    checkData(data, ismark) {
      if (ismark) {
        let tdata = [],
          ndata = [];
        for (let i = 0; i < data.length; i++) {
          let isTop = false;
          if (this.maxTopNumber > 0 && i < this.maxTopNumber) {
            if (this.topProps) {
              isTop = !!data[i][this.topProps];
            }
          }
          isTop ? tdata.push(data[i]) : ndata.push(data[i]);
        }
        this.topData = this.normalData.concat(tdata);
        this.normalData = this.normalData.concat(ndata);
      } else {
        this.normalData = this.normalData.concat(data);
      }
    },
    clearData() {
      this.data = [];
    },
    watchScroll(evt) {
      if (this.saveScroll) {
        let pos = evt.currentTarget.scrollTop;
        window.sessionStorage.setItem(this.scrollKey, pos);
      }
    },
    restoreScroll() {
      if (this.saveScroll) {
        let pos = window.sessionStorage.getItem(this.scrollKey);
        if (pos) {
          pos = pos || 0;
          this.$el.querySelector(".list-box").scrollTop = pos;
        }
      }
    },
  },
  watch: {
    httpParam: {
      // 参数改变再次请求接口
      handler(val) {
        this.loadData(true);
      },
      deep: true,
    },
  },
};
</script>
