import Special from "../components/Special";
import portrait from "../components/portrait";
import teachAssistant from "../components/teachAssistant";
import briefing from "../components/briefing";
import gzmine from "../components/mine";
import everyclass from "./everyclass";
import gzfooter from "./gzfooter";
import loginDialog from "./login";
import gzliveclass from "./liveclass";
import gzassess from "./assess";
import recommend from "../components/recommend";
import education from "./education";
import educationlist from "./educationlist";
import educationdetail from "./educationdetail";
import gzsliders from "./slidebar";
import livesearch from "./livesearch/";
import gzrecentLive from "./recentLive";
import gzlivehistory from "./livehistory";
import newlearnspecial from "./learnspecial";
import gzbindexamine from "./bindexamine";
import gzqualitydetail from "./qualitydetail";
import gzztbsearch from "./ztbsearch";
import gzztbcard from "./ztbcard";
import newdatagrid from "./newdatagrid";
import listtitle from "./listtitle";
import gzmanual from "./manual";
import gzclassroomsearch from "./classroomsearch";
import gzclassroomlist from "./classroomlist";
import gzclassroomnext from "./classroomnext";
import gzbindztbassess from "./bindztbassess";
import gzcourseware from "./courseware";
import forgetpwd from "./login";
import gzspecialdetail from "./specialdetail";
import specialdetailgraduate from "./specialdetailgraduate";
import gzinfopage from "./gzinfopage";
import gzclassexpand from "./classexpand";
import gzteachfinshlist from "./gzteachfinshlist";
import globalsearch from "./globalsearch";
import banner from "./banner";
import allsearch from "./allsearch";
import other from "./other";
import course from "./course";
import specialColumn from "./specialColumn";
import importData from "./importData";
import replyComponent from "./replyComponent";
import sub from "./sub";
import manage from "./manage";
import tree from "./tree";
import tabPanel from "./tabPanel";
import longmarch from "./longmarch";
const components = [
  ...tabPanel,
  ...tree,
  ...manage,
  ...course,
  ...banner,
  ...Special,
  ...portrait,
  ...briefing,
  ...teachAssistant,
  ...everyclass,
  ...gzfooter,
  ...loginDialog,
  ...gzliveclass,
  ...gzassess,
  ...recommend,
  ...education,
  ...educationlist,
  ...educationdetail,
  ...gzsliders,
  ...livesearch,
  ...gzrecentLive,
  ...gzlivehistory,
  ...gzmine,
  ...newlearnspecial,
  ...gzbindexamine,
  ...gzqualitydetail,
  ...gzztbsearch,
  ...gzztbcard,
  ...newdatagrid,
  ...listtitle,
  ...gzmanual,
  ...gzclassroomsearch,
  ...gzclassroomlist,
  ...gzclassroomnext,
  ...gzbindztbassess,
  ...gzcourseware,
  ...forgetpwd,
  ...gzspecialdetail,
  ...specialdetailgraduate,
  ...gzinfopage,
  ...gzclassexpand,
  ...gzteachfinshlist,
  ...globalsearch,
  ...allsearch,
  ...other,
  ...specialColumn,
  ...importData,
  ...replyComponent,
  ...sub,
  ...longmarch,
];

function install(Vue) {
  components.forEach(it => {
    Vue.component(it.name, it);
    if (!window.$components["nc"]) {
      window.$components["nc"] = {};
    }
    window.$components["nc"][it.name] = it;
  });
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export { install };

export default install;
