<template>
  <div class="dsf_nc_educationdetail">
    <div class="header">
      <span class="jstitle">{{ list.title }}</span>
      <p class="time">
        <i class="iconfont icon-calendar"></i
        ><span class="jstime">{{ setTime(list.date_choice) }}</span>
      </p>
      <p>
        <i class="iconfont icon-yanjing"></i
        ><span class="jsnum">{{ list.clicks || 0 }}</span>
      </p>
    </div>
    <div class="detail-title">
      <p v-if="list.base_address">
        <span>地址</span><i class="jstext-1">{{ list.base_address }}</i>
      </p>
      <p v-if="list.base_contact">
        <span>联系人</span><i class="jstext-2">{{ list.base_contact }}</i>
      </p>
      <p v-if="list.base_phone">
        <span>电话</span><i class="jstext-3">{{ list.base_phone }}</i>
      </p>
      <p v-if="list.base_site">
        <span>官网</span
        ><i class="jstext-4" style="cursor: pointer">{{ list.base_site }}</i>
      </p>
    </div>
    <div class="content" v-html="setHtmlStr(list.content)"></div>
    <div class="resource">
      <div v-if="list.file_upload">
        <div
          class="row"
          v-for="(item, index) in JSON.parse(list.file_upload)"
          :key="index"
          @click="godetail(item)"
        >
          <span class="title">相关文件:</span>
          <div class="imgbox">
            <img
              src="../../../assets/styles/img/pcimg/guizhou/mov.png"
              alt=""
            />
          </div>
          <span class="info">{{ item.originalFileName }}</span>
        </div>
      </div>
      <div v-if="list.file_upload">
        <div
          class="row"
          v-for="(item, index) in JSON.parse(list.video_upload)"
          :key="index"
          @click="godetail(item)"
        >
          <span class="title">相关视频:</span>
          <div class="imgbox">
            <img
              src="../../../assets/styles/img/pcimg/guizhou/mov.png"
              alt=""
            />
          </div>
          <span class="info">{{ item.originalFileName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcEducationDetail",
  ctrlCaption: "党性教育详情",
  mixins: [$mixins.control],
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.showList();
    // console.log(this.list['nc_operate_jdwh.video_upload'],"++++++");
  },
  mounted() {},
  methods: {
    godetail(item) {
      console.log(item);
      if (item.suffix == "mp4") {
        window.open(
          `/page.html#/pc/nc/page/pc/education/courseware?id=${item.id}&type=${item.suffix}`
        );
      } else if (
        item.suffix == "docx" ||
        item.suffix == "xlsx" ||
        item.suffix == "pptx" ||
        item.suffix == "pdf" 
      ) {
        window.open(
          `${dsf.url.getWebPath("/preview/file")}?url=${
            dsf.config.setting_public_file_host
          }${item.relativePath}`
        );
        // let url = encodeURIComponent(item.absolutePath)
        // let url = encodeURIComponent('https://gzwy.gov.cn/dsfa/upload/files/20220822/e6e940e2232b499e8bf9d031798d8352.pptx')
        // 在新窗口打开编码后 的链接
        // window.open('http://view.officeapps.live.com/op/view.aspx?src=' + url, '_target')
      }
    },
    setTime(time) {
      let tiems = "";
      if (time) {
        tiems = time.split(" ")[0];
      } else {
        tiems = "暂无";
      }
      return tiems;
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    showList() {
      let that = this;
      const id = this.$route.query.id;
      const mainUrl = `/nc/jdwh/getDetail?id=${id}`;
      that.dsf.http
        .get(mainUrl)
        .done(function (res) {
          if (res.success) {
            that.list = res.data;
            console.log(res.data, "获取浏览量");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    /* 处理视频播放器有下载按钮 */
    setHtmlStr(content){
      let str = "";
      if(content){
        str = content.replace(/\<video/gi, '<video controlslist="nodownload" autoplay')
      }
      return str;
    }
  },
});
</script>

<style></style>
