<template>
  <div class="dsf_nc_briefing">
    <div class="home">
      <div class="box" @mouseout="out" @mouseover="over">
        <img
          @click="gobanner(item)"
          v-for="(item, index) in bannerList"
          v-show="listIndex === index"
          :key="index"
          :src="setImg(item.cover)"
          alt=""
        />
        <!-- <p class="left" @click="changePage(prevIndex)">&lt;</p>d -->
        <ul>
          <li
            :class="{ color: index == listIndex }"
            v-for="(item, index) in bannerList"
            @click="changePage(index)"
            :key="index"
          ></li>
        </ul>
        <!-- <p class="right" @click="changePage(nextIndex)">&gt;</p> -->
      </div>
    </div>

    <div class="brief_list">
      <li class="items" v-for="(items, index) in dataList" :key="index">
        <div class="leftbox" >
          <!-- <img
            :src="setImg(items.cover)"
            alt=""
            onerror="onerror=null;src='/dsfa/res/dsf_styles/themes/nc/404.png'"
          /> -->
          <div class="title ell">
            {{ items["nc_brief_PCHomeResourceListData.title"] }}
          </div>
        </div>
        <div
          class="btn"
          @click.self="goview(items)"
          :style="{
            backgroundColor: `${btnbgcolor(items) ? ' ' : '#ccc'}`,
          }"
        >
          查看
        </div>
      </li>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzBriefing", //dsf.ncgzbriefing
  ctrlCaption: "贵州简报",
  mixins: [$mixins.control],
  props: {},
  computed: {
    // //上一张
    // prevIndex() {
    //   if (this.listIndex == 0) {
    //     return this.list.length - 1;
    //   } else {
    //     return this.listIndex - 1;
    //   }
    // },
    // //下一张
    // nextIndex() {
    //   if (this.listIndex == this.list.length - 1) {
    //     return 0;
    //   } else {
    //     return this.listIndex + 1;
    //   }
    // },
  },
  data() {
    return {
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
      dataList: [],
      bannerList: [],
    };
  },
  created() {
    //定时器
    this.setTimer();
    this.getBannerData();
    this.getDataList();
  },
  mounted() {},
  methods: {
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //3秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.bannerList.length) {
          this.listIndex = 0;
        }
      }, 3000);
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    getBannerData() {
      let that = this;
      const mainUrl = "/inc/brief/PcHome/banner";
      const params = {
        maxsize: 6,
        briefId: location.hash.split("=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.bannerList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getDataList() {
      let that = this;
      const mainUrl = "/inc/meta/list/data";
      const params = {
        pageNum: 1,
        pageSize: 100,
        query: JSON.stringify({ searchValue: "" }),
        order: JSON.stringify([]),
        filter: JSON.stringify([]),
        namespace: "nc.brief",
        pageName: "PCHome",
        id: location.hash.split("=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
            console.log(res.data);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    goview(item, type) {
      // let istrue = "";
      let sdate = new Date(
        item["nc_brief_PCHomeResourceListData.time_sdate"]
      ).getTime(); // "2022-09-27 00:00:00",
      let edate = new Date(
        item["nc_brief_PCHomeResourceListData.time_edate"]
      ).getTime(); // "2022-09-30 00:00:00",
      let nowDate = new Date().getTime();
      if (
        item["nc_brief_PCHomeResourceListData.isAdvancedModeText"] ==
        "非高级模式"
      ) {
        window.open(
          `${dsf.url.getWebPath("/preview/file")}?url=${
            dsf.config.setting_public_file_host
          }${item["nc_brief_PCHomeResourceListData.url"]}`,
          "_blank"
        );
        // istrue = true;
      } else if (
        item["nc_brief_PCHomeResourceListData.isAdvancedModeText"] ==
          "高级模式" &&
        nowDate > sdate &&
        nowDate < edate
      ) {
        window.open(
          `${dsf.url.getWebPath("/preview/file")}?url=${
            dsf.config.setting_public_file_host
          }${item["nc_brief_PCHomeResourceListData.url"]}`,
          "_blank"
        );
        // istrue = true;
      }
      console.log(nowDate < sdate, nowDate > edate);
      // return istrue;
    },
    btnbgcolor(item) {
      let istrue = false;
      let sdate = new Date(
        item["nc_brief_PCHomeResourceListData.time_sdate"]
      ).getTime(); // "2022-09-27 00:00:00",
      let edate = new Date(
        item["nc_brief_PCHomeResourceListData.time_edate"]
      ).getTime(); // "2022-09-30 00:00:00",
      let nowDate = new Date().getTime();
      if (
        item["nc_brief_PCHomeResourceListData.isAdvancedModeText"] ==
        "非高级模式"
      ) {
        istrue = true;
      } else if (
        item["nc_brief_PCHomeResourceListData.isAdvancedModeText"] ==
          "高级模式" &&
        nowDate > sdate &&
        nowDate < edate
      ) {
        istrue = true;
      }
      return istrue;
    },
    gobanner(item) {
      if (item.url) open(location.origin + item.url, "_blank");
    },
  },
});
</script>
<style lang="scss" scoped>
.home {
  width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    position: relative;
    width: 1200px;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top: 50%;
      left: 0;
    }
    .right {
      position: absolute;
      top: 50%;
      right: 0;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      top: 90%;
      right: 50%;
      margin-right: -75px;
      .color {
        background: red;
        color: red;
      }
      li {
        cursor: pointer;
        width: 20px;
        height: 6px;
        background: #999;
        border-radius: 6px;
        margin-left: 10px;
      }
    }
  }
}
</style>
