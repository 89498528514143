<template>
  <div class="dd-login-view">
    <div class="dd-login-guide">
      <img v-show="showIcon" :src="guideImg | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
    </div>
    <div class="remind" v-show="!showIcon">正在校验用户信息...</div>
  </div>
</template>
<script>
export default {
  name: "ddLogin",
  data() {
    return {
      showIcon: false,
      guideImg: dsf.config.setting_nc_image_start_img,
    };
  },
  created() {
    this.$ddPlatform.initLogin();
  },
  methods: {
    headLoadSuccess() {
      this.showIcon = true;
    },
    headLoadError() {
      this.showIcon = false;
    },
  },
};
</script>
<style lang="scss">
.dd-login-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .dd-login-guide {
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100vh;
    }
  }
  .remind {
    font-size: 16px;
    color: #333;
  }
}
</style>
