<template>
  <div
    class="dsf_headerInfo"
    v-if="visible"
    :style="{ width: width, height: height }"
  >
    <userTitle :title="title" :showMore="false" v-if="showHeader"></userTitle>
    <div class="content">
      <!-- <el-image :src="imageUrl | imgFormat" fit="cover" class="avatar"> -->
      <!-- <div slot="error" class="image-slot">
          <el-image fit="cover" :src="$replace(dsf.config.setting_public_user_default_header)"></el-image>
        </div> -->
      <!-- </el-image> -->
      <!-- <dsf-icon name="icon_wrong" class="deleteImage" @click="handleDelteImage" v-if="imageUrl"></dsf-icon> -->
      <DsfNcDefaultHeader
        :src="imageUrl"
        :defaultHeaderUrl="defaultHeaderUrl"
        class="DefaultHeader"
      ></DsfNcDefaultHeader>
      <div class="changehedaer" @click="goDetail">修改头像</div>
      <!-- <el-upload class="avatar-uploader" action="/dsf5/file/upload/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <el-button size="small" class="upload">点击上传</el-button> 
        <div slot="tip" class="el-upload__tip">仅支持JPG、JPEG、GIF、PNG格式的图片文件，</div>
        <div slot="tip" class="el-upload__tip">文件不能大于4MB</div> 
      </el-upload> -->
    </div>
  </div>
</template>

<script>
import userTitle from "./userTitle.vue";
export default dsf.component({
  name: "DsfNcUserHeaderInfo",
  ctrlCaption: "头像信息",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "头像信息",
    },
    width: {
      type: String,
      default: "480px",
    },
    height: {
      type: String,
      default: "400px",
    },
    reqUrl: { type: String, default: "" },
    uploadUrl: { type: String, default: "" },
    imageUrl: { type: String, default: "" },
    headerMoreUrl: { type: String, default: "" },
  },
  data() {
    return {
      defaultHeaderUrl: require('../../../assets/styles/img/pcimg/guizhou/gr_tx.png'),
    };
  },
  components: {
    userTitle,
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.getData();
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.success) {
        this.saveHeader(res.data);
      }
    },
    saveHeader(data) {
      console.log(data);
      if (this.uploadUrl) {
        const loading = dsf.layer.loading();
        this.dsf.http
          .get(this.uploadUrl, { data }, $$webRoot.nc)
          .done((res) => {
            if (res.success) {
              this.dsf.layer.message(res.message || "头像修改成功", true);
            } else {
              this.dsf.layer.message(res.message || "头像上传失败", false);
            }
          })
          .error((response) => {
            this.dsf.layer.message(response.message || "头像上传失败", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    handleDelteImage() {
      this.imageUrl = "";
      this.saveHeader([]);
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG、GIF、PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
      }
      return isJPG && isLt2M;
    },
    getData() {
      if (this.reqUrl) {
        this.dsf.http
          .get(this.reqUrl, {})
          .done((res) => {
            if (res.success) {
              this.imageUrl = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error((response) => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          });
      }
    },
    goDetail() {
      if (this.headerMoreUrl) {
        console.log(this.$open);
        this.$open({
          url: this.headerMoreUrl,
          state: 3,
          width: "600px",
          height: "300px",
          title: "修改信息",
        });
        this.dialogVisible = true;
        this.$openDialog({ title: "修改" + this.title, content: "test/iframe.html" });
      }
    },
  },
});
</script>
