<template>
  <div class="dsf_nc_gzmine">
    <div class="list">
      <div class="item" v-for="(item, indexs) in List" :key="indexs">
        <div class="img">
          <img :src="item.imgUrl" />
        </div>
        <div class="info">
          <div class="number" v-if="indexs == 0">
            {{ dataList.year || 0 }}
          </div>
          <div class="number" v-if="indexs == 1">
            {{ `${dataList.wckccount || 0}/${dataList.kccount || 0}` }}
          </div>
          <div class="number" v-if="indexs == 2">
            {{ `${dataList.wczxscount || 0}/${dataList.zxscount || 0}` }}
          </div>
          <div class="number" v-if="indexs == 3">
            {{ dataList.integral || 0 }}
          </div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzMine",
  ctrlCaption: "学习中心我的",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      List: [
        {
          desc: "学习年度",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/learncenter/niandu.png"),
        },
        {
          desc: "必修课程",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/learncenter/bixiukecheng.png"),
        },
        {
          desc: "总学时",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/learncenter/xueshi.png"),
        },
        {
          desc: "积分",
          imgUrl: require("../../../assets/styles/img/pcimg/guizhou/learncenter/zhuantiban.png"),
        },
      ],
      dataList: {},
    };
  },
  created() {
    this.getdata();
  },
  mounted() {},
  methods: {
    getdata() {
      let that = this;
      const mainUrl = "nc/gz/learn/year/task";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style></style>

