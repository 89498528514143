var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dsf_nc_special_detail_course_item"},[(_vm.lists.length)?_c('ul',{staticClass:"nc_detail_wrap"},_vm._l((_vm.lists),function(item,index){return _c('li',{key:index,class:['nc_detail_item', { gjms: item.advancedMode }]},[_c('div',{staticClass:"detail_top"},[_c('div',{staticClass:"title_box"},[_c('span',{staticClass:"icon iconfont icon-icon_shiyongwendang"}),_c('div',{staticClass:"item_title"},[_vm._v(_vm._s(item.unitName))]),_c('span',{staticClass:"font"},[_vm._v("   (选修: "+_vm._s(item.electives || 0)+"门/"+_vm._s(item.electivesPeriod || 0)+"学时  必修: "+_vm._s(item.compulsory || 0)+"门/"+_vm._s(item.compulsoryPeriod || 0)+"学时) ")])]),_c('div',{staticClass:"item_top_right",on:{"click":function($event){return _vm.clickFold(index)}}},[(item.fold)?_c('span',{staticClass:"font"},[_vm._v("展开")]):_c('span',{staticClass:"font"},[_vm._v("收起")]),(item.fold)?_c('span',{staticClass:"icon iconfont icon-xia1 sq"}):_vm._e(),(!item.fold)?_c('span',{staticClass:"icon iconfont icon-shang1 sq"}):_vm._e()])]),(item.fold && item.advancedMode)?_c('div',{staticClass:"detail_desc"},_vm._l((item.subList),function(items,idx){return _c('div',{key:items.subId,staticClass:"detail_desc_item"},[_c('div',{staticClass:"detail_desc_left"},[_c('span',{class:['type_name', items.typeValue == 'work' ? '' : items.typeValue],style:({
                background: `${items.typeValue == 'work' ? 'rgb(112, 146, 190)' : items.typeValue == 'asq' ? '#ff6f73' : ''}`,
              })},[_vm._v(" "+_vm._s(items.typeText == "课程包" ? "课程" : items.typeText)+" ")]),_vm._v("   "),_c('div',{staticClass:"type_category_box"},[_c('span',{staticClass:"type_category ell"},[_c('span',{style:({
                    color: `${items.categoryText == '必修' ? '#ff6f73' : '#69c33b'}`,
                    marginRight: '10px',
                  })},[_vm._v(" 【"+_vm._s(items.categoryText)+"】 ")]),_vm._v(" "+_vm._s(items.name)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(items.name.length > 15),expression:"items.name.length > 15"}],staticClass:"type_category_hover"},[_vm._v(_vm._s(items.name))])]),_c('div',{staticClass:"date font",style:(`opacity:${items.timeSdate && items.timeEdate ? '1' : '0'}`)},[_vm._v(" "+_vm._s(_vm.dsf.date.format(items.timeSdate, "yyyy-mm-dd hh:ii") || "")+" 至 "+_vm._s(_vm.dsf.date.format(items.timeEdate, "yyyy-mm-dd hh:ii") || "")+" ")]),_c('div',{staticClass:"wcxs font"},[_vm._v(_vm._s(items.period || "0")+"学时")]),_c('div',{staticClass:"teacher font ell"},[_vm._v(_vm._s(items.teacher || "-"))]),_c('div',{staticClass:"progress"},[_c('span',{staticClass:"font"},[_vm._v("已学: "+_vm._s(items.progress || 0)+"%")])])]),_c('div',{staticClass:"detail_desc_right"},[_c('div',{ref:"enter_btn",refInFor:true,staticClass:"enter_btn",style:(`${
                _vm.isstudent == 1
                  ? ''
                  : _vm.itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !_vm.isview && _vm.getbtninfo(items) != '待批阅'
                  ? ''
                  : 'color: #aaa; cursor: default'
              }`),on:{"click":function($event){_vm.isstudent == 1
                  ? _vm.clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null, 1)
                  : _vm.showClick && _vm.clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)}}},[_vm._v(" "+_vm._s(_vm.getbtninfo(items))+" ")])])])}),0):(item.fold && !item.advancedMode)?_c('div',{staticClass:"detail_desc"},_vm._l((item.subList),function(items,idx){return _c('div',{key:items.subId,staticClass:"detail_desc_item"},[_c('div',{staticClass:"detail_desc_left"},[_c('span',{class:['type_name', items.typeValue == 'work' ? '' : items.typeValue],style:({
                background: `${items.typeValue == 'work' ? 'rgb(112, 146, 190)' : ''}`,
              })},[_vm._v(" "+_vm._s(items.typeText == "课程包" ? "课程" : items.typeText)+" ")]),_vm._v("   "),_c('div',{staticClass:"type_category_box"},[_c('span',{staticClass:"type_category ell"},[_vm._v(_vm._s(items.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(items.name.length > 15),expression:"items.name.length > 15"}],staticClass:"type_category_hover"},[_vm._v(_vm._s(items.name))])]),_c('div',{class:['font', !!items.categoryValue && Number(items.categoryValue) ? 'bx' : 'xx']},[_vm._v(" "+_vm._s(items.categoryText)+" ")]),_c('div',{staticClass:"duration_box"},[_c('div',{staticClass:"font duration ell"},[_vm._v(_vm._s(_vm.setDuration(items)))]),(items.typeValue == 'live' || items.typeValue == 'exam')?_c('div',{class:['duration_hover', { duration_none: _vm.setDuration(items) == '-' }]},[_vm._v(" "+_vm._s(_vm.setDuration(items))+" ")]):_vm._e()]),_c('div',{staticClass:"wcxs font"},[_vm._v(_vm._s(items.period || "0")+"学时")]),_c('div',{staticClass:"teacher font ell"},[_vm._v(_vm._s(items.teacher || "-"))]),_c('div',{staticClass:"progress"},[_c('el-progress',{attrs:{"percentage":items.progress || 0,"stroke-width":"10","show-text":false}}),_c('span',{staticClass:"pivot-txt"},[_vm._v(_vm._s(items.progress || 0)+"%")])],1)]),_c('div',{staticClass:"detail_desc_right"},[_c('div',{ref:"enter_btn",refInFor:true,staticClass:"enter_btn",style:(`${
                _vm.isstudent == 1
                  ? ''
                  : _vm.itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !_vm.isview && _vm.getbtninfo(items) != '待批阅'
                  ? ''
                  : 'color: #aaa; cursor: default'
              }`),on:{"click":function($event){_vm.isstudent == 1
                  ? _vm.clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null, 1)
                  : _vm.showClick && _vm.clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)}}},[_vm._v(" "+_vm._s(_vm.getbtninfo(items))+" ")])])])}),0):_vm._e()])}),0):_c('div',{staticClass:"ds-empty-data",domProps:{"textContent":_vm._s('暂无数据')}})])
}
var staticRenderFns = []

export { render, staticRenderFns }