<template>
  <div class="dsf_nc_courseware">
    <video
      v-if="type == 'mp4' || type == 'avi'"
      controls
      class="video"
      controlslist="nodownload" 
      autoplay
      :src="videourl"
    ></video>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzCourseware",
  ctrlCaption: "贵州教育基地详情视频课件",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      videourl: "",
      type: "",
    };
  },

  created() {
    let search = location.hash.split("&");
    this.videourl = `/upload/file/mp4/${search[0].split("=")[1]}`;
    this.type = search[1].split("=")[1];
  },
  mounted() {},
  methods: {},
});
</script>
<style lang="scss" scoped>
.dsf_nc_courseware {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video {
  width: 1200px;
  height: 650px;
  margin: 0 auto;
}
@media all and (min-width: 800px) and (max-width: 1200px) {
  .video {
    width: 600px;
    height: 325px;
    margin: 0 auto;
  }
}
</style>