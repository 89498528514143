<template>
  <div class="dsf_nc_xlyteachemail">
    <!-- tab -->
    <div class="com-title-wrap">
      <div class="com-title-inner">
        <div class="leftheade">
          <span :class="['com-title', curindex == 0 ? 'active' : '']" @click="curindex = 0">收件箱</span>
          <span :class="['com-title', curindex == 1 ? 'active' : '']" @click="curindex = 1">发件箱</span>
          <span :class="['com-title', curindex == 2 ? 'active' : '']" @click="curindex = 2">草稿箱</span>
        </div>
        <div class="righthead">
          <div class="writebtn" @click="writeEmail">写邮件</div>
          <div class="searchbox">
            <input type="text" placeholder="搜索" class="inputbox" :value="searchVal" ref="searchVal" @input="input" @keydown.enter="getReceiveData(10, 1, emialType, searchVal)" />
            <i class="iconfont icon-Magnifier search-btn" @click="getReceiveData(10, 1, emialType, searchVal)"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 收件箱 -->
    <div class="tablelist" v-show="curindex == 0">
      <div class="hasdata" v-if="receiveData.length">
        <div class="rows">
          <div class="row" v-for="(item, index) in receiveData" :key="index" @click="godetail(item['nc_class_train_mailbox_sub_id'], item)">
            <div class="name">
              <div :class="['workicon', item['yjzt_text'] == '未读' ? '' : 'isread']"></div>
              <span class="addresser">{{ item["ds_create_user_name"] }}</span>
              <!-- <span class="emailtitle"
                >{{ item.title.indexOf("回复") != -1 ? "" : "回复："
                }}{{ item.title }}</span -->
              <span class="emailtitle">{{ item.title }}</span>
              <span class="emailcontent" v-html="item.content"></span>
            </div>
            <div class="uptime">{{ item.fssj }}</div>
          </div>
        </div>
        <el-pagination
          style="margin-top: 100px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          layout="prev, pager, next ,total, jumper, sizes"
          :total="total"
        ></el-pagination>
      </div>
      <div class="hasdata" v-else>
        <div class="nodata"></div>
      </div>
    </div>
    <!-- 发件箱 -->
    <div class="tablelist" v-show="curindex == 1">
      <div class="hasdata" v-if="SendData.length">
        <div class="rows">
          <div class="row" v-for="(item, index) in SendData" :key="index" @click="gosendDetail(item['nc_class_train_mailbox_sub_id'], item)">
            <div class="name">
              <span class="addresser">{{ item["jsr_text"] }}</span>
              <span class="emailtitle">{{ item.title }}</span>
              <span class="emailcontent" v-html="item.content"></span>
            </div>
            <div class="uptime">{{ item.fssj }}</div>
          </div>
        </div>
        <el-pagination
          style="margin-top: 100px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          layout="prev, pager, next ,total, jumper, sizes"
          :total="total"
        ></el-pagination>
      </div>
      <div class="hasdata" v-else>
        <div class="nodata"></div>
      </div>
    </div>
    <!-- 草稿箱 -->
    <div class="tablelist" v-show="curindex == 2">
      <div class="hasdata" v-if="draftData.length">
        <div class="rows">
          <div class="row" v-for="(item, index) in draftData" :key="index" @click="savepost(item)">
            <!-- @click="godetail(id)" -->
            <div class="name">
              <!-- <span class="addresser">{{}}</span> -->
              <span class="emailtitle">{{ item.title }}</span>
              <span class="emailcontent" v-html="item.content"></span>
            </div>
            <div class="uptime">{{ item["ds_create_time"] }}</div>
            <!-- <div class="uptime">{{ initDate(item["publisher_time"]) }}</div> -->
          </div>
        </div>
        <el-pagination
          style="margin-top: 50px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          layout="prev, pager, next ,total, jumper, sizes"
          :total="total"
        ></el-pagination>
      </div>
      <div class="hasdata" v-else>
        <div class="nodata"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcXlyTeachEmail",
  ctrlCaption: "贵州训练营班主任信箱",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      receiveData: [],
      SendData: [],
      draftData: [],
      emialType: 1,
      detailList: {},
      curindex: 0,
      searchVal: "",

      currentPage4: 1,
      total: null,
      isread: false,
      newPageSize: 10,
      newPageNum: 1,
      detailTime: null,
    };
  },
  created() {
    // 默认 收件 1页10条
    this.getReceiveData(10, 1, 1, "");
    // this.getTotal(10, 1, 1, "");
  },
  mounted() {},
  watch: {
    curindex: {
      handler(newval, oldval) {
        console.log(12456);
        if (newval == 0 && newval != oldval) {
          this.getReceiveData(this.newPageSize, this.newPageNum, 1, this.searchVal);
        }
        if (newval == 1 && newval != oldval) {
          this.getReceiveData(this.newPageSize, this.newPageNum, 2, this.searchVal);
        }
        if (newval == 2 && newval != oldval) {
          this.getReceiveData(this.newPageSize, this.newPageNum, 3, this.searchVal);
        }
      },
    },
  },
  methods: {
    savepost(item) {
      let _that = this;
      dsf.layer.openDialog({
        title: "发送邮件",
        width: "1300px",
        height: "500px",
        showClose: true,
        params: {
          path: `/nc/class/train/mailbox/edit?id=${item._id}&classId=${this.$route.query.id}`,
          detailList: item,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                if (!data["nc_class_train_mailbox.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.jsr"]) {
                  dsf.layer.message("接收人不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  _that.emailPost(data, "send", "发送成功", item);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 搜索
    input() {
      this.$nextTick(() => {
        this.searchVal = this.$refs.searchVal.value;
        if (this.$refs.searchVal.value.trim().length == 0) {
          this.getReceiveData(this.newPageSize, this.newPageNum, this.emialType, this.searchVal);
        }
        // console.log(this.searchVal, "searchVal");
      });
    },
    // 写邮件按钮
    writeEmail() {
      let _that = this;
      dsf.layer.openDialog({
        title: "写邮件",
        width: "1300px",
        height: "500px",
        showClose: true,
        params: {
          path: `/nc/class/train/mailbox/edit?id=${location.href.split("=")[1]}`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                if (!data["nc_class_train_mailbox.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.jsr"]) {
                  dsf.layer.message("接收人不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  _that.emailPost(data, "send", "发送成功");
                }
              });
            },
          },
          {
            text: "暂存",
            handler: function (res) {
              return res.yes().then(function (data) {
                if (!data["nc_class_train_mailbox.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.jsr"]) {
                  dsf.layer.message("接收人不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_mailbox.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  _that.emailPost(data, "save", "暂存成功");
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    handleSizeChange(val) {
      this.newPageSize = val;
      this.getReceiveData(this.newPageSize, this.newPageNum, this.emialType, this.searchVal);
    },
    handleCurrentChange(val) {
      this.newPageNum = val;
      this.getReceiveData(this.newPageSize, this.newPageNum, this.emialType, this.searchVal);
    },
    // 列表数据
    getReceiveData(pageSize, pageNum, type, query) {
      let that = this;
      // 记录type便于搜索
      that.emialType = type;
      const mainUrl = "/nc/class/train/mail/queryMailBox";
      const classId = this.$route.query.id;
      const params = {
        pageSize,
        pageNum,
        classId,
        type,
        query,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "classId");
            if (type == 1) {
              that.receiveData = res.data;
            }
            if (type == 2) {
              that.SendData = res.data;
            }
            if (type == 3) {
              that.draftData = res.data;
            }
            that.getTotal(10, 1, that.emialType, that.searchVal);
          } else {
         /*    that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            }); */
            dsf.layer.message(res.message || "获取列表数据异常", false);
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取收件箱详情
    godetail(id, item) {
      item["yjzt_text"] = "已读";
      let that = this;
      const mainUrl = "/nc/class/train/mail/queryMailDetail";
      const params = {
        mailSubId: id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.detailList = res.data;
            // eventBus.$emit("getDetails", res.data);
            let replylog = dsf.layer.openDialog({
              title: "邮件详情",
              width: "1300px",
              height: "500px",
              showClose: true,
              params: {
                path: `nc/class/train/mailbox/detail?id=${id}`,
                detailList: that.detailList,
              },
              btns: [
                {
                  text: "回复",
                  handler: function (res) {
                    //教辅邮件详回复情弹窗
                    dsf.layer.openDialog({
                      title: "回复邮件",
                      width: "1300px",
                      height: "500px",
                      showClose: true,
                      params: {
                        path: `nc/class/train/mailbox/studentResponse`,
                        detailList: that.detailList,
                      },
                      btns: [
                        {
                          text: "发送",
                          handler: function (res) {
                            return res.yes().then(function (data) {
                              if (!data["nc_class_train_mailbox.title"]) {
                                dsf.layer.message("标题不能为空", false);
                                // 返回false可以阻止弹窗关闭
                                return false;
                              } else if (!data["nc_class_train_mailbox.jsr"]) {
                                dsf.layer.message("接收人不能为空", false);
                                // 返回false可以阻止弹窗关闭
                                return false;
                              } else if (!data["nc_class_train_mailbox.content"]) {
                                dsf.layer.message("内容不能为空", false);
                                // 返回false可以阻止弹窗关闭
                                return false;
                              } else {
                                const mainUrl = `/inc/meta/persistData`;
                                let mailSubId = replylog.options.params.detailList.mailSubId;
                                // let that = this;
                                let actionType = "send";
                                let isNewEmail = "";
                                let namespace = "nc.class.train.mailbox";
                                let pageName = "studentResponse";
                                let classId = that.$route.query.id;

                                const params = {
                                  classId,
                                  namespace,
                                  pageName,
                                  data: JSON.stringify(data),
                                  mailSubId,
                                  actionType,
                                  isNewEmail,
                                };
                                dsf.http
                                  .post(mainUrl, params)
                                  .done(function (res) {
                                    if (res.success) {
                                      dsf.layer.message("回复成功");
                                    } else {
                                      dsf.layer.message(res.message || "获取列表数据异常", false);
                                    }
                                  })
                                  .error(function (error) {
                                    // that.dsf.layer.toast("服务器异常", false);
                                  })
                                  .always(function () {
                                    // that.dsf.layer.closeLoading(loader);
                                  });
                              }
                            });
                          },
                        },
                        {
                          text: "关闭 ",
                        },
                      ],
                    });
                    //回复弹窗结束
                  },
                },
                {
                  text: "关闭 ",
                },
              ],
            });
          } else {
          /*   that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            }); */
            dsf.layer.message(res.message || "获取列表数据异常", false);
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取发件箱详情
    gosendDetail(id, item) {
      // item["yjzt_text"] = "已读";
      let that = this;
      const mainUrl = "/nc/class/train/mail/queryMailDetail";
      const params = {
        mailSubId: id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.detailList = res.data;
            // eventBus.$emit("getDetails", res.data);
            let replylog = dsf.layer.openDialog({
              title: "邮件详情",
              width: "1300px",
              height: "500px",
              showClose: true,
              params: {
                path: `nc/class/train/mailbox/detail?id=${id}`,
                detailList: that.detailList,
              },
              btns: [
                {
                  text: "关闭 ",
                },
              ],
            });
          } else {
            /* that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            }); */
            dsf.layer.message(res.message || "获取列表数据异常", false);
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取total
    getTotal(pageSize, pageNum, type, query) {
      let that = this;
      const mainUrl = "/nc/class/train/mail/queryMailBoxCount";
      const params = {
        classId: location.href.split("=")[1],
        pageSize,
        pageNum,
        type,
        query,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data.count;
          } else {
            dsf.layer.message(res.message || "获取列表数据异常", false);
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 封装发邮件
    emailPost(data, actionType, message, item) {
      let that = this;
      const mainUrl = `/inc/meta/persistData?id=${location.href.split("=")[1]}`;
      let mailSubId = item ? item["nc_class_train_mailbox_sub_id"] : "";
      let isNewEmail = "";
      let mailId = item ? item["nc_class_train_mailbox_id"] : "";
      let namespace = "nc.class.train.mailbox";
      let pageName = "edit";
      let classId = this.$route.query.id;
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        mailId,
        mailSubId,
        actionType,
        isNewEmail,
        classId,
      };
      dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            if (that.curindex == 1 || that.curindex == 2) {
              that.getReceiveData(that.newPageSize, that.newPageNum, that.emialType, that.searchVal);
            }
          } else {
            dsf.layer.message(res.message || "获取列表数据异常", false);
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
