import * as dd from "dingtalk-jsapi";
import openLink from "dingtalk-jsapi/api/biz/util/openLink";
const corpId = dsf.url.queryString("corpId");
const { setting_public_mobileindex_url, setting_public_pcindex_url, setting_nc_ding_goods_code, setting_nc_ding_conversation_token } = dsf.config;

function ddLogin(code) {
  const loginUrl = "/nc/login/ding";
  const params = {
    code: code,
    corpId: corpId,
  };
  dsf.http
    .post(loginUrl, params)
    .done(res => {
      if (res.success) {
        const path = dsf.client.mobile() ? setting_public_mobileindex_url : setting_public_pcindex_url;
        dsf.cookies.set("isLogin", 1);
        dsf.cookies.set("corpId", corpId);
        if (dsf.client.mobile()) {
          location.href = dsf.config.setting_public_mobileindex_url;
        } else {
          const sourceUrl = "/ssologin/getSsoUrl";
          const params = {
            goUrl: dsf.config.setting_public_pcindex_url,
            goServe: location.origin + dsf.config.webRoot.default,
          };
          dsf.http
            .get(sourceUrl, params)
            .done(({ success, data, message }) => {
              if (success) {
                window.open(data);
                setTimeout(() => {
                  location.href = "/page.html#/pc/welcome";
                }, 1000);
              } else {
                dsf.layer.pc.message("跳转首页:" + message, false);
              }
            })
            .error(error => {
              dsf.httpError(error);
            });
        }

        // location.href = path;
      } else {
        dsf.layer.message("自动登录:" + res.message, false);
      }
    })
    .error(() => {
      dsf.layer.message("自动登录:" + err.message, false);
    });
}

function initDdConfig() {
  return new Promise((resolve, reject) => {
    const sourceUrl = "/api/ding/publics/config";
    dsf.http
      .get(sourceUrl, { corpId: corpId })
      .done(res => {
        if (res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .error(error => {
        reject(error);
      });
  });
}

function authentication() {
  initDdConfig()
    .then(res => {
      dd.ready(function() {
        dd.runtime.permission.requestAuthCode({
          corpId: corpId,
          onSuccess: res => {
            ddLogin(res.code);
          },
          onFail: err => {
            dsf.layer.message("获取authCode失败");
          },
        });
      });
    })
    .catch(err => {
      dsf.layer.message("API：/api/ding/publics/config" + err.message, false);
    });
}

const platformInit = {
  initLogin: function() {
    if (dd && dd.version && dd.env.platform != "notInDingTalk") {
      authentication();
    } else {
      console.log("当前环境", dsf.client);
    }
  },
  openSverice: function() {
    const path = `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?wh_biz=tm&showmenu=false&goodsCode=${setting_nc_ding_goods_code}&corpId=${corpId}&token=${setting_nc_ding_conversation_token}`;
    openLink({
      url: path,
      onSuccess: function(result) {
        /**/
      },
      onFail: function(err) {
        dsf.layer.message(JSON.stringify(err), false);
      },
    });
  },
};
export default platformInit;