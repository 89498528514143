<template>
  <div class="dsf_nc_special_detail_course_item">
    <ul class="nc_detail_wrap" v-if="lists.length">
      <li :class="['nc_detail_item', { gjms: item.advancedMode }]" v-for="(item, index) in lists" :key="index">
        <div class="detail_top">
          <div class="title_box">
            <span class="icon iconfont icon-icon_shiyongwendang"></span>
            <div class="item_title">{{ item.unitName }}</div>
            <span class="font">
              &nbsp;&nbsp;(选修: {{ item.electives || 0 }}门/{{ item.electivesPeriod || 0 }}学时&nbsp;&nbsp;必修: {{ item.compulsory || 0 }}门/{{ item.compulsoryPeriod || 0 }}学时)
            </span>
          </div>
          <div class="item_top_right" @click="clickFold(index)">
            <span v-if="item.fold" class="font">展开</span>
            <span v-else class="font">收起</span>
            <span class="icon iconfont icon-xia1 sq" v-if="item.fold"></span>
            <span class="icon iconfont icon-shang1 sq" v-if="!item.fold"></span>
          </div>
        </div>
        <div class="detail_desc" v-if="item.fold && item.advancedMode">
          <div class="detail_desc_item" v-for="(items, idx) in item.subList" :key="items.subId">
            <div class="detail_desc_left">
              <span
                :style="{
                  background: `${items.typeValue == 'work' ? 'rgb(112, 146, 190)' : items.typeValue == 'asq' ? '#ff6f73' : ''}`,
                }"
                :class="['type_name', items.typeValue == 'work' ? '' : items.typeValue]"
              >
                {{ items.typeText == "课程包" ? "课程" : items.typeText }}
              </span>
              &nbsp;
              <div class="type_category_box">
                <span class="type_category ell">
                  <span
                    :style="{
                      color: `${items.categoryText == '必修' ? '#ff6f73' : '#69c33b'}`,
                      marginRight: '10px',
                    }"
                  >
                    【{{ items.categoryText }}】
                  </span>
                  {{ items.name }}
                </span>
                <span class="type_category_hover" v-show="items.name.length > 15">{{ items.name }}</span>
              </div>
              <div class="date font" :style="`opacity:${items.timeSdate && items.timeEdate ? '1' : '0'}`">
                {{ dsf.date.format(items.timeSdate, "yyyy-mm-dd hh:ii") || "" }}
                至
                {{ dsf.date.format(items.timeEdate, "yyyy-mm-dd hh:ii") || "" }}
              </div>
              <div class="wcxs font">{{ items.period || "0" }}学时</div>
              <div class="teacher font ell">{{ items.teacher || "-" }}</div>
              <div class="progress">
                <span class="font">已学: {{ items.progress || 0 }}%</span>
              </div>
            </div>
            <div class="detail_desc_right">
              <div
                class="enter_btn"
                ref="enter_btn"
                :style="`${
                  isstudent == 1
                    ? ''
                    : itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !isview && getbtninfo(items) != '待批阅'
                    ? ''
                    : 'color: #aaa; cursor: default'
                }`"
                @click="
                  isstudent == 1
                    ? clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null, 1)
                    : showClick && clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)
                "
              >
                {{ getbtninfo(items) }}
              </div>
            </div>
          </div>
        </div>
        <div class="detail_desc" v-else-if="item.fold && !item.advancedMode">
          <div class="detail_desc_item" v-for="(items, idx) in item.subList" :key="items.subId">
            <div class="detail_desc_left">
              <span
                :style="{
                  background: `${items.typeValue == 'work' ? 'rgb(112, 146, 190)' : ''}`,
                }"
                :class="['type_name', items.typeValue == 'work' ? '' : items.typeValue]"
              >
                {{ items.typeText == "课程包" ? "课程" : items.typeText }}
              </span>
              &nbsp;
              <div class="type_category_box">
                <span class="type_category ell">{{ items.name }}</span>
                <span class="type_category_hover" v-show="items.name.length > 15">{{ items.name }}</span>
              </div>
              <div :class="['font', !!items.categoryValue && Number(items.categoryValue) ? 'bx' : 'xx']">
                {{ items.categoryText }}
              </div>
              <div class="duration_box">
                <div class="font duration ell">{{ setDuration(items) }}</div>
                <div :class="['duration_hover', { duration_none: setDuration(items) == '-' }]" v-if="items.typeValue == 'live' || items.typeValue == 'exam'">
                  {{ setDuration(items) }}
                </div>
              </div>
              <div class="wcxs font">{{ items.period || "0" }}学时</div>
              <div class="teacher font ell">{{ items.teacher || "-" }}</div>
              <div class="progress">
                <el-progress :percentage="items.progress || 0" stroke-width="10" :show-text="false"></el-progress>
                <span class="pivot-txt">{{ items.progress || 0 }}%</span>
              </div>
            </div>
            <div class="detail_desc_right">
              <div
                class="enter_btn"
                ref="enter_btn"
                :style="`${
                  isstudent == 1
                    ? ''
                    : itemStatus(items, item, idx, idx > 0 ? item.subList[idx - 1].progress : null) && items.playState && !isview && getbtninfo(items) != '待批阅'
                    ? ''
                    : 'color: #aaa; cursor: default'
                }`"
                @click="
                  isstudent == 1
                    ? clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null, 1)
                    : showClick && clickEnter(item, items, item.classId, item, idx, idx > 0 ? item.subList[idx - 1].progress : null)
                "
              >
                {{ getbtninfo(items) }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "dsfNcSpecialDetailCourseItem",
  props: {
    lists: {
      type: Array,
      default: () => {
        return [];
      },
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isview: {
      type: Boolean,
      default: false,
    },
    issfax: {
      type: String,
    },
  },
  computed: {
    ...mapState(["isstudent"]),
  },
  data() {
    return {
      showClick: false,
      isCanClick: false,
      workInfo: {},
      textinfo: "",
    };
  },
  created() {
    // this.getIsStudent();
  },
  methods: {
    // ...mapActions(["getIsStudent"]),
    getDetail(id) {
      let that = this;
      const mainUrl = `/inc/nc/homework/student/details?homeworkId=${id}`;
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.dataList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          that.dsf.layer.closeLoading(loader);
        });
    },
    // 刷新数据
    getList() {
      let that = this;
      console.log(this.$route.query.id, "that.queryString.id---------------");
      const mainUrl = "/inc/nc/class/course/list";
      const params = {
        id: this.$route.query.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "=================");
            that.lists = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getbtninfo(items) {
      // console.log(items, "文字·");
      let info = "进入";
      if (items.typeValue == "work") {
        if (items.progress == 100) {
          info = "查看";
        }
        if (items.progress == 0) {
          if (items.homeWorkState == "0") {
            info = "撰写";
          }
          if (items.homeWorkState == "1") {
            info = "待批阅";
          }
          if (items.homeWorkState == "-1") {
            info = "退回";
          }
        }
        this.textinfo = info;
      }
      return info;
    },
    clickFold(index) {
      this.lists[index]["fold"] = !this.lists[index]["fold"];
    },
    setDuration(item) {
      console.log(item, "item");
      let durationHtml = "";
      switch (item.typeValue) {
        case "course": // 课程
          durationHtml = item.duration;
          break;
        case "live": // 直播
          durationHtml = item.duration ? "开播: " + item.duration : "-";
          break;
        case "exam": // 考试
          durationHtml = item.duration ? "开考: " + item.duration : "-";
          break;
        // case "courses": // 课程包
        //   durationHtml = item.duration;
        //   break;
        default:
          durationHtml = item.duration;
          break;
      }
      return durationHtml || "-";
    },
    itemStatus(item, parentItem, childIndex, lastProgress) {
      //当前单元不在学习范围内
      if (!item.playState) return false;
      if (this.issfax == "1" && parentItem.study == "0") return false;
      // 能点击 &&  当前单元没有设置排序
      if (this.showClick && parentItem.orderTypeValue == "0") return true;
      // 能点击 && 当前单元设置了排序 && 不是第一节课 && 上个进度为100
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex > 0 && lastProgress == 100) return true;
      //能点击 && 当前单元设置了排序 && 当前是第一节课
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex == 0) return true;
      // 能点击 &&  当前单元设置了排序 && 当前进度100
      if (this.showClick && parentItem.orderTypeValue == "1" && item.progress == 100) return true;
      //  状态为结业
      if (this.detailData.state == 3) return true;
      // if (
      //   this.detailData.state == 3 ||
      //   (this.issfax == "1" && parentItem.study == "1")
      // )

      return false;
    },
    clickEnter(item, items, specialId, parentItem, childIndex, lastProgress, teacher) {
      let statues = this.itemStatus(items, parentItem, childIndex, lastProgress);
      if (teacher) statues = true;
      if (!items.playState && this.isstudent != 1) return dsf.layer.message("不在学习时间范围", false);
      if (!statues || this.isview) return dsf.layer.message("请按序进行学习", false);

      // 点击进入
      let that = this;
      let type = items.typeValue;
      let classid = specialId; // 专题班id
      let id = items.businessId; // 课程id;考试id
      let url = "";
      console.log(type, id, items);
      if (type == "work") {
        if (this.getbtninfo(items) == "待批阅") return;
        // if (this.getbtninfo(items) == "退回") {
        //   dsf.layer.openDialog({
        //     title: that.workInfo.name,
        //     width: "400px",
        //     height: "350px",
        //     showClose: true,
        //     showCancelButton: true,
        //     closeOnClickModal: false,
        //     closeOnPressEscape: false,
        //     content: "DsfNcHomeworkSummary",
        //     // params: {
        //     //   path: "/pc/nc/page/pc/special/isaccept",
        //     // },
        //     // btns: [
        //     //   {
        //     //     text: "发送",
        //     //     handler: function (res) {
        //     //       return res.yes().then(function (data) {
        //     //         that.getWorkDesc(id, items);
        //     //       });
        //     //     },
        //     //   },
        //     //   {
        //     //     text: "关闭 ",
        //     //   },
        //     // ],
        //   });
        // } else {
        that.getWorkDesc(id, items);
        // }
      }
      switch (type) {
        case "course": // 课程
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "courses": // 课程包
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "video": // 视频
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "audio": // 音频
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "draft": // 文稿
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "media": // 富媒体
          url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
          break;
        case "live": // 直播
          url = `/page.html#/pc/nc/pagelive/liveDetail?id=${id}`;
          break;
        case "exam": // 考试
          this.toExamDetail(items, specialId);
          break;
        case "asq": // 问卷
          let params = {
            asqId: items.asqInfoId,
            businessType: "1",
            subId: items.subId,
          };
          this.dsf.http
            .get("/nc/asq/api/getAsqUrl", params)
            .done(res => {
              if (res.success) {
                url = res.data;
              }
              this.$open({ url, state: 2 });
            })
            .error(err => {
              dsf.layer.message(err.message || "获取考试异常", false);
            })
            .always(function () {});
          break;
        default:
          break;
      }
      this.$open({ url, state: 2 });
    },
    getWorkDesc(id, items) {
      // 为0
      // if (!items.progress) {
      let that = this;
      // const mainUrl = `/nc/class/learning/assess`;
      // // const mainUrl = `/inc/nc/homework/assessment/wait-reading/${id}`;
      // that.dsf.http
      //   .get(mainUrl)
      //   .done(function (res) {
      //     if (res.success) {
      // console.log(res.data, "课程描述00");
      // that.workInfo = res.data;
      dsf.layer.openDialog({
        title: items.name,
        width: "1000px",
        height: "550px",
        showClose: true,
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        content: "DsfNcGzHomeworkSummary",
        // content: "DsfNcHomeworkSummary",
        // params: {
        //   path: "/pc/nc/page/pc/special/unitwork",
        //   data: res.data,
        // },
        params: {
          state: items.homeWorkState,
          homeworkId: items.businessId,
          studentId: items.homeWorkStudentId,
          cb: data => {
            if (data) {
              // this.reData();
              that.getList();
            }
          },
        },
      });
      //   } else {
      //     that.message({
      //       message: res.message || "获取列表数据异常",
      //       type: "warning",
      //     });
      //   }
      // })
      // .error(function (error) {
      //   // that.dsf.layer.toast("服务器异常", false);
      // })
      // .always(function () {
      //   // that.dsf.layer.closeLoading(loader);
      // });
      // } else {
      //   let that = this;
      //   dsf.layer.openDialog({
      //     title: "查看作业",
      //     width: "1000px",
      //     height: "550px",
      //     showClose: true,
      //     showCancelButton: true,
      //     closeOnClickModal: false,
      //     closeOnPressEscape: false,
      //     params: {
      //       path: `nc/page/pc/special/workview?id=${that.workInfo["nc_homework_id"]}`,
      //       data: that.workInfo,
      //     },
      //     btns: [
      //       {
      //         text: "关闭 ",
      //       },
      //     ],
      //   });
      // }
    },
    postrequest(data, thats) {
      console.log(data, "数据");
      let that = this;
      const mainUrl = "/inc/nc/homework/assessment/writing";
      const params = {
        content: data.content,
        homeworkId: data.homeworkId,
        title: data.title,
      };
      that.dsf.http
        .post(mainUrl, params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("作业撰写成功");
            thats.getList();
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },

    iswrite() {},
    toExamDetail(item) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: item["businessId"], //考试id
        businessId: item["subId"], //业务id
        businessType: 1,
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setIcon(status) {
      let iconText = "";
      switch (status) {
        case "course" || "video" || "audio" || "courses":
          iconText = "icon-shipin1";
          break;
        case "draft":
          iconText = "icon-wengao-1";
          break;
        case "live":
          iconText = "icon-a-zhibobofangshexiangjitianxianmianxing";
          break;
        case "exam":
          iconText = "icon-kaoshi";
          break;
        case "media":
          iconText = "icon-fumeitixiaoxi";
          break;
        case "work":
          iconText = "icon-kehouzuoye";
          break;
        default:
          iconText = "";
      }
      return iconText;
    },
  },
  watch: {
    lists: {
      handler(val) {
        val.forEach(element => {
          this.$set(element, "fold", true);
        });
      },
      immediate: true,
    },
    detailData: {
      handler(val) {
        if (val.state == 2 || val.state === 3) {
          this.showClick = true;
        } else {
          this.showClick = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
