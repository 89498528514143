<template>
  <div class="dsf_nc_allsearh">
    <div class="content">
      <div class="content-inner">
        <div class="search-com-wrap">
          <p class="search-title">首页 > 搜索</p>
          <p class="search-remind" id="SearchResultMsg" v-if="count">
            <!-- 您搜索的内容“{{ keyword }}”，共有{{ count }}条相关内容 -->
            以下为搜索结果
          </p>
          <ul class="search-filters" id="SearchFilters">
            <label class="search-filters-title">类型 :</label>

            <li :class="{ 'search-filters-active': index == checkedIndex }" v-for="(item, index) in typearr" :key="index" @click="checkedtype(index, item)">
              {{ item.name == "专题班" ? "班级" : item.name }}
              <!-- （{{
                item.name == "全部" ? count : item.groupcount
              }}） -->
            </li>
          </ul>
          <!-- <ul class="search-filters search-date">
            <label class="search-filters-title">时间 : </label>
            <el-date-picker
              v-model="value1"
              @change="changetiem"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
            >
              >
            </el-date-picker>
          </ul> -->
          <div class="search-list" id="SearchResult">
            <template v-for="(item, index) in dataList">
              <div :key="index">
                <div class="search-item" data-typeid="00" v-for="(items, indexs) in item.result" :key="indexs" @click="godetail(items)">
                  <h3 class="search-item-title ComTitleClick" data-id="cdc7bb19c69c426c9545a0c3b5fcd9f6">
                    <i :class="['search-item-title-icon', steicon(items)]"></i>
                    <span style="lineHeight: '49px',display:inlineBlock">{{ items.title }}</span>
                    <a href="javascript:void(0);" class="CommonMoreHref" @click.stop="gomore(item)" v-show="serchType == '20'">更多</a>
                  </h3>
                  <ul class="search-item-course ComTitleClick" data-id="cdc7bb19c69c426c9545a0c3b5fcd9f6">
                    <li>
                      <a href="javascript:void(0)">
                        <span class="search-item-course-title" v-html="items.remark"></span>
                        <span class="search-item-course-time">{{ items.fbrq }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcAllsearch",
  ctrlCaption: "贵州全局搜索页面",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      currentPage4: 1,
      value1: "",
      npageNum: 1,
      npageSize: 10,
      total: 0,
      count: 0,
      checkedIndex: 0,
      typearr: [{ name: "全部", typeid: "20" }],
      typearrtwo: ["全部"],
      keyword: "",
      serchType: "20",
    };
  },
  watch: {},
  created() {
    this.keyword = decodeURI(decodeURI(location.href.split("search=")[1]));
    this.getData(1);
    eventBus.$on("allsearch", data => {
      this.keyword = data[0];
      this.typearr = [{ name: "全部", typeid: "20" }];
      this.checkedIndex = 0;
      if (data[1] == "#/pc/nc/pagehome/index") {
        this.getData(1);
      } else {
        this.getData(2);
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle);
    this.eventHub.$off("allsearch");
  },
  methods: {
    scrollHandle(e) {
      this.$nextTick(() => {
        if (this.loading || this.loaded) return;
        const docH = document.querySelector(".search-list")?.offsetHeight;
        if (this.dataList[0]?.result.length < 10) return;
        if (e.target.scrollTop > this.npageNum * docH - 580 && this.checkedIndex) {
          ++this.npageNum;
          this.getType();
        }
      });
    },
    // 时间选择
    changetiem(val) {
      if (val && val[0] == val[1]) {
        console.log(val[1]);
      }
      console.log(val);
    },
    // 类型切换
    checkedtype(index, item) {
      this.checkedIndex = index;
      this.serchType = item.typeid;
      this.npageNum = 1;
      if (item.typeid == "20") {
        this.getData();
      } else {
        this.getType(1);
      }
    },
    steicon(items) {
      let iconclass = null;
      switch (items.typename) {
        case "课程":
          iconclass = "search-item-title-icon-kc";
          break;
        case "专题班":
          iconclass = "search-item-title-icon-ztb";
          break;
        case "简报":
          iconclass = "search-item-title-icon-pd";
          break;
        case "专栏":
          iconclass = "search-item-title-icon-pd";
          break;
        case "学员":
          iconclass = "search-item-title-icon-xy";
          break;
        case "名师":
          iconclass = "search-item-title-icon-ms";
          break;
        case "新闻":
          iconclass = "search-item-title-icon-xw";
          break;
        case "直播":
          iconclass = "search-item-title-icon-zb";
          break;
        case "活动":
          iconclass = "search-item-title-icon-hd";
          break;
        default:
          break;
      }
      return iconclass;
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    gomore(item) {
      // 发筛选请求
      console.log(this.typearrtwo.indexOf(item.typename));
      this.checkedIndex = this.typearrtwo.indexOf(item.typename);
      this.serchType = item.typeid;
      this.dataList = [];
      this.getType();
    },
    godetail(items) {
      let { id } = items;
      let that = this;
      switch (items.typename) {
        case "课程":
          that.checkUserAuth(id);
          break;
        case "专题班":
          if (items["type_value"] == "1") {
            // 专题班
            window.open("/page.html#/pc/nc/pagespecial/specialDetail?id=" + id);
          } else if (items["type_value"] == "2") {
            // 训练营
            window.open("/ncIndex.html#/pc/nc/page/pc/teachassistant/train?id=" + id);
          } else if (items["type_value"] == "3") {
            // 考试班
            // window.open("/page.html#/pc/nc/pagespecial/specialDetail?id=" + id);
          } else if (items["type_value"] == "4") {
            // 微专业
            // window.open("/page.html#/pc/nc/pagespecial/specialDetail?id=" + id);
          }
          break;
        case "简报":
          window.open("/page.html#/pc/nc/brief/PCHome?id=" + id);
          break;
        case "专栏":
          window.open("/page.html#/pc/nc/page/pc/commonpage/classcolumn?id=" + id);
          break;
        case "学员":
          //   window.open("/page.html#/pc/nc/pagecourse/coursePlayer?id=" + id);
          break;
        case "名师":
          window.open("/page.html#/pc/nc/pageteacher/teachersDetail?id=" + id);
          break;
        case "新闻":
          window.open("/ncIndex.html#/pc/nc/page/pc/commonpage/guide?id=" + id);
          break;
        case "直播":
          window.open("/page.html#/pc/nc/pagelive/liveDetail?id=" + id);
          break;
        case "活动":
          //   window.open("/page.html#/pc/nc/pagecourse/coursePlayer?id=" + id);
          break;
        default:
          break;
      }
    },
    // 课程权限
    checkUserAuth(id) {
      window.open("/page.html#/pc/nc/pagecourse/coursePlayer?id=" + id);
      //   let checkUrl = dsf.url.getWebPath("/nc/kjk/frontuse/kcZtbPriviledge");
      //   let params = {
      //     type: 2,
      //     relId: id,
      //   };
      //   dsf.http
      //     .request(checkUrl, params, "GET")
      //     .done(function (res) {
      //       console.log(res);
      //       if (res.success) {
      //   var path = dsf.url.getWebPath(
      //     "/page.html#/pc/nc/pagecourse/coursePlayer?id=" + id
      //   );
      //   window.open(path);
      //       } else {
      //         dsf.layer.message("您没有权限访问该课程", false);
      //       }
      //     })
      //     .error(function (err) {
      //       dsf.layer.message("课程检查权限接口异常,请稍后重试", false);
      //     })
      //     .always(function () {})
      //     .exec();
    },
    getData(type) {
      this.keyword = decodeURI(decodeURI(sessionStorage.getItem("keyword")));
      let that = this;
      const mainUrl = "/inc/nc/search/searchDocumentGroup";
      const params = {
        pageNum: 1,
        pageSize: 30,
        searchContent: this.keyword,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
            // type类型
            if (type == 1) {
              that.dataList.forEach(item => {
                that.typearr.push({
                  name: item.typename,
                  typeid: item.typeid,
                  groupcount: item["group_count"],
                });
                that.typearrtwo.push(item.typename);
                that.count += item.group_count;
              });
            } else if (type == 2) {
              that.count = 0;
              that.dataList.forEach(item => {
                that.typearr.push({
                  name: item.typename,
                  typeid: item.typeid,
                  groupcount: item["group_count"],
                });
                that.typearrtwo.push(item.typename);
                that.count += item.group_count;
              });
            }
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    getType(type) {
      let that = this;
      const mainUrl = "/inc/nc/search/searchAllByKeyWord";
      const params = {
        pageNum: this.npageNum,
        pageSize: this.npageSize,
        serchType: this.serchType,
        searchContent: this.keyword,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // 切换时变为空
            if (type) {
              that.dataList = [];
            }
            that.dataList.push(res.data);
            that.total = res.data.result.length;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
