<template>
  <div class="dsf_nc_xlydetail" ref="xlywindow" v-if="isdiscuss">
    <div class="breadbarlist">
      <div class="breadbar">
        <div class="home" @click.self="changeindex">
          <i class="iconfont icon-home"></i> 论坛首页 >
        </div>
        <span class="allpost" @click.self="allpage">所有帖子</span> >
        <span class="listtab">帖子详情</span>
      </div>
      <div class="postbtn" @click="pushbtn">发布帖子</div>
    </div>
    <!-- 内容 -->
    <div class="detail_itemtit" ref="content_box">
      <div class="detail_itemtit_wrap">
        <p class="detail-elip" id="mytitle">{{ detailinfo.content?.title }}</p>
        <div class="topicinfo">
          <span class="tcio"
            >楼主:<span class="val">{{
              detailinfo.content?.userName
            }}</span></span
          ><span class="tcio"
            >时间:<span class="val">{{
              detailinfo.content?.createTime
            }}</span></span
          >
        </div>
        <div class="topiccon" v-html="detailinfo.content?.content"></div>
      </div>
      <div class="rightcon">
        <div
          :class="['jslike', detailinfo.content?.isLike ? 'red' : '']"
          val="0"
          @click="makeGood"
        >
          <i class="iconfont"></i>
          <span>{{ detailinfo.content?.liked }}</span>
        </div>
        <div class="rightcon_wrap">
          <h6 class="jsback" @click="oneReply">
            <i class="iconfont"></i><span>回复</span>
          </h6>
          <h6 class="jsonlylook" val="0" @click="islook">
            {{ isOnlyLandlord ? "全部" : "只看楼主" }}
          </h6>
          <h6
            class="jsstar"
            val="0"
            @click="Collec"
            :style="{ color: `${isCollec ? '#DB3131' : ''}` }"
          >
            <i
              class="iconfont"
              :style="{ color: `${isCollec ? '#DB3131' : ''}` }"
              >{{ isCollec ? "" : "" }}</i
            ><span>{{ isCollec ? "已收藏" : "收藏" }}</span>
          </h6>
        </div>
      </div>
    </div>
    <!-- 讨论 -->
    <div class="detail_page_box" ref="page_box">
      <div
        class="bbs_item"
        indexid="2"
        v-for="(item, index) in detailinfo.comments?.commentArray"
        :key="index"
      >
        <div class="ileft" style="min-height: 170px">
          <div class="ileftbox">
            <img :src="setImg(item.photo)" />
          </div>
          <h6 class="layui-elip">{{ item["plr_text"] }}</h6>
          <h6 style="visibility: hidden" class="organ" title="null">null</h6>
          <h5 v-show="lzuserid == item['plr_value']"></h5>
          <h4 v-show="lzuserid == item['plr_value']">楼主</h4>
        </div>
        <div class="iright" style="min-height: 170px">
          <div class="irightbox">
            <div class="icitem">
              <p class="ell" title="内容">{{ item["comment_content"] }}</p>
            </div>
            <div class="icitemlast">
              <i>{{ detailinfo.comments?.commentArray.length - index }}楼</i>
              <p>1天前</p>
              <span class="jsdataback" @click="bottomReply(item)">回复</span>
            </div>
            <div class="commentbox" id="box2" v-show="item.childList">
              <p class="isshowh" @click="showAllReply(index)">
                <span class="jsisshowh" ref="jsisshowh"> 展开回复</span>
              </p>
              <div
                class="messbox"
                v-show="activeIndex.indexOf(index) > -1 ? true : false"
              >
                <div
                  class="replyitem"
                  v-for="(items, index) in item.childList || childList"
                  :key="index"
                >
                  <div class="replyitemcon">
                    <div class="leftimgbox">
                      <img :src="setImg(items.photo)" />
                    </div>
                    <div class="reprightbox">
                      <p class="p1">
                        <span>{{ items["plr_text"] }}</span
                        ><b>&nbsp;回复&nbsp;</b
                        ><span>{{ items["bplr_text"] }}：</span
                        ><i>{{ items["comment_content"] }}</i>
                      </p>
                      <p class="p2">
                        <span>{{ items["ds_create_time"] }}</span
                        ><b class="jschildrep" @click="bottomReply(items)"
                          >回复</b
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <p class="repmore" v-if="item.childList">
                  <span
                    @click="moreComment(item)"
                    class="jsmore"
                    v-show="item.childList.length >= 3 && ismore"
                    >更多评论</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="detailinfo.comments">
        <el-pagination
          v-if="detailinfo.comments.commentArray.length != 0"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="all_el-pagination"
        >
        </el-pagination>
        <div
          class="nodatas"
          v-if="detailinfo.comments.commentArray.length == 0"
        >
          暂无评论
        </div>
      </template>
    </div>
    <!-- 发表模块 -->
    <div class="replyinpbox">
      <ul class="bul layui-nav" lay-filter="demo">
        <li class="layui-nav-item layui-this">
          <a href="javascript:;">发表回复</a>
        </li>
        <span
          class="layui-nav-bar"
          style="width: 0px; left: 20px; opacity: 0; top: 55px"
        ></span>
      </ul>
      <div class="replybottomcon">
        <textarea
          :value="texttarevalue"
          @input="inputtextvalue"
          id="texta"
          name=""
          rows=""
          cols=""
          ref="texttarea"
          placeholder="请输入你的回复"
        ></textarea>
        <p @click="publish">
          <span class="jspublish">发表</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isactive: true,
      allactive: true,
      detailinfo: {},
      replyList: [],
      childList: [],
      isOnlyLandlord: false,
      isCollec: 0,
      activeIndex: [],
      ismore: true,
      isLike: 0,
      // 发帖参数
      commentId: "",
      texttarevalue: "",
      userName: "",
      userId: "",
      communityId: "",
      cooikeUserid: "",

      isdiscuss: true,
      ondata: null,
      total: 0,
      pageNum: 1,
      pageSize: 5,
      cpageSize: 3,
    };
  },
  props: {
    tabIndex: {
      type: Number,
      default: "",
    },
  },
  // watch: {
  //   tabIndex: {
  //     handler(newval) {
  //       console.log(newval, "neaval");
  //       if (newval == 6) {
  //         this.getdetailinfo(this.ondata);
  //       }
  //     },
  //   },
  // },
  created() {
    this.cooikeUserid = dsf.getCookie("userId");
    this.isOnlyLandlord = false;
    eventBus.$on("detailinfo", (data) => {
      this.ondata = data;
      this.getdetailinfo(this.ondata);
    });
    // console.log(this.tabIndex, "tabindex");
  },
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 1) {
          // this.getinitdata(-1);
        }
      },
    },
  },
  methods: {
    changeindex() {
      this.$emit("changeindex");
    },
    allpage() {
      this.$emit("allpage");
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getdetailinfo(this.ondata);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getdetailinfo(this.ondata);
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    // 评论回复
    bottomReply(item) {
      this.commentId = item["comment_id"];
      this.bplrText = item["plr_text"];
      this.bplrValue = item["plr_value"];
      this.communityId = this.detailinfo.content.communityId;
      this.gotext();
    },
    oneReply() {
      this.commentId = "";
      this.bplrText = this.detailinfo.content.userName;
      this.bplrValue = this.detailinfo.content.userId;
      this.communityId = this.detailinfo.content.communityId;
      this.gotext();
    },
    // 点赞
    makeGood() {
      let communityId = this.detailinfo.content.communityId;
      let that = this;
      const mainUrl = "/nc/class/train/community/like";
      const params = {
        // 帖子id
        communityId,
        value: that.isLike ? 0 : 1,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            if (!that.isLike) {
              dsf.layer.message("点赞成功");
            } else {
              dsf.layer.message("取消点赞成功");
            }
            that.getdetailinfo(that.ondata);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 收藏
    Collec() {
      let communityId = this.detailinfo.content.communityId;
      let value = this.isCollec ? 0 : 1;
      let that = this;
      const mainUrl = "/nc/class/train/community/collect";
      const params = {
        // 帖子id
        communityId,
        value,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            if (!that.isCollec) {
              that.isCollec = 1;
              dsf.layer.message("收藏成功");
            } else {
              that.isCollec = 0;
              dsf.layer.message("取消收藏成功");
            }
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取高度位移到指定位置
    gotext() {
      this.$nextTick(() => {
        this.$refs.texttarea.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "end",
          inline: "center",
        });
        this.$refs.texttarea.focus();
      });
    },
    // 同步文本域
    inputtextvalue() {
      this.$nextTick(() => {
        this.texttarevalue = this.$refs.texttarea.value;
      });
    },
    // 发表按钮
    publish() {
      // this.isdiscuss = false;
      // console.log(this.isdiscuss, "关了");
      let classValue = location.hash.split("=")[1];

      let that = this;
      // return;
      const mainUrl = "/nc/class/train/community/publishComment";
      const params = {
        // 评论id
        commentId: that.commentId,
        //  回复内容
        commentContent: that.texttarevalue,
        bplrText: that.bplrText,
        bplrValue: that.bplrValue,
        // 帖子id
        communityId: that.communityId,
        // plrText,
        // plrValue,
        classValue,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("评论成功");
            that.getdetailinfo(that.ondata);
            // that.$nextTick(() => {
            that.texttarevalue = "";
            // that.$refs.texttarea.value = "";
            // });
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 全部/楼主
    islook() {
      // 看楼主
      if (!this.isOnlyLandlord) {
        let that = this;
        const mainUrl = "/nc/class/train/community/onlyOwnerComment";
        const params = {
          communityId: that.detailinfo.content.communityId,
          owenId: that.detailinfo.content.userId,
          pageNum: that.pageNum,
          pageSize: that.pageSize,
        };
        that.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if (res.success) {
              console.log(res.data);
              that.detailinfo.comments.commentArray = res.data;
              that.isOnlyLandlord = !that.isOnlyLandlord;
            } else {
              that.message({
                message: res.message || "获取列表数据异常",
                type: "warning",
              });
            }
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            // that.dsf.layer.closeLoading(loader);
          });
      } else {
        // 看所有
        this.isOnlyLandlord = !this.isOnlyLandlord;
        this.getdetailinfo(this.ondata);
      }
    },
    // 详情信息
    getdetailinfo(data) {
      let communityId =
        data.communityId ||
        data["nc_class_train_community_id"] ||
        data.replyJson["nc_class_train_community_id"];
      let classId = data["class_value"] || location.hash.split("=")[1];
      let that = this;
      const mainUrl = "/nc/class/train/community/queryDetail";
      const params = {
        communityId,
        classId,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        cPageNum: 1,
        cPageSize: that.cpageSize,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.detailinfo = res.data;
            // 回复参数
            // that.commentId = this.commentId;
            that.commentContent = that.texttarevalue;
            that.bplrText = that.detailinfo.content.userName;
            that.bplrValue = that.detailinfo.content.userId;
            that.lzuserid = that.detailinfo.content.userId;
            that.communityId = that.detailinfo.content.communityId;
            that.total = that.detailinfo.count;
            // 收藏标识
            that.isCollec = res.data.content.isCollect;
            that.isLike = res.data.content.isLike;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 发帖弹窗
    pushbtn() {
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?class_value=${
            location.hash.split("=")[1]
          }`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  that.postrequest(data);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 发布请求
    postrequest(data) {
      let that = this;
      const mainUrl = "/inc/meta/persistData";
      let namespace = "nc.class.train.community";
      let pageName = "edit";
      let classId = this.$route.query.id;
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        classId,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("发布成功");
            that.getinindata(that.allpageNum, that.allpageSize, "", that.type);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    //更多评论
    moreComment(item) {
      console.log(item, "更多评论");
      let that = this;
      const mainUrl = "/nc/class/train/community/moreComment";
      const params = {
        classId: this.$route.query.id,
        commentId: item["nc_class_train_community_comment_id"],
        communityId: that.communityId,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "更多的数据");
            that.childList = res.data;
            that.cpageSize = 100;
            // 隐藏更多评论
            that.ismore = false;
            that.getdetailinfo(that.ondata);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    showAllReply(index) {
      let thisIdnex = this.activeIndex.indexOf(index);
      if (this.activeIndex.indexOf(index) > -1) {
        this.activeIndex.splice(thisIdnex, 1);
        this.$nextTick(() => {
          this.$refs.jsisshowh[index].innerHTML = "展开回复";
        });
      } else {
        this.$refs.jsisshowh[index].innerHTML = "收起回复";
        this.activeIndex.push(index);
      }
    },
  },
};
</script>

<style></style>
