<template>
  <div class="dsf-special-source-control-item">
    <div class="control-item" :class="{ 'item-readonly': readonly }">
      <div class="controll-head" style="position: relative">
        <div class="chapter-name-wrap">
          <el-input v-model="name" placeholder="请输入维护章节名称" class="chapter-name" @blur="chapterNameBlur" :disabled="readonly"></el-input>
        </div>

        <el-upload
          :action="actionurl"
          list-type="picture-card"
          :file-list="fileList"
          :auto-upload="true"
          :limit="1"
          v-if="uploadshow == 'channel'"
          :on-success="success"
          :headers="{
            'access-control-allow-credentials': true,
            'X-Powered-By': 'Express',
          }"
          :before-upload="beforeAvatarUpload"
        >
          <span style="color: #aaa">上传章节封面</span>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url ? file.url : cover && JSON.parse(cover)[0].relativePath" alt="" />
            <span class="el-upload-list__item-actions">
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <div class="controll-head-desc">
          <dl class="controll-head-desc-item" v-show="showMustBe">
            必修
            <span>{{ desc.compulsory || 0 }}</span>
            门
          </dl>
          <dl class="controll-head-desc-item" v-show="showMustBe">
            选修
            <span>{{ desc.electives || 0 }}</span>
            门
          </dl>
          <dl class="controll-head-desc-item">
            共
            <span>{{ desc.totalPeriod || 0 }}</span>
            学时
          </dl>
          <dl class="chapter-handle">
            <dsf-button icon="icon_up1" size="mini" @click="$emit('chapterToUp', idx)" />
            <dsf-button icon="icon_down1" size="mini" @click="$emit('chapterToDown', idx)" />
            <dsf-button icon="icon_del1" size="mini" class="control-item-delete" @click="deleteChaterItem" />
          </dl>
        </div>
      </div>
      <div class="controll-buttons">
        <div class="controll-sort" v-if="isShowAx">
          是否按序学习
          <el-switch v-model="isSort" :active-color="curColor" width="36" @change="changeSort" :disabled="readonly"></el-switch>
        </div>
        <div class="controll-button-wrap">
          <template>
            <dsf-button size="small" v-for="it in buttons" :key="it.id" :text="it.text" @click="toOpenDialog(it)" />
          </template>
          <!-- <div class="controll-more-btn" v-if="buttons.length > 3">
            <dsf-button class="more-btn" text="更多" size="small" icon="shuangjiantouxia" @click="showMoreBtns"></dsf-button>
            <div class="oth-btns" v-if="moreBtns">
              <dsf-button text="更多" size="small" icon="shuangjiantouxia"></dsf-button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="controll-content">
        <div class="content-inner">
          <dl class="item" v-for="(it, index) in list" :key="it._id" :style="`cursor: ${readonly ? 'unset' : 'pointer'}`">
            <dd class="title ellipsis" :title="it['name']">
              <span style="margin-right: 20px;">{{ index + 1 }}</span>
              <dsf-icon :name="getSurceItemIcon(it['typeText'])" />
              {{ it["name"] }}
            </dd>
            <!-- <dd class="address">{{ it["subId"] || "" }}</dd> -->
            <dd class="name ellipsis" :title="it['teacher']">
              {{ it["teacher"] || "" }}
            </dd>
            <dd class="time" v-if="showTime && isShowAx" style="width: 344px">
              <el-date-picker
                :readonly="readonly"
                v-model="it.time"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeTime(index)"
                :picker-options="pickerDiabled"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </dd>
            <dd :class="['study-time', { allowEdit: it['typeText'] !== '课程' }]">
              <el-input
                v-model="it['period']"
                placeholder="学时"
                class="study-time-input"
                :readonly="it['typeText'] == '课程'"
                type="number"
                min="0"
                :max="maxHours"
                @blur="studyHourBlur(it['period'])"
                :disabled="readonly"
              ></el-input>
              学时
            </dd>
            <dd class="specila-select" v-show="showMustBe">
              <el-select v-model="it['categoryText']" class="specila-select-select" @change="changeSelect(index)" :disabled="readonly">
                <el-option v-for="item in courseClass" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </dd>
            <dd class="buttons">
              <dsf-button icon="icon_up1" size="mini" @click="toUp(index)" />
              <dsf-button icon="icon_down1" size="mini" @click="toDown(index)" />
              <dsf-button icon="icon_check" size="mini" @click="toPreview(index)" />
              <dsf-button icon="icon_del1" size="mini" @click="toDetle(it['subId'], idx)" />
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="mask" v-if="moreBtns" @click="moreBtns = false"></div>
  </div>
</template>

<script>
export default {
  name: "DsfNcSpecialSourceControlItem",
  // ctrlCaption: "专题班资源配置列",
  props: {
    isShowAx: {
      type: Boolean,
      default: true,
    },
    cover: {
      type: String,
      default: "",
    },
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    keys: {
      type: Array,
      default() {
        return [];
      },
    },
    sourceUrl: {
      type: String,
      default: "/nc/pack/unit/save",
    },
    idx: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    unitId: {
      type: String,
      default: "",
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: Object,
      default() {
        return {};
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    stateConfig: {
      type: Object,
      default() {
        return {
          course: {
            code: [],
            text: "课程",
          },
          courses: {
            code: [],
            text: "课程包",
          },
          work: {
            code: [],
            text: "作业",
          },
          exam: {
            code: [],
            text: "考试",
          },
          asq: {
            code: [],
            text: "问卷",
          },
          live: {
            code: [],
            text: "直播",
          },
          article: {
            code: [],
            text: "文章",
          },
          channel: {
            code: [],
            text: "频道",
          },
          doc: {
            code: [],
            text: "文稿",
          },
        };
      },
    },
    sort: {
      type: String,
      default: "0",
    },
    maxHours: {
      type: Number,
      default: 5,
    },
    showMustBe: {
      type: Boolean,
      default: true,
    },
    sDate: {
      type: String,
      default: "",
    },
    eDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileList: [],
      actionurl: "/file/upload/",
      dialogVisible: false,
      disabled: false,
      uploadshow: location.hash.split("/")[3],
      state: "",
      _datas: [],
      result: [],
      coursePack: [],
      courseClass: [
        {
          value: "选修",
          label: "选修",
        },
        {
          value: "必修",
          label: "必修",
        },
      ],
      isSort: this.sort == "1" ? true : false,
      moreBtns: false,
      readonly: this.$route.query.isview == 1 ? true : false,
      pickerDiabled: {
        disabledDate(time) {
          // if (sDate && eDate) return time.getTime() < sDate || time.getTime() > eDate;
        },
      },
    };
  },
  computed: {
    curColor() {
      return this.$root.currentTheme["normal"];
    },
  },
  created() {
    if (this.cover) {
      this.fileList.push({
        name: JSON.parse(this.cover)[0]["originalFileName"],
        url: JSON.parse(this.cover)[0]["absolutePath"],
      });
    }
  },
  mounted() {
    this.initTime();
  },
  watch: {
    sDate(n) {
      if (n) {
        this.initTime();
      }
    },
    fileList: {
      handler(n, o) {
        console.log("fileList:", n, o);
      },
      deep: true,
    },
  },
  methods: {
    // 图片上传
    handleRemove(file) {
      this.deleteCover();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        dsf.layer.message("上传图片只能是 JPG或者 PNG 格式!", false);
        this.handleRemove();
      } else {
        if (!isLt2M) {
          dsf.layer.message("上传头像图片大小不能超过 2MB!", false);
          this.handleRemove();
        }
      }
      return isJPG && isLt2M;
    },
    getCourseBag(id) {
      let that = this;
      that._datas = [];
      that.result = [];
      that.coursePack = [];
      const mainUrl = "inc/meta/data";
      const params = {
        id,
        pageName: "edit",
        namespace: "nc.courses.package",
        data: JSON.stringify({ _id: id }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          // debugger;
          if (res.success) {
            console.log(res.data, "课程包");
            if (res.data["nc_courses_package_sub"]?.length > 0) {
              that.coursePack.push(...res.data["nc_courses_package_sub"]);
              console.log(that.coursePack, "数组");
              // 去重数组
              for (var i = 0; i < that.coursePack.length; i++) {
                var flag = true;
                for (var j = 0; j < that.result.length; j++) {
                  if (that.coursePack[i]["nc_courses_package_sub.code"] === that.result[j]["nc_courses_package_sub.code"]) {
                    flag = false;
                  }
                }
                if (flag) {
                  that.result.push(that.coursePack[i]);
                  // console.log(that.result, "去重数组");
                }
                that._datas = JSON.parse(JSON.stringify(that.result));
                // that._datas = [...that.result]
              }
              console.log(that._datas, "去重数组_datas");
            }
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 删除章节封面
    deleteCover() {
      let url = "/nc/pack/unit/deleteCover";
      this.$http
        .post(url, {
          unitId: this.unitId,
        })
        .done(res => {
          if (res.success) {
            dsf.layer.message("删除成功");
            this.fileList.splice(0, 1);
          } else {
            dsf.layer.message("删除失败", "error");
          }
        })
        .error(error => {})
        .always(() => {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    reloadData() {
      this.initTime();
    },
    success(res, file, fileList) {
      debugger;
      this.saveSpecilaList(res.data);
    },
    initTime() {
      if (this.sDate && this.eDate) {
        const sDate = new Date(this.sDate),
          eDate = new Date(this.eDate);
        this.pickerDiabled.disabledDate = time => {
          return time.getTime() < sDate || time.getTime() > eDate;
        };
      }
    },
    changeTime(idx) {
      if (!this.list[0]["time"] && this.list.length != 1) {
        dsf.layer.pc.message("时间配置请先填写第一条数据", false);
      } else {
        const time = this.list[idx]["time"];
        const sTime = time ? time[0] : null;
        const eTime = time ? time[1] : null;
        this.list[idx].timeSdate = sTime ? dsf.date.format(sTime, "yyyy-mm-dd hh:ii:ss") : null;
        this.list[idx].timeEdate = eTime ? dsf.date.format(eTime, "yyyy-mm-dd hh:ii:ss") : null;
        this.saveSpecilaList();
      }
    },
    clickMore() {
      this.$open({ url: this.moreUrl });
    },
    studyHourBlur(it) {
      if (it > this.maxHours) return;
      this.saveSpecilaList();
    },
    changeSort() {
      this.saveSpecilaList();
    },
    changeSelect(idx) {
      let value = this.list[idx]["categoryText"] == "必修" ? 1 : 0;
      this.list[idx]["categoryValue"] = value;
      this.$forceUpdate();
      this.saveSpecilaList();
    },
    filterList(state) {
      if (!state) return [];
      // console.log(this.stateConfig);
      // if (state == "asq") state = "question";
      const text = this.stateConfig[state].text;
      // const text = this.stateConfig.text;
      const { list } = this;
      let temp = [];
      let ids = [];
      _.each(list, it => {
        if (it["typeText"] == text) {
          if (ids.indexOf(it["businessId"]) < 0) {
            ids.push(it["businessId"]);
            temp.push({
              title: it.name,
              _id: it["businessId"],
            });
          }
        }
      });
      return { value: temp };
    },
    toOpenDialog(d) {
      if (this.readonly) return;
      let that = this;
      let [title, url, size, saveName] = d.params;
      let width, height;
      if (size) {
        [width, height] = size.default.split(",");
      } else {
        [width, height] = ["80%", "500px"];
      }
      const state = this.getQueryParam(url.value.code, "typeValue") || "";
      that.state = state;
      // console.log(state);
      if (url) {
        this.$openDialog({
          title: title.value.code || "弹框",
          width: width,
          height: height,
          footerAlign: "",
          params: {
            path: url.value.code,
            choiceParams: this.filterList(state),
          },
          btns: [
            {
              text: "确定",
              handler: async res => {
                const _name = saveName || "system-dialog-save";
                let data = await res.yes();
                // debugger;
                let _list = [];
                let _data = [];
                if (Object.prototype.toString.call(data) == "[object Object]") data = [data];
                _.each(data, it => {
                  let keys = {
                    categoryValue: 0,
                    categoryText: "选修",
                  };
                  for (let key in it) {
                    let keyArr = key.split(".");
                    let nKey = keyArr.length == 1 ? keyArr[0] : keyArr[1];
                    keys[nKey] = it[key];
                  }
                  _.each(this.list, (item, index) => {
                    if (it._id == item.businessId) {
                      keys["subId"] = item.subId;
                      keys["categoryValue"] = item.categoryValue;
                      keys["categoryText"] = item.categoryText;
                    } else {
                      _list.push(item);
                    }
                  });
                  if (state == "courses") {
                    for (let i = 0; i < data.length; i++) {
                      that.getCourseBag(data[i]._id);
                    }
                    setTimeout(() => {
                      if (that._datas) {
                        _data = JSON.parse(JSON.stringify(that._datas));
                        console.log(_data, "_data111");
                        // _data.push(...that._datas);

                        // that._datas = JSON.parse(JSON.stringify([]));
                      }
                      // console.log(_data, "_data222");
                      console.log(that._datas, "that._datas");
                    }, 1000);
                  } else {
                    _data.push(keys);
                    console.log(_data, "_data");
                  }
                });
                setTimeout(() => {
                  this.initMapKeys(_data, _list, state);
                }, 1000);
              },
            },
            {
              text: "取消",
            },
          ],
        });
      }
    },
    unique(data) {
      let _data = [];
      let ids = [];
      _.each(data, it => {
        let id = it["businessId"] || it._id;
        if (ids.indexOf(id) < 0) {
          ids.push(id);
          _data.push(it);
        }
      });
      return _data;
    },
    initMapKeys(data, list, value) {
      // debugger;
      // console.log(data, "initdata");
      const text = this.stateConfig[value].text;
      let newList = [];
      _.each(data, it => {
        // console.log(it, "itititit");
        if (value === "live") it.duration = it.time_sdate;
        if (value === "courses") value = "course";
        let obj = {
          subId: it.subId, //唯一标识 （_id）（没有就不传）
          businessId: value === "asq" ? it["batch_id"] : it["nc_courses_package_sub.course_id"] || it["_id"], //业务Id（所选资源的id）
          typeValue: value, //类型
          typeText: text, //类型
          name: it["title"] || it["nc_courses_package_sub.title"] || it["name"], //名称
          categoryValue: it.categoryValue || 0, //类别（必/选修）1：必修，0:选修
          categoryText: it.categoryText || "选修", // 类别（必/选修）1：必修，0:选修
          period: it["period"] || it["nc_courses_package_sub.all_period"] || 0, //学时
          timeSdate: "", //开始日期 （没有就不传）
          timeEdate: "", //结束日期（没有就不传）
          order: it["$order"], //排序
          duration: it["duration"] || it["nc_courses_package_sub.duration"] || "", //时长
          teacher: it["teacher_text"] || it["nc_courses_package_sub.teacher"] || "",
          _id: it["_id"],
          batchId: it["batch_id"],
          asqInfoId: it["asq_id"],
        };
        newList.push(obj);
      });
      // console.log(newList, "newList");
      this.list = this.unique([...list, ...newList]);
      newList = [];
      this.saveSpecilaList();
      // this.list = this.unique([...list]);
    },
    saveSpecilaList(cover) {
      /**
       * subList 课程列表
       * unitName 单元名称
       * unitId 单元id 没有就不传
       * classId; 课程id
       * orderTypeText;  是否按序  1：是，0：否
       * orderTypeValue;  是否按序  1：是，0：否
       **/

      let params = {
        cover: cover ? JSON.stringify(cover) : this.cover,
        unitName: this.name,
        unitId: this.unitId || "",
        classId: this.$route.query.id,
        orderTypeValue: this.isSort ? 1 : 0,
        orderTypeText: this.isSort ? "是" : "否",
        subList: this.list,
      };
      // console.log(this.list);
      this.$emit("updateSaveList", { it: params, idx: this.idx });
    },
    loadChapterItemInfo() {
      const sourceUrl = "/nc/pack/unit/period";
      let params = {
        id: this.$route.query.id,
      };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.desc = data;
          } else {
            error(message);
          }
        })
        .error(({ message }) => {
          error(message);
        })
        .always(res => {});
      function error(err) {
        dsf.layer.pc.message("查询章节信息:" + err, false);
      }
    },
    getQueryParam(url, typeValue) {
      var params = url.split("&");
      if (!params && params.length < 1) return;
      for (var i = 0; i < params.length; i++) {
        var pair = params[i].split("=");
        if (pair[0] == typeValue) {
          return pair[1];
        }
      }
      return false;
    },
    toUp(idx) {
      if (this.readonly) return;

      const { list } = this;
      if (idx == 0) return;
      const it = list[idx];
      const temp = list[idx - 1];
      this.$set(this.list, idx, temp);
      this.$set(this.list, idx - 1, it);
      this.saveSpecilaList(false);
    },
    toDown(idx) {
      if (this.readonly) return;
      const { list } = this;
      if (idx == list.length - 1) return;
      const it = list[idx];
      const temp = list[idx + 1];
      this.$set(this.list, idx, temp);
      this.$set(this.list, idx + 1, it);
      this.saveSpecilaList(false);
    },
    toPreview(idx) {},
    toDetle(id, idx) {
      if (this.readonly) return;
      // console.log(this._data, "this._data");
      let { unitId } = this;
      if (unitId) {
        const sourceUrl = "/nc/pack/unit/sub/delete";
        let params = {
          id: id,
        };
        dsf.http
          .get(sourceUrl, params, $$webRoot.nc)
          .done(({ success, data, message }) => {
            dsf.layer.pc.message("删除课件:" + message, success);
            setTimeout(() => {
              console.log(this.list, "list");
            }, 1000);
            if (success) {
              this.$emit("updateChapterList");
            }
          })
          .error(() => {
            dsf.layer.pc.message("删除课件出错:" + err, false);
          })
          .always(res => {});
      } else {
        this.list.splice(idx, 1);
      }
    },
    getSurceItemIcon(text) {
      let icon = "";
      switch (text) {
        case "课程":
          icon = "icon_course";
          break;
        case "课程包":
          icon = "icon_course";
          break;
        case "作业":
          icon = "icon-kehouzuoye";
          break;
        case "考试":
          icon = "icon_exam";
          break;
        case "问卷":
          icon = "icon_questionnaire";
          break;
        case "直播":
          icon = "icon_live";
          break;
        case "文章":
          icon = "icon_article";
          break;
        case "面授":
          icon = "icon_facetoface";
          break;
        default:
          icon = "kecheng";
          break;
      }
      return icon;
    },
    showMoreBtns() {
      this.moreBtns = !this.moreBtns;
    },
    chapterNameBlur() {
      if (!this.name) return dsf.layer.message("章节名称不能为空", false);
      this.saveSpecilaList();
    },
    deleteChaterItem() {
      if (this.readonly) return;

      let that = this;
      let { unitId, idx } = this;
      if (unitId) {
        dsf.layer
          .confirm({
            message: "您确定要删除当前章节吗？",
          })
          .then(function () {
            const sourceUrl = "/nc/pack/unit/delete";
            let params = {
              id: unitId,
            };
            dsf.http
              .get(sourceUrl, params, $$webRoot.nc)
              .done(({ success, data, message }) => {
                dsf.layer.pc.message("删除章节:" + message, success);
                if (success) that.$emit("deleteChaterItem", idx);
              })
              .error(() => {
                dsf.layer.pc.message("删除章节出错:" + err, false);
              })
              .always(res => {});
          });
      } else {
        that.$emit("deleteChaterItem", idx);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cover {
  width: 100%;
  height: 100%;
}
::v-deep .el-upload--picture-card {
  height: 80px;
  line-height: 80px;
  position: absolute;
  top: 20px;
  left: 35%;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  position: absolute;
  top: 20px;
  left: 35%;
  height: 80px;
  line-height: 80px;
  z-index: 10;
}
</style>
>
