<template>
  <el-container class="ds-bbs-page bbs-view breadbar">
    <el-header>
      <div class="breadbarlist">
        <div class="home">
          <i class="iconfont icon-home"></i>
          论坛首页
        </div>
        <!-- 'allPage?c=' + $route.query.catalogId  -->
        >
        <div class="all">{{ secondURL ? secondURL : "所有帖子" }}</div>
      </div>
      <!-- {{ pageTitle || $route.params.title || $route.meta.title }} -->

      <!-- v-if="showSorted && !managerStatus" -->
      <div class="create-post" @click="handleClick">发布帖子</div>
    </el-header>
    <DsfVirtualScroll class="list">
      <el-main>
        <slot />
        <!-- <div>12456</div> -->
        <div
          class="sort-button"
          v-if="showSorted && $route.meta.title != '帖子详情'"
        >
          <div class="hotandnew">
            <div class="all">全部</div>
            <div class="right">
              <div
                :class="['new', isnew ? 'active' : '']"
                @click="isnew = true"
              >
                最新
              </div>
              <div
                :class="['hot', isnew ? '' : 'active']"
                @click="isnew = false"
              >
                最热
              </div>
            </div>
          </div>
          <el-select
            v-if="managerStatus"
            v-model="selectvalue"
            placeholder="请选择"
            @change="changeType"
          >
            <el-option
              v-for="item in selection"
              :key="item.text"
              :label="item.text"
              :value="item.text"
            ></el-option>
          </el-select>
        </div>
      </el-main>
    </DsfVirtualScroll>
  </el-container>
</template>

<script>
export default {
  name: "DsfBbsViews",
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    showSorted: {
      type: Boolean,
      default: true,
    },
    managerStatus: {
      type: Boolean,
      default: false,
    },
    secondURL: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      path: "allPage?cname=所有帖子",
      isnew: true,
      userStatus: false,
      sortedData: [
        {
          text: "最新",
        },
        {
          text: "最热",
        },
      ],
      selectvalue: "全部",
      selection: [
        {
          text: "全部",
        },
        {
          text: "待审核",
        },
        {
          text: "未通过",
        },
        {
          text: "已发布",
        },
        {
          text: "已关闭",
        },
      ],
      checkSort: 0,
    };
  },
  created() {
    if (this.$route.query.c) {
      this.path =
        "allPage?c=" +
        this.$route.query.c +
        "&cname=" +
        this.$route.query.cname;
    }
    if (this.$route.query.u) {
      this.path =
        "allPage?u=" +
        this.$route.query.u +
        "&cname=" +
        this.$route.query.cname;
    }
    if (this.secondURL && this.secondURL === "我的帖子") {
      this.path = "myPost";
    } else if (this.secondURL && this.secondURL === "我的收藏") {
      this.path = "allPage?star=1";
    } else if (this.secondURL && this.secondURL.includes("回复")) {
      if (this.$route.query.u) {
        this.path = "myReply?u=" + this.$route.query.u;
      } else {
        this.path = "myReply";
      }
    }

    document.title =
      this.pageTitle || this.$route.params.title || this.$route.meta.title;
    this.userStatus = this.$route.query.u ? true : false;
  },
  methods: {
    handleClick() {
      this.$openDialog({
        title: "发布帖子",
        width: "80%",
        height: "80vh",
        overflow: "auto",
        params: {
          path: "bbs/topic/edit?close=2",
        },
        btns: [],
      });
    },
    changeType(val) {
      // console.log(val, " -changeType view");
      this.$emit("sorted", 0, val);
    },
    handleSorted(type, text) {
      this.checkSort = type;
      this.$emit("sorted", type, text);
    },
  },
  watch: {
    isnew: {
      handler(n) {
        this.$emit("sorted", n ? 0 : 1, n ? "最新" : "最热");
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ds-bbs-page.bbs-view .el-main .sort-button {
  top: 7px;
  right: 0;
  width: 100%;
  .hotandnew {
    width: 100%;
  }
}
.breadbar {
  .el-header {
    height: 62px !important;
    background: #f5f5f5;
  }
  .breadbarlist {
    display: flex;
    cursor: pointer;
    .home,
    .all {
      cursor: pointer;
    }
  }
}
.hotandnew {
  width: 854px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  .all {
    font-size: 24px;
    color: #333;
    height: 60px;
    line-height: 60px;
    border-bottom: 2px solid #df2221;
  }
  .hot {
    margin-left: 10px;
  }
  .hot,
  .new {
    height: 34px;
    line-height: 34px;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    padding: 0 18px;
    font-size: 18px;
    color: #999;
    cursor: pointer;
    display: inline-block;
  }
  .active {
    color: #df2221;
    border-color: #df2221;
  }
}
</style>
