function findMenuByList(menuList) {
  for (let i = 0; i < menuList.length; i++) {
    let item = menuList[i];
    let menu = findMenu(item);
    if (menu?.menu_url) {
      return menu;
    }
  }
  return null;
}

function findMenu(menu) {
  if (!menu) return null;
  if (dsf.type(menu) == "array") return findMenuByList(menu);
  if (menu.menu_url) return menu;
  if (menu.children) return findMenuByList(menu.children);
  menu.menu_url = '#/404/_' + Date.now();
  return menu;
}

/**
 * 判断不同路由页面是否会复用
 * @param menu
 * @param from
 * @returns {boolean}
 */
function isSamePage(menu, from) {
  let to = menu?.__url_obj;
  let url = menu?.menu_url;
  if (!url) {
    return false;
  }
  if (!to) {
    let isRouter = false;
    if (url.startsWith("/#")) {
      url = url.substr(2);
      isRouter = true;
    } else if (url.indexOf("#") === 0) {
      url = url.substr(1);
      isRouter = true;
    }
    if (url.indexOf("/") !== 0) {
      url = '/' + url;
    }
    if (isRouter) {
      let currentUrl = dsf.url.parse(window.location.href);
      url = currentUrl.pathname + "#" + url;
    }
    to = menu.__url_obj = dsf.url.parse(url);
  }
  return to.hash != '#' + from.fullPath && to.hashKey == from.path;
}

/**
 * 打开菜单页（路由模式）
 */
function openPageByDefault(menu, isReplace) {
  let opentype = menu.menu_opentype_value;
  let url = menu.menu_url;
  if (url.indexOf('#') == 0 && url.indexOf('#/') != 0) {
    url = url.replace('#', '#/');
  }
  switch (opentype) {
  // 主窗口打开
  case '1':
    if (isSamePage(menu, this.$route)) {
      this.viewLoaded = false;
      this.$nextTick(() => {
        this.viewLoaded = true;
      });
    }
    isReplace ? window.top.location.replace(url) : (window.top.location.href = url);
    break;
  // 新窗口打开
  case '2':
    dsf.layer.openWindow(url);
    break;
  // 对话框打开
  case '3':
    dsf.layer.openDialog({
      title: menu.menu_name,
      width: '950px',
      height: '540px',
      params: {
        path: url,
      }
    });
    break;
  // 当前窗口打开
  default:
    if (isSamePage(menu, this.$route)) {
      this.viewLoaded = false;
      this.$nextTick(() => {
        this.viewLoaded = true;
      });
    }
    isReplace ? location.replace(url) : (location.href = url);
  }
}

/**
 * 打开菜单页（单页面标签页模式）
 */
function openPageBySinglePageVm(menu) {
  let opentype = menu.menu_opentype_value;
  switch (opentype) {
  // 对话框打开
  case '3':
    dsf.layer.openDialog({
      title: menu.menu_name,
      width: '950px',
      height: '540px',
      params: {
        path: url,
      }
    });
    break;
  // 当前窗口打开
  default:
    window.__singlePageVm__.$refs.main?.openTag(menu);
  }
}

export default {
  findMenu,
  openPage(menu, isReplace) {
    if (!menu) return;
    if (!menu.menu_url) {
      menu.menu_url = '';
    }
    if (window.__singlePageVm__) {
      openPageBySinglePageVm.call(this, menu);
    } else {
      openPageByDefault.call(this, menu, isReplace);
    }
  }
};