<template>
  <div class="dsf_resouce_list">
    <ul class="resouce_list_wrap" v-if="lists.length">
      <li class="resouce_list_items" v-for="(item, index) in lists" :key="index">
        <i :class="`icon ${item['nc_class_file.type']}`"></i>
        <span class="name ell">{{ item["nc_class_file.title"] }}</span>
        <span
          :class="['ylook', { gray_font: !specialState && resouceType == 'special' }]"
          v-if="isstudent != 3"
          @click="(resouceType == 'special' && specialState && handleClickLook(item)) || (resouceType != 'special' && handleClickLook(item))"
        >
          预览
        </span>
        <span
          :class="['ydown', { gray_font: !specialState && resouceType == 'special' }]"
          v-if="item['nc_class_file.download_free'] && item['nc_class_file.download_free'].value == 1 && isstudent != 3"
          @click="(resouceType == 'special' && specialState && handleClickDown(item)) || (resouceType != 'special' && handleClickDown(item))"
        >
          下载
        </span>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
    <div class="resouce_list_page" v-if="lists.length" :style="{ 'text-align': paginationPosition }">
      <el-pagination
        ref="pagination"
        :class="[paginationStyle]"
        :hide-on-single-page="false"
        :total="pageCount"
        :page-size="pageSize"
        :page-sizes="limits"
        :current-page.sync="currentPage"
        :layout="'slot,' + pagerLayout"
        @size-change="pageSizeChange"
        @current-change="pageSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default dsf.component({
  name: "DsfNcPcResouceListNew",
  ctrlCaption: "资料列表-新",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/meta/list/data",
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: "right",
    },
    // 分页样式
    paginationStyle: {
      type: String,
      default: "style1",
    },
    //一页显示多少条记录
    pageSize: {
      type: Number,
      default: 10,
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100];
      },
    },
    // 分页组建的布局,自定义分页排版(逗号分隔)
    pagerLayout: {
      type: String,
      default: "prev,pager,next,jumper",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    resouceType: {
      type: String,
      default: "special",
    },
  },
  data() {
    return {
      lists: [
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "doc",
        },
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "xlsx",
        },
      ],
      currentPage: 1,
      pageCount: 0,
      pageTotalUrl: "/meta/list/count",
      params: {
        id: "",
        pageNum: 1,
        pageSize: this.pageSize,
        query: JSON.stringify({ searchValue: "", class_id: "@[url('id')]", publish_state_value: "[1]" }),
        order: JSON.stringify([
          { code: "publish_state_value", sort: "desc" },
          { code: "publish_time", sort: "desc" },
          { code: "ds_update_time", sort: "desc" },
        ]),
        filter: "[]",
        namespace: "nc.class.teaching",
        pageName: "upload",
        classId: "",
        specialDetailInfo: "",
      },
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "f5e7063e252d42ceb5520a6c5f9dd03e";
    this.params.classId = this.params.id;
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
      this.initPage();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initPage() {
      this.dsf.http
        .get(this.pageTotalUrl, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.pageCount = res.data.count;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {});
    },
    pageSizeChange(evt) {
      this.params.pageNum = evt;
      this.initData();
    },
    handleClickLook(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      // 预览
      let relativePath = item["nc_class_file.file"];
      if (!relativePath) return;
      relativePath = JSON.parse(relativePath)[0].relativePath;
      let root = dsf.url.getWebPath("preview/file");
      const preUrl = dsf.config.setting_public_file_host;
      let url = `${root}?url=${preUrl}${relativePath}`;
      // window.open(url);
      dsf.layer.openWindow(":" + url);
    },
    // 下载前
    handlerDownBefore(sourceUrl, params, succFn, failFun) {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    handleClickDown(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      this.handlerDownBefore("nc/class/teaching/file/download", { fileId: item._id, classId: this.params.id }, res => {
        // 下载
        var url = dsf.url.getWebPath("file/download");
        let file = item["nc_class_file.file"];
        if (!file) return;
        file = JSON.parse(file)[0];
        $(
          `<form action="${url}" method="get">
          <input type="text" name="files" value="${file.id}"/>
          <input type="text" name="zipName" value="${file.originalFileName || ""}"/>
        </form>`
        )
          .appendTo("body")
          .submit()
          .remove();
      });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
  computed: {
    ...mapState(["isstudent"]),
    ...mapGetters(["getSpecialInfo"]),
    specialState() {
      // 专题班控制资料下载的状态
      if (this.getSpecialInfo.state === 0 || this.getSpecialInfo.state === 4 || this.getSpecialInfo.state == -1) {
        return false;
      } else {
        return true;
      }
    },
  },
});
</script>
