<template>
  <div class="dsf_nc_portrait">
    <div class="portrait_wrap">
      <div class="portrait_header">
        <ul class="user_info">
          <li>
            <img :src="setImg(headInfo.photo)" alt="" />
            <!-- <p><span>lv4举人</span></p> -->
          </li>
          <li>
            <p>
              hi，{{ headInfo.name }}
              <span
                >用心服务好每一位学员<i
                  class="layui-icon layui-icon-edit"
                  style="
                    font-size: 20px;
                    color: #fff;
                    border-bottom: 1px solid #fff;
                  "
                ></i>
              </span>
            </p>
            <p>{{ headInfo.code }}&nbsp;&nbsp;教辅管理员</p>
          </li>
          <li>
            <ul class="annual_report">
              <li>
                <p>{{ year }}</p>
                <p>年度</p>
              </li>
              <li>
                <p>
                  {{ ztbnum }}
                </p>
                <p>专题班</p>
              </li>
              <li>
                <p>{{ xynum }}</p>
                <p>学员</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="portrait_content">
        <ul class="portraint_kyosuke">
          <li>
            <div class="module_header">
              <i class="color_piece"></i>
              <h5>画像</h5>
            </div>
            <div class="chart_wrap">
              <div
                id="portrait_chart"
                ref="cavans"
                style="width: 600px; height: 400px"
              ></div>
            </div>
          </li>
          <li>
            <ul class="kyosuke_tags"></ul>
          </li>
        </ul>
        <div class="portrait_jobs">
          <div class="module_header">
            <i class="color_piece"></i>
            <h5>工作量</h5>
          </div>
          <ul class="jobs_list">
            <li v-for="(item, index) in workload" :key="index">
              <img :src="item.iconUrl || 0" alt="" />
              <dl>
                <dt>
                  <span>{{ dataList[index]?.num || 0 }}{{ item.unit }}</span>
                </dt>
                <dd>{{ item.desc }}</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default dsf.component({
  name: "DsfNcGzNewPortrait",
  ctrlCaption: "教辅画像",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      headInfo: {},
      initinfo: {
        head_logo: require("../../../assets/styles/img/pcimg/guizhou/portrait/head_logo.png"),
      },
      year: "",
      ztbnum: "",
      xynum: "",
      workload: [
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/fwxy.png"),
          unit: "个",
          desc: "管理的专题班",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/jxgg.png"),
          unit: "人",
          desc: "服务的学员",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/pyzy.png"),
          unit: "次",
          desc: "发布的教学公告",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/hdtw.png"),
          unit: "篇",
          desc: "批阅的作业",
        },
        // {
        //   iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/sczl.png"),
        //   unit: "条",
        //   desc: "回答的提问",
        // },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/wjdc.png"),
          unit: "份",
          desc: "上传的学习资料",
        },
        {
          iconUrl: require("../../../assets/styles/img/pcimg/guizhou/portrait/glztb.png"),
          unit: "份",
          desc: "发起的问卷调查",
        },
      ],

      echartsinstance: null,
      options: {
        color: ["#D53A34", "#334B5C"],
        legend: {
          data: ["平均", "我"],
          right: "80px",
          bottom: "80px",
          orient: "vertical",
        },
        radar: [
          {
            indicator: [
              {
                text: "完成度",
              },
              {
                text: "专注度",
              },
              {
                text: "活跃度",
              },
              {
                text: "勤奋度",
              },
              {
                text: "满意度",
              },
            ],
            center: ["44%", "50%"],
            radius: 140,
            startAngle: 90,
            splitNumber: 4,
            name: {
              formatter: "{value}",
              textStyle: {
                fontSize: "20",
                color: "#333333",
              },
            },
            splitArea: {
              areaStyle: {
                /*color: ['rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],*/
                color: "#fff",
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 4,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0091FF",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#0091FF",
              },
            },
          },
        ],
        series: [
          {
            name: "雷达图",
            type: "radar",
            itemStyle: {
              emphasis: {
                // color: 各异,
                lineStyle: {
                  width: 4,
                },
              },
            },
            data: [
              {
                value: [100, 8, 0.4, -80, 2000],
                name: "平均",
                symbol: "circle",
                symbolSize: 5,
                lineStyle: {
                  color: "#D73D38",
                },
                shadowColor: "#ff6700",
                areaStyle: {
                  normal: {
                    color: "rgba(255, 102, 102, .4)",
                  },
                },
              },
              {
                value: [60, 5, 0.3, -100, 1500],
                name: "我",
                lineStyle: {
                  color: "#3B5364",
                },
                areaStyle: {
                  normal: {
                    color: "rgba(57, 165, 254, .4)",
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.getloadData();
    this.getHeadInfo();
  },
  watch: {
    options() {
      this.echartsinstance.setOption(this.options);
    },
  },
  mounted() {
    // this.$nextTick(function () {
    //   this.drawLine("portrait_chart");
    // });
    this.$nextTick(() => {
      this.echartsinstance = echarts.init(this.$refs.cavans);
      this.echartsinstance.setOption(this.options);
    });
  },

  methods: {
    changeData() {
      this.option = {
        color: ["#67F9D8", "#FFE434"],
        legend: {
          data: ["平均", "我"],
          right: "80px",
          bottom: "80px",
          orient: "vertical",
        },
        radar: [
          {
            indicator: [
              {
                text: "完成度",
              },
              {
                text: "专注度",
              },
              {
                text: "活跃度",
              },
              {
                text: "勤奋度",
              },
              {
                text: "满意度",
              },
            ],
            center: ["44%", "50%"],
            radius: 140,
            startAngle: 90,
            splitNumber: 4,
            name: {
              formatter: "{value}",
              textStyle: {
                fontSize: "20",
                color: "#333333",
              },
            },
            splitArea: {
              areaStyle: {
                /*color: ['rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],*/
                color: "#fff",
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 4,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0091FF",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#0091FF",
              },
            },
          },
        ],
        series: [
          {
            name: "雷达图",
            type: "radar",
            itemStyle: {
              normal: {
                borderColor: "rgba(255,0,0,1)",
                // 拐点的描边颜色。[ default: '#000' ]
                borderWidth: 3,
                // 拐点的描边宽度，默认不描边。[ default: 0 ]
              },
            },
            data: [
              {
                value: [100, 8, 0.4, -80, 2000],
                name: "平均",
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                  normal: {
                    color: "rgba(255, 102, 102, .4)",
                  },
                },
                borderColor: "#fff",
              },
              {
                value: [60, 5, 0.3, -100, 1500],
                name: "我",
                areaStyle: {
                  normal: {
                    color: "rgba(57, 165, 254, .4)",
                  },
                },
              },
            ],
          },
        ],
      };
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    getHeadInfo() {
      let that = this;
      const mainUrl = "/inc/nc/org/user/detail";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.headInfo = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getloadData() {
      let that = this;
      const mainUrl = "nc/zxsd/teacherAndBench/displaySelfPage";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.year = res.data.year;
            that.dataList = res.data.gethxCount;
            that.ztbnum = res.data.gethxCount[0].num;
            that.xynum = res.data.gethxCount[1].num;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
