<template>
  <div class="dsf_nc_forgetpwd">
    <div class="login-tab-content" v-if="isphonecode">
      <div class="login-tab-content-item" style="display: block">
        <div class="login-activation-mobile">
          <div class="login-activation-after login-activation-wrap">
            <label>姓名</label>
            <input
              type="text"
              placeholder="请输入姓名"
              ref="UserForgetName"
              id="UserForgetName"
              @input="updatainput"
            />
          </div>
          <div class="login-activation-after login-activation-wrap">
            <label>学员编号</label>
            <input
              type="text"
              placeholder="请输入学员编号"
              id="UserForgetCode"
              ref="UserForgetCode"
              @input="updatainput"
            />
          </div>
          <div class="login-activation-after login-activation-wrap">
            <label>+86</label>
            <input
              type="text"
              placeholder="请输入手机号"
              id="UserForgetPhone"
              ref="UserForgetPhone"
              @input="ishasphone"
            />
          </div>
          <div class="login-activation-wrap">
            <input
              type="text"
              placeholder="请输入短信验证码"
              class="login-activation-code"
              id="UserForgetPwdCode"
              ref="UserForgetPwdCode"
              maxlength="6"
              @input="updatainput"
            />
            <button
              class="login-code-btn"
              ref="UserForgetCodeBtn"
              @click="getcode"
              disabled
            >
              获取验证码
            </button>
          </div>
          <button class="login-btn" @click="next">下一步</button>
        </div>
      </div>
    </div>
    <!-- 设置密码 -->
    <div v-if="!isphonecode" class="setpwd">
      <div class="login-user-login" id="UserForgetSec">
        <div class="login-tab">
          <div class="login-tab-item login-tab-auto">
            <span>设置新密码</span>
          </div>
        </div>
        <div class="login-tab-content">
          <div class="login-tab-content-item" style="display: block">
            <div class="login-activation-mobile">
              <div class="login-activation-wrap">
                <input
                  type="password"
                  placeholder="请输入新密码"
                  id="UserFPwd"
                  ref="UserAcPwdHide"
                  data-type="1"
                />
                <p class="login-forget-pwd-remind" id="UserForgetPwdRemind">
                  <!-- 密码设置6到8位,必须包含字母、数字、特殊字符 -->
                  密码设置8到15位,必须包含字母和数字
                </p>
                <i
                  class="iconfont icon-biyan"
                  id="UserAcPwdHide"
                  ref="pwdeye"
                  @click="showpwd"
                ></i>
              </div>
              <div class="login-activation-wrap">
                <input
                  type="password"
                  placeholder="请再次输入新密码"
                  id="UserSPwd"
                  ref="UserAcRPwdHide"
                  data-type="1"
                />
                <i
                  class="iconfont icon-biyan"
                  id="UserAcRPwdHide"
                  ref="repwdeye"
                  @click="showrepwd"
                ></i>
              </div>
              <button
                class="login-btn"
                id="UserForgetPwdSure"
                @click="isright"
                @keydown.enter="isright"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcForGetPwd",
  ctrlCaption: "贵州新忘记密码",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      isphonecode: true,
      name: "",
      code: "",
      phone: "",
      phonecode: "",
      show:0,
      count: "",
      timer: null,
      userId: "",
      tierCode: null,
      isMessage: false,
      isClickCode: false,
    };
  },
  created() {
    this.ishasphone();
  },
  mounted() {},
  methods: {
    // 获取验证码
    getcode() {
      if(this.isClickCode || this.isMessage) return;
      var phoneReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var names = this.$refs.UserForgetName.value;
      var xycode = this.$refs.UserForgetCode.value;
      var hasphone = this.$refs.UserForgetPhone.value;
      if (!names) return dsf.layer.message("请输入学员姓名", false);
      if (!xycode) return dsf.layer.message("请输入学员编号", false);
      if (!phoneReg.test(hasphone))
        return dsf.layer.message("请输入正确的手机号", false);
      if (names && xycode && hasphone) {
        let that = this;
        const mainUrl = "/ncLogin/forgetPasswordSendSmsCode";
        const params = {
          name: this.name,
          code: this.code,
          phone: this.phone,
        };
        that.isClickCode = true;
        that.dsf.http
          .post(mainUrl, params)
          .done(function (res) {
            if (res.success) {
              const times = 60; // 倒计时时间
              if (!that.timer) {
                that.count = times;
                that.show = 1;
                that.timer = setInterval(() => {
                  if (that.count > 0 && that.count <= times) {
                    if (that.$refs.UserForgetCodeBtn) {
                      that.$refs.UserForgetCodeBtn.disabled = true;
                      that.count--;
                      that.$refs.UserForgetCodeBtn.innerHTML = `重新发送(${that.count})`;
                    }
                  } else {
                    if (that.$refs.UserForgetCodeBtn) {
                      that.show = 2;
                      that.$refs.UserForgetCodeBtn.disabled = false;
                      that.$refs.UserForgetCodeBtn.innerHTML = `获取验证码`;
                      clearInterval(that.timer);
                      that.timer = null;
                    }
                  }
                }, 1000);
              }
              dsf.layer.message("发送成功");
            } else {
              /* 防止页面有多个错误提示 */
              that.isMessage = true;
              dsf.layer.message(res.message, false);
              that.tierCode = setTimeout(()=>{
                that.isMessage = false;
                clearTimeout(that.tierCode);
              }, 3000)
            }
          })
          .error(function (error) {
            //   that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            that.isClickCode = false;
            //   that.dsf.layer.closeLoading(loader);
          });
      }
    },
    // 保存输入框值
    updatainput() {
      this.$nextTick(() => {
        this.name = this.$refs.UserForgetName.value;
        this.code = this.$refs.UserForgetCode.value;
        this.phonecode = this.$refs.UserForgetPwdCode.value;
      });
    },
    // 下一步
    next() {
      this.$nextTick(() => {
        var phoneReg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        var names = this.$refs.UserForgetName.value;
        var xycode = this.$refs.UserForgetCode.value;
        var hasphone = this.$refs.UserForgetPhone.value;
        var hasCode = this.$refs.UserForgetPwdCode.value;
        if (!names) return dsf.layer.message("请输入学员姓名", false);
        if (!xycode) return dsf.layer.message("请输入学员编号", false);
        if (!hasphone) return dsf.layer.message("请输入手机号", false);
        if (!phoneReg.test(hasphone))
          return dsf.layer.message("请输入正确的手机号", false);
        if (!hasCode) return dsf.layer.message("请输入短信验证码", false);
        console.log(hasCode, "hasCode");
        if (names && xycode && hasphone && hasCode) {
          let that = this;
          const mainUrl = "/ncLogin/validLoginInfo";
          const params = {
            name: this.name,
            code: this.code,
            phone: this.phone,
            phoneCode: this.phonecode,
          };
          that.dsf.http
            .post(mainUrl, params)
            .done(function (res) {
              if (res.data.success) {
                that.userId = res.data.data.userId;
                that.isphonecode = false;
              } else {
                dsf.layer.message(res.data.message, false);
              }
            })
            .error(function (error) {
              // that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              // that.dsf.layer.closeLoading(loader);
            });
        }
      });
    },
    // 密码显示文本
    showpwd() {
      this.$nextTick(() => {
        if (this.$refs.UserAcPwdHide.type == "password") {
          this.$refs.UserAcPwdHide.type = "text";
          this.$refs.pwdeye.classList.remove("icon-biyan");
          this.$refs.pwdeye.classList.add("icon-yanjing1");
        } else if (this.$refs.UserAcPwdHide.type == "text") {
          this.$refs.UserAcPwdHide.type = "password";
          this.$refs.pwdeye.classList.remove("icon-yanjing1");
          this.$refs.pwdeye.classList.add("icon-biyan");
        }
      });
    },
    // 确认密码显示文本
    showrepwd() {
      this.$nextTick(() => {
        if (this.$refs.UserAcRPwdHide.type == "password") {
          this.$refs.UserAcRPwdHide.type = "text";
          this.$refs.repwdeye.classList.remove("icon-biyan");
          this.$refs.repwdeye.classList.add("icon-yanjing1");
        } else if (this.$refs.UserAcRPwdHide.type == "text") {
          this.$refs.UserAcRPwdHide.type = "password";
          this.$refs.repwdeye.classList.remove("icon-yanjing1");
          this.$refs.repwdeye.classList.add("icon-biyan");
        }
      });
    },
    // 按钮显示样式
    ishasphone() {
      var phoneReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      this.$nextTick(() => {
        this.phone = this.$refs.UserForgetPhone.value;
        if (
          this.$refs.UserForgetPhone.value.trim() &&
          phoneReg.test(this.phone)
        ) {
          this.$refs.UserForgetCodeBtn.disabled = false;
          this.$refs.UserForgetCodeBtn.classList.add("activecode");
        } else {
          this.$refs.UserForgetCodeBtn.classList.remove("activecode");
          this.$refs.UserForgetCodeBtn.disabled = true;
        }
      });
    },
    // 密码校验规则
    selfverify(newpwd) {
      return new Promise((resolve) => {
        this.dsf.http
          .get(
            "nc/reg/ncVerifyPwd",
            { pwd: dsf.base64.encode(newpwd) },
            $$webRoot.nc
          )
          .done((res) => {
            if (res.success) {
              if (res.data.success) {
                resolve(res.data.success);
              } else {
                this.dsf.layer.message(res.data.message, false);
              }
            } else {
              this.dsf.layer.message(res.message || "验证密码失败", false);
            }
          })
          .error((response) => {
            // this.dsf.layer.message(response.message || "验证密码失败", false);
          });
      });
    },
    // 设置密码确认按钮
    async isright() {
      // var newpwd = "",
      //   renewpwd = "";
      // this.$nextTick(() => {
      var newpwd = this.$refs.UserAcPwdHide.value.trim();
      var renewpwd = this.$refs.UserAcRPwdHide.value.trim();
      // });
      if (newpwd == "") return dsf.layer.message("密码不能为空", false);
      await this.selfverify(newpwd);
      if (renewpwd == "") return dsf.layer.message("确认密码不能为空", false);
      if (newpwd !== renewpwd)
      return dsf.layer.message("两次密码不一致", false);
      
     // if (this.CheckPassWord(newpwd)) {
        let that = this;
        const mainUrl = "/ncLogin/forgetPasswordReset";
        const params = {
          password: dsf.md5(dsf.md5(newpwd)),
          passwordConfirm: dsf.md5(dsf.md5(renewpwd)),
          userId: that.userId,
        };
        that.dsf.http
          .post(mainUrl, params)
          .done(function (res) {
            if (res.data.success) {
              that.dsf.layer.message(res.data.message);
              location.reload();
            } else {
              that.dsf.layer.message(res.data.message || "获取列表数据异常",false);
              
            }
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            // that.dsf.layer.closeLoading(loader);
          });
      // } else {
      //   return dsf.layer.message("密码不符合密码规则", false);
      // }
    },
  },
});
</script>
<style></style>
