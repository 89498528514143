<template>
  <label class="dsf-college-zxqy-home-header-search" :class="{ show: show }">
    <i class="iconfont icon-sousuo" @click="submit"></i>
    <input ref="input" v-model.trim="keywords" :placeholder="placeholder" type="text" @focus="focus" @blur="blur" @keypress.enter="doSearch" />
  </label>
</template>

<script>
export default {
  name: "zyqyHomeSearch",
  props: {
    placeholder: {
      type: String,
      default: "请输入关键词",
    },
    search: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      isFocus: false,
      keywords: "",
    };
  },
  computed: {
    show() {
      return this.isFocus || this.keywords;
    },
  },
  methods: {
    submit() {
      if (this.isFocus) {
        this.btClick = true;
        this.doSearch();
      }
    },
    focus() {
      this.isFocus = true;
    },
    blur() {
      setTimeout(() => {
        if (!this.btClick) {
          this.isFocus = false;
        }
        this.btClick = false;
      }, 300);
    },
    doSearch() {
      if (this.search) {
        let isClear = this.search(this.keywords);
        if (isClear.then) {
          isClear.then(res => {
            if (res) {
              this.keywords = "";
              this.$refs.input.blur();
            }
          });
        } else if (isClear === true) {
          this.keywords = "";
          this.$refs.input.blur();
        }
      } else {
        this.$emit("search", this.keywords);
      }
    },
  },
};
</script>
