<template>
  <div class="dsf_nc_gzdatagrid">
    <div class="ds-panel-headers">
      <div class="title"><label class="ds-font3"> 课程列表 </label></div>
      <div class="header-right">
        <div class="ds-datagrid-header">
          <div class="head-right-item" style="margin-left: 10px">
            <form class="from">
              <div class="search-input el-input">
                <input
                  ref="searchint"
                  type="text"
                  autocomplete="off"
                  placeholder="请输入检索关键字"
                  class="el-input__inner"
                  @input="input"
                  @keydown.enter="search"
                />
              </div>
              <button class="search-button" @click="search">
                <i class="iconfont icon-fangdajing"></i>
              </button>
            </form>
          </div>
          <div class="head-right-item" style="margin-left: 10px">
            <div
              class="ds-control ds-button-bar align-center ds-no-padding"
              ctrl_type="dsf.buttonbar"
            ></div>
          </div>
          <div slot-name="header-attach" class="head-right-item"></div>
        </div>
      </div>
    </div>
    <div class="list">
      <el-table
        stripe
        border
        :data="dataList"
        style="width: 100%"
        @row-click="clickfinsh"
        :cell-style="cellstyle"
      >
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="NAME" label="名称" width="500px">
        </el-table-column>

        <el-table-column
          prop="type_text"
          align="center"
          label=" 类型"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="category_text"
          align="center"
          label="学习类型"
          width="auto"
        >
        </el-table-column>
        <el-table-column
          prop="finish_count"
          align="center"
          label="完成人数"
          width="auto"
          id="finshnum"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper , sizes"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzdatagrid",
  ctrlCaption: "贵州数据列表",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      currentPage4: 1,
      pageNum: 1,
      pageSize: 10,
      keyword: "",
      total: 0,
    };
  },
  created() {
    this.getData();
    this.getcount();
  },
  mounted() {},
  methods: {
    input() {
      this.$nextTick(() => {
        this.keyword = this.$refs.searchint.value;
        if (this.$refs.searchint.value.trim() == "") {
          this.getData();
          this.getcount();
        }
      });
    },
    search() {
      this.keyword = this.$refs.searchint.value.trim();
      this.getData();
      this.getcount();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
      this.getcount();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
      this.getcount();
    },
    clickfinsh(row) {
      console.log(row);
      this.$openDialog({
        title: "完成状况",
        // 弹框宽，支持%、px、vw等
        width: "1000px",
        // 弹框高，支持px、vh等，不支持%
        height: "600px",
        showClose: true,
        params: {
          // 页面A的物理路径
          path: "#/pc/nc/page/pc/teachassistant/finishcount",
          row: row,
        },
      });
    },
    cellstyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row, column, rowIndex, columnIndex);
      // let cellStyle;
      // switch (row.nonChecked) {
      //   case 1:
      // cellStyle = "color:red;!important";
      //     break;
      //   case 0:
      //     cellStyle = "";
      //     break;
      //   default:
      //     cellStyle = "";
      // }
      // if (column.label == "未审核") {
      //   return cellStyle;
      // }
      if (columnIndex === 2) {
        //第3列字体颜色是红色
        return "td_red";
      }
      // return cellStyle;
    },
    getData() {
      let that = this;
      const mainUrl = "/nc/jfzx/teach/course/list";
      const params = {
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        classId: this.$route.query.id,
        keyword: that.keyword,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "9999");
            that.dataList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getcount() {
      let that = this;
      const mainUrl = "/nc/jfzx/teach/course/listCount";
      const params = {
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        classId:  that.$route.query.id,
        keyword: that.keyword,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.total = res.count;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style lang="scss">
// .dsf_nc_gzdatagrid {
//   .list {
//     .el-table__body-wrapper.is-scrolling-none {
//       .el-table__body {
//         tbody {
//           width: 10px !important;
//           background: #000 !important;
//           // tr {
//           //   .el-table__cell:nth-child(4) {
//           //     color: red;
//           //   }
//           .el-table__row {
//             color: red !important;
//             >>> td.table_1_column_5 {
//               color: blue !important;
//               .cell {
//                 color: red !important;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
/deep/ .el-table__body-wrapper tbody td {
  color: red;
}
</style>
