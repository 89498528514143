<template>
  <div class="dsf-nc-college-home-xc-top">
    <div class="top-user">
      <span>技术支持电话：010-65646071 邮箱:edu@mee.gov.cn</span>
      <div v-if="isLogin">
        <span class="top-user-name">
          <i class="top-user-icon icon-system-setting"></i>
          欢迎您,{{ dsf.cookies.get("user_name") }}
        </span>
        <span class="top-user-name">
          <i class="top-user-icon icon-setting"></i>
          个人设置
        </span>
        <span class="top-user-name">
          <i class="top-user-icon icon-exit"></i>
          退出
        </span>
      </div>
      <div v-else class="cursor: pointer;">登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfCollegeHomeTop",
  components: {},
  props: {
    mainWidth: {
      type: [Number, String],
      default: "100%",
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      },
    },
    logoUrl: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    // 未登录状态下自定义按钮
    customBts: {
      type: Array,
      default() {
        return [];
      },
    },
    clickBefore: {
      type: Function,
      required: false,
    },
    doSearch: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      height: "auto",
      isLogin: dsf.cookies.get("isLogin") == 1,
    };
  },
  created() {},
  methods: {},
};
</script>
