<template>
  <div class="dsf_teach_assistant_billboard" v-if="visible">
    <div class="teach_assistant_title">
      <span class="font">班级概况</span>
    </div>
    <ul class="class_situation_wrap">
      <li class="class_situation_items" v-for="(item, index) in classSituationData" :key="index">
        <div class="img_box">
          <el-image :src="item.imgIcon" style="width: 96px; height: 96px">
            <div slot="error" class="image-slot">
              <img :src="dsf.config.setting_error_img" alt="" />
            </div>
          </el-image>
        </div>
        <div class="situation_right">
          <div class="count">{{ item.count }}</div>
          <div class="name">{{ item.name }}</div>
        </div>
      </li>
    </ul>
    <div class="teach_assistant_title">
      <span class="font">学习进度</span>
    </div>
    <ul class="learning_progress_wrap">
      <li class="learning_progress_items" v-for="(item, index) in learningProgressData" :key="index + 'progress'">
        <div class="learning_progress_items_inner">
          <div class="name">{{ item.text }}</div>
          <div class="progress">{{ item.progress }}</div>
        </div>
      </li>
    </ul>
   <!--  <div class="teach_assistant_title">
      <span class="font">在线情况</span>
    </div> -->
    <!-- <div class="btns">
      <div :class="`week btn ${weekormonth ? 'active' : ''}`" @click="clickSwitchBtns('week')">周</div>
      <div :class="`month btn ${weekormonth ? '' : 'active'}`" @click="clickSwitchBtns('month')">月</div>
    </div> -->
 <!--    <div class="online_situation" id="onlineSituation">
      <div class="online_situation_inner" id="onlineSituationEchats" ref="onlineSituationEchats" :style="{ width: '100%', height: '280px' }"></div>
    </div> -->
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcTeachAssistantBillBoardNew",
  ctrlCaption: "教辅中心-数据看板-新",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/teaching/data",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      classSituationData: [
        {
          imgIcon: require("../../../assets/imgs/teachassistant/xurs.png"),
          name: "学员人数",
          count: "6",
          value: "total",
        },
        {
          imgIcon: require("../../../assets/imgs/teachassistant/bdrs.png"),
          name: "报到人数",
          count: "2",
          value: "checkinCount",
        },
        {
          imgIcon: require("../../../assets/imgs/teachassistant/jyrs.png"),
          name: "结业人数",
          count: "6",
          value: "graduationCount",
        },
        // {
        //   imgIcon: require("../../../assets/imgs/teachassistant/dpyzy.png"),
        //   name: "待批阅作业",
        //   count: "1",
        //   value: "",
        // },
        {
          imgIcon: require("../../../assets/imgs/teachassistant/bdl.png"),
          name: "报到率",
          count: "50%",
          value: "checkinRatio",
        },
        {
          imgIcon: require("../../../assets/imgs/teachassistant/jyl.png"),
          name: "结业率",
          count: "90%",
          value: "graduationRatio",
        },
      ],
      learningProgressData: [
        { text: "5", progress: "0~30%" },
        { text: "0", progress: "31%~60%" },
        { text: "1", progress: "61%~80%" },
        { text: "8", progress: "81%~90%" },
        { text: "1", progress: "91%~100%" },
      ],
      params: {
        id: "",
      },
      echartsUrl: "nc/class/teaching/statistics",
      echartsParams: {
        id: "",
        type: 1,
      },
      weekormonth: true,
    };
  },
  created() {
    this.params.id = this.queryString["id"] || "af85fbe15c8842a196dfa44c8b7467bf";
    this.echartsParams.id = this.queryString["id"] || "af85fbe15c8842a196dfa44c8b7467bf";
  },
  mounted() {
    if (!window.echarts) {
      this.loadScript(() => {
        if (!this.isDesign) {
          this.initData();
          // this.echartsData();
        } else {
          let data = {
            abscissa: [
              "00:00-01:59",
              "02:00-03:59",
              "04:00-05:59",
              "06:00-07:59",
              "08:00-09:59",
              "10:00-11:59",
              "12:00-13:59",
              "14:00-15:59",
              "16:00-17:59",
              "18:00-19:59",
              "20:00-21:59",
              "22:00-23:59",
            ],
            login: [10, 30, 10, 2, 1, 1, 3, 2, 1, 0, 3, 2],
            online: [0, 2, 3, 1, 1, 3, 3, 2, 2, 0, 1, 2],
          };
          this.onlineSituationEchats(data);
        }
      });
    } else {
      if (!this.isDesign) {
        this.initData();
        // this.echartsData();
      } else {
        let data = {
          abscissa: [
            "00:00-01:59",
            "02:00-03:59",
            "04:00-05:59",
            "06:00-07:59",
            "08:00-09:59",
            "10:00-11:59",
            "12:00-13:59",
            "14:00-15:59",
            "16:00-17:59",
            "18:00-19:59",
            "20:00-21:59",
            "22:00-23:59",
          ],
          login: [10, 30, 10, 2, 1, 1, 3, 2, 1, 0, 3, 2],
          online: [0, 2, 3, 1, 1, 3, 3, 2, 2, 0, 1, 2],
        };
        this.onlineSituationEchats(data);
      }
    }
  },
  methods: {
    // 加载js
    loadScript(callback) {
      this.$http
        .importFiles([[dsf.url.getWebPath("$/js/libs/echarts/echarts.min.js"), ".js"]])
        .then(() => {
          callback?.();
        })
        .catch(() => {
          dsf.layer.message("加载fabric文件报错", false);
          reject();
        });
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { overview, process } = res.data;
            this.classSituationData.forEach(element => {
              for (const key in overview) {
                if (element.value == key) {
                  element.count = overview[key];
                }
              }
            });
            this.learningProgressData = [];
            for (const keys in process) {
              let obj = {};
              obj.text = process[keys];
              obj.progress = keys;
              this.learningProgressData.push(obj);
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    echartsData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.echartsUrl, this.echartsParams)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.onlineSituationEchats(data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    onlineSituationEchats(data) {
      let echartsDom = document.getElementById("onlineSituationEchats");
      let myChart = echarts.init(echartsDom);
      let option = {
        animation: false,
        title: {
          textAlign: "center",
          left: "50%",
          top: "10px",
          textStyle: {
            fontSize: 14,
            colot: "#333",
          },
        },
        tooltip: {
          // triggerOn: "none",
        },
        legend: {
          left: "80%",
          top: "20px",
          data: [
            {
              name: "学习人数",
              // 强制设置图形为圆。
              icon: "circle",
            },
            // {
            //   name: "在学人数",
            //   // 强制设置图形为圆。
            //   icon: "circle",
            // },
          ],
        },
        xAxis: {
          type: "category",
          data: data.abscissa,
          // axisTick: {
          //   show: false,
          //   length: 0,
          // },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 12,
            // interval: 0,
            rotate: 30,
          },
          min: "dataMin",
          max: "dataMax",
        },
        yAxis: {
          type: "value",
          // data: [0, 100, 200, 300],
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "#666",
            fontSize: 12,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "学习人数",
            type: "line",
            // data: [10, 30, 10, 2, 1, 1, 3, 2, 1, 0, 3, 2],
            data: data.login,
            itemStyle: {
              color: "#32C5FF",
              fontSize: 16,
            },
            barWidth: 10,
          },
          // {
          //   name: "在学人数",
          //   type: "line",
          //   // data: [0, 2, 3, 1, 1, 3, 3, 2, 2, 0, 1, 2],
          //   data: data.online,
          //   itemStyle: {
          //     color: "#315A96",
          //     fontSize: 16,
          //   },
          //   barWidth: 10,
          // },
        ],
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            bottom: 0,
            start: 1,
            end: 20,
            height: 10,
          },
          {
            type: "inside",
            xAxisIndex: [0],
            start: 1,
            end: 20,
          },
        ],
      };
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      setTimeout(() => {
        myChart.resize();
      }, 1000);
    },
    clickSwitchBtns(status) {
      if (status == "week") {
        this.weekormonth = true;
        this.echartsParams.type = 1;
      } else {
        this.weekormonth = false;
        this.echartsParams.type = 2;
      }
      this.echartsData();
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
