<template>
  <div class="dsf_nc_classroomlist" v-if="visible">
    <div class="content">
      <div
        class="item-wrap pointer addclass"
        v-if="datas.length"
        :style="{ width: cardWidth }"
      >
        <div
          class="one"
          v-for="(item, index) in datas"
          :key="index"
          @click="goDetail(item['nc_live_info_id'])"
        >
          <div class="img-box" :style="{ width: '100%' }">
            <dsf-nc-image :src="setImg(item.cover)" :error-src="defaultSrc" />
          </div>
          <div class="info" :style="{ width: '100%' }">
            <p class="ell2">
              {{ item.title }}
            </p>
            <div class="detail" :style="{ display: 'initial' }">
              <div class="teacher">
                {{ item.speaker }}
              </div>
              <div class="views">
                <span class="num">{{ item.time_sdate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="errorimg">
        <img src="../../../assets/styles/img/pcimg/guizhou/nodata.png" alt="" />
      </div>
      <div class="block" style="margin: 30px 0">
        <el-pagination
          v-if="pageisshow"
          ref="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="8"
          layout="prev, pager, next,total, jumper,sizes"
          background
          :popper-class="edpagination"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzClassRoomList", //gzclassroomlist
  ctrlCaption: "贵州大讲堂列表",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    cardWidth: {
      type: String,
      default: "220px",
    },
    detailUrl: {
      type: String,
      default: "/pc/nc/pagelive/liveDetail?id=",
    },

    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "标题" },
          { from: "teacher", to: "teacher", type: "text", desc: "授课教师" },
          { from: "views", to: "views", type: "text|number", desc: "浏览量" },
          { from: "nc_live_info_id", to: "id", type: "text", desc: "id" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      datas: [],
      total: null,
      currentPage4: 1,
      row: 8,
      page: 1,
      isserach: null,
      pageisshow: true,
    };
  },
  created() {
    this.getList(1, 8);
    this.getcount();
    eventBus.$on("search", (data, isserach) => {
      // 更新分页
      this.pageisshow = false;
      this.$nextTick(() => {
        this.currentPage4 = 1;
        this.pageisshow = true;
      });
      this.isserach = isserach;
      this.datas = data;
      this.getSearchCount(isserach);
    });
    eventBus.$on("reset", (data) => {
      // 更新分页
      this.pageisshow = false;
      this.$nextTick(() => {
        this.currentPage = 1;
        this.pageisshow = true;
      });
      this.datas = data;
      this.isserach = null;
      this.getList(1, this.row);
      this.getcount();
    });
  },
  mounted() {},
  components: {},
  methods: {
    // 当前条数钩子
    handleSizeChange(val) {
      this.row = val;
      this.getList(this.page, val, this.isserach);
    },
    // 当前页数钩子
    handleCurrentChange(val) {
      this.page = val;
      this.getList(val, this.row, this.isserach);
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    goDetail(id) {
      window.open(
        `${location.origin}/page.html#/pc/nc/pagelive/liveDetail?id=${id}`
      );
    },
    getList(pageNum, pageSize, searchValue) {
      let that = this;
      const mainUrl = "/nc/zbgl/list";
      const params = {
        pageNum,
        pageSize,
        query: JSON.stringify({
          searchValue: searchValue ? searchValue : "",
          type: 2, //直播历史
          video: 0,
        }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.datas = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getcount() {
      let that = this;
      const mainUrl = "/nc/zbgl/listCount";
      const params = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({
          searchValue: "",
          type: 2, //直播历史
          video: 0,
        }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getSearchCount(searchValue) {
      let that = this;
      const mainUrl = "/nc/zbgl/listCount";
      const params = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({
          searchValue,
          type: 2,
          video: 0,
        }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style lang="scss" scoped></style>
