<template>
  <div class="dsf_nc_gzfooter">
    <div class="content">
      <div class="rightinfo">
        <div class="itemleft">
          <div class="title">友情链接</div>
          <div class="body">
            <div class="name">
              贵阳市
              <br />
              遵义市
              <br />
              六盘水市
              <br />
              安顺市
              <br />
              毕节市
              <br />
            </div>
            <div class="place">
              铜仁市
              <br />
              黔东南苗族侗族自治州
              <br />
              黔南布依族苗族自治州
              <br />
              黔西南布依族苗族自治州
              <br />
            </div>
          </div>
        </div>
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="body" v-for="(_item, index) in item.content" :key="index">
            {{ _item }}
          </div>
        </div>
      </div>
      <div class="leftlogo">
        <div class="logoimg">
          <img class="logo" :src="imgUrl" alt="" />
          <span class="line"></span>
          <a href="http://bszs.conac.cn/sitename?method=show&id=B45E4251655CA53DE05310291AAC041E" target="_blank">
            <img class="shiyeimg" src="../../../assets/styles/img/pcimg/guizhou/icon-foot.png" />
          </a>
        </div>
        <div class="logoinfo">
          <span>{{ this.copyright }}</span>
          <div style="width: 460px; display: flex; margin-left: -80px">
            <span style="cursor: pointer" @click="gothree">{{ this.filling }}</span>
            <span style="cursor: pointer; display: flex; align-items: center" @click="gothree">
              <img width="18px" height="18px" style="margin-right: 8px" src="../../../assets/styles/img/pcimg/guizhou/batb.png" alt="" />
              {{ this.filling2 }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzFooter",
  ctrlCaption: "页脚",
  mixins: [$mixins.control],
  props: {
    copyright: {
      type: String,
      default: "贵州省党员干部网络学院版权所有",
    },
    filling: {
      type: String,
      default: "黔ICP备18004040号-6",
    },
    filling2: {
      type: String,
      default: "贵公网安备 52011102002920号",
    },
  },
  components: {},
  data() {
    return {
      imgUrl: require("../../../assets/styles/img/pcimg/guizhou/logo.png"),
      list: [
        {
          title: "联系方式",
          content: ["技术支持: 0851-85505045","联系电话: 0851-85505037","联系邮箱: gzwy85528952@126.com",],
        },
        {
          title: "合作伙伴",
          content: ["云上贵州大数据产业发展有限公司", "上海梦创双杨数据科技股份有限公司", "深圳市仕通优途科技有限公司", "上海喜马拉雅科技有限公司", "阿里云技术有限公司"],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    gothree() {
      window.open(`https://beian.mps.gov.cn/#/query/webSearch`);
    },
    init(obj) {
      for (const key in obj) {
      }
    },
  },
});
</script>

<style></style>
