<template>
  <div class="dsf_nc_qualitydetail">
    <div class="nc_assessment-box">
      <div class="nc_assessment">
        <div class="title-box">
          <p class="title">素质评估</p>
          <div class="title-search-box">
            <input id="search" ref="search" class="title-search" :value="val" placeholder="搜索" type="text" @input="write"
              @keydown.enter="getData" />
            <img id="search-btn" src="../../../assets/styles/img/pcimg/guizhou/search.png" alt="" @click="getData" />
          </div>
        </div>
        <p class="content"></p>
        <ul id="nc-assessment-lists">
          <li class="nc-assessment-item" v-for="(item, index) in dataList" :key="index">
            <div class="nc-assessment-item-box">
              <p class="heade">{{ item.projectName }}</p>
              <div class="item-bg">
                <img :src="setImg(item.cover)" alt="" ref="img" />
              </div>
              <div class="footer-box" :style="initstyle(item.iskscp)">
                <p class="date" v-if="item.iskscp">
                  {{ setDate(item.time_sdate) }} -
                  {{ setDate(item.time_edate) }}
                </p>
                <p class="date" v-else>

                </p>
                <div class="btn" @click="godetail(item.url, item.nc_szcp_jbxx_id, item)">
                  {{ item.iskscp ? " 点击进入" : "暂未开始，敬请期待" }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from "vue";

export default dsf.component({
  name: "DsfNcGzQualityDetail", //dsf.ncgzqualitydetail
  ctrlCaption: "素质评估详情页",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      val: "",
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.setDate();
  },
  methods: {
    write() {
      this.$nextTick(() => {
        this.val = this.$refs.search.value.trim();
      });
    },
    initstyle(iskscp) {
      return iskscp ? "" : "background:#ccc";
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].absolutePath;
        // src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    setDate(date) {
      if (date) {
        var initdate = date.split(" ");
        var init = initdate[0];
      }

      return init;
    },
    godetail(url, id, item) {
      /* iskscp:  意思就是 当状态 (sfqy_value)启用=1时 且 当前时间  >= 这个工具的开始时间  这个值就是1 */
      //2023.03.02 gaoxugen改
      let that = this;
      if (item.iskscp && item.sfqy_value == "1") {
        if (url && url !== "null") {
          window.open(url);
        } else {
          let loader = dsf.layer.loading();
          const mainUrl = "/inc/nc/szcp/getDetailInfo";
          const params = {
            nc_szcp_jbxx_id: id,
          };
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                window.open(res.data.url);
              } else {
                that.$message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              //   that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              dsf.layer.closeLoading(loader);
            });
        }
      } else {
        that.$message({
          message: "暂未开始，敬请期待！",
          type: "warning",
        });
      }

      /*   if (url && url !== "null") {
          if (item.iskscp && item.sfqy_value == "1") {
            window.open(url);
          }
        } else {
          let that = this;
          let loader = dsf.layer.loading();
          const mainUrl = "/inc/nc/szcp/getDetailInfo";
          const params = {
            nc_szcp_jbxx_id: id,
          };
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                window.open(res.data.url);
              } else {
                that.$message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              //   that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              dsf.layer.closeLoading(loader);
            });
        } */
    },
    getData() {
      let that = this;
      const mainUrl = "/inc/nc/szcp/getList";
      let userid = dsf.getCookie("userId");
      let search = this.val ? this.val : "";
      const params = {
        userId: userid,
        start: 1,
        size: 15,
        keyword: search,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
