var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dsf_nc_specialdetail",style:({ display: _vm.visible ? 'block' : 'none' })},[_c('div',{staticClass:"special_detail_innerwrap",style:({ width: _vm.itemWidth })},[_c('div',{staticClass:"detail_imgbox cover"},[_c('dsf-nc-image',{attrs:{"src":_vm._f("imgFormat")(_vm.detailData.cover),"width":"530px","height":"280px","error-src":_vm.defaultSrc}})],1),_c('div',{staticClass:"detail_conent"},[_c('div',{staticClass:"detail_name ell"},[_vm._v(_vm._s(_vm.detailData.name))]),_c('div',{staticClass:"bm_date font"},[_vm._v("报名日期："+_vm._s(_vm.setDate(_vm.detailData.bmsjSdate, _vm.detailData.bmsjEdate, "至")))]),_c('div',{staticClass:"px_date font"},[_vm._v("培训日期："+_vm._s(_vm.setDate(_vm.detailData.pxsjSdate, _vm.detailData.pxsjEdate, "至")))]),_c('div',{staticClass:"jytj font"},[_vm._v("结业条件：选修 "+_vm._s(_vm.detailData.usercompulsoryGate || 0)+" 门     必修 "+_vm._s(_vm.detailData.compulsory || 0)+" 门")]),(_vm.showDw)?_c('div',{staticClass:"unit font"},[_c('span',{staticClass:"sponsor"},[_vm._v("主办单位："+_vm._s(_vm.detailData.zbdw || "暂无"))]),_vm._v("     "),_c('span',{staticClass:"undertake"},[_vm._v("承办单位："+_vm._s(_vm.detailData.cbdw || "暂无"))])]):_vm._e(),_c('div',{staticClass:"student font"},[_vm._v("学员人数："+_vm._s(_vm.detailData.studentCount))]),_c('div',{staticClass:"foot_wrap"},[_c('ul',{staticClass:"labe_wrap"},_vm._l((_vm.labelLists),function(item,index){return _c('li',{key:index,staticClass:"labe_item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"btn_box"},[(
              (_vm.detailData.state == -1 && (_vm.isstudent == 2 || _vm.isstudent == 3)) ||
              (_vm.detailData.state == 3 && _vm.isstudent == 0) ||
              (_vm.detailData.state == 3 && _vm.isstudent == 2) ||
              (_vm.detailData.bm == 1 && _vm.showBtn && _vm.detailData.state != 1 && _vm.detailData.state != 2 && _vm.isstudent == 0) ||
              (_vm.detailData.bm == 1 && _vm.showBtn && _vm.detailData.state != 1 && _vm.detailData.state != 2 && _vm.isstudent == 2) ||
              (_vm.detailData.bm == 1 && _vm.showBtn && _vm.detailData.state != 1 && _vm.detailData.state != 2 && _vm.isstudent == 3)
            )?_c('div',{class:['btn', _vm.isDuringDate(_vm.detailData.bmsjSdate, _vm.detailData.bmsjEdate) ? '' : 'gray'],on:{"click":function($event){!_vm.isview && _vm.detailData.state != 3 && _vm.clickEnter(_vm.detailData)}}},[_vm._v(" "+_vm._s(_vm.setStatus(_vm.detailData))+" ")]):_vm._e(),(_vm.detailData.state == 5)?_c('div',{staticClass:"reason"},[_vm._v(" "+_vm._s(_vm.detailData.reason)+" ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }