<template>
  <div class="dsf_nc_manual">
    <div class="leftbox">
      <div v-if="initpage" class="news_wrap" handler="newsWrap">
        <h5 class="news_title">
          {{ initpage.title }}
        </h5>
        <h3 class="subtitle"></h3>
        <div class="news_info">
          <div>
            <span class="tiem">{{ initdate(initpage) }}</span>
            <span class="lookview"
              ><i class="icon"></i>{{ initpage.page_view || 1 }}</span
            >
          </div>
        </div>
        <div
          class="news_content"
          v-if="initpage.content"
          v-html="initpage.content"
        ></div>
        <video
          width="80%"
          controls
          class="news_content"
          v-else
          :src="setImg(initpage.videos)"
        ></video>
      </div>
      <el-empty v-else :image-size="150"></el-empty>
    </div>
    <div class="rightbox">
      <div class="newest_news">
        <div class="module_header">
          <h6
            handler="module_header_h"
            style="border-bottom-color: rgb(234, 77, 77)"
          >
            操作手册
          </h6>
        </div>
        <ul class="news_list" handler="newestList" v-if="dataList.length > 0">
          <li
            v-for="(item, index) in dataList"
            :key="index"
            @click="updata(item.cms_articles_id)"
          >
            <span style="background-color: rgb(234, 77, 77)">{{
              index + 1
            }}</span
            >{{ item.title }}
          </li>
        </ul>
        <el-empty v-else :image-size="150"></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzManual",
  ctrlCaption: "贵州操作手册",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      initpage: [],
      initid: null,
      urlId: location.hash.split("?id=")[1],
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    initdate(times) {
      if (times.publish_time) {
        var newtime = times.publish_time;
        return newtime.split(" ")[0];
      }
    },
    updata(item) {
      let that = this;
      const mainUrl = `/nc/articles/getDetail?id=${
        item || that.urlId || that.initid
      }`;
      that.dsf.http
        .get(mainUrl)
        .done(function (res) {
          if (res.success) {
            that.initpage = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },

    getData() {
      let that = this;
      const mainUrl = "/nc/articles/list";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // res.data = [];
            that.dataList = res.data;
            that.initpage = res.data[0] || null;
            that.initid = that.initpage.cms_articles_id;
            that.updata();
            console.log(res.data, "手册");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
