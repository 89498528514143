<template>
  <div class="ds-control ds-home-tab-panel" :class="{ 'ds-no-padding': !isDesign }" :style="ctrlStyle" :mode="styleType" :showShadow="showShadow">
    <div class="ds-home-tab-panel-warp" :style="warpStyle">
      <div class="ds-home-tab-panel-head">
        <div v-if="titleIcon || resTitle" class="ds-home-tab-panel-head-title">
          <DsfIcon v-if="titleIcon" :name="titleIcon" class="ds-home-tab-panel-head-title-icon" />
          <div v-if="resTitle" class="ds-home-tab-panel-head-title-text">
            {{ resTitle }}
          </div>
        </div>
        <div class="ds-home-tab-panel-head-scroll">
          <div v-show="showTabs" class="ds-home-tab-panel-head-items">
            <template v-for="(tab, index) in userTabs">
              <div v-if="!tab.hide" :key="tab.name" :ref="tab.name" class="ds-home-tab-panel-head-item" :active="tabActive == tab.name" @click="clickTab($event, tab, index)">
                {{ tab.title }}
                <div v-show="tab.badge > 0" class="ds-home-tab-panel-head-item-badge">
                  <span v-show="tabActive == tab.name">
                    {{ tab.badge }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-show="showTabs" class="ds-home-tab-panel-head-indicator">
          <DsfIcon class="ds-home-tab-panel-head-indicator-icon ds-home-tab-panel-head-indicator-left" name="left" @click="moveScroll('left')" />
          <DsfIcon class="ds-home-tab-panel-head-indicator-icon ds-home-tab-panel-head-indicator-right" name="right" @click="moveScroll('right')" />
        </div>
        <slot name="header-right" :active="tabActive"></slot>
        <slot name="add">
          <div v-show="getAdd && getAdd.url && addShow" class="ds-home-tab-panel-head-icon ds-home-tab-panel-head-more" @click="panelIconBtnClick('add')">
            <template v-if="addText">{{ addText }}</template>
            <DsfIcon v-if="addIcon" :name="addIcon" />
          </div>
        </slot>
        <slot name="more">
          <div v-show="getMore && getMore.url && moreShow" class="ds-home-tab-panel-head-icon ds-home-tab-panel-head-more" @click="panelIconBtnClick('more')">
            <template v-if="moreText">{{ moreText }}</template>
            <DsfIcon v-if="moreIcon" :name="moreIcon" />
          </div>
        </slot>
        <slot name="config">
          <div v-show="settingShow" class="ds-home-tab-panel-head-icon ds-home-tab-panel-head-more" @click="panelIconBtnClick('setting')">
            <template v-if="settingText">{{ settingText }}</template>
            <DsfIcon v-if="settingIcon" :name="settingIcon" />
          </div>
        </slot>
      </div>
      <template v-for="(slot, index) in userTabs">
        <template v-if="singleSlot && !index">
          <div v-if="!slot.hide" :key="slot.name" v-show="singleSlot || tabActive == slot.name" class="ds-home-tab-panel-main" :style="mainStyle" :slot-name="slot.name">
            <slot v-if="isDesign" :name="slot.name"></slot>
            <DsfVirtualScroll v-else>
              <slot :name="slot.name"></slot>
            </DsfVirtualScroll>
          </div>
        </template>
        <template v-else-if="!singleSlot">
          <div v-if="!slot.hide" :key="slot.name" v-show="tabActive == slot.name" class="ds-home-tab-panel-main" :style="mainStyle" :slot-name="slot.name">
            <slot v-if="isDesign" :name="slot.name"></slot>
            <DsfVirtualScroll v-else-if="slot.lazy">
              <slot v-if="slot.isLadding" :name="slot.name"></slot>
            </DsfVirtualScroll>
            <DsfVirtualScroll v-else>
              <slot :name="slot.name"></slot>
            </DsfVirtualScroll>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfGzHomeTabPanel",
  ctrlCaption: "标签面板-贵州",
  mixins: [$mixins.layout],
  provide() {
    return {
      $homePanel: this,
    };
  },
  design: {
    isMask: false,
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [];
      },
    },
    selectTabsVals: {
      type: Array,
      default: null,
    },
    // 标题
    title: {
      type: String,
      default: "面板标题",
    },
    // 标题Icon
    titleIcon: {
      type: String,
      default: "qiapianmoshi_kuai",
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: true,
    },
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 更多图标 icon名称
    moreIcon: {
      type: String,
      default: "gengduo",
    },
    // 更多文字
    moreText: {
      type: String,
      default: "",
    },
    // 更多文字
    moreShow: {
      type: Boolean,
      default: false,
    },
    settingIcon: {
      type: String,
      default: "shezhi",
    },
    settingText: {
      type: String,
      default: "",
    },
    settingShow: {
      type: Boolean,
      default: false,
    },
    addIcon: {
      type: String,
      default: "add",
    },
    addText: {
      type: String,
      default: "",
    },
    addShow: {
      type: Boolean,
      default: false,
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 边框
    margin: {
      type: Array,
      default() {
        return [8, 8, 8, 8];
      },
    },
    // 风格
    styleType: {
      type: String,
      default: "one",
    },
    // 默认激活
    active: {
      type: String,
      default: "",
    },
    // 用户设置的选中
    userActive: {
      default: null,
    },
    // 是否可见
    visible: {
      type: Boolean,
      default: true,
    },
    singleTabShow: {
      type: Boolean,
      default: true,
    },
    singleSlot: {
      type: Boolean,
      default: false,
    },
    badgeUrl: {
      type: String,
      default: "",
    },
    openScroll: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    beforeOpenSetting: {
      default: null,
    },
    singleTabAyncTitle: {
      default: false,
    },
  },
  data() {
    return {
      tabActive: "",
      selectTabsValsCopy: null,
    };
  },
  computed: {
    ctrlStyle() {
      let { width, height, visible, isDesign } = this;
      let style = {
        padding: this.isDesign ? undefined : this.unitSize(this.margin).join(" "),
      };
      if (width == "100%") {
        style.width = "100%";
      }
      if (height == "100%") {
        style.height = "100%";
      }
      if (isDesign) {
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    warpStyle() {
      let { width, height, backgroundColor, borderRadius } = this;
      const style = {
        width,
        height,
        backgroundColor,
        borderRadius: borderRadius.join("px ") + "px",
      };
      let isBorderRadius = borderRadius.some(radius => radius);
      if (isBorderRadius) style.overflow = "hidden";
      return style;
    },
    mainStyle() {
      let { padding } = this;
      let style = {};
      style.padding = padding.join("px ") + "px";
      return style;
    },
    getMore() {
      if (this.isDesign) {
        return false;
      }
      let more = { url: "" };
      let tab = this.getActiveTab();
      if (tab && tab.more) {
        more = tab.more;
      }
      return more;
    },
    getAdd() {
      if (this.isDesign) {
        return false;
      }
      let add = { url: "" };
      let tab = this.getActiveTab();
      if (tab && tab.add) {
        add = tab.add;
      }
      return add;
    },
    showTabs() {
      let { singleTabShow, singleTabAyncTitle, userTabs } = this;
      return (!singleTabAyncTitle && singleTabShow) || userTabs.length > 1;
    },
    realSelectTabsVals() {
      if (this.selectTabsValsCopy) {
        console.log();
        return this.selectTabsValsCopy;
      }
      let rootCtrl = this.getRootCtrl();
      if (rootCtrl.selectTabsVals) {
        return rootCtrl.selectTabsVals;
      }
      console.log();
      if (rootCtrl.$attrs?.selectTabsVals) {
        return rootCtrl.$attrs.selectTabsVals;
      }
      return this.selectTabsVals;
    },
    userTabs() {
      if (this.isDesign) {
        return this.slots;
      }
      let tabs = {};
      _.forEach(this.slots, tab => {
        tabs[tab.name] = tab;
        tabs[tab.name]["isLadding"] = tab.lazy && tab.name != this.active ? false : true;
      });
      let realSelectTabsVals = _.map(this.slots, "name");
      return realSelectTabsVals.filter(name => tabs[name]).map(name => tabs[name]);
    },
    resTitle() {
      let { title, singleTabAyncTitle, userTabs } = this;
      if (singleTabAyncTitle && userTabs?.length == 1) {
        return userTabs[0].title || title;
      }
      return title;
    },
  },
  watch: {
    styleType() {
      this.resize();
    },
    getMore() {
      this.resize();
    },
    userTabs() {
      this.resize();
    },
    slots: {
      immediate: true,
      deep: true,
      handler() {
        this.asyncActive();
      },
    },
    active: {
      handler() {
        this.asyncActive();
      },
    },
  },
  created() {
    this.getBadgeUrl();
    if (this.isDesign) {
      this.$dispatch("design-height-change", this.height);
    }
    if (this.active) {
      this.tabActive = this.active;
    }
  },
  mounted() {},

  methods: {
    resize() {
      if (this.resizeSetTimeout) {
        clearTimeout(this.resizeSetTimeout);
      }
      this.resizeSetTimeout = setTimeout(() => {
        this.$nextTick(() => {
          this.asyncStyle();
        });
      }, 300);
    },
    getRootCtrl() {
      let node = this;
      while (!node.caption && node.$parent !== node) {
        node = node.$parent;
      }
      if (node.caption) {
        return node;
      }
      return this;
    },
    panelIconBtnClick(type) {
      if (this.isDesign) return;
      if (type == "add") {
        this.toAdd();
      } else if (type == "more") {
        this.toMore();
      } else if (type == "setting") {
        this.openSetting();
      }
    },
    openSetting() {
      if (this.$listeners["setting-click"]) {
        return this.$dispatch("setting-click", this.getActiveTab());
      }
      this.defaultOpenSetting();
    },
    toOpen(opt) {
      if (!opt || !opt.url) {
        return;
      }
      this.$open(opt);
    },
    defaultOpenSetting() {
      this.$openDialog({
        title: "个性化设置",
        showClose: true,
        content: "DsfTabPanelSetting",
        height: "500px",
        width: "800px",
        params: {
          currentData: this.slots || [],
          selectTabsVals: this.realSelectTabsVals || _.map(this.userTabs, "name") || [],
          noUrl: true,
        },
        btns: [
          {
            text: "重置",
            handler: res => {
              res?.reset();
            },
          },
          {
            text: "确定",
            handler: res => {
              let realSelectTabsVals = (res.yes && res.yes()) || [];
              let rootCtrl = this.getRootCtrl();
              if (!realSelectTabsVals.length) {
                realSelectTabsVals = _.map(this.slots, "name");
              }
              if (realSelectTabsVals.indexOf(this.tabActive) === -1) {
                this.tabActive = realSelectTabsVals[0] || "";
              }
              this.selectTabsValsCopy = realSelectTabsVals;
              if (rootCtrl.caption) {
                rootCtrl.$emit("update:selectTabsVals", realSelectTabsVals);
                rootCtrl.$addCustom(rootCtrl.caption, {
                  selectTabsVals: realSelectTabsVals,
                });
              }
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    asyncActive() {
      if (this.slots && this.slots[0]) {
        if (!this.slots.some(slot => !slot.hide && slot.name == this.tabActive)) {
          this.tabActive = this.slots[0].name;
        }
      } else if (this.active) {
        this.tabActive = this.active;
      } else {
        this.tabActive = null;
      }
      this.asyncStyle();
    },
    // 跳转新增
    toAdd() {
      if (this.$listeners["add-click"]) {
        return this.$dispatch("add-click", this.getActiveTab());
      }
      this.toOpen(this.getAdd);
    },
    // 跳转更多
    toMore() {
      if (this.$listeners["more-click"]) {
        return this.$dispatch("more-click", this.getActiveTab());
      }
      this.toOpen(this.getMore);
    },
    getActiveTab() {
      let { tabActive, slots } = this;
      return slots.find(slot => slot.name == tabActive);
    },
    getBadgeUrl() {
      let { badgeUrl, isDesign, slots } = this;
      if (isDesign || !badgeUrl) return false;
      let param = {};
      this.$http
        .get(badgeUrl, param)
        .done(({ success, data, message }) => {
          if (success && data) {
            slots.forEach(slot => {
              this.$set(slot, "badge", data[slot.name] || 0);
            });
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || "未知错误", false);
        });
    },
    async reloadData() {
      await this.getBadgeUrl();
      this.$childrenReloadData();
    },
    asyncStyle() {
      if (this.asyncStyleOut) {
        clearTimeout(this.asyncStyleOut);
      }
      this.asyncStyleOut = setTimeout(() => {
        let elDom = $(this.$el);
        let itemsDom = elDom.find(".ds-home-tab-panel-head-items");
        let scrollDom = elDom.find(".ds-home-tab-panel-head-scroll");
        let indicatorDom = elDom.find(".ds-home-tab-panel-head-indicator");
        this.indicatorLeftDom = elDom.find(".ds-home-tab-panel-head-indicator-left");
        this.indicatorRightDom = elDom.find(".ds-home-tab-panel-head-indicator-right");
        this.itemsDom = itemsDom;
        this.scrollDom = scrollDom;
        this.indicatorDom = indicatorDom;
        if (itemsDom[0].scrollWidth - scrollDom.width() < 2) {
          indicatorDom.hide();
        }
        this.asyncArrowStyle();
      });
    },
    moveScroll(direction, distance) {
      let { scrollDom } = this;
      let moveDistance = dsf.isUnDef(distance) ? scrollDom.width() / 2 : distance;
      if (direction == "left") {
        moveDistance = scrollDom.scrollLeft() - moveDistance;
      } else {
        moveDistance = scrollDom.scrollLeft() + moveDistance;
      }
      scrollDom.animate({ scrollLeft: moveDistance }, 300, () => {
        this.asyncArrowStyle();
      });
    },
    asyncArrowStyle() {
      let { scrollDom, itemsDom, indicatorLeftDom, indicatorRightDom } = this;
      let scrollWidth = scrollDom.width();
      let scrollLeft = scrollDom.scrollLeft();
      let itemWidth = itemsDom[0].scrollWidth;
      indicatorLeftDom.removeAttr("disable");
      indicatorRightDom.removeAttr("disable");
      if (scrollLeft <= 0) {
        indicatorLeftDom.attr("disable", "disable");
      }
      if (scrollLeft + scrollWidth - itemWidth > -1) {
        indicatorRightDom.attr("disable", "disable");
      }
    },
    clickTab(e, tab, index) {
      this.tabActive = tab.name;
      this.userTabs[index].isLadding = true;
      this.$dispatch("change", tab);
      this.moveToTabLocation(tab.name);
    },
    moveToTabLocation(key) {
      let { scrollDom, $refs } = this;
      if (!$refs[key] || !$refs[key][0]) return;
      let tabDom = $($refs[key][0]);
      let start = scrollDom.scrollLeft();
      let end = start + scrollDom.width();
      let tabStart = tabDom.position().left + start;
      let tabEnd = tabStart + tabDom.width() + parseInt(tabDom.css("marginLeft"));
      if (tabStart - start > 2 && tabEnd - end < -2) return;
      scrollDom.animate({ scrollLeft: tabStart + parseInt(tabDom.css("marginLeft")) }, 300, () => {
        this.asyncArrowStyle();
      });
    },
  },
});
</script>
