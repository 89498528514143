<template>
  <div
    class="dsf_specialdetail_relevevatn_information"
    :style="{
      width: itemWidth,
      display: visible ? 'block' : 'none',
      margin: itemSpace.replace(/,/g, ' '),
    }"
  >
    <div class="intro_title">
      <div class="desc">
        <span class="font">{{ title }}</span>
        <span class="more" v-if="showMore && moreStyle == 'tb'" @click="!isview && !!showMore && clickMore()"></span>
        <span class="more_font" v-else-if="showMore && moreStyle == 'wz'" @click="!isview && !!showMore && clickMore()">更多</span>
      </div>
      <span class="line" v-if="showLine"></span>
    </div>
    <ul class="relevant_information_wrap" v-if="lists.length">
      <li class="infomation_items" v-for="(item, index) in lists" :key="index">
        <i :class="`icon ${item['nc_class_file.type']}`"></i>
        <span class="name ell">{{ item["nc_class_file.title"] }}</span>
        <span
          :class="[
            'ylook',
            {
              gray_font: (!specialState && resouceType == 'special') || isview,
            },
          ]"
          v-if="isstudent != 3"
          @click="(!isview && resouceType == 'special' && specialState && handleClickLook(item)) || (!isview && resouceType != 'special' && handleClickLook(item))"
        >
          预览
        </span>
        <span
          :class="[
            'ydown',
            {
              gray_font: (!specialState && resouceType == 'special') || isview,
            },
          ]"
          v-if="item['nc_class_file.download_free'] && item['nc_class_file.download_free'].value == 1 && isstudent != 3"
          @click="(!isview && resouceType == 'special' && specialState && handleClickDown(item)) || (!isview && resouceType != 'special' && handleClickDown(item))"
        >
          下载
        </span>
      </li>
    </ul>
    <div v-else class="ds-empty-data" v-text="'暂无数据'"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default dsf.component({
  name: "DsfNcspecialDetailRelevantInformationNew",
  ctrlCaption: "专题班详情简介相关资料-新",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "相关资料",
    },
    url: {
      type: String,
      default: "/meta/list/data",
    },
    page: {
      type: Number,
      default: 3,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    moreUrl: {
      type: Object,
      default: () => ({}),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreStyle: {
      type: String,
      default: "tb",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    itemSpace: {
      type: [String, Array],
      default: "0,0,0,0",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    resouceType: {
      type: String,
      default: "special",
    },
  },
  data() {
    return {
      lists: [
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "doc",
        },
        {
          "nc_class_file.title": "深入学习贯彻习近平新时代深入学习贯彻习近平新时代深入学习贯彻习近平新时代",
          "nc_class_file.type": "xlsx",
        },
      ],
      params: {
        id: "",
        pageNum: 1,
        pageSize: this.page,
        query: JSON.stringify({
          searchValue: "",
          class_id: "@[url('classId')]",
          publish_state_value: "[1]",
        }),
        order: JSON.stringify([
          { code: "publish_state_value", sort: "desc" },
          { code: "publish_time", sort: "desc" },
          { code: "ds_update_time", sort: "desc" },
        ]),
        filter: "[]",
        namespace: "nc.class.teaching",
        pageName: "upload",
        classId: "",
        specialDetailInfo: "",
        isview: false,
      },
    };
  },
  created() {
    this.params.id = this.queryString.id || "f5e7063e252d42ceb5520a6c5f9dd03e";
    this.params.classId = this.params.id;
    this.isview = this.queryString.isview == 1 ? true : false;
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickMore() {
      if (this.resouceType == "special" && !this.specialState) return; //专题班根据状态判断是否可查看更多列表
      if (!this.showMore) return;
      this.$openDialog({
        title: "全部资料",
        content: "DsfNcPcResouceListNew",
        width: "520px",
        height: "508px",
        params: {
          id: this.queryString.id,
          resouceType: this.resouceType,
          cb: data => {},
        },
      });
    },
    handleClickLook(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      // 预览
      let relativePath = item["nc_class_file.file"];
      if (!relativePath) return;
      relativePath = JSON.parse(relativePath)[0].relativePath;
      let root = dsf.url.getWebPath("preview/file");
      const preUrl = dsf.config.setting_public_file_host;
      let url = `${root}?url=${preUrl}${relativePath}`;
      // window.open(url);
      dsf.layer.openWindow(":" + url);
    },
    // 下载前
    handlerDownBefore(sourceUrl, params, succFn, failFun) {
      let loading = dsf.layer.pc.loading();
      this.dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          res.success && succFn && succFn(res);
          if (!res.success) error(res);
        })
        .error(err => {
          error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
      function error(err) {
        failFun && failFun(err);
        dsf.layer.pc.message(err.message, false);
      }
    },
    handleClickDown(item) {
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      this.handlerDownBefore("nc/class/teaching/file/download", { fileId: item._id, classId: this.params.id }, res => {
        // 下载
        var url = dsf.url.getWebPath("file/download");
        let file = item["nc_class_file.file"];
        if (!file) return;
        file = JSON.parse(file)[0];
        $(
          `<form action="${url}" method="get">
          <input type="text" name="files" value="${file.id}"/>
          <input type="text" name="zipName" value="${file.originalFileName || ""}"/>
        </form>`
        )
          .appendTo("body")
          .submit()
          .remove();
      });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
  computed: {
    ...mapState(["isstudent"]),
    ...mapGetters(["getSpecialInfo"]),
    specialState() {
      // 专题班控制资料下载的状态
      if (this.getSpecialInfo.state === 0 || this.getSpecialInfo.state === 4 || this.getSpecialInfo.state == -1) {
        return false;
      } else {
        return true;
      }
    },
  },
});
</script>
