<template>
  <div class="ds-control ds-subtable-cell-content ds-no-padding">
    <slot :name="slots[0].name"></slot>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfSubTableCellContentNew",
  mixins: [$mixins.layout],
  provide() {
    return {
      $sublistContent: this
    };
  },
  ctrlCaption: "子表单元格内容-新",
  treeProxy: false,
  layoutClone: true,
  props: {
    treeProxy: {
      type: Boolean,
      default: true
    },
    // layoutClone: {
    //   type: Boolean,
    //   default: true
    // },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    }
    // cloneProps:{
    //   type:Boolean,
    //   default:true
    // }
  },
  data() {
    return {
      scope: null
    };
  },
  updated() {
    // console.log("更新")
  },
  created() {},
  mounted() {
  },

  methods: {},
  watch: {}
});
</script>
