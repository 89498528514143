<template>
  <dsf-bbs-views
    :page-title="pageTitle"
    @sorted="sortedQuery"
    :showSorted="showSorted"
    :managerStatus="managerStatus"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="allPage">
        <dsf-list-content
          :managerStatus="managerStatus"
          :list-content="listContent"
          @page-event="queryData"
          @sorted="sortedQuery"
        />
      </el-tab-pane>
    </el-tabs>
  </dsf-bbs-views>
</template>

<script>
import DsfBbsViews from "../base/bbs/BbsViews";
import DsfListContent from "../base/bbs/ListContent";
export default {
  name: "DsfAllPage",
  data() {
    return {
      queryParams: {
        userId: "",
        catalogId: "",
        sorted: 0,
        pageNumber: 1,
        pageSize: 10,
        keywords: "",
        status: "",
        belongId: "",
      },
      queryUrl: "",
      activeName: "allPage",
      pageTitle: "所有帖子",
      listContent: {},
      managerStatus: false,
      showSorted: true,
    };
  },
  created() {
    this.showSorted = this.$route.query.star == 1 ? false : true;
    this.queryParams.userId = this.$route.query.u || "";
    this.queryParams.catalogId = this.$route.query.c;
    if (this.$route.query?.isAll) {
      if (this.$route.query.isAll == 0) {
        this.queryParams.belongId = this.$route.query.belongId;
      } else {
        this.queryParams.belongId = "";
      }
    } else {
      this.queryParams.belongId = this.$route.query.belongId;
    }
    // this.queryParams.belongId = this.$route.query.belongId
    if (this.$route.query.star == 1) {
      this.queryUrl = "web/user/getMyCollect";
      this.pageTitle = "我的收藏";
      // 查询板块下主题信息
      this.queryData();
    } else if (this.$route.query.hasAuditRole) {
      //查询权限
      this.queryRole();
    } else {
      this.queryUrl = "web/topic/list";
      this.pageTitle = this.$route.query.u
        ? "他的帖子"
        : this.$route.params?.title;
      // 查询板块下主题信息
      this.queryData();
    }
  },
  methods: {
    queryRole() {
      this.bbsLoading = true;
      this.$http
        .get("web/topic/hasAuditRole", {}, $$webRoot.bbs)
        .done((res) => {
          // console.log(res);
          if (res.success && res.state == 20000) {
            this.managerStatus = res.data;
            if (res.data) {
              this.pageTitle = "主题审查";
              this.queryUrl = "web/topic/verify";
              this.queryParams = {
                pageSize: 10,
                pageNumber: 1,
                type: -1,
              };
              // 查询板块下主题信息
              this.queryData();
            } else {
              this.queryUrl = "web/topic/list";
              this.pageTitle = this.$route.query.u
                ? "他的帖子"
                : this.$route.params?.title;
              // 查询板块下主题信息
              this.queryData();
            }
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {});
    },
    queryData(pageSize) {
      // 最新 最热
      if (dsf.isDef(pageSize)) {
        this.queryParams.pageNumber = pageSize;
      }
      this.$http
        .get(this.queryUrl, this.queryParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.listContent = res.data;
            if (this.$route.query.c && this.$route.query.cname) {
              this.pageTitle = this.$route.query.cname;
            }
            // JSON.parse(item['topicImg'])
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    sortedQuery(sorted, text) {
      this.queryParams.pageNumber = 1;
      if (text == "未通过") {
        this.queryParams.type = 0;
        this.queryParams.status = -1;
      } else if (text == "待审核") {
        this.queryParams.status = 0;
        this.queryParams.type = 2;
      } else if (text == "已发布") {
        this.queryParams.type = 1;
        this.queryParams.status = 3;
      } else if (text == "全部") {
        this.queryParams.type = -1;
        this.queryParams.status = -1;
      } else if (text == "已关闭") {
        this.queryParams.status = 0;
        this.queryParams.type = 1;
      } else {
        this.queryParams.sorted = sorted || 0;
      }
      this.queryData();
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      // this.queryParams.type = -1;
      // this.queryParams.pageNumber = 1;
      // this.queryParams.sorted = 0;
    },
  },
  components: {
    DsfBbsViews,
    DsfListContent,
  },
};
</script>

<style lang="scss" scoped>
.ds-bbs-page.bbs-view .el-main .sort-button {
  top: 7px;
  right: 0;
}
</style>