<template>
  <div class="dsf_nc_comment" :style="{ width: itemWidth }">
    <!-- <div slot-name="default" class="ds-home-panel-content" style="height:150px">
      <slot name="default"></slot>
    </div> -->
    <div class="comment-input">
      <el-input type="textarea" placeholder="请输入评论内容" v-model="commentText"></el-input>
      <button class="comment-save">评论</button>
    </div>
    <div class="comment">
      <div class="comment-item">
        <div class="comment-item-avatar">
          <dsf-image width="48px" height="48px" />
        </div>
        <div class="comment-item-desc">
          <p class="comment-item-desc-name">
            <span class="name">马老板</span>
            <span class="time">2020.12.21 14:00</span>
          </p>
          <div class="comment-item-content">
            <p class="comment-item-content-text">当你有一个傻瓜时，你会很痛苦；你有 50 个傻瓜是最幸福的</p>
            <div class="comment-item-content-text-other">
              <p class="comment-item-content-text-other-item">
                <span class="name">张三:</span>
                <span class="text">附议</span>
              </p>
            </div>
          </div>
          <div class="comment-item-handle">
            <span>
              <i class="iconfont icon-pinglun"></i>
              15
            </span>
            <span>
              <i class="iconfont icon-dianzan"></i>
              15
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcComment",
  ctrlCaption: "评论",
  mixins: [$mixins.layout],
  props: {
    title: {
      type: String,
      default: "标题",
    },
    itemWidth: {
      type: String,
      default: "480px",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      },
    },
  },
  computed: {},
  data() {
    return {
      commentText: "",
    };
  },
  methods: {},
});
</script>
