<template>
  <div class="dsf_nc_recommend">
    <div class="title">
      <div class="imgbox">
        <img src="~@/assets/styles/img/pcimg/guizhou/coursepush/b5.png" alt="" />
      </div>
    </div>
    <el-radio-group text-color="#fff" fill="#A00E0A" v-model="currentOutValue" :size="size" class="my-out-el-tabs">
      <el-radio-button v-for="item in tabList" :key="item.nc_flml_id" :label="item.nc_flml_id" data-name:item.name @click="updatatype">{{ item.name }}</el-radio-button>
    </el-radio-group>
    <div class="tab-container">
      <div class="tab-title">
        <el-radio-group text-color="#fff" fill="#A00E0A" v-model="currentInValue" size="small" class="my-in-el-tabs">
          <el-radio-button label="zr">最热</el-radio-button>
          <el-radio-button label="zx">最新</el-radio-button>
        </el-radio-group>
        <div class="more" @click="more">更多</div>
      </div>
      <div class="tab-content">
        <ul
          :class="['tab-content-ul-left']"
          :style="{
            zIndex: 3,
            backgroundColor: '#fff',
          }"
        >
          <li class="tab-content-li" v-for="(item, index) in list" :key="index" @click="godetail(item['_id'])">
            <dsf-nc-image class="image" :src="setImg(item['nc_courses_page_newhomesource.cover'])" :error-src="defaultSrc" />
            <div class="title-item">
              {{ item["nc_courses_page_newhomesource._name"] }}
            </div>
          </li>
        </ul>

        <ul
          :class="['tab-content-ul-left', translate.showTransitionRight ? 'my-transition' : '']"
          :style="{
            transform: `rotateY(${translate.leftValue}deg)`,
            zIndex: `${translate.leftindex}`,
            transformOrigin: 'right',
            backgroundColor: '#fff',
          }"
        >
          <li class="tab-content-li" v-for="(item, index) in list3" :key="index" @click="godetail(item['_id'])">
            <dsf-nc-image class="image" :src="setImg(item['nc_courses_page_newhomesource.cover'])" :error-src="defaultSrc" />
            <div class="title-item">
              {{ item["nc_courses_page_newhomesource._name"] }}
            </div>
          </li>
        </ul>

        <div class="rightbox">
          <ul
            :class="['tab-content-ul-right', translate.showTransitionLeft ? 'my-transition' : '']"
            :style="{
              transform: `rotateY(${translate.rightValue}deg)`,
              zIndex: `${translate.rightindex}`,
              transformOrigin: 'left',
              backgroundColor: '#fff',
            }"
          >
            <li class="tab-content-li" v-for="(item, index) in list2" :key="index" @click="godetail(item['_id'])">
              <dsf-nc-image class="image" :src="setImg(item['nc_courses_page_newhomesource.cover'])" :error-src="defaultSrc" />
              <div class="title-item">
                {{ item["nc_courses_page_newhomesource._name"] }}
              </div>
            </li>
          </ul>

          <ul
            :class="['tab-content-ul-right']"
            :style="{
              zIndex: 0,
            }"
          >
            <li class="tab-content-li" v-for="(item, index) in list4" :key="index" @click="godetail(item['_id'])">
              <dsf-nc-image class="image" :src="setImg(item['nc_courses_page_newhomesource.cover'])" :error-src="defaultSrc" />
              <div class="title-item">
                {{ item["nc_courses_page_newhomesource._name"] }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bgimg">
      <img src="../../../assets/styles/img/pcimg/guizhou/bg/b3-bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcRecommend",
  ctrlCaption: "课程推荐",
  mixins: [$mixins.control],
  props: {
    /* 上面tab的尺寸 */
    size: {
      type: String,
      default: "medium", //medium small mini
    },
    /* 初始化选中的tab  上面的tab */
    currentOutValue: {
      type: String,
      default: "e4b25c320212444284d672035413eac7",
    },
    /* 初始化选中的tab  下面的tab*/
    currentInValue: {
      type: String,
      default: "zr",
    },
  },

  data() {
    return {
      list: [],
      list2: [],
      list3: [],
      list4: [],
      tabList: [
        {
          name: "全部",
          nc_flml_id: "e4b25c320212444284d672035413eac7",
        },
      ],
      tabdate: null,
      translate: {
        leftValue: 0,
        rightValue: 0,
        showTransitionLeft: false,
        showTransitionRight: false,
        leftindex: 2,
        rightindex: 1,
      },
      oldOutIndex: 0, //上一个tab的下标
      oldlist: null,
      oldlist4: null,
      twotabname: null,
      hotnewType: [{ code: "view_totle", sort: "desc" }],
    };
  },

  watch: {
    /* 监听上面tab变化 */
    currentOutValue: {
      handler(newName, oldName) {
        /* 从查找tabList数组中找出当前选中的下标 */
        let newOutIndex = (this.tabList || []).findIndex(item => item?.nc_flml_id === newName);
        // 获取tab名
        // this.tabdate = this.tabList[newOutIndex]["treeinfo_globalid"];
        this.tabdate = this.tabList[newOutIndex]["nc_flml_id"];
        this.translate.showTransitionLeft = false;
        this.translate.showTransitionRight = false;
        /* 如果当前选中的下标会大于上一个下标的时候 设置left的translate的值  否则设置right的translate的值 */
        if (newOutIndex > this.oldOutIndex) {
          // 下一页
          this.translate.rightindex = 4;
          this.translate.leftindex = 6;
          this.translate.leftValue = -180;
          setTimeout(() => {
            this.translate.showTransitionRight = true;
            this.translate.leftValue = 0;
          });
          this.updatatype(this.hotnewType);
        } else if (newOutIndex < this.oldOutIndex) {
          // 上一页
          this.translate.leftindex = 4;
          this.translate.rightindex = 6;
          this.translate.rightValue = 180;
          setTimeout(() => {
            this.translate.showTransitionLeft = true;
            this.translate.rightValue = 0;
          });
          this.updatatype(this.hotnewType);
        }
        // this.currentInValue = "zr";
        this.oldOutIndex = newOutIndex;
      },

      immediate: true,
    },
    /* 监听下面tab变化 */
    currentInValue(newName) {
      if (newName == "zr") {
        this.hotnewType = [{ code: "view_totle", sort: "desc" }];
        this.updatatype([{ code: "view_totle", sort: "desc" }], 1);
      } else {
        this.hotnewType = [{ code: "ds_create_time", sort: "desc" }];
        this.updatatype([{ code: "ds_create_time", sort: "desc" }], 1);
      }
    },
    oldlist: {
      handler(newval, oldval) {
        setTimeout(() => {
          this.list = newval;
        });
      },
    },
    oldlist4: {
      handler(newval, oldval) {
        setTimeout(() => {
          this.list4 = newval;
        });
      },
    },
  },
  created() {
    this.showList();
    this.showTabList();
  },
  mounted() {},
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },

    more() {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/ncIndex.html#/pc/nc/pagecourse/courseList`);
      } else {
        this.islogin();
      }
    },
    godetail(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}`);
      } else {
        this.islogin();
      }
    },
    showList() {
      let that = this;
      const mainUrl = "/inc/meta/list/data";
      const params = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({ searchValue: "" }),
        order: JSON.stringify([{ code: "view_totle", sort: "desc" }]),
        filter: [],
        namespace: "nc.page.pc.course",
        pageName: "indexcourselist",
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.list = res.data.slice(0, 4);
            that.list2 = res.data.slice(4, 8);
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    showTabList() {
      let that = this;
      const mainUrl = "/inc/nc/flml/findScreenTreeUse";
      const params = {};
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.tabList.push(...res.data);
            // console.log(that.tabList, " that.tabList");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    initdown(hotnew) {
      let time = "";
      if (hotnew) {
        time = 0;
      } else {
        time = 1000;
      }
      if (this.list3 || this.list2) {
        setTimeout(() => {
          this.oldlist = this.list3;
          this.oldlist4 = this.list2;
        }, time);
      }
      clearTimeout();
    },

    updatatype(order, hotnew) {
      let that = this;
      if (that.tabdate == "e4b25c320212444284d672035413eac7") that.tabdate = "";
      const mainUrl = "/inc/meta/list/data";
      const params = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({
          searchValue: "",
          classification_value: that.tabdate,
        }),
        order: JSON.stringify(order),
        filter: JSON.stringify([]),
        namespace: "nc.page.pc.course",
        pageName: "indexcourselist",
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // if (res.data.length == 0) {
            // }
            if (res.data.length <= 2) {
              that.list3 = res.data;
              that.list2 = "";
            } else if (res.data.length > 2 && res.data.length <= 4) {
              that.list3 = res.data.slice(0, 2);
              that.list2 = res.data.slice(2, 4);
            } else if (res.data.length > 4 && res.data.length <= 6) {
              that.list3 = [...res.data.slice(0, 2), ...res.data.slice(4, 6)];
              that.list2 = res.data.slice(2, 4);
            } else {
              that.list3 = res.data.slice(0, 4);
              that.list2 = res.data.slice(4, 8);
            }
            // console.log(res.data, "data");

            that.initdown(hotnew);
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style></style>
