<template>
  <div class="login-user-logins" id="phoneVer">
    <div class="login-tab">
      <div class="login-tab-item tabSel">
        <span>请输入验证信息</span>
      </div>
    </div>
    <div class="login-tab-content" id="UserLoginContent">
      <div class="login-tab-content-item shwItem">
        <div class="login-info login-user-wrap">
          <i class="icon userIcon"></i>
          <input
            type="text"
            placeholder="请输入姓名"
            autocomplete="off"
            id="username1"
          />
        </div>
        <div class="login-info login-user-wrap">
          <i class="icon userIcon"></i>
          <input
            type="text"
            placeholder="请输入学员账号"
            autocomplete="off"
            id="codeBh"
          />
        </div>
        <div class="login-info login-user-wrap">
          <i class="icon userIcon"></i>
          <input
            type="text"
            placeholder="请输入学员手机号码后4位"
            autocomplete="off"
            maxlength="4"
            id="verification"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
          />
        </div>
        <div class="login-info login-user-wrap">
          <i class="icon userIcon"></i>
          <input
            type="text"
            placeholder="请再次确认学员手机号码后4位"
            maxlength="4"
            autocomplete="off"
            id="reverification"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
          />
        </div>
        <button class="login-btn" @click="over" @keydown.enter="over">
          完成
        </button>
        <div class="phone_tips">
          （注：若当前登录的是管理员账号，请输入管理员账号的信息；如姓名为单位名称、账号是以字母开头的编号、手机号输入使用人的即可，此信息仅用于找回密码）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcNewChangePhone",
  ctrlCaption: "贵州校验手机号",
  mixins: [$mixins.control],
  props: {
    reqUrl: {
      type: String,
      default: "/nc/org/user/detail",
    },
    getCodeUrl: {
      type: String,
      default: "nc/org/user/sendNcVerifCode",
    },
    authCodeUrl: {
      type: String,
      default: "nc/org/user/updatePhone",
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    // // 清楚所有cookie
    // clearCookie() {
    //   var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    //   if (keys) {
    //     for (var i = keys.length; i--; ) {
    //       document.cookie =
    //         keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
    //       document.cookie =
    //         keys[i] +
    //         "=0;path=/;domain=" +
    //         document.domain +
    //         ";expires=" +
    //         new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
    //       document.cookie =
    //         keys[i] +
    //         "=0;path=/;domain=kevis.com;expires=" +
    //         new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
    //     }
    //   }
    // },
    // 完成按钮
    over() {
      var $userVal = $("#username1").val();
      var $code = $("#codeBh").val();
      var $verification = $("#verification").val();
      var $reverification = $("#reverification").val();
      if (
        $userVal &&
        $code &&
        $verification &&
        $verification == $reverification &&
        $verification.length == 4 &&
        $reverification.length == 4
      ) {
        let that = this;
        const mainUrl = "/nc/user/setVerification";
        const params = {
          name: $userVal,
          code: $code,
          verificationText: $verification,
          verificationText2: $reverification,
        };
        that.dsf.http
          .post(mainUrl, params)
          .done(function (res) {
            if (res.success) {
              that.dsf.layer.message(res.message);
              // that.clearCookie();
              location.reload();
            } else {
              that.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            // that.dsf.layer.closeLoading(loader);
          });
      } else {
        if ($userVal == "") {
          dsf.layer.message("用户名不能为空", false);
          return;
        } else if ($code == "") {
          dsf.layer.message("编号不能为空", false);
          return;
        } else if ($verification.length < 4) {
          dsf.layer.message("手机号后四位不能小于4位", false);
          return;
        } else if ($verification != $reverification) {
          dsf.layer.message("手机号后四位两次输入不同", false);
        }
      }
    },
  },
});
</script>
