<template>
  <div class="dsf_nc_xlysgin">
    <div class="calender2">
      <p class="titlewarn">恭喜你连续签到{{ dataList.lxDays }}天</p>
      <img class="headimg" src="../../../assets/styles/img/pcimg/guizhou/qd-img.png" alt="" />
      <div :class="['sginbtn', dataList.isSign == 1 ? 'issgin' : ``]" @click="tosgin"></div>
      <div class="datacontentbox">
        <div class="date-headers">
          <div class="date-header">
            <div class="prevmouth">
              <i class="el-icon-arrow-left" @click="handlePrev"></i>
            </div>
            <div class="middle">{{ month }}月 {{ year }}年</div>
            <div class="nextmouth">
              <i class="el-icon-arrow-right" @click="handleNext"></i>
            </div>
            <div></div>
          </div>
        </div>
        <div class="date-content">
          <div class="week-header">
            <div v-for="item in ['日', '一', '二', '三', '四', '五', '六']" :key="item">
              {{ item }}
            </div>
          </div>
          <div class="week-day">
            <div :class="['every-day']" v-for="item in 42" :key="item">
              <div
                ref="nowmouth"
                v-if="item - beginDay > 0 && item - beginDay <= curDays"
                :class="[`${year}-${month}-${item - beginDay}` === `${year}-${month}-${day}` ? 'nowDay' : '', getqd(year, month, item)]"
              >
                {{ item - beginDay }}
              </div>
              <div class="other-day" v-else-if="item - beginDay <= 0" ref="prevDays">
                {{ item - beginDay + prevDays }}
              </div>
              <div class="other-day" v-else>
                {{ item - beginDay - curDays }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcXlySgin",
  ctrlCaption: "贵州训练营签到",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      isqd: true,
      dataList: [],
      year: null,
      month: null,
      day: null,

      // currentDay: null,
      currentYearMonthTimes: null, //当前年的月的天数
      // monthOneDay: null, //一个月中的某一天
      curDate: null, //当天的日期
      prevDays: null, //上一月天数
      issgin: false,
      qdyq: [],
      qdwq: [],
      prevDays: 0,
      datemax: 1,
    };
  },
  computed: {
    curDays() {
      return new Date(this.year, this.month, 0).getDate();
    },
    // 设置该月日期起始值（找到一号是在哪里）
    beginDay() {
      return new Date(this.year, this.month - 1, 1).getDay();
    },
  },
  created() {
    this.$nextTick(() => {
      this.prevDays = this.$refs.prevDays.length;
      // console.log(this.$refs.prevDays, "prevDays");
    });
    this.getSginDate();
    this.getInitDate();
    this.currentYearMonthTimes = this.mGetDate(this.year, this.month); //本月天数
    this.prevDays = this.mGetDate(this.year, this.month - 1);
    this.curDate = `${this.year}-${this.month}-${this.day}`;
    console.log(this.curDate);
  },
  methods: {
    getqd(y, m, d) {
      let that = this;
      var time = `${y}-${m >= 10 ? "" : "0"}${m}-${d - that.prevDays >= 10 ? "" : "0"}${d - that.prevDays}`;
      console.log(that.qdwq, "that.qdwq");
      if (that.qdyq.indexOf(time) > -1) {
        return "yd-day";
      } else if (that.qdwq.indexOf(time) > -1) {
        return "wq-day";
      } else {
        return "";
      }
    },

    computedDay() {
      const allDay = new Date(this.year, this.month, 0).getDate();
      if (this.day > allDay) {
        this.day = allDay;
      }
    },
    //设置页头显示的年月日
    getInitDate() {
      const date = new Date();
      this.year = date.getFullYear();
      this.month = date.getUTCMonth() + 1;
      this.day = date.getDate();
    },
    // 如果要获取当前月份天数:
    mGetDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    handlePrev() {
      if (this.month == 1) {
        this.month = 12;
        this.year--;
      } else {
        this.month--;
      }
      this.computedDay();
    },
    handleNext() {
      if (this.month == 12) {
        this.month = 1;
        this.year++;
      } else {
        this.month++;
      }
      this.computedDay();
    },
    tosgin() {
      let that = this;
      const mainUrl = "/nc/class/train/sign";
      const params = { classId: location.href.split("=")[1] };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.getSginDate();
          } else {
            console.log("获取列表数据异常");
            dsf.layer.message(res.message, false);
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    getSginDate() {
      let that = this;
      const mainUrl = "/nc/class/train/querySignData";
      const params = {
        classId: location.href.split("=")[1],
        year: that.year,
        month: that.month,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            // that.issgin = true;
            that.dataList = res.data;
            that.qdyq = res.data.signInDate;
            that.qdwq = res.data.nonSignInDate;
          } else {
            // that.message({
            //   message: res.message || "获取列表数据异常",
            //   type: "warning",
            // });
            dsf.layer.message(res.message);
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
