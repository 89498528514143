<template>
  <div class="dsf-nc-college-xc-footer">
    <div class="dsf-nc-xc-footer-inner">
      <div class="footer">
        <div class="infoot-desc">
          <ul>
            <li>
              <p>常用链接</p>
              <span>中共中央党校（国家行政学院）</span>
              <span>中国共产党员网</span>
              <span>中国干部网络学院</span>
            </li>
            <li>
              <p>常用链接</p>
              <span>中共中央党校（国家行政学院）</span>
              <span>中国共产党员网</span>
              <span>中国干部网络学院</span>
            </li>
            <li>
              <p>常用链接</p>
              <span>中共中央党校（国家行政学院）</span>
              <span>中国共产党员网</span>
              <span>中国干部网络学院</span>
            </li>
          </ul>
          <div class="wx-code-wrap">
            <i class="wx-qrcode"></i>
            <span>微信公众号</span>
          </div>
        </div>
        <div class="infoot-desc-bottom">京ICP备05009132号 中华人民共和国生态环境部版权所有</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfCollegeAliFooter",
  props: {
    mainWidth: {
      type: [Number, String],
      default: "100%",
    },
  },
  data() {
    return {
      items: [
        {
          icon: "",
          name: "阿里研究院版权所有",
          total: null,
        },
        {
          icon: "fasong2",
          name: "网站访问量",
          total: 286545,
        },
        {
          icon: "nav-add-qz",
          name: "注册人数",
          total: 286545,
        },
        {
          icon: "nav-qz",
          name: "今日访问量",
          total: 286545,
        },
        {
          icon: "renjijiaohu",
          name: "今日PC累计登录人数",
          total: 286545,
        },
        {
          icon: "shouji",
          name: "今日移动端登录人数",
          total: 286545,
        },

        {
          icon: "",
          name: "中国干部网络学院版权所有 京ICP备  17328572  京公网安备123145631356",
          total: null,
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
