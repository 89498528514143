<template>
  <div class="dsf_nc_xlymypost">
    <div class="breadbarlist">
      <div class="breadbar">
        <div class="home" @click="changeindex">
          <i class="iconfont icon-home"></i> 论坛首页 >
        </div>
        <span class="listtab">我的帖子</span>
      </div>
      <div class="postbtn" @click="postbtn">发布帖子</div>
    </div>
    <div class="hotananew">
      <div class="left">
        <div :class="['all', allactive ? 'active' : '']" @click="getall">
          已发布
        </div>
        <div :class="['off', allactive ? '' : 'active']" @click="getclose">
          已关闭
        </div>
      </div>
    </div>
    <!-- 全部 -->
    <div class="datalist" v-show="allactive">
      <div class="dataitem" v-for="(item, index) in dataList" :key="index">
        <div class="titledom">
          <span class="title">{{ item.title }}</span>
          <span class="close" @click="updatabtn(item, index)" ref="close"
            >关闭帖子</span
          >
          <!-- <span class="detail" v-show="!isupdatabtn">修改</span> -->
          <span class="detail" @click="godetail(item)">详情</span>
          <span class="detail" @click="detelebtn(item)">删除</span>
        </div>
        <div class="all_content" v-html="item.content"></div>
        <img class="all_content_img" :src="setImg(item.cover)" />
        <div class="all_content_info">
          <div class="all_item_person">
            <i class="iconfont"></i>{{ item["ds_create_user_name"] }}
            <span class="all_item_date">{{ item["ds_create_time"] }}</span>
          </div>
          <div class="all_item_do_block">
            <div class="all_block_item">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["collect_num"] || 0 }}</span>
            </div>
            <div class="all_block_item m30">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["view_num"] || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="dataList">
        <div class="nodatas" v-if="dataList.length == 0">暂无数据</div>
      </template>
      <el-pagination
        v-if="isPafination"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        class="all_el-pagination"
      >
      </el-pagination>
    </div>
    <!-- 已关闭 -->
    <div class="datalist" v-show="!allactive">
      <div class="dataitem" v-for="(item, index) in dataList" :key="index">
        <div class="titledom">
          <span class="title">{{ item.title }}</span>
          <!-- <span class="close">关闭帖子</span> -->
          <span class="detail" @click="dialogbtn(item)">修改</span>
          <span class="detail" @click="godetail(item)">详情</span>
          <span class="detail" @click="detelebtn(item)">删除</span>
        </div>
        <div class="all_content" v-html="item.content"></div>
        <img class="all_content_img" :src="setImg(item.cover)" />
        <div class="all_content_info">
          <div class="all_item_person">
            <i class="iconfont"></i>{{ item["ds_create_user_name"] }}
            <span class="all_item_date">{{ item["ds_create_time"] }}</span>
          </div>
          <div class="all_item_do_block">
            <div class="all_block_item">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["collect_num"] || 0 }}</span>
            </div>
            <div class="all_block_item m30">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["view_num"] || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <p class="nodatas" v-if="dataList.list">暂无数据</p>
      <el-pagination
        v-if="isPafination"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        class="all_el-pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isactive: true,
      allactive: true,
      dataList: [],
      currentPage: 1,
      isPafination: true,
      total: 0,
      pageNum: 1,
      pageSize: 5,
      isupdatabtn: true,
      type: 1,
    };
  },
  props: {
    tabIndex: {
      type: Number,
      default: "",
    },
  },
  created() {},
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 1) {
          this.getinitdata(this.pageNum, this.pageSize, this.type);
        }
      },
    },
  },
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    changeindex() {
      this.$emit("changeindex");
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getinitdata(this.pageNum, this.pageSize, this.type);
    },
    dialogbtn(item) {
      console.log(item, "id");
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?id=${item._id}`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  const mainUrl = "/nc/class/train/community/edit";
                  // let namespace = "nc.class.train.community";
                  // let pageName = "edit";
                  // let classId = location.hash.split("=")[1];
                  let datas = {
                    title: data["nc_class_train_community.title"],
                    img: data["nc_class_train_community.img"],
                    content: data["nc_class_train_community.content"],
                  };
                  // data = JSON.stringify(datas);
                  // console.log(data, "dadatda");
                  const params = {
                    ...datas,
                    communityId: item._id,
                  };
                  that.dsf.http
                    .post(mainUrl, params)
                    .done(function (res) {
                      if (res.success) {
                        dsf.layer.message("发布成功");
                        that.getall();
                      } else {
                        that.message({
                          message: res.message || "获取列表数据异常",
                          type: "warning",
                        });
                      }
                    })
                    .error(function (error) {
                      // that.dsf.layer.toast("服务器异常", false);
                    })
                    .always(function () {
                      // that.dsf.layer.closeLoading(loader);
                    });
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    godetail(item) {
      console.log(item, "我的帖子");
      this.tabIndex = 6;
      eventBus.$emit("tabIndex6", item);
    },
    // 发布帖子按钮
    postbtn() {
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?class_value=${
            location.hash.split("=")[1]
          }`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  that.postrequest(data);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 发布请求
    postrequest(data) {
      let that = this;
      const mainUrl = "/inc/meta/persistData";
      let namespace = "nc.class.train.community";
      let pageName = "edit";
      let classId = this.$route.query.id;
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        classId,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("发布成功");
            that.getall();
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 初始化页面数据
    getinitdata(pageNum, pageSize, type) {
      // type 0 all 1 close
      let that = this;
      const mainUrl = "/nc/class/train/community/listByUserId";
      const params = {
        pageNum,
        pageSize,
        classId: this.$route.query.id,
        type,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // console.log(res.data, "初始化页面数据");
            that.dataList = res.data;
            that.total = res.count;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取全部
    getall() {
      this.allactive = true;
      this.getinitdata(this.pageNum, this.pageSize, 1);
      this.type = 1;
    },
    // 获取关闭
    getclose() {
      this.allactive = false;
      this.getinitdata(this.pageNum, this.pageSize, 0);
      this.type = 0;
    },
    // 关闭状态
    updatabtn(item) {
      let that = this;
      const mainUrl = "/nc/class/train/community/closeRecord";
      const params = {
        communityId: item["nc_class_train_community_id"],
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("关闭成功");
            that.getinitdata(that.pageNum, that.pageSize, that.type);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 删除
    detelebtn(item) {
      let that = this;
      dsf.layer
        .confirm("确认要删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const mainUrl = "/nc/class/train/community/deleteRecord";
          const params = {
            communityId: item["nc_class_train_community_id"],
          };
          that.dsf.http
            .post(mainUrl, params)
            .done(function (res) {
              if (res.success) {
                that.getinitdata(that.pageNum, that.pageSize, that.type);
                dsf.layer.message("删除成功");
              } else {
                that.message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              // that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              // that.dsf.layer.closeLoading(loader);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
