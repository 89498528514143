<template>
  <div class="dsf_nc_specialdetail" :style="{ display: visible ? 'block' : 'none' }">
    <div class="special_detail_innerwrap" :style="{ width: itemWidth }">
      <div class="detail_imgbox cover">
        <!-- <dsf-image :src="detailData.cover | imgFormat" width="530px" height="280px" /> -->
        <dsf-nc-image :src="detailData.cover | imgFormat" width="530px" height="280px" :error-src="defaultSrc" />
      </div>
      <div class="detail_conent">
        <div class="detail_name ell">{{ detailData.name }}</div>
        <div class="bm_date font">报名日期：{{ setDate(detailData.bmsjSdate, detailData.bmsjEdate, "至") }}</div>
        <div class="px_date font">培训日期：{{ setDate(detailData.pxsjSdate, detailData.pxsjEdate, "至") }}</div>
        <div class="jytj font">结业条件：选修 {{ detailData.usercompulsoryGate || 0 }} 门 &nbsp;&nbsp;&nbsp; 必修 {{ detailData.compulsory || 0 }} 门</div>
        <div class="unit font" v-if="showDw">
          <span class="sponsor">主办单位：{{ detailData.zbdw || "暂无" }}</span>
          &nbsp;&nbsp;&nbsp;
          <span class="undertake">承办单位：{{ detailData.cbdw || "暂无" }}</span>
        </div>
        <div class="student font">学员人数：{{ detailData.studentCount }}</div>
        <div class="foot_wrap">
          <ul class="labe_wrap">
            <li class="labe_item" v-for="(item, index) in labelLists" :key="index">
              {{ item }}
            </li>
          </ul>
          <div class="btn_box">
            <!--  -->
            <div
              :class="['btn', isDuringDate(detailData.bmsjSdate, detailData.bmsjEdate) ? '' : 'gray']"
              v-if="
                (detailData.state == -1 && (isstudent == 2 || isstudent == 3)) ||
                (detailData.state == 3 && isstudent == 0) ||
                (detailData.state == 3 && isstudent == 2) ||
                (detailData.bm == 1 && showBtn && detailData.state != 1 && detailData.state != 2 && isstudent == 0) ||
                (detailData.bm == 1 && showBtn && detailData.state != 1 && detailData.state != 2 && isstudent == 2) ||
                (detailData.bm == 1 && showBtn && detailData.state != 1 && detailData.state != 2 && isstudent == 3)
              "
              @click="!isview && detailData.state != 3 && clickEnter(detailData)"
            >
              {{ setStatus(detailData) }}
            </div>
            <div class="reason" v-if="detailData.state == 5">
              {{ detailData.reason }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default dsf.component({
  name: "DsfNcGzSpecialDetail",
  ctrlCaption: "贵州专题详情头部",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/train/info",
    },
    enterStudyUrl: {
      type: Object,
      default: () => ({
        url: `page.html#/pc/nc/pagecourse/coursePlayer?id=@[lastCourseId]&type=ztb`,
      }),
    },
    visible: {
      type: Boolean,
      default: true,
    },
    showDw: {
      type: Boolean,
      default: true,
    },
    isshowbtn: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "1200px",
    },
  },
  data() {
    return {
      showBtn: false,
      detailData: {},
      params: {
        id: "",
      },
      personalInfoUrl: "/nc/class/apply/info",
      labelLists: [],
      defaultSrc: dsf.config.setting_nc_image_default_img,
      clickApplyBtn: false,
      // isShowPersonInforForm: false,
      isview: false,
    };
  },
  created() {
    // console.log(this.isDuringDate("2023-03-05 05:50:50","2023-03-05 05:50:50"));
    // debugger
  },
  async mounted() {
    if (!this.isDesign) {
      this.params.id = this.queryString.id || "b9b594f762d241f49189236b0d841c4f";
      this.isview = this.queryString.isview == 1 ? true : false;

      await this.getState(this.params); /* 获取专题班信息 */
      this.detailData = this.getSpecialInfoGz;
      // this.detailData.state = -1;
      this.labelLists = this.detailData.labelsText ? this.detailData.labelsText.split("^") : [];

      await this.getIsStudent(); /* 获取当前学员状态 */
      if (this.isstudent == 1 && this.coursestate == 0) this.specialApplygz(this.params); /* 如果为班主任则自动报名 */
      // this.detailData = this.getSpecialInfo;

      this.$nextTick(() => {
        this.$page.resize();
      });
    }
    setTimeout(() => {
      if (this.detailData.state != 1 || this.detailData.state != 2) this.showBtn = true;
    }, 0);
    // // 默认是false
    // await setTimeout(() => {
    // }, 0);
  },
  computed: {
    ...mapState(["isstudent", "coursestate", "specialInfo"]),
    ...mapGetters(["getSpecialInfo", "getSpecialApplyState", "getSpecialApplyInfor", "getSpecialInfoGz", "getApplyStateGz"]),
  },
  watch: {
    getSpecialInfo: {
      handler(data) {
        if (data) {
          // this.detailData.state = -1;
          this.detailData = data;
        }
      },
      immediate: true,
    },
    getSpecialApplyState: {
      handler(data) {
        if (data && !this.isDesign) {
          debugger;
          this.specialInfo(this.params);
        }
      },
      immediate: true,
    },
    getSpecialApplyInfor: {
      async handler(data) {
        if (data && this.detailData.state == 0 && this.detailData.signStatus == 1 && this.clickApplyBtn) {
          // 未报名且点击报名表单后处理
          if (dsf.isEmptyObject(data.items) || data.items != "[]") {
            this.$openDialog({
              title: "填写个人信息",
              width: "520px",
              height: "auto",
              content: "DsfNcPersonalInformationForm",
              params: {
                cb: async data => {
                  if (data) {
                    debugger;
                    await this.specialApplygz({ ...this.params, data });
                    this.$emit("apply");
                  }
                },
              },
              dialogArgs: {},
            });
          } else {
            debugger;
            await this.specialApplygz(this.params);
            this.$emit("apply");
          }
        }
      },
      immediate: true,
    },
    isstudent: {
      handler(n, o) {
        console.log(n);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["getState", "specialInfo", "specialApplygz", "specialApplyInfo", "getIsStudent"]),
    async reData() {
      debugger;
      await this.getState(this.params);
      this.detailData = this.getSpecialInfoGz;
      this.labelLists = this.detailData.labelsText ? this.detailData.labelsText.split("^") : [];
    },
    async clickEnter(item) {
      if (!this.isDuringDate(this.detailData.bmsjSdate, this.detailData.bmsjEdate) && this.isstudent == 0 && this.detailData.state == 0) {
        return dsf.layer.message("当前已过报名时间", false);
      }
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      let status = item.state; // 状态
      let signStatus = item.signStatus; // 1 能报名

      if (status == 0 && signStatus == 1) {
        this.clickApplyBtn = true; // 申请按钮
        /*     this.specialApplyInfo(this.params);
        return; */
        await this.specialApplygz({ id: this.params.id });
        this.isshowbtn = false;
        // setTimeout(() => {
        this.$emit("apply");
        // }, 1000);
      }
    },
    toExamDetail(item) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: item["lastCourseId"], //考试id
        businessId: item["lastResourceSubId"], //业务id
        businessType: 1,
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setDate(startDate, endDate, interval) {
      // 设置日期格式
      let start = startDate ? startDate.slice(0, 10) : "";
      let end = endDate ? endDate.slice(0, 10) : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },
    setStatus(data) {
      // 设置状态
      let status = data.state; // 状态
      let studentCount = data.studentCount; // 学员人数
      let sfxzrs = data.txrs; //是否有人数限制
      let html = "";
      switch (status) {
        case -1:
          html = "未报名,请联系省/市州级网院管理员";
          break;
        case 0:
          if (sfxzrs && studentCount >= sfxzrs) {
            html = "人数已满";
            return;
          }
          html = "报名";
          break;
        case 1:
          html = "待审核";
          break;
        case 2:
          html = "开始学习";
          break;
        case 3:
          html = "已结业";
          break;
        case 4:
          html = "审核中";
          break;
        case 5:
          html = "已拒绝";
          break;
        default:
          html = "";
      }
      return html;
    },
    //
    isDuringDate(beginDateStr, endDateStr) {
      var curDate = new Date().getTime(),
        beginDate = new Date(beginDateStr).getTime(),
        endDate = new Date(endDateStr).getTime();
      console.log(beginDate, curDate, endDate);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }

      return false;
    },
  },
});
</script>
