<template>
  <div class="dsf_nc_xlydata">
    <div class="com-title-inner">
      <span class="com-title">资料</span>
    </div>
    <div class="tablelist">
      <div class="rowheader">
        <div class="name">资料名称</div>
        <div class="upder">上传人</div>
        <div class="uptime">上传时间</div>
        <div class="dom">操作</div>
      </div>
      <div class="rows">
        <div class="row" v-for="(item, index) in tableData" :key="index">
          <div class="name">
            <img class="workicon" src="../../../assets/styles/img/pcimg/guizhou/icon-word.png" alt="" />
            <span>{{ item.title }}</span>
          </div>
          <div class="upder">{{ item.publisher_text }}</div>
          <div class="uptime">{{ initDate(item.publish_time) }}</div>
          <div class="dom">
            <div class="look" @click="goview(item)">
              <img src="../../../assets/styles/img/pcimg/guizhou/xxzx-zl-ck.png" alt="" />
              <span style="margin-left: 5px">查看</span>
            </div>
            <div class="down" @click="godetail(item)" v-if="item['download_free_value']">
              <img src="../../../assets/styles/img/pcimg/guizhou/xxzx-zl-down.png" alt="" />
              <span style="margin-left: 5px">下载</span>
            </div>
            <!-- <el-button type="text" size="small" class="icon">
              <img
                src="../../../assets/styles/img/pcimg/guizhou/xxzx-zl-ck.png"
                alt=""
              /><span style="margin-left: 8px">查看</span>
            </el-button>
            <el-button type="text" size="small" class="icon"
              ><img
                style="margin-top: 5px"
                src="../../../assets/styles/img/pcimg/guizhou/xxzx-zl-down.png"
                alt=""
              />
              <span style="margin-left: 8px">下载</span></el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcXlyData",
  ctrlCaption: "贵州训练营资料",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      //   dataList: [],
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    initDate(time) {
      return time.split(" ")[0];
    },
    godetail(item) {
      // 调用下载接口
      let that = this;
      const mainUrl = "inc/nc/class/teaching/file/download";
      const params = {
        fileId: item["nc_class_file_id"],
        classId: item["class_id"],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });

      // 前端下载
      let id = JSON.parse(item.file)[0].relativePath.split("/")[4];
      var url = dsf.url.getWebPath("file/download");
      $(
        '<form action="' +
          url +
          '" method="get">' + // action请求路径及推送方法
          '<input type="text" name="files" value="' +
          id +
          '"/>' + // id^fileName
          '<input type="text" name="zipName" value="' +
          (JSON.parse(item.file)[0].originalFileName || "") +
          '"/>' + // 压缩包名称
          "</form>"
      )
        .appendTo("body")
        .submit()
        .remove();
    },
    goview(item) {
      window.open(`${dsf.url.getWebPath("/preview/file")}?url=${dsf.config.setting_public_file_host}${JSON.parse(item.file)[0].relativePath}`, "_blank");
    },
    handleClick(row) {
      console.log(row);
    },
    getData() {
      let that = this;
      const mainUrl = "/nc/class/file/getClassFileList";
      const params = { classId: location.href.split("=")[1] };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.tableData = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
