<template>
  <div class="dsf_nc_xlymycollection">
    <div class="breadbarlist">
      <div class="breadbar">
        <div class="home" @click="changeindex">
          <i class="iconfont icon-home"></i> 论坛首页 >
        </div>
        <span class="listtab">我的收藏</span>
      </div>
      <div class="postbtn" @click="postbtn">发布帖子</div>
    </div>
    <div class="datalist">
      <div
        class="dataitem"
        v-for="(item, index) in clooectInfo"
        :key="index"
        @click="godetail(item)"
      >
        <div class="titledom">
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="all_content" v-html="item.content"></div>
        <div class="all_content_info">
          <div class="all_item_person">
            <i class="iconfont"></i>{{ item["ds_create_user_name"] }}
            <span class="all_item_date">{{ item["ds_create_time"] }}</span>
          </div>
          <div class="all_item_do_block">
            <div class="all_block_item" style="color: #c73939">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["collect_num"] }}</span>
            </div>
            <div class="all_block_item m30">
              <i class="iconfont"></i
              ><span class="all_item_num">{{ item["view_num"] }}</span>
            </div>
            <div class="all_block_item">
              <i class="icon iconfont"></i
              ><span class="all_item_num">{{ item["reply_num"] }}</span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="clooectInfo">
        <el-pagination
          v-if="clooectInfo.length"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          class="all_el-pagination"
        >
        </el-pagination>
        <div class="nodatas" v-if="!clooectInfo.length">暂无收藏</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      clooectInfo: null,
      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 3) {
          console.log("收藏");
          this.getCollectInfo(this.pageNum, this.pageSize);
        }
      },
    },
  },
  created() {},
  methods: {
    // 发布帖子按钮
    postbtn() {
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?class_value=${
            location.hash.split("=")[1]
          }`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  that.postrequest(data);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 发布请求
    postrequest(data) {
      let that = this;
      const mainUrl = "/inc/meta/persistData";
      let namespace = "nc.class.train.community";
      let pageName = "edit";
      let classId = this.$route.query.id;
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        classId,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("发布成功");
            that.getinindata(that.allpageNum, that.allpageSize, "", that.type);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getCollectInfo(this.pageNum, this.pageSize);
    },
    changeindex() {
      this.$emit("changeindex");
    },
    // 收藏信息
    getCollectInfo(pageNum, pageSize) {
      let that = this;
      const mainUrl = "/nc/class/train/community/collectList";
      let classId = this.$route.query.id;
      const params = {
        pageNum,
        pageSize,
        classId,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.clooectInfo = res.data;
            that.total = res.count;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    godetail(item) {
      this.tabIndex = 6;
      console.log(item, "item");
      eventBus.$emit("tabIndex6", item);
    },
  },
};
</script>

<style></style>
