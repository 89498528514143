<template>
  <div class="dsf_nc_classroomsearch">
    <input
      ref="input"
      @input="changeinp"
      @keyup.enter="search"
      :value="val"
      type="text"
      placeholder="请输入您要查询的内容"
    />
    <div class="btnbox">
      <div class="search" @click="search">搜索</div>
      <div class="reset" @click="reset">重置</div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzClassRoomSearch",
  ctrlCaption: "贵州大讲堂搜索",
  mixins: [$mixins.card],
  props: {
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      val: "",
      list: [],
    };
  },
  created() {},
  mounted() {},
  components: {},
  methods: {
    changeinp() {
      this.$nextTick(() => {
        this.val = this.$refs.input.value;
        if (this.$refs.input.value.trim().length == 0) {
          this.reset();
        }
      });
    },
    search() {
      let that = this;
      const isserach = that.val.trim();
      if (isserach) {
        const mainUrl = "/nc/zbgl/list";
        const params = {
          pageNum: 1,
          pageSize: 8,
          query: JSON.stringify({
            searchValue: `${that.val.trim()}`,
            type: 2,
            video: location.hash == "#/pc/nc/pagelive/wonderfulLive" ? 1 : 0,
          }),
        };
        that.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if (res.success) {
              eventBus.$emit("search", res.data, isserach);
            } else {
              that.$message({
                message: res.message || "获取列表数据异常",
                type: "warning",
              });
            }
          })
          .error(function (error) {
            //  that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            //  that.dsf.layer.closeLoading(loader);
          });

        const mainUrl2 = "/nc/zbgl/list";
        const params2 = {
          pageNum: 1,
          pageSize: 4,
          query: JSON.stringify({
            searchValue: `${that.val.trim()}`,
            type: 1,
            video: location.hash == "#/pc/nc/pagelive/wonderfulLive" ? 1 : 0,
          }),
        };
        that.dsf.http
          .get(mainUrl2, params2)
          .done(function (res) {
            if (res.success) {
              eventBus.$emit("search2", res.data);
            } else {
              that.$message({
                message: res.message || "获取列表数据异常",
                type: "warning",
              });
            }
          })
          .error(function (error) {
            //  that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            //  that.dsf.layer.closeLoading(loader);
          });
      }
    },

    reset() {
      this.val = "";
      let that = this;
      const mainUrl = "/nc/zbgl/list";
      const searchValue = this.val.trim();
      // 近期直播搜索请求
      const params = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({
          searchValue,
          type: 1, //近期直播
          video: location.hash == "#/pc/nc/pagelive/wonderfulLive" ? 1 : 0,
        }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "近期直播resste  data");
            eventBus.$emit("reset2", res.data);
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });

      // 直播历史搜索请求
      const paramss = {
        pageNum: 1,
        pageSize: 8,
        query: JSON.stringify({
          searchValue,
          type: 2, //直播历史
          video: location.hash == "#/pc/nc/pagelive/wonderfulLive" ? 1 : 0,
        }),
      };
      that.dsf.http
        .get(mainUrl, paramss)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "直播历史");
            eventBus.$emit("reset", res.data);
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style lang="scss" scoped></style>
