<template>
  <div class="dsf_import_data">
    <template v-if="isDesign">
      导入学员信息
    </template>
    <!--   <div class="import_error" v-if="tableType == 1">
      <p class="import_error_tips">导入的账号有重复数据，是否只导入使用管理员单位下的学员</p>
      <el-radio-group v-model="radioRepeat">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="2">否</el-radio>
      </el-radio-group>
    </div> -->
    <template v-else>
      <div class="import_error" v-if="authOutCount > 0">
        <p class="import_error_tips">导入的数据有超过管理员权限外的数据{{authOutCount}}条，是否只导入管理员单位权限内的学员</p>
        <el-radio-group v-model="radioAuth">
          <el-radio label="1">是</el-radio>
          <el-radio label="2">否</el-radio>
        </el-radio-group>
      </div>
      <div class="import_tips">共{{importData.errorCount * 1 + importData.importCount}}条记录 正确数据{{importData.importCount}}条 错误数据{{importData.errorCount}}条 <span class="red">上传前请检查是否有空格</span></div>
      <el-table border class="import_table" height="530" :data="importData.errorList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column type="index" label="序号" width="100"></el-table-column>
        <!-- <el-table-column prop="dsfa_oua_user_id" label="ID">
          <template slot-scope="scope">
            <input class="input_edit" v-if="scope.$index === rowIndex" v-model="scope.row.dsfa_oua_user_id" type="text">
            <span v-else>{{ scope.row.dsfa_oua_user_id }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="姓名">
          <template slot-scope="scope">
            <input class="input_edit" v-if="scope.$index === rowIndex" v-model="scope.row.name" type="text">
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="编号">
          <template slot-scope="scope">
            <!-- <input class="input_edit" v-if="scope.$index === rowIndex" v-model="scope.row.code" type="text"> -->
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tree_name_path" label="所属单位">
          <template slot-scope="scope">
            <!-- <input class="input_edit" v-if="scope.$index === rowIndex" v-model="scope.row.tree_name_path" type="text"> -->
            <span>{{ scope.row.tree_name_path }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <i v-if="scope.$index === rowIndex" @click="rowIndex = ''" class="iconfont icon-baocun1"></i>
            <i v-else @click="rowIndex = scope.$index" class="iconfont icon-tianxie"></i> -->
            <i @click="handleDelete(scope.$index)" class="iconfont icon-shanchu"></i>
          </template>
        </el-table-column>
      </el-table>
    </template>

  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfNcImportData',
  ctrlCaption: '导入学员信息',
  mixins: [$mixins.control],
  props: {
    importData: Object
  } /* 导入异常数据 */,
  data() {
    return {
      radioAuth: '1' /* 默认选中权限内数据 */,
      authOutCount: 0 /* 权限外数据 */,
      rowIndex: '' /* 但前修改的某行 */
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.importData.errorCount > 0 &&
        this.importData.errorList.forEach((element) => {
          if (element.check_result == '-4') {
            this.authOutCount++;
          }
        });
    }
  },
  watch: {},
  methods: {
    handleDelete(index) {
      this.rowIndex = '';
      dsf.layer.pc
        .confirm('是否要删除这条数据？')
        .then(() => {
          this.importData.errorList.splice(index, 1);
        })
        .catch(() => {});
    },
    initData() {
      let loading = dsf.layer.pc.loading();
      let params = {
        pageSize: this.pagesize,
        pageNum: this.pagenum,
        keyword: this.keyword
      };
      dsf.http
        .get(this.url, params, $$webRoot.nc)
        .done((res) => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
            console.log(res.data, 'ncnewlearnspecial44');
          }
        })
        .error((err) => {
          this.error(err);
        })
        .always((res) => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    }
  }
});
</script>
