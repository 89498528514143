<template>
  <div class="dsf_nc_xlynotice">
    <div class="com-title-wrap" v-if="!isdetail">
      <div class="com-title-inner">
        <span class="com-title">公告</span>
      </div>
    </div>
    <div class="com-title-wrap" v-else>
      <div class="com-title-inner">
        <span class="com-title">公告详情</span>
        <div class="applybtn" @click="goback()">
          <i class="iconfont icon-zuo"></i>
          <span>返回</span>
        </div>
      </div>
    </div>
    <div class="tablelist" v-if="!isdetail">
      <div class="rowheader">
        <div class="name">标题</div>
        <div class="upder">状态</div>
        <div class="uptime">发布时间</div>
        <div class="dom">操作</div>
      </div>
      <div class="rows">
        <div
          class="row"
          v-for="(item, index) in dataList"
          :key="index"
          :style="{
            color: `${item.status ? '' : '#000'}`,
            fontWeight: `${item.status ? '400' : '700'}`,
          }"
        >
          <div class="name">
            <img class="workicon" src="../../../assets/styles/img/pcimg/guizhou/xxzx-gg-icon.png" alt="" />
            <span class="nametext">{{ item.objectname }}</span>
          </div>
          <div class="upder">{{ item.status ? "已读" : "未读" }}</div>
          <div class="uptime">{{ initDate(item["publisher_time"]) }}</div>
          <div class="dom">
            <div class="look" @click="godetail(item['dsfa_wf_process_link_id'])">
              <img src="../../../assets/styles/img/pcimg/guizhou/xxzx-zl-ck.png" alt="" />
              <span style="margin-left: 5px" @click="godetail(item['dsfa_wf_process_link_id'])">查看</span>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        style="margin-top: 100px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="newPageSize"
        layout="prev, pager, next ,total, jumper, sizes"
        :total="total"
      ></el-pagination>
    </div>
    <div class="tablelist" v-else>
      <p class="detailtime">{{ initDate() }}</p>
      <div class="content">{{ detailList.objectname }}</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default dsf.component({
  name: "DsfNcXlyNotice",
  ctrlCaption: "贵州训练营详情公告",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      detailList: [],
      currentPage4: 1,
      total: null,
      isdetail: false,
      newPageSize: 10,
      newPageNum: 1,
      detailTime: null,
    };
  },
  created() {
    this.getData();
    this.getTotal();
  },
  mounted() {},
  methods: {
    ...mapActions(["getunreadCount"]),
    goback() {
      this.isdetail = false;
      this.getData();
    },
    initDate(time) {
      let that = this;
      if (time) return time.split(" ")[0];
      if (that.detailTime) return that.detailTime.split(" ")[0];
    },
    handleSizeChange(val) {
      this.newPageSize = val;
      this.getData();
      // this.getData(this.newPageSize, this.newPageNum);
    },
    handleCurrentChange(val) {
      this.newPageNum = val;
      this.getData(this.newPageSize, this.newPageNum);
    },
   
    // 渲染列表
    getData() {
      let that = this;
      const mainUrl = "/nc/class/train/notice/getNoticeList";
      const params = {
        classId: location.href.split("=")[1],
        pageSize: that.newPageSize,
        pageNum: that.newPageNum,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
            // if (type) {
            that.getunreadCount();
            // }
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 进入详情+已读
    godetail(id) {
      this.isdetail = true;
      let that = this;
      that.godetaildata(id);
      const mainUrl = "/message/info/markRead";
      const params = { id };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取详情
    godetaildata(id) {
      this.isdetail = true;
      let that = this;
      const mainUrl = "/nc/class/train/notice/info";
      const params = { id };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.detailTime = res.data.publisher_time;
            that.detailList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取total
    getTotal() {
      let that = this;
      const mainUrl = "/nc/class/train/notice/getNoticeListCount";
      const params = {
        classId: location.href.split("=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data[0]["count(dwpl.dsfa_wf_process_link_id)"];
          } else {
            // that.message({
            //   message: res.message || "获取列表数据异常",
            //   type: "warning",
            // });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
