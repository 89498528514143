<template>
  <div
    class="ds-control ds-productBanner ds-no-padding"
    :id="id"
    :style="{
      'padding-top': `calc(100% - ${h})`,
    }"
  >
    <el-carousel
      ref="swipe"
      :arrow="showButton"
      :indicator-position="showIndicators"
      :direction="vertical ? 'vertical' : 'horizontal'"
      :autoplay="isAutoPlay"
      :interval="autoPlay"
      :trigger="indicatorTrigger"
      :type="type"
      :height="h"
      @change="
        index => {
          activeIndex = index;
        }
      "
    >
      <el-carousel-item v-for="(item, index) in images" :key="`ds-banner-item-${index}`" :label="indicatorNumber ? index + 1 : ''">
        <div :class="['ds-banner-item', `${index}`]" @click="onSwipeClick(item, index)">
          <slot v-bind="{ row: item, $index: index }">
            <!-- <dsf-image class="el-carousel-item-image" :src="item.image" :style="{ 'border-radius': borderRadius }" :error-src="errorImgSrc" /> -->
            <!--  -->
            <DsfNcDefaultHeader class="el-carousel-item-image" :src="item.imgUrl" :style="{ 'border-radius': borderRadius }" :round="false" :defaultHeaderUrl="noImgUrl"></DsfNcDefaultHeader>
            <slot name="desc" v-bind="{ row: item, $index: index }"></slot>
          </slot>
        </div>
        <template v-if="showTitle">
          <p class="ds-banner-item-title ell" :style="getTitleStyle">
            {{ item.title }}
          </p>
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzBanner",
  ctrlCaption: "产品轮播图",
  mixins: [$mixins.control],
  props: {
    ratio: {
      type: String,
      default: "200px",
    },
    showButton: {
      //切换箭头的显示时机 always/hover/never
      type: String,
      default: "hover",
    },
    showIndicators: {
      //指示器的位置  inside/none/outside
      type: String,
      default: "inside",
    },
    vertical: {
      //垂直滚动
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      //指示灯颜色 为空默认使用皮肤辅色
      type: String,
      default: "#fff",
    },
    //为空默认为高亮颜色的透明度0.48
    indicatorDefaultColor: {
      type: String,
      default: "",
    },
    indicatorWidth: {
      type: Number,
      default: 30,
    },
    indicatorHeight: {
      type: Number,
      default: 2,
    },
    indicatorRadius: {
      type: [String, Number],
      default: "0",
    },
    //选中指示器宽度
    indicatorActiveWidth: {
      type: String,
      default: "",
    },
    //选中指示器圆角
    indicatorActiveRadius: {
      type: String,
      default: "0",
    },
    indicatorNumber: {
      type: Boolean,
      default: false,
    },
    indicatorMarginBottom: {
      type: String,
      default: "0",
    },
    autoPlay: {
      type: [String, Number],
      default: 3000,
    },
    isAutoPlay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: [String, Number],
      default: "0px",
    },
    indicatorTrigger: {
      type: String,
      default: "hover",
    },
    type: {
      type: String,
      default: "normal",
    },
    titlePosition: {
      type: String,
      default: "bottom",
    },
    list: {
      type: Array,
      default() {
        return [
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
          { image: dsf.config.setting_nc_image_default_img },
        ];
      },
    },
    url: {
      type: String,
      default: "",
    },
    toUrl: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 0,
    },
    // 配置字段名
    imageKeyName: {
      type: String,
      default: "image",
    },
    titleKeyName: {
      type: String,
      default: "title",
    },
    showTitle: {
      type: Boolean,
      default: false,
      desc: "是否显示标题",
    },
    titleWidth: {
      type: String,
      default: "100%",
      desc: "标题宽度",
    },
    titleHeight: {
      type: String,
      default: "40px",
      desc: "标题高度",
    },
    titleFontSize: {
      type: String,
      default: "16px",
      desc: "标题字体大小",
    },
    titleLineHeight: {
      type: String,
      default: "40px",
      desc: "标题行高",
    },
    titleMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    titlePadding: {
      type: Array,
      default() {
        return [0, 0, 0, 20];
      },
    },
    titleColor: {
      type: String,
      default: "",
    },
    titleBgColor: {
      type: String,
      default: "",
    },
    descKeyName: {
      type: String,
      default: "desc",
    },
    jumpWayKeyName: {
      type: String,
      default: "jumpWay",
    },
    // 图片加载失败的替代图片
    errorImgSrc: {
      type: String,
      default: "",
    },
  },
  computed: {
    rootHeight() {
      let ratio = this.ratio;
      if (/^\d+\/\d+$/.test(ratio)) {
        ratio = ratio.split("/");
        ratio = (100 * ratio[1]) / ratio[0] + "%";
      }
      return ratio;
    },
    images() {
      let data = this.data;
      let max = this.max;
      if (max) {
        let $data = (data || []).slice(0, max);
        if (!$data.length) {
          $data = [{ image: this.noImgUrl }];
        }
        return $data;
      } else {
        return data.length ? data : [{ image: this.noImgUrl }];
      }
    },
    getTitleStyle() {
      let style = {};
      switch (this.titlePosition) {
        case "bottom":
          style = { bottom: 0, left: 0 };
          break;
        case "top":
          style = { top: 0, left: 0 };
          break;
        case "left":
          style = { left: 0, top: 0 };
          break;
        case "right":
          style = { right: 0, top: 0 };
          break;
      }
      if (this.showTitle) {
        style.width = this.titleWidth;
        style.height = this.titleHeight;
        style.fontSize = this.titleFontSize;
        style.lineHeight = this.titleLineHeight;
        style.margin = this.titleMargin.join("px ") + "px";
        style.padding = this.titlePadding.join("px ") + "px";
        style.color = this.titleColor;
        style.background = this.titleBgColor;
        if (this.titlePosition == "left" || this.titlePosition == "right") {
          style.writingMode = "tb-rl";
        }
      }
      return style;
    },
  },
  data() {
    return {
      id: "dsfBanner" + dsf.uuid(16),
      data: [],
      activeIndex: 0,
      h: "100%",
      titleStyle: {},
      noImgUrl: "",
    };
  },
  watch: {
    indicatorWidth() {
      this.updateStyleDom();
    },
    showIndicators() {
      this.setH();
    },
    titlePosition() {
      this.updateStyleDom();
    },
    indicatorHeight() {
      this.setH();
      this.updateStyleDom();
    },
    indicatorColor() {
      this.updateStyleDom();
    },
    indicatorDefaultColor() {
      this.updateStyleDom();
    },
    indicatorRadius() {
      this.updateStyleDom();
    },
    indicatorActiveWidth() {
      this.updateStyleDom();
    },
    indicatorActiveRadius() {
      this.updateStyleDom();
    },
    indicatorMarginBottom() {
      this.updateStyleDom();
    },
    list(v, ov) {
      if (!this.url) {
        this.data = dsf.mix(true, [], v);
      }
    },
  },
  created() {
    if (this.isDesign) {
      let data = [];
      if (!this.list.length) {
        data = [{ image: "" }, { image: "" }];
      } else {
        data = this.list;
      }
      this.data = dsf.mix(true, [], data);
    } else {
      if (!this.url) {
        this.data = dsf.mix(true, [], this.list);
      } else {
        this.loadData();
      }
    }
    // if (!this.errorImgSrc) {
    //   this.errorImgSrc = dsf.config.setting_nc_image_default_img || "";
    // }

    this.setH();
    let styleDom = document.createElement("style");
    document.body.append(styleDom);
    this.styleDom = styleDom;
    this.updateStyleDom();
    // this.noImageSrc ||
    // this.noImgUrl = dsf.config.setting_nc_image_banner_img || dsf.config.setting_nc_image_default_img || "";
    this.noImgUrl = dsf.config.setting_nc_image_banner_img || dsf.config.setting_nc_image_default_img || "1";
  },
  destroyed() {
    this.styleDom?.remove();
  },
  methods: {
    // 获取专栏信息
    getClassInfo(id) {
      let that = this;
      const mainUrl = "/nc/pack/channel/info";
      const params = {
        id,
      };
      return new Promise(reslove => {
        that.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            reslove(res);
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            // that.dsf.layer.closeLoading(loader);
          });
      });
    },
    setH() {
      if (this.showIndicators == "outside" || this.type == "card") {
        this.h = `calc(100% - 24px - ${this.indicatorHeight}px)`;
      } else {
        this.h = "100%";
      }
    },

    updateStyleDom() {
      if (this.styleDom) {
        let bgColor = this.indicatorColor;
        let defaultColor = this.indicatorDefaultColor;
        //选中指示器宽度
        let indicatorActiveWidth = this.indicatorActiveWidth && this.indicatorActiveWidth != "0" ? this.indicatorActiveWidth : this.indicatorWidth;
        //选中指示器圆角
        let indicatorActiveRadius = this.indicatorActiveRadius && this.indicatorActiveRadius != "0" ? this.indicatorActiveRadius : this.indicatorRadius;

        let opacity = 1;
        if (bgColor === "theme") {
          bgColor = this.$root.themesConfig[this.$root.setting.theme].normal;
        }
        if (!defaultColor) {
          opacity = 0.48;
          defaultColor = bgColor;
        }

        this.styleDom.innerHTML = `
          #${this.id} .el-carousel__button{
            height: ${this.indicatorHeight}px;
            width: ${this.indicatorWidth}px;
            border-radius: ${this.indicatorRadius};
            background-color: ${defaultColor};
            opacity: ${opacity};
          }
          #${this.id} .el-carousel__indicator.is-active button{
            background-color: ${bgColor};
            opacity: 1;
            width:${indicatorActiveWidth}px;
            border-radius: ${indicatorActiveRadius};
            color: #fff;
          }
        `;
        this.styleDom.innerHTML += `
          #${this.id} .el-carousel__indicators{
            margin-bottom: ${this.indicatorMarginBottom}px
          }
        `;
        if (this.titlePosition === "bottom" && this.showTitle) {
          this.styleDom.innerHTML += `
              #${this.id} .el-carousel__indicators{
                left:initial;
                right:0;
                transform: translateX(-20%)
              }
            `;
        }
      }
    },
    formatApiData(data) {
      if (!Array.isArray(data)) {
        return [];
      }
      return data.map(it => {
        return {
          url: "",
          ...it,
          image: dsf.getFilePath(it[this.imageKeyName]),
          title: it[this.titleKeyName],
          desc: it[this.descKeyName],
          jumpWay: it[this.jumpWayKeyName],
        };
      });
    },
    loadData() {
      let url = this.$replace(this.url, this);
      this.$http.get(url).then(({ data }) => {
        this.data = this.formatApiData(data.data);
      });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      try {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
      } catch (err) {
        console.log(err);
      }
    },
    getChannelState(params) {
      const mainUrl = "/nc/pack/getState";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
    async onSwipeClick(item, index) {
      if (this.type === "normal" || index == this.activeIndex) {
        if (this.$listeners["click"]) {
          this.$dispatch("click", item);
        } else {
          /* 判断是否为专栏（专栏单独处理） */
          if (item.url.includes("/pc/nc/page/pc/commonpage/classcolumn")) {
            /* 获取当前专栏是否公开以及是否停用 */
            let { success, message, data } = await this.getChannelState({ id: item.url.split("?id=")[1]});
            let state = data.state;
            if (state == 1) {
              dsf.layer.pc.message('专栏不存在', false);
            }else if(state == 2){
              dsf.layer.pc.message('当前专栏已停用，请尝试学习其他专栏', false);
            }else if(state == 3){
              /* 专栏未公开,需要登录 */
              dsf.layer.alert("当前专栏需要登录后才能学习，点击确定进行登录").then(res => {
                this.$checkLogin().then(res => {
                  if (res) {
                    dsf.http
                      .get("/nc/notice/loginPopupNotice", {})
                      .done(res => {
                        if (!res.success) {
                          this.error(res);
                        } else {
                          let { data } = res;
                          if (data.content) {
                            sessionStorage.setItem("systemStatus", 1);
                          }
                        }
                      })
                      .error(err => {
                        console.log(err);
                      })
                      .always(res => {});
                  }
                });
              })
            }else{
              this.$open({
                url: item.url,
                state: item.opentype_value,
              })
            }
          }else{
            this.$checkLogin().then(res => {
              if (res) {
                let toUrl = "";
                if (item.url) {
                  toUrl = item.url;
                } else if (this.toUrl) {
                  toUrl = this.$replace(this.toUrl, item);
                }
                this.$openWindow({
                  url: toUrl,
                  state: item.jumpWay,
                });
              }
            });
          }
        }
      }
      return
      if (item.url.indexOf("/pc/nc/page/pc/commonpage/classcolumn") > 0) {
        let res = await this.getClassInfo(item.url.split("?id=")[1]);
        if (!res.success) {
          if (res.message == "用户id不存在") {
            dsf.layer.alert("当前专栏需要登录后才能学习，点击确定进行登录").then(res => {
              this.$checkLogin().then(res => {
                if (res) {
                  dsf.http
                    .get("/nc/notice/loginPopupNotice", {})
                    .done(res => {
                      if (!res.success) {
                        this.error(res);
                      } else {
                        let { data } = res;
                        if (data.content) {
                          sessionStorage.setItem("systemStatus", 1);
                        }
                      }
                    })
                    .error(err => {
                      console.log(err);
                    })
                    .always(res => {});
                }
              });
            });
            return false;
          }
          if (res.message == "专栏已停用") {
            return dsf.layer.alert("当前专栏已停用，请尝试学习其他专栏");
          }
        }
        // if (res.data.sfgkValue == "0") {
        //   dsf.layer.alert("当前专栏暂未公开，请尝试学习其他专栏");
        //   return false;
        // }
      }
      if (this.type === "normal" || index == this.activeIndex) {
        if (this.$listeners["click"]) {
          this.$dispatch("click", item);
        } else {
          let toUrl = "";
          if (item.url) {
            toUrl = item.url;
          } else if (this.toUrl) {
            toUrl = this.$replace(this.toUrl, item);
          }
          this.$openWindow({
            url: toUrl + "",
            state: item.jumpWay,
          });
        }
      }
    },
    validateApiFiled(data) {
      if (!Array.isArray(data.data)) {
        console.error("数据源必须为数组");
      } else {
        const flag = data.data.every(it => {
          return Object.prototype.toString.call(it) === "[object Object]" && Object.prototype.hasOwnProperty.call(it, "image");
        });
        if (!flag) {
          console.error(`
            接口数据格式请参考: \n
            [{\n
              image: '',\n
              title: '',\n
              desc: ''\n
            }]
          `);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
/deep/.el-carousel__arrow {
  font-size: 24px !important;
  width: 48px;
  height: 48px;
  background-color: #14171c9e;
}
</style>
