<template>
  <div v-if="isDesign" class="dsf-nc-spacil-card-no-hover">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :error-src="defaultSrc" />
      <div class="info">
        <div class="card-state" style="background-color: #0080ff">报名</div>
        <div class="card-info">
          <span>2019.07.24 ～ 2019.08.27</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">网络学院卡片组件</div>
    </div>
    <div class="footer">
      <span>
        <i class="iconfont icon-wodexuanzhong"></i>
        <b>1234</b>
      </span>
      <span>
        <i class="iconfont icon-wodexuanzhong"></i>
        <b>1234</b>
      </span>
      <span>
        <i class="iconfont icon-wodexuanzhong"></i>
        <b>1234</b>
      </span>
    </div>
  </div>
  <div v-else class="dsf-nc-spacil-card-no-hover" :title="data['title']" @click="_clickBefore(click)">
    <div class="cover dsf-nc-image">
      <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      <div class="info">
        <div class="card-state" :style="`background-color: ${showBgColor(data['stateText'])}`">{{ data["stateText"] }}</div>
        <div class="card-info">
          <span v-if="data['show_description'] == 1">{{ data["state_description"] }}</span>
          <span v-else>{{ data["sDate"] }} 至 {{ data["eDate"] }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">{{ data["title"] }}</div>
    </div>
    <div class="footer">
      <span>
        <i class="iconfont icon-icon-bi"></i>
        <b>必修门数：{{ data["compulsoryHours"] || 0 }}</b>
      </span>
      <span>
        <i class="iconfont icon-icon-xuan"></i>
        <b>选修门数：{{ data["electivesHours"] || 0 }}</b>
      </span>
      <span>
        <i class="iconfont icon-icon-people"></i>
        <b>{{ data["views"] || 0 }}</b>
      </span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcSpecialCardByNoHoverNew",
  ctrlCaption: "专题卡片-新",
  mixins: [$mixins.card],
  design: {
    isMask: false,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "nc_class_page_mainData.cover", to: "cover", type: "text", desc: "封面" },
          { from: "nc_class_page_mainData.name", to: "title", type: "text", desc: "课题" },
          { from: "nc_class_page_mainData.pxsj_sdate", to: "sDate", type: "text", desc: "开始时间" },
          { from: "nc_class_page_mainData.pxsj_edate", to: "eDate", type: "text", desc: "结束时间" },
          { from: "nc_class_page_mainData.compulsoryperiod", to: "compulsoryHours", type: "text|number", desc: "必修门数", prefix: "必修门数：" },
          { from: "nc_class_page_mainData.electivesperiod", to: "electivesHours", type: "text|number", desc: "选修门数", prefix: "选修门数：" },
          { from: "nc_class_page_mainData.current_state_value", to: "stateValue", type: "text|number", desc: "状态值" },
          { from: "nc_class_page_mainData.current_state_text", to: "stateText", type: "text", desc: "状态文字" },
          { from: "nc_class_page_mainData.studentcount", to: "views", type: "text|number", desc: "浏览量" },
          { from: "nc_class_page_mainData.show_description", to: "show_description", type: "text|number", desc: "是否显示倒计时" },
          { from: "nc_class_page_mainData.state_description", to: "state_description", type: "text|number", desc: "倒计时描述" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  created() {},
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "报名中":
          c = "#0080FF";
          break;
        case "进行中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#F67039";
          break;
        case "已结束":
          c = "#808080";
          break;
        default:
          c = "#0080FF";
          break;
      }
      return c;
    },
  },
});
</script>
