<template>
  <div class="dsf_nc_teachfinshlist" ref="finsh">
    <!-- <input type="radio" value="已完成" /><span>已完成</span>
    <input type="radio" value="已完成" /><span>未完成</span> -->
    <el-radio-group v-model="radio">
      <el-radio-button v-for="(value, key) in typeObj" :label="key"></el-radio-button>
      <!-- <el-radio-button label="未完成"></el-radio-button> -->
    </el-radio-group>
    <el-table
      :data="dataList"
      height="calc(100% - 120px)"
      border
      style="width: 100%; margin-top: 15px; overflow-x: hidden"
    >
      <el-table-column prop="name" label="姓名" width="319px">
      </el-table-column>
      <el-table-column prop="code" label="编号" width="320px">
      </el-table-column>
      <el-table-column prop="unit" label="单位" width="320px">
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 15px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[15, 30, 45]"
      background
      :page-size="15"
      layout="prev, pager, next,total,  jumper, sizes"
      :total="count"
    >
    </el-pagination>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzTeachFinshList",
  ctrlCaption: "贵州教辅中心完成学时列表",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      radio: "已完成",
      typeObj: {
        "已完成": 1,
        "未完成": 2,
      },
      dataList: [],
      currentPage4: 1,
      // basnessid: null,
      count: null,
      newPagesize: 15,
      newPagenum: 1,
      row: [],
    };
  },
  created() {},
  watch: {
    radio: {
      handler(newval, oldval) {
    /*     if (newval == "未完成") {
          this.getData(2);
          this.getCount(2);
        }

        if (newval == "已完成") {
          this.getData(1);
          this.getCount(1);
        } */
        this.getData();
        this.getCount();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.$dialog.$options.propsData.options.params.row, "弹窗");
      this.row = this.$dialog.$options.propsData.options.params.row;
      // this.basnessid =
      //   this.$refs.finsh.offsetParent.parentElement.parentElement.parentElement.parentElement
      //     .getAttribute("path")
      //     .split("=")[1];
      this.getData();
      this.getCount();
    });
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.newPagesize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.newPagenum = val;
      this.getData();
    },
    getData(type) {
      let that = this;
      const mainUrl = "/nc/jfzx/finishCourserUsers";
      const params = {
        pageSize: that.newPagesize,
        pageNum: that.newPagenum,
        type: this.typeObj[this.radio], // 完成类型 1 完成 2未完成
        packId: that.$route.query.id,
        classId: that.$route.query.id, //课程id
        businessId: that.row["business_id"], //
        typeValue: that.row["type_value"], //课程类型
        batchId: that.row["type_value"] == "asq" ? that.row["_id"] : that.row["_id"],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getCount(type) {
      let that = this;
      const mainUrl = "/nc/jfzx/finishCourserUsersCount";
      
      const params = {
        pageSize: that.newPagesize,
        pageNum: that.newPagenum,
        type: this.typeObj[this.radio], // 完成类型 1 完成 2未完成
        packId: that.$route.query.id,
        classId: that.$route.query.id, //课程id
        businessId: that.row["business_id"], //
        typeValue: that.row["type_value"], //课程类型
        batchId: that.row["type_value"] == "asq" ? that.row["_id"] : that.row["_id"],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // that.count = res.data[0]["count(distinct p.dsfa_oua_user_id)"];
            that.count = res.count;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
