<template>
  <div class="dsf_nc_listtitle">
    <div class="live-list-header live-history" style="display: block">
      <div class="content" handler="content">
        <div class="title-left"></div>
        <div class="big-title" handler="big">新</div>
        <div class="small-title" handler="small">时代学习大讲堂</div>
        <div class="right">
          <p class="title-right"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcListTitle",
  ctrlCaption: "列表标题",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
});
</script>
<style lang="scss">
.dsf_nc_listtitle {
  .content {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
  }
  .live-list-header .content .big-title {
    display: inline-block;
    color: #942320;
    font-size: 40px;
    margin-left: 37px;
    font-weight: 600;
  }
  .live-list-header .content .title-left {
    background-color: #942320;
    width: 24px;
    height: 3px;
    transform: translate3d(0px, 35px, 0px);
  }
  .live-list-header .content .small-title {
    color: #942320;
    font-size: 24px;
    transform: translate3d(7px, 1px, 0px);
    margin-right: 17px;
    display: inline-block;
    font-weight: 600;
  }
  .live-list-header .content .right {
    width: 85%;
    background: #d8d8d8;
    height: 1px;
    display: inline-block;
    transform: translate3d(0px, -7px, 0px);
  }
  .live-list-header .content .title-right {
    background-color: #942320;
    height: 3px;
    width: 278px;
  }
}
</style>