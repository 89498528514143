<template>
  <div class="long_march_map_content long_march">
    <template v-if="isDesign">
      <div class="long_march_map long_march_map_design">
        <!-- 底部标识 -->
        <div class="breviaries">
          <div class="breviaries_item" v-for="(item, index) in mapList" :key="index">
            <img class="item_xian" :src="item.img" alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="long_march_map">
        <div class="map" id="map" @click="dialogTableVisible = true"></div>
        <!-- 底部标识 -->
        <div class="breviaries">
          <div class="breviaries_item" @click="lookDetail(item)" v-for="(item, index) in mapList" :key="index">
            <img class="item_xian" :src="item.img" alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <el-dialog append-to-body class="map_dialog" width='auto' :modal="false" :visible.sync="dialogTableVisible">
        <div class="map_dialog_content">
          <img @click="dialogTableVisible = false" class="map_gb" src="@/assets/styles/img/pcimg/guizhou/longmarch/map_gb.png" alt="">
          <div @click="lookDetail(item)" class="map_dialog_item" v-for="(item, index) in mapList" :key="index">{{item.name}}</div>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import L from 'leaflet';
import "leaflet/dist/leaflet.css"
export default dsf.component({
  name: 'DsfNcMap',
  ctrlCaption: '总图',
  mixins: [$mixins.control],
  props: {},
  data() {
    return {
      map: null,
      myIcon: null /* 图标 */,
      predefinedPointsAndTextsOne: [
        { latlng: [30.5175, 114.3573], text: '江口' },
        { latlng: [30.5118, 114.34], text: '石阡' },
        { latlng: [30.502, 114.316], text: '瓮安' },
        { latlng: [30.492, 114.318], text: '福泉' },
        { latlng: [30.487, 114.2925], text: '贵阳' },
        { latlng: [30.502, 114.2675], text: '黔西' },
        { latlng: [30.505, 114.256], text: '大方' },
        { latlng: [30.507, 114.245], text: '毕节' }
      ],
      predefinedPointsAndTextsTwo: [
        { latlng: [30.4785, 114.3673], text: '黎平' },
        { latlng: [30.4888, 114.35], text: '剑河' },
        { latlng: [30.5, 114.345], text: '镇远' },
        { latlng: [30.5, 114.336], text: '施秉' },
        { latlng: [30.496, 114.328], text: '黄平' },
        { latlng: [30.502, 114.316], text: '瓮安' },
        { latlng: [30.519, 114.3175], text: '湄潭' },
        { latlng: [30.526, 114.3105], text: '绥阳' },
        { latlng: [30.5288, 114.302], text: '娄山关' },
        { latlng: [30.5328, 114.271], text: '土城' },
        { latlng: [30.518, 114.233], text: '威信(扎西)' },
        { latlng: [30.524, 114.267], text: '古蔺' },
        { latlng: [30.5298, 114.277], text: '二郎滩' },
        { latlng: [30.5239, 114.2815], text: '仁怀' },
        { latlng: [30.487, 114.2925], text: '贵阳' },
        { latlng: [30.4786, 114.2907], text: '惠水' },
        { latlng: [30.4645, 114.2708], text: '紫云' },
        { latlng: [30.451, 114.2568], text: '贞丰' },
        { latlng: [30.46, 114.243], text: '兴仁' }
      ],
      predefinedPointsAndTextsThere: [
        { latlng: [30.504, 114.3213], text: '草塘' },
        { latlng: [30.5052, 114.3275], text: '余庆' },
        { latlng: [30.529, 114.352], text: '木黄' }
      ],
      dialogTableVisible: false,
      mapList: [
        {
          name: '中央红军路线',
          img: require('@/assets/styles/img/pcimg/guizhou/longmarch/item_two.png'),
          link: '/pc/nc/page/pc/longmarch/two',
          longMarchId: '017293fafec04e11b44a8e2150cfa2cb',
        },
        {
          name: '红六军团路线',
          img: require('@/assets/styles/img/pcimg/guizhou/longmarch/item_there.png'),
          link: '/pc/nc/page/pc/longmarch/there',
          longMarchId: '71515ee215fd4f01a67dba3e2bb477cf',
        },
        {
          name: '红二、红六军团路线',
          img: require('@/assets/styles/img/pcimg/guizhou/longmarch/item_one.png'),
          link: '/pc/nc/page/pc/longmarch/one',
          longMarchId: '6c43707421cd46c18aacc2fb8564a1e5',
        }
      ]
    };
  },
  components: {},
  watch: {},
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = L.map('map', {
        attributionControl: false, //去除右下角leaflet标识
        zoomControl: false //去除放大缩小按钮控件
        /*      maxZoom: 5, //最大缩放级别
        minZoom: 1, //最小缩放级别 */
      }).setView([30.5, 114.3], 13);
      // 禁止拖动地图
      this.map.dragging.disable();

      // 禁止缩放地图
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
     
      var imageUrl = require('@/assets/styles/img/pcimg/guizhou/longmarch/map_z.png');
      // 初始时也应用一次
      var bounds = this.map.getBounds();
      var southWest = bounds.getSouthWest();
      var northEast = bounds.getNorthEast();
      var imageBounds = [
        [southWest.lat, southWest.lng],
        [northEast.lat, northEast.lng]
      ];
      L.imageOverlay(imageUrl, imageBounds).addTo(this.map);

      this.myIcon = L.icon({
        iconUrl:  require('@/assets/styles/img/pcimg/guizhou/longmarch/qz.png'),
        iconSize: [40, 40], // 图标的大小
        // iconUrl: require('@/assets/styles/img/pcimg/guizhou/longmarch/wz.png'),
        // iconSize: [25, 41], // 图标的大小
        iconAnchor: [12, 41], // 图标的锚点，即图标的位置应该定位在哪个像素
        popupAnchor: [0, 0] // 弹出窗口的锚点
      });
      this.addPoint(this.predefinedPointsAndTextsOne, '#f43c4c'); // 启动添加点的进程
      // this.addPoint(this.predefinedPointsAndTextsTwo, '#f43c4c'); // 启动添加点的进程
      // this.addPoint(this.predefinedPointsAndTextsThere, '#f43c4c'); // 启动添加点的进程
    },
    addPoint(predefinedPointsAndTexts, linecolor = 'red') {
      var polyline = L.polyline([], { color: linecolor }).addTo(this.map);
      for (let i = 0; i < predefinedPointsAndTexts.length; i++) {
        setTimeout(() => {
          var pointData = predefinedPointsAndTexts[i];
          L.marker(pointData.latlng, {
            icon: this.myIcon
          })
            .addTo(this.map)
            .on('click', function () {
              // alert('你点击了坐标：' + this.getLatLng());
            })
            .bindTooltip(pointData.text, { permanent: true, className: 'my-custom-tooltip' }); // 在标记点旁边一直显示文字
          polyline.addLatLng(pointData.latlng);
          // 添加以下代码来平移地图到新添加的点
          // this.map.panTo(pointData.latlng);
        }, i * 1000);
      }
    },
    lookDetail(item) {
      if (!item.link) return;
      const dzUrl = '/nc/longmarch/signUp';
      this.$http
        .get(
          dzUrl,
          {
            longMarchId: item.longMarchId,
          },
          $$webRoot.nc
        )
        .done((res) => {
          if (res.success) {
            this.$router.push(item.link+'?longMarchId='+item.longMarchId);
          } else {
            dsf.layer.pc.message(res.message, false);
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
        });
    },
  }
});
</script>
