<template>
  <div class="dsf-changePwd-control newchangepwd">
    <el-form
      :model="userInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="userInfo-ruleForm"
    >
      <el-form-item label="单位" prop="deptName" class="no-marging twotop">
        <p>{{ userInfo.deptName || "暂无" }}</p>
      </el-form-item>
      <el-form-item label="姓名" prop="loginName" class="no-marging twotop">
        <p>{{ userInfo.name || "暂无" }}</p>
      </el-form-item>
      <el-form-item label="编号" prop="code" class="no-marging twotop">
        <p>{{ userInfo.code || "暂无" }}</p>
      </el-form-item>
      <el-form-item prop="oldPassword">
        <img
          class="mmicon"
          src="../../../assets/styles/img/pcimg/guizhou/mm.png"
          alt=""
        />
        <el-input
          type="password"
          placeholder="请输入旧密码"
          v-model.trim="userInfo.oldPassword"
          clearable
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item prop="newPassword">
        <img
          class="mmicon"
          src="../../../assets/styles/img/pcimg/guizhou/mm.png"
          alt=""
        />
        <el-input
          v-model.trim="userInfo.newPassword"
          type="password"
          placeholder="请输入新密码"
          ref="newpwd"
          clearable
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword" class="confirmPassword">
        <img
          class="mmicon"
          src="../../../assets/styles/img/pcimg/guizhou/mm.png"
          alt=""
        />
        <el-input
          v-model.trim="userInfo.confirmPassword"
          type="password"
          placeholder="请再次输入新密码"
          clearable
          show-password
        ></el-input>
      </el-form-item>
      <p class="step-tip" style="color: #cd0911">
        <!-- 密码规则：设置口令长度不小于8位，口令必须为字母、数字和符号中的任意两种组合 -->
        <!-- 密码设置6到8位,必须包含字母、数字、特殊字符 -->
        密码设置8到15位,必须包含字母和数字
      </p>
      <el-form-item class="btn-form">
        <div class="btns">
          <el-button
            type="primary"
            class="sumit"
            @click="submitForm('ruleForm')"
            @keydown.enter="submitForm('ruleForm')"
            >提 交</el-button
          >
          <!-- <el-button class="close" @click="closePage" v-if="!hideCancelButton"
            >关 闭</el-button -->
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcNewChangePwd",
  ctrlCaption: "修改密码",
  mixins: [$mixins.control],
  data() {
    return {
      userInfo: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          // { min: 6, message: "长度最小8个字符", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          // { min: 6, message: "长度最小8个字符", trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请确认密码"));
              }
              if (value != this.userInfo.newPassword) {
                return callback(new Error("两次密码不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      messageTips: "",
    };
  },
  props: {
    reqUrl: {
      type: String,
      default: "/nc/org/user/detail",
    },
    submitUrl: {
      type: String,
      default: "nc/org/user/updatePassword",
    },
    hideCancelButton: {
      type: [Boolean, String, Number],
      default: false,
    },
  },
  computed: {},
  watch: {},
  async mounted() {
    if (this.isDesign) {
      this.userInfo = {
        deptName: "成都",
        loginName: "张三",
      };
    } else {
      this.getData();
      let res = await this.getiInterval();
      if (res.success) {
        this.messageTips = res.data.notice;
      }
    }
    this.hideCancelButton = Boolean(this.queryString["hideCancelButton"] || 0);
  },

  beforeDestroy() {},
  methods: {
    getData() {
      if (this.reqUrl) {
        const loading = dsf.layer.pc.loading();
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done((res) => {
            if (res.success) {
              this.userInfo = res.data;
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error((response) => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          })
          .always(function () {
            dsf.layer.pc.closeLoading(loading);
          });
      }
    },
    //  清空cookie
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=kevis.com;expires=" +
            new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
        }
      }
    },
    // 密码校验规则
    CheckPassWord(p) {
      //必须为字母加数字且长度不小于8位
      var pwdReg = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,}).*$/;
      return pwdReg.test(p);
    },
    refpwd() {
      this.$nextTick(() => {
        return this.$refs.newpwd.value.trim();
      });
      if (this.$refs.newpwd) return this.$refs.newpwd.value.trim();
    },
    // 提交按钮
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        // console.log(this.refpwd(), "this.refpwd()");
        // console.log(
        //   this.CheckPassWord(this.refpwd()),
        //   "this.CheckPassWord(this.refpwd())"
        // );

        //if (this.CheckPassWord(this.refpwd())) {
        //验证密码规则
        if (valid) {
          //验证密码规则
          await this.selfverify();
          if (this.$listeners["handleSubmit"]) {
            this.$dispatch("handleSubmit", this.userInfo);
          } else {
            const loading = dsf.layer.pc.loading();
            this.dsf.http
              .post(
                this.submitUrl,
                {
                  originPassword: dsf.md5(dsf.md5(this.userInfo.oldPassword)),
                  newPassword: dsf.md5(dsf.md5(this.userInfo.newPassword)),
                },
                $$webRoot.nc
              )
              .done((res) => {
                if (res.success) {
                  if (
                    this.$dialog._props.options.params.path ==
                    "/pc/nc/page/pc/login/changePwd?type=theone"
                  ) {
                    this.dsf.layer.message("修改密码成功");
                    this.clearCookie();
                    location.reload();
                  } else {
                    if (!Number(dsf.getCookie("verificationText"))) {
                      dsf.layer.openDialog({
                        title: "填写手机号后四位",
                        width: "450px",
                        height: "460px",
                        showClose: false,
                        showCancelButton: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        params: {
                          path: "/pc/nc/page/pc/login/phonehsw",
                        },
                      });
                      window.openInfo.close();
                    } else {
                      this.clearCookie();
                      location.reload();
                    }
                    this.dsf.layer.message(res.message);
                  }
                } else {
                  this.dsf.layer.message(res.message || "获取数据异常", false);
                }
              })
              .error((response) => {
                this.dsf.layer.message(
                  response.message || "获取数据异常",
                  false
                );
              })
              .always(function () {
                dsf.layer.pc.closeLoading(loading);
              });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
        // } else {
        //   this.dsf.layer.message("不符合密码必须规则，请重新输入", false);
        // }
      });
    },
    getiInterval() {
      return new Promise((resolve) => {
        this.dsf.http
          .get("user/password/getUpdatePwdRule", {}, $$webRoot.nc)
          .done((res) => {
            if (res.success) {
              resolve(res);
            } else {
              this.dsf.layer.message(res.message || "获取密码规则错误", false);
            }
          })
          .error((response) => {
            this.dsf.layer.message(
              response.message || "获取密码规则错误",
              false
            );
          });
      });
    },
    selfverify() {
      return new Promise((resolve) => {
        this.dsf.http
          .get(
            "nc/reg/ncVerifyPwd",
            { pwd: dsf.base64.encode(this.userInfo.newPassword) },
            $$webRoot.nc
          )
          .done((res) => {
            if (res.success) {
              if (res.data.success) {
                resolve(res.data.success);
              } else {
                this.dsf.layer.message(res.data.message, false);
              }
            } else {
              this.dsf.layer.message(res.message || "验证密码失败", false);
            }
          })
          .error((response) => {
            // this.dsf.layer.message(response.message || "验证密码失败", false);
          });
      });
    },
    closePage() {
      if (this.$listeners["handleClosePage"]) {
        this.$dispatch("handleClosePage", this.userInfo);
      } else {
        // dsf.layer.closeWindow();
        // this.close();
        this.$root.close();
      }
    },
    reLogin() {
      dsf.storage.session.clear();
      dsf.cookies.remove("userId");
      dsf.cookies.set("isLogin", "");
      dsf.cookies.set("authorization_token", "");
      this.$root.close();
      window.location.href =
        location.origin +
        dsf.url.getWebPath(dsf.config.setting_public_pcindex_url) +
        "?showLogin=1";
      window.location.reload();
    },
  },
});
</script>
<style lang="scss">
.newchangepwd {
  .sumit {
    background-color: #cd0911 !important;
    border: 1px solid #cd0911;
    width: 200px;
  }

  .sumit:hover {
    border: 1px solid #cd0911;
  }

  .twotop {
    border: 1px solid #dcdfe6;
  }

  .el-form-item__content {
    margin: 0 !important;

    input {
      padding-left: 50px !important;
    }
  }

  .mmicon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 20px;
    z-index: 10;
  }
}
</style>
