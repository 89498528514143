<template>
  <div class="dsf_nc_jfemaildetail">
    <p class="title">{{ dataList.title }}</p>
    <div class="header">
      <div class="header_item">发送人：{{ dataList.sendUserName }}</div>
      <div class="header_item">发送时间：{{ dataList.dsCreateTime }}</div>
      <div class="header_item">紧急程度：{{ dataList.degree }}</div>
    </div>
    <div class="recipient">接收人：{{ dataList.jsrText }}</div>
    <div class="recipient content">
      <span>内容：</span>
      <div class="datacontent" v-html="dataList.content"></div>
    </div>
    <div class="recipient filerow">
      <!-- <span class="filename">附件：{{ 1 }}</span> -->
      <div>
        附件：
        <template v-if="dataList.file">
          <div class="rowitem" v-for="(item, index) in fileArr" :key="index">
            <span class="filename">{{ setFile(item) }}</span>
            <span class="filedom"></span>
              <span class="dombtn" @click.self="fileview(item)">预览</span>
              <span @click="filedown(dataList.file, index)" class="dombtn">
                下载
              </span>
            </span>
          </div>
        </template>
      </div>
    </div>
    <ul class="statistics">
      <li :class="['statistics_itme', tabindex == 0 ? 'active' : '']" @click="tabindex = 0">已读统计</li>
      <li :class="['statistics_itme', tabindex == 1 ? 'active' : '']" @click="tabindex = 1">未读统计</li>
      <div class="statistics_content">{{ tabindex ? "未读人:" : "已读人：" }}&nbsp;{{ tabindex ? dataList.unReadUserName : dataList.readUserName }}</div>
    </ul>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcJfEmailDetail",
  ctrlCaption: "贵州教辅信箱详情", //jfemaildetail  jfemaildetail
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: {},
      tabindex: 0,
      fileArr: [],
    };
  },
  created() {
    // eventBus.$on("getDetails", (data) => {
    //   console.log(data, "传来的data");
    //   this.dataList = data;
    // });
    // console.log(this.$dialog.options, "that.$dialog.options");
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    // eventBus.$off("getDetails");
  },
  methods: {
    filedown(item, index) {
      // console.log(JSON.parse(item) instanceof Array, "数组");
      let moreArr = JSON.parse(item);
      let id = moreArr[index].relativePath.split("/")[4];
      console.log(id);
      var url = dsf.url.getWebPath("file/download");
      $(
        '<form action="' +
          url +
          '" method="get">' + // action请求路径及推送方法
          '<input type="text" name="files" value="' +
          id +
          '"/>' + // id^fileName
          '<input type="text" name="zipName" value="' +
          (moreArr[index].originalFileName || "") +
          '"/>' + // 压缩包名称
          "</form>"
      )
        .appendTo("body")
        .submit()
        .remove();
    },
    fileview(fileUrl) {
      window.open(`${dsf.url.getWebPath("/preview/file")}?url=${dsf.config.setting_public_file_host}${fileUrl.relativePath}`, "_blank");
    },
    setFile(cover) {
      let src = "";
      if (cover) {
        src = cover.originalFileName;
      } else {
        src = null;
      }
      return src;
    },
    getData() {
      let that = this;
      console.log(that.$dialog.options.params, "1345789");
      const mainUrl = "/nc/class/train/mail/queryMailDetail";
      const params = {
        mailSubId: that.$dialog.options.params.id || that.$dialog.options.params.detailList.mailSubId,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.dataList = res.data;
            that.fileArr = JSON.parse(that.dataList.file);
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //   that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //   that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
