<template>
  <div class="welcome">欢迎使用学习邦，目前部分功能正在完善，请在浏览器使用。</div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {};
  },
  methods: {},
};
</script>
