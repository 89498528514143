<script>
export default dsf.component({
  name: "DsfSubTableColumnNew",
  inheritAttrs: false,
  mixins: [$mixins.layout],
  ctrlCaption: "子表列",
  props: {
    column: {
      type: Object,
      default() {
        return {
          label: "",
          children: []
        };
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    }
    // totalWidth: {
    //   type: Number,
    //   default: 0
    // }
  },
  data() {
    return {
      // realWidth: this.column.width
      tableWidth: 0,
      editable: false
    };
  },
  created() {},
  computed: {
    ownerTable() {
      let parent = this.$parent;
      while (parent.$options.name != "DsfSubTableNew") {
        parent = parent.$parent;
      }
      return parent;
    },
    bodyWidth() {
      return this.elTable.bodyWidth;
    },

    elTable() {
      return this.$parent;
    }
  },
  beforeMount() {},
  mounted() {},
  methods: {
    getWidth(column) {
      let width = column.width;
      let totalWidth = this.ownerTable.tableWidth;
      if (totalWidth) {
        if (dsf.type(width) == "string" && width && width.endsWith("%")) {
          let w = parseFloat(width.replace("%", "")) / 100;
          let indexColumnWidth = this.ownerTable.isShowIndexColumn ? 60 : 0;
          let operateColumnWidth = this.ownerTable.isShowOperateColumn ? 130 : 0;
          let tw = totalWidth - indexColumnWidth - operateColumnWidth;
          width = parseFloat(tw) * w;
        }
      }
      return width;
    }
  },
  updated() {
    // console.log("ddd");
  },
  render(h) {
    // let table=this.ownerTable;
    return renderHeader.call(this, h, this.column, this.$vnode.data.key);
  }
});

function renderHeader(h, column, key) {
  let _this = this;
  let child = [];
  let scopedSlots = null;
  if (column.children && column.children.length > 0) {
    child = _.map(column.children, (c, i) => {
      return renderHeader.call(this, h, c, i);
    });
  } else {
    child = this.$slots[column.columnId];
    scopedSlots = {
      header(scope) {
        return renderElTableColumnHeader(h, _this, column, scope);
      },
      default(scope) {
        return _this.$scopedSlots[column.columnId] && _this.$scopedSlots[column.columnId](scope);
      }
    };
  }
  let props = { ...column, type: "" };
  delete props.width;
  let data = {
    key: key,
    props: { ...props },
    attrs: {
      "class-name": column.columnId,
      columnKey: column.columnId,
      ...this.$attrs,
      minWidth: props.minWidth,
      width: this.getWidth(column),
      'column-id': column.columnId
      // sortable: this.sortable
    }
  };
  if (scopedSlots) {
    data.scopedSlots = scopedSlots;
  }
  if (this.ownerTable) {
    let vnode = h("el-table-column", data, child);
    return vnode;
  }
  return null;
}
function renderTextContent(h, vm, column) {
  return h("dsf-text-content-editable", {
    model: {
      value: column.label,
      callback(v) {
        column.label = v;
      }
    },
    on: {
      "update:editable"(v) {
        vm.editable = v;
      }
    },
    nativeOn: {
      dblclick(event) {
        this.editable = true;
      }
    },
    props: {
      editable: vm.editable
    }
  });
}

function renderElTableColumnHeader(h, vm, column, scope) {
  let res = [];
  if (column.required) {
    res.push(
      h("i", {
        staticClass: "required",
        staticStyle: { "margin-right": "5px" }
      })
    );
  }
  let data = {
    staticClass: "ds-table-header-conent"
  };
  if (vm.ownerTable.isDesign) {
    //设计模式附加双击可编辑事件
    data.on = {
      dblclick: (event) => {
        vm.editable = true;
        vm.$nextTick(() => {
          dsf.designer.beginEditTextConent(event.currentTarget);
        });
      }
    };
  }
  if (!vm.editable) {
    data.domProps = {
      innerHTML: `${column.prefix || ""}<span>${column.label}</span>${column.suffix || ""}`
    };
  }
  res.push(h("div", data, [vm.editable ? renderTextContent(h, vm, column) : vm._e()]));
  return res;
}
</script>
