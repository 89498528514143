<template>
  <div class="dsf_nc_xygzdatagrid">
    <div class="list">
      <el-table stripe border :data="dataList" style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="name" label="课程名称" width="auto">
        </el-table-column>
        <el-table-column
          prop="type_text"
          align="center"
          label="类型"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="duration"
          align="center"
          label="总时长"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="lastWatch"
          align="center"
          label="已学时长"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="isComplete"
          align="center"
          label="是否完成"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="progress"
          align="center"
          label="进度"
          width="130"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper , sizes"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcGzXydatagrid",
  ctrlCaption: "贵州学员报表",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      dataList: [],
      currentPage4: 1,
      pageNum: 1,
      pageSize: 10,
      keyword: "",
      total: 0,
      title: "课程列表",
      tabletitle: [
        { type: "index", algin: "center", label: "序号", width: "50" },
        {
          algin: "center",
          label: "名称",
          width: "auto",
          prop: "NAME",
        },
        {
          algin: "center",
          label: "类型",
          width: "110",
          prop: "category_text",
        },
        {
          algin: "center",
          label: "完成人数",
          width: "110",
          prop: "finish_count",
        },
      ],
      userId: "",
    };
  },
  created() {
    this.$nextTick(() => {
      this.userId =
        this.$parent.$dialog.$options.propsData.options.params.path.split(
          "uid="
        )[1];
      console.log(this.userId);
      this.getData();
      this.getcount();
    });
  },
  mounted() {},
  methods: {
    input() {
      this.$nextTick(() => {
        this.keyword = this.$refs.searchint.value;
        if (this.$refs.searchint.value.trim() == "") {
          this.getData();
          this.getcount();
        }
      });
    },
    search() {
      this.keyword = this.$refs.searchint.value.trim();
      this.getData();
      this.getcount();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
      this.getcount();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
      this.getcount();
    },
    // clickfinsh(row) {
    //   console.log(row);
    //   this.$openDialog({
    //     title: "完成状况",
    //     // 弹框宽，支持%、px、vw等
    //     width: "1000px",
    //     // 弹框高，支持px、vh等，不支持%
    //     height: "600px",
    //     showClose: true,
    //     params: {
    //       // 页面A的物理路径
    //       path: "#/pc/nc/page/pc/teachassistant/finishcount",
    //       row: row,
    //     },
    //   });
    // },
    getData() {
      let that = this;
      const mainUrl = "/nc/jfzx/learningRecord";
      const params = {
        classId: this.$route.query.id,
        userId: that.userId,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.dataList = res.data;
            console.log(that.dataList, "hat.tabletitle");
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getcount() {
      let that = this;
      const mainUrl = "/nc/jfzx/learningRecordCount";
      const params = {
        classId:  this.$route.query.id,
        userId: that.userId,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.total = res.count;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>
<style></style>
