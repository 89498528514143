<template>
  <div class="dsf_ncpc_manage_myinfo" :style="{ width: itemWidth, display: visible ? 'block' : 'none' }">
    <div class="my_company">{{ info.unitName || "" }}</div>
    <div class="manager_name">管理员：{{ info.name || "" }}</div>
    <div class="organiz_count">组织人数：{{ info.unitUserCount || 0 }}</div>
  <!--   <div class="manage_bottom">
      <p class="font">年度学时完成情况</p>
      <div class="counts_wrap">
        <div class="count_left">
          <i class="icon"></i>
          <div class="progress_count">{{ info.periodRatio || 0 }}%</div>
        </div>
        <div class="hours">{{ info.currentPeriod || 0 }}/{{ info.totalPeriod || 0 }}</div>
      </div>
      <el-progress :percentage="info.currentPeriod || 0" stroke-width="11" color="#0A55D4" :show-text="false"></el-progress>
    </div> -->
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcPcManageMyInfoNew",
  ctrlCaption: "个人信息",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/org/user/info",
    },
    itemWidth: {
      type: String,
      default: "390px",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      info: {},
      params: {},
    };
  },
  created() {},
  mounted() {
    if (!this.isDesign) {
      this.info = {};
      this.initData();
    }
  },
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (!success) {
            dsf.layer.message(message || "请求异常", false);
          } else {
            this.info = data;
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
  },
});
</script>
