import Special from "./Special";
import gzdetailcourselists from "./gzdetailcourselists";
import xlysidebar from "./xlysidebar";
import xlyclass from "./xlyclass"
import xlydata from "./xlydata"
import xlysgin from "./xlysgin"
import xlyquestion from "./xlyquestion"
import xlyranking from "./xlyranking"
import xlynotice from "./xlynotice"
import xlyteachemail from "./xlyteachemail"
import xlycommunity from "./xlycommunity"
import gzhomeworksummary from "./gzhomeworksummary"
import gzhomework from "./gzhomework"
import gzexam from "./xlyexam.vue"
import specialAnnouncementNotice from "./specialAnnouncementNotice.vue"
import specialCardByNoHover from "./specialCardByNoHover.vue"
import specialDetailRelevantInformation from "./specialDetailRelevantInformation.vue"
export default [specialDetailRelevantInformation, specialCardByNoHover,specialAnnouncementNotice,Special, gzdetailcourselists, xlysidebar, xlyclass, xlydata, xlysgin, xlyquestion, xlyranking, xlynotice, xlyteachemail, xlycommunity, gzhomeworksummary, gzhomework, gzexam];