<template>
  <div class="dsf_nc_xlyexam">
    <div class="com-title-wrap">
      <div class="com-title-inner">
        <span class="com-title">考试</span>
      </div>
    </div>
    <p style="margin: 15px 0">学习进度需要达到100%</p>
    <div class="complete_examine_desc">
      <div :class="['exam', 'btn_box', { khwc: examPass == 1 }, { gray: !examStateValue || isview }]" v-if="isShowExam && examId" @click="!isview && examStateValue && clickExam(scheduleData)">
        <dsf-icon name="kaoshi"></dsf-icon>
        <div class="btn">
          <span class="txt">{{ exam().fontBtn }}</span>
        </div>
        <div class="exam_score score" :style="exam().color">
          {{ exam().font }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default dsf.component({
  name: "DsfNcGzExam",
  ctrlCaption: "贵州训练营考试",
  mixins: [$mixins.control],
  props: {
    isShowExam: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      dataList: [],
      params: {
        id: location.hash.split("?id=")[1],
      },
      scheduleData: {},
      unitAssess: [],
      examStateValue: 0, // 考试和小结能不能点击 是否置灰
      certificateSrc: require("_nc/assets/imgs/nocredential.png"),
      isDownLoad: false,
      certificateUrl: "/nc/class/certificate",
      examId: "",
      examPass: 0,
      isShowHomework: false, // 作业页面
      isShowBriefSummary: false, // 小结页面
      showReadOrWrite: false, //小结显示只读或可写
      certificateState: false, //后台是否配置了证书
      homeworkId: "",
      homeworkName: "",
      homeworkDone: "", //是否完成作业
      briefsummaryUrl: "/nc/homework/assessment/writing",
      homeworkState: "", // -1 审核未通过,!-1 审核通过
      homeworkStudentId: "",
      homeworkTxt: "查看小结",
      examTxt: "开始考试",
      specialApplyUrl: "nc/class/info",
      specialDetailData: {},
      // dialogTitle: "撰写小结",
      isview: false,
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapState(["isstudent"]),
  },
  mounted() {},
  methods: {
    initData() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get("/nc/class/learning/assess", this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            // console.log(res.data, "unitAssess");
            this.scheduleData = res.data;
            this.unitAssess = res.data.unitAssess.length ? res.data.unitAssess : [];
            // console.log(this.unitAssess, " this.unitAssess");
            if (this.isstudent == 1) {
              this.examStateValue = 1;
            } else {
              this.examStateValue = Number(res.data.stateValue) || 0;
            }

            this.examId = res.data.examId;
            this.examPass = Number(res.data.examPass) || 0;
            this.homeworkId = res.data.homeworkId;
            this.homeworkName = res.data.homeworkName;
            this.homeworkDone = res.data.homeworkDone;
            this.homeworkState = res.data.homeworkState;
            this.homeworkStudentId = res.data.homeworkStudentId;
            this.certificateState = res.data.certificateState;
          }
        })
        .error(err => {
          //   this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    toExamDetail(id) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: id, //考试id
        businessId: this.params.id, //业务id
        businessType: 2, // 专题班考试： 考试类型2
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    exam() {
      let params = {
        font: "",
        color: "",
      };
      if (this.scheduleData.examId) {
        if (this.scheduleData.examPass == 1) {
          //考试通过
          params.font = this.scheduleData.score + "分";
          params.color = "#69C33B";
          params.fontBtn = "查看考试";
        } else {
          params.font = "待完成";
          params.color = "#666666";
          params.fontBtn = "开始考试";
        }
      } else {
        params.font = "待完成";
        params.color = "#666666";
        params.fontBtn = "开始考试";
      }
      return params;
    },
    clickExam(data) {
      // 点击考试
      let id = data.examId;
      if (!id) return dsf.layer.message("暂无考试id", false);
      this.toExamDetail(id);
    },
  },
});
</script>
<style></style>
