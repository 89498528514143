<template>
  <div class="dsf-nc-college-ali-footer">
    <div class="dsf-nc-ali-footer-inner">
      <div class="dsf-nc-ali-footer-item" v-for="it in items" :key="it.name">
        <i :class="it.icon" v-if="it.icon" />
        <div class="desc">
          <span class="desc-name">{{ it.name }}</span>
          <span class="desc-total">{{ it.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfCollegeAliFooter",
  props: {
    mainWidth: {
      type: [Number, String],
      default: "100%",
    },
  },
  data() {
    return {
      items: [
        {
          icon: "",
          name: "阿里研究院版权所有",
          total: null,
        },
        {
          icon: "foot-icon icon-img-user-see",
          name: "网站访问量",
          total: 286545,
        },
        {
          icon: "foot-icon icon-img-user-register",
          name: "注册人数",
          total: 286545,
        },
        {
          icon: "foot-icon icon-img-user-seenum",
          name: "今日访问量",
          total: 286545,
        },
        {
          icon: "foot-icon icon-img-pc",
          name: "今日PC累计登录人数",
          total: 286545,
        },
        {
          icon: "foot-icon icon-img-user-mobile",
          name: "今日移动端登录人数",
          total: 286545,
        },
        {
          icon: "",
          name: "中国干部网络学院版权所有 京ICP备  17328572  京公网安备123145631356",
          total: null,
        },
      ],
    };
  },
  created() {
    this.initFooter();
  },
  methods: {
    initFooter() {
      dsf.http
        .get("nc/saas/count/home", {}, $$webRoot.nc)
        .done(({ message, success, data }) => {
          if (!success) {
            error(message);
          } else {
            this.items = [
              {
                icon: "",
                name: "阿里研究院版权所有",
                total: null,
              },
              {
                icon: "foot-icon icon-img-user-see",
                name: "网站访问量",
                total: data["totalView"] || 0,
              },
              {
                icon: "foot-icon icon-img-user-register",
                name: "注册人数",
                total: data["userNum"],
              },
              {
                icon: "foot-icon icon-img-user-seenum",
                name: "今日访问量",
                total: data["todayView"],
              },
              {
                icon: "foot-icon icon-img-pc",
                name: "今日PC累计登录人数",
                total: data["todayPcLogin"],
              },
              {
                icon: "foot-icon icon-img-user-mobile",
                name: "今日移动端登录人数",
                total: data["todayMoblieLogin"],
              },
              {
                icon: "",
                name: "中国干部网络学院版权所有 京ICP备  17328572  京公网安备123145631356",
                total: null,
              },
            ];
          }
        })
        .error(err => {
          error(err);
        })
        .always(res => {});
      function error(message) {
        dsf.layer.message(message || "请求异常", false);
      }
    },
  },
};
</script>
