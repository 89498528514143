<template>
  <div class="dsf_userInfoPage" v-if="visible">
    <div class="item">
      <userInfo
        :keysMap="keysMap"
        :showHeader="showHeader"
        :moreUrl="infoMoreUrl"
        :resData="resData"
        :width="width"
        :height="height"
      ></userInfo>
    </div>
    <div class="item">
      <userHeader
        :imageUrl="imageUrl && resData[imageUrl]"
        :showHeader="showHeader"
        :headerMoreUrl="headerMoreUrl"
        :width="width"
        :height="height"
      ></userHeader>
    </div>
    <!-- <div class="item">
      <userAccount
        :keysMap="keysMap2"
        :showHeader="showHeader"
        :moreUrl="accountMoreUrl"
        :changePwdUrl="changePwdUrl"
        :resData="resData"
        :width="width"
        :height="height"
        :pageType="pageType"
      ></userAccount>
    </div> -->
    <!-- <div class="item">
      <userPortrayal :keysMap="keysMap3" :showHeader="showHeader" :resData="resData" :width="width" :height="height"></userPortrayal>
    </div> -->
  </div>
</template>

<script>
import userInfo from "./userInfo.vue";
import userHeader from "./userHeader.vue";
export default dsf.component({
  name: "DsfNcGzInfoPage",
  ctrlCaption: "贵州基本信息",
  mixins: [$mixins.control],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    pageType: {
      type: String,
      default: "tacitly",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "470px",
    },
    height: {
      type: String,
      default: "400px",
    },
    infoMoreUrl: { type: String, default: "" },
    changePwdUrl: { type: String, default: "/pc/nc/page/pc/login/changePwd" },
    accountMoreUrl: { type: String, default: "" },
    headerMoreUrl: { type: String, default: "/pc/nc/userinfo/edit" },
    reqUrl: { type: String, default: "/nc/org/user/detail" },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "name", to: "name", type: "text", desc: "姓名" },
          { from: "sexText", to: "sex", type: "text", desc: "性别" },
          { from: "job", to: "job", type: "text", desc: "职务" },
          { from: "phone", to: "phone", type: "text|number", desc: "手机号" },
          { from: "hobbies", to: "hobbies", type: "text", desc: "兴趣爱好" },
          { from: "tags", to: "tags", type: "text", desc: "个人标签" },
        ];
      },
    },
    imageUrl: { type: String, default: "" },
    keysMap2: {
      type: Array,
      default: () => {
        return [
          { from: "unit", to: "unit", type: "text", desc: "单位" },
          { from: "userName", to: "userName", type: "text", desc: "用户名" },
          { from: "pwd", to: "pwd", type: "text", desc: "密码" },
        ];
      },
    },
    keysMap3: {
      type: Array,
      default: () => {
        return [
          { from: "xxdUser", to: "xxdUser", type: "text", desc: "用户学习度" },
          { from: "wcdUser", to: "wcdUser", type: "text", desc: "用户完成度" },
          { from: "cjdUser", to: "cjdUser", type: "text", desc: "用户成就度" },
          { from: "gxdUser", to: "gxdUser", type: "text", desc: "用户贡献度" },
          { from: "cydUser", to: "cydUser", type: "text", desc: "用户参与度" },
          { from: "xxdAvg", to: "xxdAvg", type: "text", desc: "平均学习度" },
          { from: "wcdAvg", to: "wcdAvg", type: "text", desc: "平均完成度" },
          { from: "cjdAvg", to: "cjdAvg", type: "text", desc: "平均成就度" },
          { from: "gxdAvg", to: "gxdAvg", type: "text", desc: "平均贡献度" },
          { from: "cydAvg", to: "cydAvg", type: "text", desc: "平均参与度" },
        ];
      },
    },
  },
  data() {
    return {
      resData: {},
      photo: "",
    };
  },
  components: {
    userInfo,
    userHeader,
  },
  mounted() {
    if (!this.isDesign) {
      this.getData();
    } else {
      this.resData = {
        name: "张三",
        // sex: "男",
        job: "教师",
        phone: 13549785461,
        // hobbies: "爱好",
        // tags: "数字化,数字化,数字化,数字化,数字化",
        unit: "成都",
        userName: "张三",
        pwd: "********",
        cjdAvg: "0",
        cjdUser: "0",
        cydAvg: "1",
        cydUser: "77",
        gxdAvg: "0",
        gxdUser: "0",
        wcdAvg: "0",
        wcdUser: "10",
        xxdAvg: "5",
        xxdUser: "297",
      };
    }
  },
  created() {
    this.photo =
      "oua2/getUserPhoto?userId=" + dsf.getCookie("userId") ||
      dsf.getCookie("user_id") ||
      "";
  },
  methods: {
    getData() {
      if (this.reqUrl) {
        this.dsf.http
          .get(this.reqUrl, {}, $$webRoot.nc)
          .done((res) => {
            if (res.success) {
              this.resData = res.data;
              this.$store.state.userName =
                this.resData[this.keysMap[0].from] || "";
            } else {
              this.dsf.layer.message(res.message || "获取数据异常", false);
            }
          })
          .error((response) => {
            this.dsf.layer.message(response.message || "获取数据异常", false);
          });
      }
    },
    reloadData() {
      this.getData();
    },
  },
});
</script>
