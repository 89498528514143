<template>
  <div class="dsf_nc_xlycommunity">
    <!-- 首页 -->
    <el-container v-show="tabIndex == 0 || tabIndex == 4">
      <el-aside width="240px">
        <div class="userinfo">
          <div class="userimg">
            <img :src="setImg(userinfo.photo)" alt="" />
          </div>
          <div class="showinfo">
            <div class="infoitem">
              <div class="count">{{ userinfo.myReplyNum || 0 }}</div>
              <div class="info">回复</div>
            </div>
            <div class="infoitem">
              <div class="count">{{ userinfo.like || 0 }}</div>
              <div class="info">点赞</div>
            </div>
            <div class="infoitem">
              <div class="count">{{ userinfo.liked || 0 }}</div>
              <div class="info">被赞</div>
            </div>
          </div>
        </div>
        <div class="navlist">
          <ul class="bbs_nav_menu">
            <li
              :class="[isactive ? 'active' : '', 'self_menu']"
              @click="(isactive = true), (tabIndex = 0)"
            >
              <a>
                <i class="icon iconfont"></i>
                首页
              </a>
            </li>
            <li style="display: list-item" @click="golist(1)">
              <a>
                <i class="icon iconfont"></i>
                我的帖子
              </a>
            </li>
            <li style="display: list-item" @click="golist(2)">
              <a>
                <i class="icon iconfont"></i>
                我的回复
              </a>
            </li>
            <li style="display: list-item" @click="golist(3)">
              <a>
                <i class="icon iconfont"></i>
                我的收藏
              </a>
            </li>
            <li
              style="display: list-item"
              :class="isactive ? '' : 'active'"
              @click="postbtn"
            >
              <a>
                <i class="icon iconfont"></i>
                发布帖子
              </a>
            </li>
          </ul>
        </div>
      </el-aside>
      <el-container>
        <el-main v-show="tabIndex == 0">
          <!-- 全部热帖 -->
          <div class="topall">
            <div class="header">
              <div class="title">全部论坛热帖</div>
              <div class="more" @click="gochange(5)">+ 更多</div>
            </div>
            <div class="content">
              <!-- <div class="item" v-for="(item, index) in homedata" :key="index"> -->
              <div
                class="item"
                @click="detailpage(item)"
                v-for="(item, index) in allDataList"
                :key="index"
              >
                <div class="left">
                  <div class="hottitle">
                    {{ item.title }}
                  </div>
                  <div class="hotname">
                    <i class="icon iconfont"></i>
                    {{ item["ds_create_user_name"] }}
                  </div>
                </div>
                <div class="hottime">{{ item["ds_create_time"] }}</div>
              </div>
            </div>
          </div>
          <!-- 与我相关 -->
          <div class="aboutme">
            <div class="header">
              <div class="title">与我相关</div>
              <div class="more" @click="gochange(2)">+ 更多</div>
            </div>
            <div class="content" v-if="replyList">
              <div class="noreply">
                <div
                  class="item"
                  v-for="(item, index) in replyList"
                  :key="index"
                >
                  <div class="top">
                    <div class="imgbox">
                      <img :src="setImg(item.photo)" alt="" />
                    </div>
                    <div class="right">
                      <div class="name">
                        {{ item["plr_text"] }}:
                        <span class="replytitle">{{
                          item["comment_content"]
                        }}</span>
                      </div>
                      <div class="replyrow">
                        <div class="time">{{ item["ds_create_time"] }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 帖子不存在 -->
                  <template v-if="!item.replyJson.isTrue">
                    <div class="bottom">
                      <div style="margin-bottom: 10px">该帖子已删除</div>
                    </div>
                  </template>
                  <!-- 帖子存在 -->
                  <template v-else>
                    <!-- 直接回复帖子 -->
                    <div
                      class="bottom"
                      v-if="!item.replyJson.isComment"
                      @click="detailpage(item)"
                    >
                      <div class="desctitle ellipsis">
                        {{ item.replyJson.title }}
                      </div>
                      <div
                        class="descinfo ellipsis"
                        v-html="item.replyJson.content"
                      ></div>
                      <div class="all_content_info">
                        <div class="all_item_person">
                          <i class="iconfont"></i>
                          {{ item.replyJson["ds_create_user_name"] }}
                          <span class="all_item_date">{{
                            item["ds_create_time"]
                          }}</span>
                        </div>
                        <div class="all_item_do_block">
                          <div class="all_block_item">
                            <i class="icon iconfont"></i>
                            <span class="all_item_num">{{
                              item.replyJson["collect_num"] || 0
                            }}</span>
                          </div>
                          <div class="all_block_item m30">
                            <i class="icon iconfont"></i>
                            <span class="all_item_num">{{
                              item.replyJson["view_num"] || 0
                            }}</span>
                          </div>
                          <div class="all_block_item">
                            <i class="icon iconfont"></i>
                            <span class="all_item_num">{{
                              item.replyJson["reply_num"] || 0
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 回复评论 -->
                    <div class="bottom2" v-else>
                      <div class="info">
                        <div class="imgbox">
                          <img :src="setImg(item.replyJson.photo)" alt="" />
                        </div>
                        <div class="rightbox">
                          <div class="name">
                            {{ item.replyJson["ds_create_user_name"] }}
                          </div>
                          <div class="time">
                            {{ item.replyJson["ds_create_time"] }}
                          </div>
                        </div>
                      </div>
                      <div class="title">
                        {{ item.replyJson["comment_content"] }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </el-main>
        <!-- 首页发布帖子 -->
        <el-main v-show="tabIndex == 4">
          <!-- <iframe
            src="#/pc/nc/class/train/community/edit"
            frameborder="0"
            class=""
          ></iframe> -->
        </el-main>
      </el-container>
    </el-container>
    <!-- 列表页 -->
    <div v-show="tabIndex == 1">
      <my-post :tabIndex="tabIndex" @changeindex="changeindex"></my-post>
    </div>
    <div v-show="tabIndex == 2">
      <my-reply :tabIndex="tabIndex" @changeindex="changeindex"></my-reply>
    </div>
    <div v-show="tabIndex == 3">
      <my-collection
        :tabIndex="tabIndex"
        @changeindex="changeindex"
      ></my-collection>
    </div>
    <div v-show="tabIndex == 5">
      <all-post :tabIndex="tabIndex" @changeindex="changeindex"></all-post>
    </div>
    <div v-show="tabIndex == 6">
      <post-detail
        :tabIndex="tabIndex"
        @changeindex="changeindex"
        @allpage="allpage"
      ></post-detail>
    </div>
  </div>
</template>
<script>
import MyPost from "../../base/bbs/MyPost.vue";
import MyReply from "../../base/bbs/MyReply.vue";
import MyCollection from "../../base/bbs/MyCollection.vue";
import AllPost from "../../base/bbs/AllPost.vue";
import PostDetail from "../../base/bbs/PostDetail.vue";
export default dsf.component({
  name: "DsfNcXlyCommunity",
  ctrlCaption: "贵州训练营班级社区",
  mixins: [$mixins.control],
  components: {
    MyPost,
    MyReply,
    MyCollection,
    AllPost,
    PostDetail,
  },
  data() {
    return {
      isactive: true,
      tabIndex: 0,
      userinfo: {},
      homedata: [],
      allDataList: [],
      replyList: [],
      pageNum: 1,
      pageSize: 5,
    };
  },
  watch: {
    tabIndex: {
      handler(newval) {
        if (newval == 0) {
          this.getinindata("", 2);
          this.getMyRply();
        }
      },
    },
  },
  created() {
    debugger;
    // 跳转详情
    eventBus.$on("tabIndex6", (data) => {
      this.tabIndex = 6;
      eventBus.$emit("detailinfo", data);
    });
    eventBus.$on("gohome", () => {
      this.tabIndex = 0;
    });
    // 首页获取五条热帖子
    this.getinindata("", 2);
    this.getuserinfo();
    this.getMyRply();
  },
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    getinindata(query, type) {
      let that = this;
      let classId = this.$route.query.id;
      const mainUrl = "/nc/class/train/community/allList";
      const params = {
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        query,
        classId,
        type,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.allDataList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 跳转详情页
    detailpage(item) {
      this.tabIndex = 6;
      eventBus.$emit("detailinfo", item);
    },
    gochange(index) {
      console.log(index);
      this.tabIndex = index;
      if (index == 5) {
      }
      if (index == 4) {
        // this.isactive = false;
      }
    },
    gopush() {},
    gohome() {},
    changeindex() {
      this.tabIndex = 0;
    },
    allpage() {
      this.tabIndex = 5;
    },
    golist(index) {
      if (index == 1) {
        this.tabIndex = 1;
      } else if (index == 2) {
        this.tabIndex = 2;
      } else {
        this.tabIndex = 3;
      }
    },
    //用户信息
    getuserinfo() {
      let that = this;
      const mainUrl = "/nc/class/train/community/communityHomeData";
      const params = {
        classId: that.queryString.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.userinfo = res.data;
            console.log(res.data, "sanlian");
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 与我相关
    getMyRply() {
      let that = this;
      const mainUrl = "/nc/class/train/community/queryMyComment";
      const params = {
        classId: this.$route.query.id,
        pageSize: 10,
        pageNum: that.pageNum,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "我的挥发度");
            that.replyList = res.data;
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 发布帖子按钮
    postbtn() {
      let that = this;
      dsf.layer.openDialog({
        title: "发布帖子",
        width: "1000px",
        height: "600px",
        showClose: true,
        params: {
          path: `nc/class/train/community/edit?class_value=${
            location.hash.split("=")[1]
          }`,
        },
        btns: [
          {
            text: "发送",
            handler: function (res) {
              return res.yes().then(function (data) {
                console.log(data, "data");
                if (!data["nc_class_train_community.title"]) {
                  dsf.layer.message("标题不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else if (!data["nc_class_train_community.content"]) {
                  dsf.layer.message("内容不能为空", false);
                  // 返回false可以阻止弹窗关闭
                  return false;
                } else {
                  that.postrequest(data);
                }
              });
            },
          },
          {
            text: "关闭 ",
          },
        ],
      });
    },
    // 发布请求
    postrequest(data) {
      let that = this;
      const mainUrl = "/inc/meta/persistData";
      let namespace = "nc.class.train.community";
      let pageName = "edit";
      let classId = this.$route.query.id;
      data = JSON.stringify(data);
      const params = {
        namespace,
        pageName,
        data,
        classId,
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message("发布成功");
            that.getall();
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 获取全部
    getall() {
      this.allactive = true;
      this.getinindata("", 2);
      this.type = 1;
    },
  },
});
</script>
<style lang="scss"></style>
