<template>
  <div>
    <div class="des-custom-order" ctrl_type="dsf.customorder">
      <div class="left">
        <div class="des-custom-order-item">
          <span>最新</span>
          <i class="sort-bar"></i>
        </div>
        <div class="des-custom-order-item">
          <span>最热</span>
          <i class="sort-bar"></i>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div
      :style="{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '1200px',
        padding: '0 20px',
        boxSizing: 'border-box',
      }"
    >
      <div
        class="dsf-nc-spacil-card-no-hover"
        :title="item['nc_class_page_mainData.name']"
        @click="_clickBefore(click)"
        v-for="(item, index) in dataList"
        :key="index"
        :style="{ width: `280px`, height: `236px` }"
      >
        <div class="cover dsf-nc-image">
          <dsf-nc-image :src="setImg(item['nc_class_page_mainData.cover']) | imgFormat" :error-src="defaultSrc" />
          <div class="info">
            <div class="card-state" :style="`background-color: ${showBgColor(item['nc_class_page_mainData.current_state_text'])}`">
              {{ item["nc_class_page_mainData.current_state_text"] }}
            </div>
            <div class="card-info">
              <span v-if="item['nc_class_page_mainData.show_description'] == 1">{{ item["nc_class_page_mainData.state_description"] }}</span>
              <span v-else>{{ item["nc_class_page_mainData.pxsj_sdate"] }} 至 {{ item["nc_class_page_mainData.pxsj_edate"] }}</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title">{{ item["nc_class_page_mainData.name"] }}</div>
        </div>
        <div class="footer">
          <span>
            <i class="iconfont icon-icon-bi"></i>
            <b>{{ item["nc_class_page_mainData.compulsoryPeriod"] }}学时</b>
          </span>
          <span>
            <i class="iconfont icon-icon-xuan"></i>
            <b>{{ item["nc_class_page_mainData.electivesPeriod"] }}学时</b>
          </span>
          <span>
            <i class="iconfont icon-icon-people"></i>
            <b>{{ item["nc_class_page_mainData.studentcount"] }}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzZtbCard",
  ctrlCaption: "贵州专题卡片",
  mixins: [$mixins.card],
  design: {
    isMask: false,
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      dataList: [],
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          {
            from: "nc_class_page_mainData.cover",
            to: "cover",
            type: "text",
            desc: "封面",
          },
          {
            from: "nc_class_page_mainData.name",
            to: "title",
            type: "text",
            desc: "课题",
          },
          {
            from: "nc_class_page_mainData.pxsj_sdate",
            to: "sDate",
            type: "text",
            desc: "开始时间",
          },
          {
            from: "nc_class_page_mainData.pxsj_edate",
            to: "eDate",
            type: "text",
            desc: "结束时间",
          },
          {
            from: "nc_class_page_mainData.compulsoryperiod",
            to: "compulsoryHours",
            type: "text|number",
            desc: "必修学时",
            prefix: "必修学时：",
          },
          {
            from: "nc_class_page_mainData.electivesperiod",
            to: "electivesHours",
            type: "text|number",
            desc: "选修学时",
            prefix: "选修学时：",
          },
          {
            from: "nc_class_page_mainData.current_state_value",
            to: "stateValue",
            type: "text|number",
            desc: "状态值",
          },
          {
            from: "nc_class_page_mainData.current_state_text",
            to: "stateText",
            type: "text",
            desc: "状态文字",
          },
          {
            from: "nc_class_page_mainData.studentcount",
            to: "views",
            type: "text|number",
            desc: "浏览量",
          },
          {
            from: "nc_class_page_mainData.show_description",
            to: "show_description",
            type: "text|number",
            desc: "是否显示倒计时",
          },
          {
            from: "nc_class_page_mainData.state_description",
            to: "state_description",
            type: "text|number",
            desc: "倒计时描述",
          },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    },

    getData() {
      let that = this;
      const mainUrl = "inc/meta/list/data";
      const params = {
        pageNum: 1,
        pageSize: 16,
        query: {
          searchValue: {},
          order: [],
          filter: [],
        },
        namespace: "nc.pagespecial",
        pageName: "specialList",
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.dataList = res.data;
            console.log(res.data, "res");
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "报名中":
          c = "#0080FF";
          break;
        case "进行中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#F67039";
          break;
        case "已结束":
          c = "#808080";
          break;
        default:
          c = "#0080FF";
          break;
      }
      return c;
    },
  },
});
</script>
