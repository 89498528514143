<template>
  <div class="dsf_nc_xlyclass">
    <div class="com-title-wrap">
      <div class="com-title-inner" style="height: 40px">
        <span class="com-title">课程</span>
        <div class="applybtn" ref="applybtn" v-if="(stateList?.bm && stateList.bm == '1' && setStatus(stateList)) || isstudent == 1 || isstudent == 2" @click="!isview && clickEnter(stateList)">
          {{ setStatus(stateList) }}
        </div>
      </div>
    </div>
    <div class="course-wrap">
      <div class="header-progress">
        <ul id="CourseProgress">
          <li class="course-end">
            <div class="name">
              开始
              <span class="dot-line">
                <i class="line"></i>
              </span>
            </div>
          </li>
          <li v-for="(item, index) in dataList" :key="index" :class="[getstatus(index, dataList, dataList.length) ? 'course-end' : '']">
            <div class="name">
              <p class="unitName">{{ item.unitName }}</p>
              <span class="dot-line">
                <i class="line"></i>
              </span>
            </div>
          </li>
          <li v-if="classAfterInfo" :class="[classAfterInfo && classAfterInfo.status == 1 ? 'course-end' : '']">
            <div class="name">
              课后考试
              <span class="dot-line">
                <i class="line"></i>
              </span>
            </div>
          </li>
          <!-- <li :class="[stateList.state == 3 ? 'course-end' : '']"> -->
          <li :class="[isgraduation() ? 'course-end' : '']">
            <div class="name">
              结业
              <span class="dot-line">
                <i class="line"></i>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="warn">
        结业条件：必修门数：{{ unitAssess.compulsoryGateProgress }}/{{ unitAssess.compulsoryGate }}门 ， 选修门数：{{ unitAssess.electivesGateProgress }}/{{ unitAssess.electivesGate }}门{{
          classAfterInfo && `，课后考试：${classAfterInfo.status == 1 ? `${classAfterInfo.exam_score || 0}分` : "待完成"}`
        }}
      </div>
      <div class="container">
        <ul class="course-list" id="CourseList">
          <li class="course-item hide-sub-list">
            <el-collapse v-model="activeNames">
              <el-collapse-item :name="item" v-for="(item, index) in dataList" :key="index">
                <template slot="title">
                  <h3 class="course-item-title">
                    <span class="sub-title">{{ item?.unitName }}</span>
                    <span class="sub-bx-title">必修: {{ item?.compulsory || 0 }}门 {{ item?.compulsoryPeriod || 0 }}学时</span>
                    <span class="sub-xs-title">选修: {{ item?.electives || 0 }}门 {{ item?.electivesPeriod || 0 }}学时</span>
                  </h3>
                </template>
                <dl class="sub-course-list">
                  <!-- <template v-if="!item.advancedMode"> -->
                  <dd
                    class="sub-course-list-item"
                    v-for="(items, indexs) in item?.subList"
                    :key="items.businessId"
                    @click="showClick && godetail(item, items, item.classId, item, indexs, indexs > 0 ? item.subList[indexs - 1].progress : null)"
                  >
                    <div class="coursetitle">
                      <div class="sub-course-list-name">
                        {{ items.name }}
                      </div>
                      <div class="alltitle">{{ items.name }}</div>
                    </div>

                    <div class="sub-course-state-wrap">
                      <span
                        class="sub-course-list-bx"
                        :style="{
                          background: `${items.categoryText == '选修' ? '#2961B4' : ''}`,
                        }"
                      >
                        {{ items.categoryText }}
                      </span>
                    </div>
                    <div class="sub-course-list-state">
                      {{ items.typeText == "课程包" ? "课程" : items.typeText }}
                    </div>
                    <div class="thetime" v-if="item.advancedMode">{{ setTime(items.timeSdate) }}至&nbsp;&nbsp;{{ setTime(items.timeEdate) }}</div>
                    <span class="sub-course-list-date">{{ items.teacher }}</span>
                    <span class="sub-course-list-xs">{{ items.period }} 学时</span>
                    <div class="workstate" v-if="items.typeValue == 'work'">
                      {{ items.homeWorkState == "2" ? "通过" : items.homeWorkState == "1" ? "待批阅" : items.homeWorkState == "-1" ? "退回" : "" }}
                    </div>
                    <el-progress type="circle" :percentage="items.progress" :width="44" color="rgb(58, 178, 105)"></el-progress>
                  </dd>
                </dl>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default dsf.component({
  name: "DsfNcXlyClass",
  ctrlCaption: "贵州训练营详情课程",
  mixins: [$mixins.control],
  props: {},
  components: {},
  data() {
    return {
      active: 2,
      dataList: [],
      stateList: {},
      activeNames: ["1"],
      isfainsh: false,
      showClick: false,
      tableDataArr: [],
      graduation: {},
      unitAssess: {},
      isGoon: 0,
      classAfterInfo: null /* isExam (是否考试：0 未考试  1 已考试) status (是否通过：0 未通过  1 已通过) score（分数）*/,
    };
  },
  created() {
    this.getData();
    this.getIsStudent();
    this.getGraduation();
  },
  mounted() {},
  beforeDestory() {
    this.$root.eventBus.$emit("reloadsildbar");
  },
  watch: {
    SpecialInfo: {
      handler(data) {
        if (data) {
          this.stateList = data;
        }
      },
      immediate: true,
    },
    SpecialApplyState: {
      handler(data) {
        if (data && !this.isDesign) {
          this.specialInfo(this.params);
        }
      },
      immediate: true,
    },
    SpecialApplyInfor: {
      handler(data) {
        if (data && this.stateList.state == 0 && this.stateList.signStatus == 1 && this.clickApplyBtn) {
          // 未报名且点击报名表单后处理
          if (dsf.isEmptyObject(data.items) || data.items != "[]") {
            this.$openDialog({
              title: "填写个人信息",
              width: "520px",
              height: "auto",
              content: "DsfNcPersonalInformationForm",
              params: {
                cb: data => {
                  if (data) {
                    this.specialApply({
                      id: location.href.split("=")[1],
                      data,
                    });
                  }
                },
              },
              dialogArgs: {},
            });
          } else {
            this.specialApply(location.href.split("=")[1]);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["isstudent"]),
  },
  methods: {
    ...mapMutations(["Set_Course_State"]),
    isgraduation() {
      let isgraduation = false;
      if (this.stateList.state == 3 && this.unitAssess.compulsoryGateProgress >= this.unitAssess.compulsoryGate && this.unitAssess.electivesGateProgress >= this.unitAssess.electivesGate) {
        isgraduation = true;
      }
      return isgraduation;
    },
    itemStatus(item, parentItem, childIndex, lastProgress) {
      if (this.stateList.state == -1 || this.stateList.state == 0 || this.stateList.state == 1 || this.stateList.state == 4) return false;
      if (this.stateList.zjsfax == "1" && parentItem.study == "0") return false;
      // 能点击 &&  当前单元没有设置排序
      if (this.showClick && parentItem.orderTypeValue == "0") return true;
      // 能点击 && 当前单元设置了排序 && 不是第一节课 && 上个进度为100
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex > 0 && lastProgress == 100) return true;
      //能点击 && 当前单元设置了排序 && 当前是第一节课
      if (this.showClick && parentItem.orderTypeValue == "1" && childIndex == 0) return true;
      // 能点击 &&  当前单元设置了排序 && 当前进度100
      if (this.showClick && parentItem.orderTypeValue == "1" && item.progress == 100) return true;
      //  状态为结业
      // if (this.stateList.state == 6) return true;
      if (this.stateList.state == 3) return true;

      return false;
    },
    toExamDetail(item) {
      const loading = dsf.layer.loading();
      /**
       * @param businessType 专题班1 考核2 单元考试1 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: item["businessId"], //考试id
        businessId: item["subId"], //业务id
        businessType: 1,
        deviceType: "pc",
      };
      this.dsf.http
        .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            // window.open(path);
            this.$open({ url: `${location.origin + path}`, state: 2 });
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    setTime(time) {
      let formter = "";
      if (time) {
        let onearr = time?.split(" ");
        let twoarr = onearr[1]?.split(":");
        formter = `${onearr[0]} ${twoarr[0]}:${twoarr[1]} `;
      }
      return formter;
    },
    getstatus(index, data, length) {
      let res = false;
      if (data) {
        if (data[index]?.completeDegree) {
          res = true;
        }
        // console.log(index, "index");
        // console.log(length, "length");
        // if (index == length - 1) {
        //   this.isfainsh = true;
        // }
      }
      return res;
    },
    ...mapActions(["specialInfo", "getState2", "getIsStudent"]),
    setDate(startDate, endDate, interval) {
      // 设置日期格式
      let start = startDate ? startDate.slice(0, 10) : "";
      let end = endDate ? endDate.slice(0, 10) : "";
      return start && end ? `${start}  ${interval}  ${end}` : "暂无";
    },

    clickEnter(item) {
      // console.log(item, "item,");
      if (!dsf.getToken()) {
        this.$checkLogin().then(res => {
          if (res) next(...params);
        });
        return;
      }
      console.log("点击按钮", item);
      let status = item.state; // 状态
      let id = item.classId; //专题班id
      let courseId = item.lastCourseId; // 课程id
      let signStatus = item.signStatus; // 1 能报名
      // let trainingStatus = item.trainingStatus; //1能学课
      let url = "";
      if (this.isstudent == 1) {
        if (this.$refs.applybtn.innerText == "继续学习") {
          let that = this;
          const mainUrl = "/nc/class/train/community/goOnStarting";
          const params = {
            classId: location.hash.split("?id=")[1],
          };
          that.dsf.http
            .get(mainUrl, params)
            .done(function (res) {
              if (res.success) {
               // dsf.layer.message("学习记录暂未同步，请点击下方列表进行学习或者稍后再试", "warning");
                if (res.data&&res.data!="{}") {
                  open(`/page.html#/pc/nc/pagecourse/coursePlayer?id=${res.data}&classid=${location.hash.split("id=")[1]}&type=ztb`);
                } else {
                  dsf.layer.message("学习记录暂未同步，请点击下方列表进行学习或者稍后再试", "warning");
                }
              } else {
                that.message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            })
            .error(function (error) {
              that.dsf.layer.toast("服务器异常", false);
            })
            .always(function () {
              that.dsf.layer.closeLoading(loader);
            });
        } else {
          // 开始学习
          this.getGoonState();
          this.getState();
          setTimeout(() => {
            open(`/page.html#/pc/nc/pagecourse/coursePlayer?id=${this.dataList[0].subList[0].businessId}&classid=${location.hash.split("id=")[1]}&type=ztb`);
          }, 500);
        }
        return false;
      }
      if (status == 0 && signStatus == "1") {
        this.clickApplyBtn = true; // 申请按钮
        this.specialApply();
        this.getData();
        this.$nextTick(() => {
          eventBus.$emit("reloadsildbar", this.$refs.applybtn.innerText);
        });
      } else if (status == 2 || (status == 6 && signStatus == "1")) {
        // console.log(this.$refs.applybtn.innerText, "ddd");
        this.$nextTick(() => {
          if (this.$refs.applybtn.innerText == "继续学习") {
            let that = this;
            const mainUrl = "/nc/class/train/community/goOnStarting";
            const params = {
              classId: location.hash.split("?id=")[1],
            };
            that.dsf.http
              .get(mainUrl, params)
              .done(function (res) {
                if (res.success) {
                 // dsf.layer.message("学习记录暂未同步，请点击下方列表进行学习或者稍后再试", "warning");
                  if (res.data) {
                    open(`/page.html#/pc/nc/pagecourse/coursePlayer?id=${res.data}&classid=${location.hash.split("id=")[1]}&type=ztb`);
                  } else {
                    dsf.layer.message("学习记录暂未同步，请点击下方列表进行学习或者稍后再试", "warning");
                  }
                } else {
                  that.message({
                    message: res.message || "获取列表数据异常",
                    type: "warning",
                  });
                }
              })
              .error(function (error) {
                that.dsf.layer.toast("服务器异常", false);
              })
              .always(function () {
                that.dsf.layer.closeLoading(loader);
              });
          } else {
            // 开始学习
            this.getGoonState();
            this.getState();
            setTimeout(() => {
              open(`/page.html#/pc/nc/pagecourse/coursePlayer?id=${this.dataList[0].subList[0].businessId}&classid=${location.hash.split("id=")[1]}&type=ztb`);
            }, 500);
          }
        });
      } else {
        if (status == 3 || status == 4 || status == 5) return;
        dsf.layer.message("暂时无法报名", false);
      }
    },
    // 专题班报名
    specialApply() {
      let that = this;
      return new Promise(resolve => {
        const mainUrl = "nc/class/enroll";
        const params = { id: that.$route.query.id };
        this.dsf.http
          .post(mainUrl, params, $$webRoot.nc)
          .done(function (res) {
            if (res.success) {
              dsf.layer.message("报名成功");
              that.getState2();
              that.specialApplyInfo(); /* 刷新报名按钮的状态 */
            } else {
              if (res.state != 20002) {
                dsf.layer.message({
                  message: res.message || "获取列表数据异常",
                  type: "warning",
                });
              }
            }
          })
          .error(function (error) {
            // that.dsf.layer.toast("服务器异常", false);
          })
          .always(function () {
            resolve(true);
            // that.dsf.layer.closeLoading(loader);
          });
      });
    },
    // 专题班报名信息
    specialApplyInfo(id) {
      let that = this;
      const mainUrl = "/nc/class/apply/info";
      const params = id || that.$route.query.id;
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            // console.log(res.data, "get");
            // that.dataList = res.data;
            // that.dsf.layer.toast(res.message, false);
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    //
    setStatus(data) {
      // 设置状态
      let status = data.state; // 状态
      // let studentCount = data.studentCount; // 学员人数
      // let sfxzrs = data.txrs; //是否有人数限制
      let html = "";
      if (this.isstudent == 1) {
        switch (status) {
          case 6:
            html = "继续学习";
            break;
          default:
            html = "开始学习";
        }
      } else {
        switch (status) {
          case -1:
            html = "立即报名";
            break;
          case 0:
            // if (sfxzrs && studentCount >= sfxzrs) {
            //   html = "人数已满";
            //   return;
            // }
            html = "报名";
            break;
          case 1:
            html = "待审核";
            break;
          case 2:
            html = "开始学习";
            break;
          case 6:
            html = "继续学习";
            break;
          case 3:
            html = "已结业";
            break;
          case 4:
            html = "审核中";
            break;
          case 5:
            html = "已拒绝";
            break;
          default:
            html = "";
        }
      }
      return html;
    },
    godetail(item, items, specialId, parentItem, childIndex, lastProgress) {
      if (this.$refs.applybtn && this.$refs.applybtn.innerText == "开始学习" && this.showClick) {
        this.getGoonState();
        this.getState();
      }
      // if (this.isstudent == 0 || this.isstudent == 2) {
      if (this.isstudent != 1) {
        // console.log(this.showClick, "能点击");
        // console.log(lastProgress, "上节课的进度");
        // console.log(items.playState, "playstate");
        let state = this.setStatus(this.stateList);
        let statues = this.itemStatus(items, parentItem, childIndex, lastProgress);

        if (state == "报名" || state == "立即报名") {
          return dsf.layer.message("请报名后再学习", false);
        } else if (state == "审核中" || state == "待审核") {
          return dsf.layer.message("请审核通过后再学习", false);
        }

        if ((state == "开始学习" || state == "继续学习") && this.stateList.zjsfax == "1" && !statues) return dsf.layer.message("请按单元顺序学习", false);
        console.log(statues);
        console.log(this.stateList.zjsfax);
        if (!items.playState && this.isstudent != 1) return dsf.layer.pc.message("不在学习时间范围", false);
        if (this.stateList.zjsfax == "1" && !statues) return dsf.layer.message("请按单元顺序学习", false);

        if (!statues) return dsf.layer.message("请按序学习", false);
        // 点击进入
        let type = items.typeValue;
        let classid = specialId; // 专题班id
        let id = items.businessId; // 课程id;考试id

        let url = "";
        console.log(items, "type");
        switch (type) {
          case "course": // 课程
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "video": // 视频
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "audio": // 音频
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "draft": // 文稿
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "media": // 富媒体
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "live": // 直播
            url = `/page.html#/pc/nc/pagelive/liveDetail?id=${id}`;
            break;
          case "exam": // 考试
            this.toExamDetail(items, specialId);
            break;
          case "asq": // 问卷
            let params = {
              asqId: items.asqInfoId,
              businessType: "1",
              subId: items.subId,
            };
            this.dsf.http
              .get("/nc/asq/api/getAsqUrl", params)
              .done(res => {
                if (res.success) {
                  url = res.data;
                }
                this.$open({ url, state: 2 });
              })
              .error(err => {
                dsf.layer.message(err.message || "获取考试异常", false);
              })
              .always(function () {});
            break;
          default:
            break;
        }
        this.$open({ url, state: 2 });

        // let id = items.businessId;
        // if (
        //   this.setStatus(this.stateList) == "开始学习" ||
        //   this.setStatus(this.stateList) == "已结业"
        // ) {
        //   // 章节按序
        //   if (this.stateList.zjsfax == 1) {
        //   } else {
        //     // dsf.layer.message("请按序学习")
        //   }
        //   if (items.typeText == "课程" || items.typeText == "课程包") {
        //     window.open(
        //       `${location.origin}/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}`
        //     );
        if (type == "work") {
          let that = this;
          dsf.layer.openDialog({
            title: items.name,
            width: "1000px",
            height: "550px",
            showClose: true,
            showCancelButton: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            content: "DsfNcGzHomeworkSummary",
            params: {
              state: items.homeWorkState,
              homeworkId: items.businessId,
              studentId: items.homeWorkStudentId,
              cb: data => {
                if (data) {
                  if (data) {
                    that.getData();
                    that.getIsStudent();
                    that.getGraduation();
                  }
                }
              },
            },
          });
        }
      } else {
        let type = items.typeValue;
        let classid = specialId; // 专题班id
        let id = items.businessId; // 课程id;考试id
        let url = "";
        switch (type) {
          case "course": // 课程
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "video": // 视频
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "audio": // 音频
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "draft": // 文稿
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "media": // 富媒体
            url = `/page.html#/pc/nc/pagecourse/coursePlayer?id=${id}&classid=${classid}&type=ztb`;
            break;
          case "live": // 直播
            url = `/page.html#/pc/nc/pagelive/liveDetail?id=${id}`;
            break;
          case "exam": // 考试
            this.toExamDetail(items, specialId);
            break;
          case "asq": // 问卷
            let params = {
              asqId: items.asqInfoId,
              businessType: "1",
              subId: items.subId,
            };
            this.dsf.http
              .get("/nc/asq/api/getAsqUrl", params)
              .done(res => {
                if (res.success) {
                  url = res.data;
                }
                this.$open({ url, state: 2 });
              })
              .error(err => {
                dsf.layer.message(err.message || "获取考试异常", false);
              })
              .always(function () {});
            break;
          default:
            break;
        }
        this.$open({ url, state: 2 });
        if (type == "work") {
          let that = this;
          dsf.layer.openDialog({
            title: items.name,
            width: "1000px",
            height: "550px",
            showClose: true,
            showCancelButton: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            content: "DsfNcGzHomeworkSummary",
            params: {
              state: items.homeWorkState,
              homeworkId: items.businessId,
              studentId: items.homeWorkStudentId,
              cb: data => {
                if (data) {
                  that.getData();
                  that.getIsStudent();
                  that.getGraduation();
                }
              },
            },
          });
        }
      }
    },
    getState() {
      let that = this;
      const mainUrl = "/nc/class/train/info";
      const params = {
        id: location.href.split("=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.stateList = res.data;
            that.Set_Course_State({ state: res.data.state });
            if (
              ((that.stateList.state == 2 || that.stateList.state == 6) && that.stateList.trainingStatus == 1) ||
              that.stateList.state == -1 ||
              that.stateList.state == 0 ||
              that.stateList.state == 1 ||
              that.stateList.state == 3 ||
              that.stateList.state == 4
            ) {
              that.showClick = true;
            } else {
              that.showClick = false;
            }
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getData() {
      let that = this;
      const mainUrl = "/nc/class/course/list";
      const params = {
        id: location.href.split("=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.getState();
            that.dataList = res.data;

            that.activeNames = [...that.dataList];

            that.getClassAfterInfo(); /* 获取课后练习信息 */
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    getGraduation() {
      let that = this;
      const mainUrl = "/nc/class/learning/assess";
      const params = {
        id: location.hash.split("?id=")[1],
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data);
            that.graduation = res.data;
            that.unitAssess = res.data.unitAssess[0];
          } else {
            that.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 修改开始学习状态
    getGoonState() {
      let that = this;
      const mainUrl = "nc/class/updateStudyStatus";
      const params = { id: location.hash.split("?id=")[1] };
      that.dsf.http
        .post(mainUrl, params)
        .done(function (res) {})
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    /* 获取课后练习信息 */
    getClassAfterInfo() {
      let that = this;
      const classAfterUrl = "/nc/pack/examInfo";
      const classAfterParams = {
        classId: this.queryString.id,
      };
      that.dsf.http
        .get(classAfterUrl, classAfterParams)
        .done(function (res) {
          if (res.success) {
            if (res.data && JSON.stringify(res.data) != "{}") {
              that.classAfterInfo = res.data;
              console.log(that.classAfterInfo);
            }
          }
        })
        .error(function (error) {})
        .always(function () {});
    },
  },
});
</script>
<style lang="scss">
.dsf_nc_xlyclass {
  .el-collapse-item__content {
    padding-bottom: 0 !important;
  }
  .el-progress__text {
    font-size: 12px !important; /* 修改为你希望的文字大小 */
  }
}
</style>
