<template>
  <div class="dsf_specialdetail_courselists" :style="{ display: visible ? 'block' : 'none' }">
    <special-detail-course-item :lists="lists" :detail-data="detailData" :isview="isview" :issfax="issfax"></special-detail-course-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { specialDetailCourseItem } from "../../base/special";
export default dsf.component({
  name: "DsfNcGzDetailCourseLists",
  ctrlCaption: "贵州专题班课程列表",
  mixins: [$mixins.control],
  props: {
    url: {
      type: String,
      default: "/nc/class/course/list",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lists: [
        {
          classId: "36f9cc6f479d41c3a7def8b9232da784",
          comPeriods: null,
          compulsory: 0,
          compulsoryHursed: null,
          compulsoryPeriod: 0,
          compulsoryper: null,
          createTime: "2021-06-18 14:50:50",
          elePeriods: null,
          electiveHursed: null,
          electiveper: null,
          electives: 2,
          electivesPeriod: 0.5,
          subList: [
            {
              businessId: "938ab2feb9d844e199a0d2d34fa36ca8",
              categoryText: "选修",
              categoryValue: "0",
              cover: null,
              duration: null,
              name: "数字经济助力县域高质量发展",
              order: 0,
              period: 0,
              progress: 100,
              subId: "db94890900ea4c5088d41e8f95f7118b",
              timeEdate: null,
              timeSdate: null,
              typeText: "课程",
              typeValue: "course",
              unitId: "90fbd8b25a744508b8438a7191e7b1b6",
            },
            {
              businessId: "938ab2feb9d844e199a0d2d34fa36ca8",
              categoryText: "选修",
              categoryValue: "0",
              cover: null,
              duration: null,
              name: "数字经济助力县域高质量发展",
              order: 0,
              period: 0,
              progress: 100,
              subId: "db94890900ea4c5088d41e8f95f7118b",
              timeEdate: null,
              timeSdate: null,
              typeText: "课程",
              typeValue: "course",
              unitId: "90fbd8b25a744508b8438a7191e7b1b6",
            },
          ],
          unitId: "90fbd8b25a744508b8438a7191e7b1b6",
          unitName: "2",
        },
      ],
      params: {
        id: "",
      },
      detailData: {},
      isview: false,
      issfax: "",
    };
  },
  // 1是 0否
  computed: {
    ...mapGetters(["getSpecialInfoGz"]),
  },
  watch: {
    getSpecialInfoGz: {
      handler(data) {
        if (!dsf.isEmptyObject(data)) {
          this.detailData = data;
          this.params.id = this.$route.query.id || this.queryString.id || data.classId || "";
          this.reData();
          this.issfax = data.zjsfax;
          console.log("是否按序表示", data.zjsfax);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.params.id = this.$route.query.id || this.queryString.id || data.classId || "";
  },
  mounted() {
    if (!this.isDesign) {
      this.lists = [];
      this.isview = this.queryString.isview == 1 ? true : false;
    }
    // this.reData();
  },
  methods: {
    reData() {
      this.list = [];
      this.initData();
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    initData() {
      let loading = dsf.layer.pc.loading();

      dsf.http
        .get(this.url, this.params, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            this.lists = res.data;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
  },
  components: {
    specialDetailCourseItem,
  },
});
</script>
