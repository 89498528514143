<template>
  <div class="dsf_nc_liveclassnext" v-if="visible">
    <div>
      <div class="content" :style="{ width: conWidth }" v-if="liveArray.length > 0">
        <div class="live-big-box">
          <div class="img-box pointer" @click="goDetail(liveObj)">
            <el-image :src="setImg(liveObj.cover || liveObj['nc_live_page_historyData.cover'])" fit="cover"></el-image>
            <div class="live-time" :style="`background-color: ${showBgColor(liveObj.state_text || liveObj['state_text'])}`">
              {{ setTime(liveObj) }}
            </div>
            <p class="time">
              {{ formatterDate(liveObj.time_sdate || liveObj["nc_live_page_historyData.time_sdate"]) }}~{{ formatterDate(liveObj.time_edate || liveObj["nc_live_page_historyData.time_edate"]) }}
            </p>
          </div>
          <div class="info">
            <p class="ell2 title">
              {{ liveObj.title || "暂无" }}
            </p>
            <div class="live-detail">
              <p class="detail-p ell">
                <span style="margin-right: 10px">{{ liveObj.speaker || "暂无演讲人" }}</span>
                <span>{{ liveObj.job || "暂无职位" }}</span>
              </p>
              <div class="num-box">
                <dsf-icon class="icon-yanjing"></dsf-icon>
                <span class="num">{{ liveObj.views || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-box" :style="{ width: `calc(100% - 620px)` }">
          <div class="live-item" v-if="liveList.length">
            <div v-for="(i, idx) in liveList" :key="idx" @click="goDetail(i)">
              <div v-if="idx < 3" class="item-wrap pointer">
                <div class="img-box">
                  <el-image :src="setImg(i.cover) || i['nc_live_page_historyData.cover']" fit="cover"></el-image>
                  <div class="seek-time" :style="`background-color: ${showBgColor(i.state_text || i['state_text'])}`">
                    {{ setTime(i) }}
                  </div>
                  <div class="live-time">
                    {{ formatterDate(i.time_sdate || i["nc_live_page_historyData.time_sdate"]) }}
                  </div>
                </div>
                <div class="info">
                  <p class="ell2">
                    {{ i.title || i["nc_live_page_historyData.title"] }}
                  </p>
                  <div class="detail">
                    <p class="name ell">
                      <span>{{ i.speaker || i["nc_live_page_historyData.speaker"] }}</span>
                      <span class="job">{{ i.job || i["nc_live_page_historyData.job"] }}</span>
                    </p>
                    <span class="num-box">
                      <dsf-icon class="icon-yanjing"></dsf-icon>
                      <span class="num">{{ i.views || 0 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noDate" v-if="liveList.length <= 0" :class="{ centerData: !liveList.length }">
            <img src="../../../assets/imgs/pcimg/live/img_nomore.png" alt="" />
            <p>没有更多近期直播了～</p>
          </div>
        </div>
      </div>
      <div class="noDate" v-else>
        <img src="../../../assets/imgs/pcimg/live/img_nomore.png" alt="" />
        <p>没有更多近期直播了～</p>
      </div>

      <!-- 更多 -->
      <!-- <div
        class="moreTitle"
        :style="{ width: conWidth }"
        v-if="isMore && liveList2.length"
      >
        <div class="border-box"></div>
        <div class="more">
          <img src="../../../assets/imgs/pcimg/live/more_left.png" alt="" />
          <span>更多</span>
          <img src="../../../assets/imgs/pcimg/live/more_right.png" alt="" />
        </div>
        <div class="border-box"></div>
      </div>
      <div
        class="live-item live-item2"
        :style="{ width: conWidth }"
        v-if="isMore && liveList2.length"
      >
        <div
          class="item-wrap pointer margin-top"
          v-for="(i, idx) in liveList2"
          :key="idx"
          @click="goDetail(i)"
        >
          <div class="img-box">
            <dsf-nc-image
              :src="i['cover'] | imgFormat"
              :error-src="defaultSrc"
            />
            <div
              class="seek-time"
              :style="`background-color: ${showBgColor(i['state_text'])}`"
            >
              {{ setTime(i) }}
            </div>
            <div class="live-time">{{ formatterDate(i.time_sdate) }}</div>
          </div>
          <div class="info">
            <p class="ell2">{{ i.title }}</p>
            <div class="detail">
              <p class="name ell">
                <span>{{ i.speaker || "" }}</span>
                <span class="job">{{ i.job || "" }}</span>
              </p>
              <span class="num-box">
                <dsf-icon class="icon-yanjing"></dsf-icon>
                <span class="num">{{ i.views || 0 }}</span>
              </span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { liveTitle } from "./index";
export default dsf.component({
  name: "DsfNcGzRecentLive", //gzrecentLive
  ctrlCaption: "近期直播",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "近期直播",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    conWidth: {
      type: String,
      default: "1200px",
    },
    // reqUrl: {
    //   type: String,
    //   default:
    //     "nc/Meta/tree/dbSource/id/?rmId=9e8395330cf947f1b317fabc6f592e48",
    // },
    detailUrl: {
      type: String,
      default: "/pc/nc/pagelive/liveDetail?id=",
    },
    detailUrlTxt: {
      type: String,
      default: "nc_live_info_id",
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      src: require("../../../assets/imgs/SC1.png"),
      liveObj: {},
      liveArray: [],
      liveList: [],
      liveList2: [],
    };
  },
  components: {
    // liveTitle,
  },
  mounted() {
    if (!this.isDesign) {
      this.getList();
      eventBus.$on("search2", data => {
        this.liveArray = data;
        this.liveObj = data[0];
        this.liveList = data.slice(1, 4);
        console.log(data);
      });
      eventBus.$on("reset2", data => {
        this.liveArray = data;
        this.liveObj = data[0];
        this.liveList = data.slice(1, 4);
      });
    }
  },
  methods: {
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      } else {
        src = "static/img/project/defaultCardImage.png";
      }
      return src;
    },
    showBgColor(text) {
      let c = "";
      switch (text) {
        case "直播中":
          c = "#34C47C";
          break;
        case "未开始":
          c = "#3C8DDB";
          break;
        case "已结束":
          c = "#999999";
          break;
        default:
          c = "#3C8DDB";
          break;
      }
      return c;
    },
    getList() {
      //      let that = this;
      // const mainUrl = "/nc/zbgl/list";
      // const params = {

      // };
      // that.dsf.http
      //   .get(mainUrl, params)
      //   .done(function (res) {
      //     if (res.success) {
      //       console.log(res.data, "type1");
      //       that.datas = res.data;
      //     } else {
      //       that.message({
      //         message: res.message || "获取列表数据异常",
      //         type: "warning",
      //       });
      //     }
      //   })
      //   .error(function (error) {
      //     that.dsf.layer.toast("服务器异常", false);
      //   })
      //   .always(function () {
      //     // that.dsf.layer.closeLoading(loader);
      //   });

      const mainUrl = "/nc/zbgl/list";
      const params = {
        pageNum: 1,
        pageSize: 4,
        query: JSON.stringify({
          searchValue: "",
          type: 1, //近期直播
          video: 1,
        }),
      };
      const loading = dsf.layer.pc.loading();
      this.$http
        .get(mainUrl, params)
        .done(res => {
          if (res.success) {
            this.liveObj = res.data[0] || {};
            this.liveArray = res.data;
            this.liveList = JSON.parse(JSON.stringify(res.data));
            if (this.liveList.length) {
              this.liveList.splice(0, 1);
            }
            this.liveList2 = JSON.parse(JSON.stringify(res.data));
            if (this.liveList2.length) {
              this.liveList2.splice(0, 4);
            }
          }
        })
        .error(function (err) {
          dsf.layer.pc.message(err.message, false);
        })
        .always(function () {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    goDetail(item) {
      dsf.layer.openWindow(this.detailUrl + item[this.detailUrlTxt]);
    },
    setTime(i) {
      return (i.tdoa || i["nc_live_page_historyData.tdoa"]) < 0 || (i.tdoa || i["nc_live_page_historyData.tdoa"]) == 0
        ? "直播中"
        : this.getDuration((i.tdoa || i["nc_live_page_historyData.tdoa"]) * 1000, "dd天hh小时mm分");
    },
    getDuration(my_time) {
      let days = my_time / 1000 / 60 / 60 / 24;
      let daysRound = Math.floor(days);
      let hours = my_time / 1000 / 60 / 60 - 24 * daysRound;
      let hoursRound = Math.floor(hours);
      let minutes = my_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      let minutesRound = Math.floor(minutes);
      let seconds = my_time / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound;
      // console.log("转换时间:", daysRound + "天", hoursRound + "时", minutesRound + "分", seconds + "秒");
      if (daysRound && daysRound < 10) {
        daysRound = "0" + daysRound;
      }
      if (hoursRound && hoursRound < 10) {
        hoursRound = "0" + hoursRound;
      }
      if (minutesRound && minutesRound < 10) {
        minutesRound = "0" + minutesRound;
      }
      let time = daysRound + "天" + hoursRound + "时" + minutesRound + "分";
      return time;
    },
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    getUrl(cover) {
      return cover ? dsf.url.getWebPath(JSON.parse(cover)[0].relativePath) : "";
    },
  },
});
</script>
<style lang="scss" scoped></style>
